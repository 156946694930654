/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CANCEL` - 취소
 * `DELIVERY` - 배달
 * `DISPOSAL` - 폐기
 * `LOADING` - 적재
 * `MOVE` - 이동
 * `RETURN` - 복귀
 * `ROTATE` - 순회
 */
export type SequenceTypeEnum = (typeof SequenceTypeEnum)[keyof typeof SequenceTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SequenceTypeEnum = {
  CANCEL: 'CANCEL',
  DELIVERY: 'DELIVERY',
  DISPOSAL: 'DISPOSAL',
  LOADING: 'LOADING',
  MOVE: 'MOVE',
  RETURN: 'RETURN',
  ROTATE: 'ROTATE'
} as const
