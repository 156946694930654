/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ELEVATOR_NOT_FOUND` - 엘리베이터를 찾을 수 없습니다.
 * `ELEVATOR_STATUS_CANNOT_OBTAIN` - 엘리베이터 상태 정보를 얻을 수 없습니다.
 */
export type ElevatorStatus404ExceptionCodeEnum =
  (typeof ElevatorStatus404ExceptionCodeEnum)[keyof typeof ElevatorStatus404ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElevatorStatus404ExceptionCodeEnum = {
  ELEVATOR_NOT_FOUND: 'ELEVATOR_NOT_FOUND',
  ELEVATOR_STATUS_CANNOT_OBTAIN: 'ELEVATOR_STATUS_CANNOT_OBTAIN'
} as const
