/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type ScenariosListScenarioStatusesItem =
  (typeof ScenariosListScenarioStatusesItem)[keyof typeof ScenariosListScenarioStatusesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenariosListScenarioStatusesItem = {
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CANCEL_IN_PROGRESS: 'CANCEL_IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  REQUEST: 'REQUEST'
} as const
