/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `MAX_MONITORING_ROBOT_COUNT_EXCEEDED` - 최대 모니터링 로봇 댓수를 초과했습니다.
 * `ROBOT_LIST_CONTAINS_NOT_MONITORABLE_ROBOTS` - 모니터링 할 수 없는 로봇이 포함되어 있습니다.
 * `UNCHANGED_MONITORING_ROBOT_LIST` - 변경된 모니터링 로봇이 없습니다.
 */
export type MonitoringUpdate400ExceptionCodeEnum =
  (typeof MonitoringUpdate400ExceptionCodeEnum)[keyof typeof MonitoringUpdate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringUpdate400ExceptionCodeEnum = {
  MAX_MONITORING_ROBOT_COUNT_EXCEEDED: 'MAX_MONITORING_ROBOT_COUNT_EXCEEDED',
  ROBOT_LIST_CONTAINS_NOT_MONITORABLE_ROBOTS: 'ROBOT_LIST_CONTAINS_NOT_MONITORABLE_ROBOTS',
  UNCHANGED_MONITORING_ROBOT_LIST: 'UNCHANGED_MONITORING_ROBOT_LIST'
} as const
