/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CUSTOM` - 커스텀
 * `DELIVERY` - 배달
 * `DELIVERY_ROTATE` - 순환 판매
 * `DISMISS` - 스테이션 이동
 * `KT_DELIVERY` - 배달(KT)
 * `KT_PATROL` - 순회(KT)
 * `LIT_PATROL` - 순회(LIT)
 * `NB_ORDER_DELIVERY` - 배달(뉴비 오더)
 * `NB_ORDER_DELIVERY_DOCUMENT` - 문서배달(뉴비 오더)
 * `OPENAPI_DELIVERY` - 배달(OpenAPI)
 * `PATROL` - 순회
 * `READY` - 대기장소 이동
 * `TEST` - 테스트
 */
export type CategoryEnum = (typeof CategoryEnum)[keyof typeof CategoryEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryEnum = {
  CUSTOM: 'CUSTOM',
  DELIVERY: 'DELIVERY',
  DELIVERY_ROTATE: 'DELIVERY_ROTATE',
  DISMISS: 'DISMISS',
  KT_DELIVERY: 'KT_DELIVERY',
  KT_PATROL: 'KT_PATROL',
  LIT_PATROL: 'LIT_PATROL',
  NB_ORDER_DELIVERY: 'NB_ORDER_DELIVERY',
  NB_ORDER_DELIVERY_DOCUMENT: 'NB_ORDER_DELIVERY_DOCUMENT',
  OPENAPI_DELIVERY: 'OPENAPI_DELIVERY',
  PATROL: 'PATROL',
  READY: 'READY',
  TEST: 'TEST'
} as const
