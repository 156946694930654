import { IconNamesEnum } from '@/constants/iconNames.enum'

export const IconMap: {
  [key in IconNamesEnum]: () => Promise<typeof import('*.svg')>
} = {
  [IconNamesEnum.Apple]: () => import(`@/icons/apple.svg`),
  [IconNamesEnum.ArrowDown]: () => import(`@/icons/arrow-down.svg`),
  [IconNamesEnum.ArrowLeft]: () => import(`@/icons/arrow-left.svg`),
  [IconNamesEnum.ArrowRight]: () => import(`@/icons/arrow-right.svg`),
  [IconNamesEnum.ArrowUp]: () => import(`@/icons/arrow-up.svg`),
  [IconNamesEnum.Ban]: () => import(`@/icons/ban.svg`),
  [IconNamesEnum.Basket]: () => import(`@/icons/basket.svg`),
  [IconNamesEnum.BatteryFair]: () => import(`@/icons/battery-fair.svg`),
  [IconNamesEnum.BellAlt]: () => import(`@/icons/bell-alt.svg`),
  [IconNamesEnum.CalendarPen]: () => import(`@/icons/calendar-pen.svg`),
  [IconNamesEnum.Calendar]: () => import(`@/icons/calendar.svg`),
  [IconNamesEnum.Camera]: () => import(`@/icons/camera.svg`),
  [IconNamesEnum.Card]: () => import(`@/icons/card.svg`),
  [IconNamesEnum.CargoOpen]: () => import(`@/icons/cargo-open.svg`),
  [IconNamesEnum.ChartGantt]: () => import(`@/icons/chart-gantt.svg`),
  [IconNamesEnum.Check]: () => import(`@/icons/check.svg`),
  [IconNamesEnum.CheckboxChecked]: () => import(`@/icons/checkbox-checked.svg`),
  [IconNamesEnum.CheckboxUnchecked]: () => import(`@/icons/checkbox-unchecked.svg`),
  [IconNamesEnum.ChevronDown]: () => import(`@/icons/chevron-down.svg`),
  [IconNamesEnum.ChevronLeftDouble]: () => import(`@/icons/chevron-left-double.svg`),
  [IconNamesEnum.ChevronLeft]: () => import(`@/icons/chevron-left.svg`),
  [IconNamesEnum.ChevronRightDouble]: () => import(`@/icons/chevron-right-double.svg`),
  [IconNamesEnum.ChevronRight]: () => import(`@/icons/chevron-right.svg`),
  [IconNamesEnum.ChevronUp]: () => import(`@/icons/chevron-up.svg`),
  [IconNamesEnum.CircleQuestion]: () => import(`@/icons/circle-question.svg`),
  [IconNamesEnum.Circuit]: () => import(`@/icons/circuit.svg`),
  [IconNamesEnum.ClipboardList]: () => import(`@/icons/clipboard-list.svg`),
  [IconNamesEnum.Cloche]: () => import(`@/icons/cloche.svg`),
  [IconNamesEnum.Clock]: () => import(`@/icons/clock.svg`),
  [IconNamesEnum.CloseSmall]: () => import(`@/icons/close-small.svg`),
  [IconNamesEnum.Close]: () => import(`@/icons/close.svg`),
  [IconNamesEnum.CloudUpload]: () => import(`@/icons/cloud-upload.svg`),
  [IconNamesEnum.Coins]: () => import(`@/icons/coins.svg`),
  [IconNamesEnum.Combine]: () => import(`@/icons/combine.svg`),
  [IconNamesEnum.Crescent]: () => import(`@/icons/crescent.svg`),
  [IconNamesEnum.Crosshair]: () => import(`@/icons/crosshair.svg`),
  [IconNamesEnum.Cursoralt]: () => import(`@/icons/cursoralt.svg`),
  [IconNamesEnum.Cursoraltnodeadd]: () => import(`@/icons/cursoraltnodeadd.svg`),
  [IconNamesEnum.Cursorhandalt]: () => import(`@/icons/cursorhandalt.svg`),
  [IconNamesEnum.Cursorpennode]: () => import(`@/icons/cursorpennode.svg`),
  [IconNamesEnum.DashboardCustomize]: () => import(`@/icons/dashboard-customize.svg`),
  [IconNamesEnum.Delete]: () => import(`@/icons/delete.svg`),
  [IconNamesEnum.DoorOpen]: () => import(`@/icons/door-open.svg`),
  [IconNamesEnum.Download]: () => import(`@/icons/download.svg`),
  [IconNamesEnum.EyeSlash]: () => import(`@/icons/eye-slash.svg`),
  [IconNamesEnum.Eye]: () => import(`@/icons/eye.svg`),
  [IconNamesEnum.FileList]: () => import(`@/icons/file-list.svg`),
  [IconNamesEnum.Fix]: () => import(`@/icons/fix.svg`),
  [IconNamesEnum.Flipbackward]: () => import(`@/icons/flipbackward.svg`),
  [IconNamesEnum.Flipforward]: () => import(`@/icons/flipforward.svg`),
  [IconNamesEnum.Gamingpad]: () => import(`@/icons/gamingpad.svg`),
  [IconNamesEnum.Gear]: () => import(`@/icons/gear.svg`),
  [IconNamesEnum.GoogleMapDisabled]: () => import(`@/icons/google-map-disabled.svg`),
  [IconNamesEnum.Handle]: () => import(`@/icons/handle.svg`),
  [IconNamesEnum.HomeFilledFalse]: () => import(`@/icons/home-filled-false.svg`),
  [IconNamesEnum.HomeFilledTrue]: () => import(`@/icons/home-filled-true.svg`),
  [IconNamesEnum.IconBoltWired]: () => import(`@/icons/icon-bolt-wired.svg`),
  [IconNamesEnum.IconBoltWireless]: () => import(`@/icons/icon-bolt-wireless.svg`),
  [IconNamesEnum.Information]: () => import(`@/icons/information.svg`),
  [IconNamesEnum.Kakao]: () => import(`@/icons/kakao.svg`),
  [IconNamesEnum.LabelFalseSizeSStateDisabledCheckSelected]: () =>
    import(`@/icons/label-false-size-s-state-disabled-check-selected.svg`),
  [IconNamesEnum.LabelFalseSizeSStateDisabledCheckUnselected]: () =>
    import(`@/icons/label-false-size-s-state-disabled-check-unselected.svg`),
  [IconNamesEnum.LabelFalseSizeSStateEnabledCheckSelected]: () =>
    import(`@/icons/label-false-size-s-state-enabled-check-selected.svg`),
  [IconNamesEnum.LabelFalseSizeSStateEnabledCheckUnselected]: () =>
    import(`@/icons/label-false-size-s-state-enabled-check-unselected.svg`),
  [IconNamesEnum.LabelFalseSizeSStateHoverCheckSelected]: () =>
    import(`@/icons/label-false-size-s-state-hover-check-selected.svg`),
  [IconNamesEnum.LabelFalseSizeSStateHoverCheckUnselected]: () =>
    import(`@/icons/label-false-size-s-state-hover-check-unselected.svg`),
  [IconNamesEnum.LabelFalseSizeSStatePressedCheckSelected]: () =>
    import(`@/icons/label-false-size-s-state-pressed-check-selected.svg`),
  [IconNamesEnum.LabelFalseSizeSStatePressedCheckUnselected]: () =>
    import(`@/icons/label-false-size-s-state-pressed-check-unselected.svg`),
  [IconNamesEnum.Layer]: () => import(`@/icons/layer.svg`),
  [IconNamesEnum.LightEmergency]: () => import(`@/icons/light-emergency.svg`),
  [IconNamesEnum.LocationArrowFilledFalse]: () => import(`@/icons/location-arrow-filled-false.svg`),
  [IconNamesEnum.LocationArrowFilledTrue]: () => import(`@/icons/location-arrow-filled-true.svg`),
  [IconNamesEnum.LocationArrowSlant]: () => import(`@/icons/location-arrow-slant.svg`),
  [IconNamesEnum.LocationCrosshairs]: () => import(`@/icons/location-crosshairs.svg`),
  [IconNamesEnum.LocationFilledFalse]: () => import(`@/icons/location-filled-false.svg`),
  [IconNamesEnum.LocationFilledTrue]: () => import(`@/icons/location-filled-true.svg`),
  [IconNamesEnum.LocationPinFair]: () => import(`@/icons/location-pin-fair.svg`),
  [IconNamesEnum.LocationPin]: () => import(`@/icons/location-pin.svg`),
  [IconNamesEnum.Loop]: () => import(`@/icons/loop.svg`),
  [IconNamesEnum.MapLocationPin]: () => import(`@/icons/map-location-pin.svg`),
  [IconNamesEnum.MapPinSlash]: () => import(`@/icons/map-pin-slash.svg`),
  [IconNamesEnum.MapPin]: () => import(`@/icons/map-pin.svg`),
  [IconNamesEnum.Maximize]: () => import(`@/icons/maximize.svg`),
  [IconNamesEnum.Microphone]: () => import(`@/icons/microphone.svg`),
  [IconNamesEnum.Minimize]: () => import(`@/icons/minimize.svg`),
  [IconNamesEnum.Minus]: () => import(`@/icons/minus.svg`),
  [IconNamesEnum.Monitor]: () => import(`@/icons/monitor.svg`),
  [IconNamesEnum.Move]: () => import(`@/icons/move.svg`),
  [IconNamesEnum.Music]: () => import(`@/icons/music.svg`),
  [IconNamesEnum.NaverMapDisabled]: () => import(`@/icons/naver-map-disabled.svg`),
  [IconNamesEnum.NaverMap]: () => import(`@/icons/naver-map.svg`),
  [IconNamesEnum.NeubilityLogo]: () => import(`@/icons/neubility-logo.svg`),
  [IconNamesEnum.Nodeadd]: () => import(`@/icons/nodeadd.svg`),
  [IconNamesEnum.OrderListFilledFalse]: () => import(`@/icons/order-list-filled-false.svg`),
  [IconNamesEnum.OrderListFilledTrue]: () => import(`@/icons/order-list-filled-true.svg`),
  [IconNamesEnum.Pen]: () => import(`@/icons/pen.svg`),
  [IconNamesEnum.Pensquare]: () => import(`@/icons/pensquare.svg`),
  [IconNamesEnum.Phone]: () => import(`@/icons/phone.svg`),
  [IconNamesEnum.Play]: () => import(`@/icons/play.svg`),
  [IconNamesEnum.Plus]: () => import(`@/icons/plus.svg`),
  [IconNamesEnum.QrScan]: () => import(`@/icons/qr-scan.svg`),
  [IconNamesEnum.RadioChecked]: () => import(`@/icons/radio-checked.svg`),
  [IconNamesEnum.RadioFalseHoverFalsePressedFalseDisabledFalse]: () =>
    import(`@/icons/radio-false-hover-false-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RadioFalseHoverFalsePressedFalseDisabledTrue]: () =>
    import(`@/icons/radio-false-hover-false-pressed-false-disabled-true.svg`),
  [IconNamesEnum.RadioFalseHoverFalsePressedTrueDisabledFalse]: () =>
    import(`@/icons/radio-false-hover-false-pressed-true-disabled-false.svg`),
  [IconNamesEnum.RadioFalseHoverTruePressedFalseDisabledFalse]: () =>
    import(`@/icons/radio-false-hover-true-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RadioTrueHoverFalsePressedFalseDisabledFalse]: () =>
    import(`@/icons/radio-true-hover-false-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RadioTrueHoverFalsePressedFalseDisabledTrue]: () =>
    import(`@/icons/radio-true-hover-false-pressed-false-disabled-true.svg`),
  [IconNamesEnum.RadioTrueHoverFalsePressedTrueDisabledFalse]: () =>
    import(`@/icons/radio-true-hover-false-pressed-true-disabled-false.svg`),
  [IconNamesEnum.RadioTrueHoverTruePressedFalseDisabledFalse]: () =>
    import(`@/icons/radio-true-hover-true-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RadioUnchecked]: () => import(`@/icons/radio-unchecked.svg`),
  [IconNamesEnum.RefreshAll]: () => import(`@/icons/refresh-all.svg`),
  [IconNamesEnum.Refresh]: () => import(`@/icons/refresh.svg`),
  [IconNamesEnum.RoundCheckFalseHoverFalsePressedFalseDisabledFalse]: () =>
    import(`@/icons/round-check-false-hover-false-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RoundCheckFalseHoverFalsePressedFalseDisabledTrue]: () =>
    import(`@/icons/round-check-false-hover-false-pressed-false-disabled-true.svg`),
  [IconNamesEnum.RoundCheckFalseHoverFalsePressedTrueDisabledFalse]: () =>
    import(`@/icons/round-check-false-hover-false-pressed-true-disabled-false.svg`),
  [IconNamesEnum.RoundCheckFalseHoverTruePressedFalseDisabledFalse]: () =>
    import(`@/icons/round-check-false-hover-true-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RoundCheckTrueHoverFalsePressedFalseDisabledFalse]: () =>
    import(`@/icons/round-check-true-hover-false-pressed-false-disabled-false.svg`),
  [IconNamesEnum.RoundCheckTrueHoverFalsePressedFalseDisabledTrue]: () =>
    import(`@/icons/round-check-true-hover-false-pressed-false-disabled-true.svg`),
  [IconNamesEnum.RoundCheckTrueHoverFalsePressedTrueDisabledFalse]: () =>
    import(`@/icons/round-check-true-hover-false-pressed-true-disabled-false.svg`),
  [IconNamesEnum.RoundCheckTrueHoverTruePressedFalseDisabledFalse]: () =>
    import(`@/icons/round-check-true-hover-true-pressed-false-disabled-false.svg`),
  [IconNamesEnum.Route]: () => import(`@/icons/route.svg`),
  [IconNamesEnum.Scissors]: () => import(`@/icons/scissors.svg`),
  [IconNamesEnum.ScreenUser]: () => import(`@/icons/screen-user.svg`),
  [IconNamesEnum.Search]: () => import(`@/icons/search.svg`),
  [IconNamesEnum.SignalFair]: () => import(`@/icons/signal-fair.svg`),
  [IconNamesEnum.SignalGood]: () => import(`@/icons/signal-good.svg`),
  [IconNamesEnum.SignalPoor]: () => import(`@/icons/signal-poor.svg`),
  [IconNamesEnum.SignalWeak]: () => import(`@/icons/signal-weak.svg`),
  [IconNamesEnum.SmileFilledFalse]: () => import(`@/icons/smile-filled-false.svg`),
  [IconNamesEnum.SmileFilledTrue]: () => import(`@/icons/smile-filled-true.svg`),
  [IconNamesEnum.Stop]: () => import(`@/icons/stop.svg`),
  [IconNamesEnum.Sun]: () => import(`@/icons/sun.svg`),
  [IconNamesEnum.SwitchOff]: () => import(`@/icons/switch-off.svg`),
  [IconNamesEnum.SwitchOn]: () => import(`@/icons/switch-on.svg`),
  [IconNamesEnum.TowerBroadcastSlash]: () => import(`@/icons/tower-broadcast-slash.svg`),
  [IconNamesEnum.Trash]: () => import(`@/icons/trash.svg`),
  [IconNamesEnum.TriangleExclamation]: () => import(`@/icons/triangle-exclamation.svg`),
  [IconNamesEnum.Union]: () => import(`@/icons/union.svg`),
  [IconNamesEnum.Upload]: () => import(`@/icons/upload.svg`),
  [IconNamesEnum.UserAlt]: () => import(`@/icons/user-alt.svg`),
  [IconNamesEnum.VideoSlash]: () => import(`@/icons/video-slash.svg`),
  [IconNamesEnum.VolumeMax]: () => import(`@/icons/volume-max.svg`),
  [IconNamesEnum.Wifi]: () => import(`@/icons/wifi.svg`)
}
