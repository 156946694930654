/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type SoundsListOrderByItem = (typeof SoundsListOrderByItem)[keyof typeof SoundsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SoundsListOrderByItem = {
  '-id': '-id',
  '-name': '-name',
  id: 'id',
  name: 'name'
} as const
