/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `graphsync` - graph sync. 이를 수행할 지도의 아이디 전송
 * `mscancel` - 특정 미션에 대한 취소 수행, 취소할 미션 아이디 전달
 * `openhatch` - 로봇의 해치(뚜껑) 오픈 제어 요청, 해당 주문 건에 대한 아이디 전달
 * `release` - 주행 모듈 제어권 복원, 최신 Task 요청 처리 하거나, 기존 Task 이어서 수행
 * `stop` - 주행 모듈 제어권 해제, 로봇 무조건 중지
 */
export type ControlCodeEnum = (typeof ControlCodeEnum)[keyof typeof ControlCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlCodeEnum = {
  graphsync: 'graphsync',
  mscancel: 'mscancel',
  openhatch: 'openhatch',
  release: 'release',
  stop: 'stop'
} as const
