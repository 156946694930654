/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type MonitoringInterveneStatusEnum =
  (typeof MonitoringInterveneStatusEnum)[keyof typeof MonitoringInterveneStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringInterveneStatusEnum = {
  COMPLETED: 'COMPLETED',
  PREOCCUPIED: 'PREOCCUPIED',
  PROCEEDING: 'PROCEEDING',
  READY: 'READY',
  WAITING: 'WAITING'
} as const
