/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `DEV` - 개발
 * `PROD` - 상용
 * `STAGING` - 스테이징
 */
export type EnvironmentEnum = (typeof EnvironmentEnum)[keyof typeof EnvironmentEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvironmentEnum = {
  DEV: 'DEV',
  PROD: 'PROD',
  STAGING: 'STAGING'
} as const
