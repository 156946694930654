import { UrlObject } from 'url'
import { TFunction } from 'i18next'

export const RouteKeys = {
  Index: '/',
  Manager: 'manager',
  Robot: 'robot',
  Site: 'site',
  Node: 'node',
  Login: 'login',
  Robots: 'robots',
  RobotId: 'robot-id',
  Notification: 'notification',
  User: 'user',
  TaskStatus: 'task-status',
  TaskList: 'task-list',
  Monitoring: 'monitoring',
  Multiple: 'multiple',
  Remote: 'remote',
  Id: 'id',
  Target: 'target',
  Sound: 'sound',
  MobilePath: 'mobile-path',
  SelectSiteRobot: 'select-site-robot',
  Generation: 'generation',
  Select: 'select',
  Watching: 'watching',
  Driving: 'driving',
  Patrol: 'patrol',
  kakaoAlimTalk: 'kakao-alim-talk',
  releasedSuccess: 'released-success',
  verifyPinFail: 'verify-pin-fail',
  verifyPin: 'verify-pin',
  video: 'video',
  '404': '404',
  MobileMiniMap: 'mobile-mini-map'
}

export const Routes = {
  Home: '/',
  Robot: `/${RouteKeys.Robot}`,
  Site: `/${RouteKeys.Site}`,
  Node: {
    Target: `/${RouteKeys.Node}/[${RouteKeys.Target}]`
  },
  User: `/${RouteKeys.User}`,
  TaskStatus: `/${RouteKeys.TaskStatus}`,
  TaskList: `/${RouteKeys.TaskList}`,
  Monitoring: {
    Target: `/${RouteKeys.Monitoring}/[${RouteKeys.Target}]`,
    MobileMiniMap: {
      Target: `/${RouteKeys.Monitoring}/[${RouteKeys.Target}]/${RouteKeys.MobileMiniMap}/[${RouteKeys.RobotId}]`
    }
  },
  Remote: {
    Robot: {
      Id: `/${RouteKeys.Remote}/${RouteKeys.Robot}/[${RouteKeys.Id}]`
    },
    Multiple: {
      Index: `/${RouteKeys.Remote}/${RouteKeys.Multiple}`,
      Monitoring: `/${RouteKeys.Remote}/${RouteKeys.Multiple}/${RouteKeys.Monitoring}`,
      Driving: {
        Index: `/${RouteKeys.Remote}/${RouteKeys.Multiple}/${RouteKeys.Driving}`,
        Id: `/${RouteKeys.Remote}/${RouteKeys.Multiple}/${RouteKeys.Driving}/[${RouteKeys.Id}]`
      }
    }
  },
  Login: `/${RouteKeys.Login}`,
  Notification: `/${RouteKeys.Notification}`,
  Sound: `/${RouteKeys.Sound}`,
  MobilePath: {
    Index: `/${RouteKeys.MobilePath}`,
    Generation: `/${RouteKeys.MobilePath}/${RouteKeys.Generation}`
  },
  Patrol: {
    Index: `/${RouteKeys.Patrol}`,
    Remote: `/${RouteKeys.Patrol}/${RouteKeys.Remote}`,
    Video: `/${RouteKeys.Patrol}/${RouteKeys.video}`
  },
  kakaoAlimTalk: {
    releasedSuccess: `/${RouteKeys.kakaoAlimTalk}/${RouteKeys.releasedSuccess}`,
    verifyPinFail: `/${RouteKeys.kakaoAlimTalk}/${RouteKeys.verifyPinFail}`,
    verifyPin: `/${RouteKeys.kakaoAlimTalk}/${RouteKeys.verifyPin}`
  },
  '404': `/${RouteKeys['404']}`
}

export const QueryKeys = {
  Target: RouteKeys.Target,
  RemoteCustomAllocationRobotId: 'remote-custom-allocation-robot-id',
  ModalOpen: 'modal-open',
  InterveneSolvedRobotName: 'interveneSolvedRobotName',
  SiteId: 'site-id',
  RobotId: 'robot-id',
  RobotIds: 'robot-ids',
  GeneratePathCompleted: 'generate-path-completed',
  ProcessingMapId: 'processing-map-id',
  SiteGlobalPathId: 'site-global-path-id',
  RemoteRobotId: RouteKeys.Id,
  LogoutFlag: 'logout-flag',
  RefreshExpired: 'refresh-expired',
  userId: 'userId',
  robotName: 'robotName',
  nodeName: 'nodeName',
  notificationCode: 'notificationCode',
  detectValue: 'detectValue',
  scenarioId: 'scenarioId',
  alarmKey: 'alarmKey',
  alarmId: 'alarmId',
  videoUrl: 'video-url'
} as const

export const QueryValues = {
  True: 'true'
}

export const getRouteToPageTitle = (t: TFunction) => ({
  [Routes.Home]: t('common:side-drawer.home'),
  [Routes.Robot]: t('common:side-drawer.robot'),
  [Routes.Site]: t('common:side-drawer.site'),
  [Routes.Node.Target]: t('common:side-drawer.node'),
  [Routes.User]: t('common:side-drawer.user'),
  [Routes.Login]: t('common:side-drawer.login'),
  [Routes.Notification]: t('common:side-drawer.notification'),
  [Routes.Monitoring.Target]: t('common:side-drawer.monitoring'),
  [Routes.Sound]: t('common:side-drawer.sound'),
  [Routes.MobilePath.Index]: t('common:side-drawer.mobile-path'),
  [Routes.MobilePath.Generation]: t('common:side-drawer.mobile-path'),
  [Routes.Remote.Multiple.Monitoring]: t('common:side-drawer.multi-monitoring'),
  [Routes.Remote.Multiple.Index]: t('common:side-drawer.monitorer'),
  [Routes.Remote.Multiple.Driving.Index]: t('common:side-drawer.driver')
})

export type HrefType = string | UrlObject
