/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ROBOT_INVALID_STATION_COORDINATE_SITE` - 충전 장소 사이트가 로봇의 사이트와 다릅니다.
 * `ROBOT_INVALID_BASE_COORDINATE_SITE` - 대기 장소 사이트가 로봇의 사이트와 다릅니다.
 * `ROBOT_INVALID_SERVICE` - 서비스가 로봇에서 지원하지 않는 서비스입니다.
 */
export type RobotUpdate400ExceptionCodeEnum =
  (typeof RobotUpdate400ExceptionCodeEnum)[keyof typeof RobotUpdate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RobotUpdate400ExceptionCodeEnum = {
  ROBOT_INVALID_STATION_COORDINATE_SITE: 'ROBOT_INVALID_STATION_COORDINATE_SITE',
  ROBOT_INVALID_BASE_COORDINATE_SITE: 'ROBOT_INVALID_BASE_COORDINATE_SITE',
  ROBOT_INVALID_SERVICE: 'ROBOT_INVALID_SERVICE'
} as const
