/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type RobotsListOrderByItem = (typeof RobotsListOrderByItem)[keyof typeof RobotsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RobotsListOrderByItem = {
  '-company': '-company',
  '-created_at': '-created_at',
  '-id': '-id',
  '-is_available': '-is_available',
  '-is_connecting': '-is_connecting',
  '-is_monitoring': '-is_monitoring',
  '-is_open': '-is_open',
  '-name': '-name',
  '-nickname': '-nickname',
  '-serial_number': '-serial_number',
  '-service': '-service',
  '-site': '-site',
  company: 'company',
  created_at: 'created_at',
  id: 'id',
  is_available: 'is_available',
  is_connecting: 'is_connecting',
  is_monitoring: 'is_monitoring',
  is_open: 'is_open',
  name: 'name',
  nickname: 'nickname',
  serial_number: 'serial_number',
  service: 'service',
  site: 'site'
} as const
