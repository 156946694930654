/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type MonitoringsIntervenesListOrderByItem =
  (typeof MonitoringsIntervenesListOrderByItem)[keyof typeof MonitoringsIntervenesListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringsIntervenesListOrderByItem = {
  '-created_at': '-created_at',
  '-id': '-id',
  '-intervened_at': '-intervened_at',
  '-monitoring_status': '-monitoring_status',
  '-proceeded_at': '-proceeded_at',
  '-resolution_type': '-resolution_type',
  '-resolved_at': '-resolved_at',
  created_at: 'created_at',
  id: 'id',
  intervened_at: 'intervened_at',
  monitoring_status: 'monitoring_status',
  proceeded_at: 'proceeded_at',
  resolution_type: 'resolution_type',
  resolved_at: 'resolved_at'
} as const
