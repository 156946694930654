/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `fleet` - 협력주행모듈
 * `padmin` - 플랫폼 Admin
 */
export type OwnerEnum = (typeof OwnerEnum)[keyof typeof OwnerEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OwnerEnum = {
  fleet: 'fleet',
  padmin: 'padmin'
} as const
