/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `DRIVING_USER_NOT_EXISTS` - 관제개입한 유저가 없습니다.
 * `DRIVING_USER_NOT_IDENTICAL` - 관제개입한 유저가 아닙니다.
 */
export type MonitoringInterveneComplete400ExceptionCodeEnum =
  (typeof MonitoringInterveneComplete400ExceptionCodeEnum)[keyof typeof MonitoringInterveneComplete400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringInterveneComplete400ExceptionCodeEnum = {
  DRIVING_USER_NOT_EXISTS: 'DRIVING_USER_NOT_EXISTS',
  DRIVING_USER_NOT_IDENTICAL: 'DRIVING_USER_NOT_IDENTICAL'
} as const
