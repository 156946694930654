/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type ScenariosPatrolsDetectionsListOrderByItem =
  (typeof ScenariosPatrolsDetectionsListOrderByItem)[keyof typeof ScenariosPatrolsDetectionsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenariosPatrolsDetectionsListOrderByItem = {
  '-created_at': '-created_at',
  '-ended_at': '-ended_at',
  '-id': '-id',
  '-scenario_status': '-scenario_status',
  '-service': '-service',
  '-started_at': '-started_at',
  created_at: 'created_at',
  ended_at: 'ended_at',
  id: 'id',
  scenario_status: 'scenario_status',
  service: 'service',
  started_at: 'started_at'
} as const
