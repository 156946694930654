/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `GAS` - 가스 센서
 * `ORA` - 객체 센서
 * `THERMAL_HIGH` - 화제 감지 센서
 * `THERMAL_LOW` - 열 감지 센서
 */
export type SensorEnum = (typeof SensorEnum)[keyof typeof SensorEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SensorEnum = {
  GAS: 'GAS',
  ORA: 'ORA',
  THERMAL_HIGH: 'THERMAL_HIGH',
  THERMAL_LOW: 'THERMAL_LOW'
} as const
