/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `a3` - Apriltag 3x3
 * `ch5` - Charuco 5x5
 */
export type CalibrationBoardTypeEnum = (typeof CalibrationBoardTypeEnum)[keyof typeof CalibrationBoardTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalibrationBoardTypeEnum = {
  a3: 'a3',
  ch5: 'ch5'
} as const
