export enum TimeZoneEnum {
  BROWSER_TIME = 'BROWSER_TIME',
  UTC = 'UTC',
  AS_KABUL = 'Asia/Kabul',
  EUROPE_TIRANE = 'Europe/Tirane',
  AF_ALGIERS = 'Africa/Algiers',
  PA_PAGO_PAGO = 'Pacific/Pago_Pago',
  EU_ANDORRA = 'Europe/Andorra',
  AF_LUANDA = 'Africa/Luanda',
  AM_ANGUILLA = 'America/Anguilla',
  AN_CASEY = 'Antarctica/Casey',
  AN_DAVIS = 'Antarctica/Davis',
  AN_DUMONTDURVILLE = 'Antarctica/DumontDUrville',
  AN_MAWSON = 'Antarctica/Mawson',
  AN_MCMURDO = 'Antarctica/McMurdo',
  AN_PALMER = 'Antarctica/Palmer',
  AN_ROTHERA = 'Antarctica/Rothera',
  AN_SYOWA = 'Antarctica/Syowa',
  AN_TROLL = 'Antarctica/Troll',
  AN_VOSTOK = 'Antarctica/Vostok',
  AM_ANTIGUA = 'America/Antigua',
  AM_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
  AM_ARGENTINA_CATAMARCA = 'America/Argentina/Catamarca',
  AM_ARGENTINA_CORDOBA = 'America/Argentina/Cordoba',
  AM_ARGENTINA_JUJUY = 'America/Argentina/Jujuy',
  AM_ARGENTINA_LA_RIOJA = 'America/Argentina/La_Rioja',
  AM_ARGENTINA_MENDOZA = 'America/Argentina/Mendoza',
  AM_ARGENTINA_RIO_GALLEGOS = 'America/Argentina/Rio_Gallegos',
  AM_ARGENTINA_SALTA = 'America/Argentina/Salta',
  AM_ARGENTINA_SAN_JUAN = 'America/Argentina/San_Juan',
  AM_ARGENTINA_SAN_LUIS = 'America/Argentina/San_Luis',
  AM_ARGENTINA_TUCUMAN = 'America/Argentina/Tucuman',
  AM_ARGENTINA_USHUAIA = 'America/Argentina/Ushuaia',
  AS_YEREVAN = 'Asia/Yerevan',
  AM_ARUBA = 'America/Aruba',
  AN_MACQUARIE = 'Antarctica/Macquarie',
  AU_ADELAIDE = 'Australia/Adelaide',
  AU_BRISBANE = 'Australia/Brisbane',
  AU_BROKEN_HILL = 'Australia/Broken_Hill',
  AU_DARWIN = 'Australia/Darwin',
  AU_EUCLA = 'Australia/Eucla',
  AU_HOBART = 'Australia/Hobart',
  AU_LINDEMAN = 'Australia/Lindeman',
  AU_LORD_HOWE = 'Australia/Lord_Howe',
  AU_MELBOURNE = 'Australia/Melbourne',
  AU_PERTH = 'Australia/Perth',
  AU_SYDNEY = 'Australia/Sydney',
  EU_VIENNA = 'Europe/Vienna',
  AS_BAKU = 'Asia/Baku',
  AM_NASSAU = 'America/Nassau',
  AS_BAHRAIN = 'Asia/Bahrain',
  AS_DHAKA = 'Asia/Dhaka',
  AM_BARBADOS = 'America/Barbados',
  EU_MINSK = 'Europe/Minsk',
  EU_BRUSSELS = 'Europe/Brussels',
  AM_BELIZE = 'America/Belize',
  AF_PORTO_NOVO = 'Africa/Porto-Novo',
  AT_BERMUDA = 'Atlantic/Bermuda',
  AS_THIMPHU = 'Asia/Thimphu',
  AM_LA_PAZ = 'America/La_Paz',
  AM_KRALENDIJK = 'America/Kralendijk',
  EU_SARAJEVO = 'Europe/Sarajevo',
  AF_GABORONE = 'Africa/Gaborone',
  AM_ARAGUAINA = 'America/Araguaina',
  AM_BAHIA = 'America/Bahia',
  AM_BELEM = 'America/Belem',
  AM_BOA_VISTA = 'America/Boa_Vista',
  AM_CAMPO_GRANDE = 'America/Campo_Grande',
  AM_CUIABA = 'America/Cuiaba',
  AM_EIRUNEPE = 'America/Eirunepe',
  AM_FORTALEZA = 'America/Fortaleza',
  AM_MACEIO = 'America/Maceio',
  AM_MANAUS = 'America/Manaus',
  AM_NORONHA = 'America/Noronha',
  AM_PORTO_VELHO = 'America/Porto_Velho',
  AM_RECIFE = 'America/Recife',
  AM_RIO_BRANCO = 'America/Rio_Branco',
  AM_SANTAREM = 'America/Santarem',
  AM_SAO_PAULO = 'America/Sao_Paulo',
  ID_CHAGOS = 'Indian/Chagos',
  AS_BRUNEI = 'Asia/Brunei',
  EU_SOFIA = 'Europe/Sofia',
  AF_OUAGADOUGOU = 'Africa/Ouagadougou',
  AF_BUJUMBURA = 'Africa/Bujumbura',
  AS_PHNOM_PENH = 'Asia/Phnom_Penh',
  AF_DOUALA = 'Africa/Douala',
  AM_ATIKOKAN = 'America/Atikokan',
  AM_BLANC_SABLON = 'America/Blanc-Sablon',
  AM_CAMBRIDGE_BAY = 'America/Cambridge_Bay',
  AM_CRESTON = 'America/Creston',
  AM_DAWSON = 'America/Dawson',
  AM_DAWSON_CREEK = 'America/Dawson_Creek',
  AM_EDMONTON = 'America/Edmonton',
  AM_FORT_NELSON = 'America/Fort_Nelson',
  AM_GLACE_BAY = 'America/Glace_Bay',
  AM_GOOSE_BAY = 'America/Goose_Bay',
  AM_HALIFAX = 'America/Halifax',
  AM_INUVIK = 'America/Inuvik',
  AM_IQALUIT = 'America/Iqaluit',
  AM_MONCTON = 'America/Moncton',
  AM_RANKIN_INLET = 'America/Rankin_Inlet',
  AM_REGINA = 'America/Regina',
  AM_RESOLUTE = 'America/Resolute',
  AM_ST_JOHNS = 'America/St_Johns',
  AM_SWIFT_CURRENT = 'America/Swift_Current',
  AM_TORONTO = 'America/Toronto',
  AM_VANCOUVER = 'America/Vancouver',
  AM_WHITEHORSE = 'America/Whitehorse',
  AM_WINNIPEG = 'America/Winnipeg',
  AT_CAPE_VERDE = 'Atlantic/Cape_Verde',
  AM_CAYMAN = 'America/Cayman',
  AF_BANGUI = 'Africa/Bangui',
  AF_NDJAMENA = 'Africa/Ndjamena',
  AM_PUNTA_ARENAS = 'America/Punta_Arenas',
  AM_SANTIAGO = 'America/Santiago',
  PA_EASTER = 'Pacific/Easter',
  AS_SHANGHAI = 'Asia/Shanghai',
  AS_URUMQI = 'Asia/Urumqi',
  ID_CHRISTMAS = 'Indian/Christmas',
  ID_COCOS = 'Indian/Cocos',
  AM_BOGOTA = 'America/Bogota',
  ID_COMORO = 'Indian/Comoro',
  AF_BRAZZAVILLE = 'Africa/Brazzaville',
  AF_KINSHASA = 'Africa/Kinshasa',
  AF_LUBUMBASHI = 'Africa/Lubumbashi',
  PA_RAROTONGA = 'Pacific/Rarotonga',
  AM_COSTA_RICA = 'America/Costa_Rica',
  EU_ZAGREB = 'Europe/Zagreb',
  AM_HAVANA = 'America/Havana',
  AM_CURACAO = 'America/Curacao',
  AS_FAMAGUSTA = 'Asia/Famagusta',
  AS_NICOSIA = 'Asia/Nicosia',
  EU_PRAGUE = 'Europe/Prague',
  AF_ABJIDJAN = 'Africa/Abidjan',
  EU_COPENHAGEN = 'Europe/Copenhagen',
  AF_DJIBOUTI = 'Africa/Djibouti',
  AM_DOMINICA = 'America/Dominica',
  AM_SANTO_DOMINGO = 'America/Santo_Domingo',
  AM_GUAYAQUIL = 'America/Guayaquil',
  PA_GALAPAGOS = 'Pacific/Galapagos',
  AF_CAIRO = 'Africa/Cairo',
  AM_EL_SALVADOR = 'America/El_Salvador',
  AF_MALABO = 'Africa/Malabo',
  AF_ASMARA = 'Africa/Asmara',
  EU_TALLINN = 'Europe/Tallinn',
  AF_ADDIS_ABABA = 'Africa/Addis_Ababa',
  AT_STANLEY = 'Atlantic/Stanley',
  AT_FAROE = 'Atlantic/Faroe',
  PA_FIJI = 'Pacific/Fiji',
  EU_HELSINKI = 'Europe/Helsinki',
  EU_PARIS = 'Europe/Paris',
  AM_CAYENNE = 'America/Cayenne',
  PA_GAMBIER = 'Pacific/Gambier',
  PA_MARQUESAS = 'Pacific/Marquesas',
  PA_TAHITI = 'Pacific/Tahiti',
  ID_KERGUELEN = 'Indian/Kerguelen',
  AF_LIBREVILLE = 'Africa/Libreville',
  AF_BANJUL = 'Africa/Banjul',
  AS_TBILISI = 'Asia/Tbilisi',
  EU_BERLIN = 'Europe/Berlin',
  EU_BUSINGEN = 'Europe/Busingen',
  AF_ACCRA = 'Africa/Accra',
  EU_GIBRALTAR = 'Europe/Gibraltar',
  EU_ATHENS = 'Europe/Athens',
  AM_DANMARKSHAVN = 'America/Danmarkshavn',
  AM_NUUK = 'America/Nuuk',
  AM_SCORESBYSUND = 'America/Scoresbysund',
  AM_THULE = 'America/Thule',
  AM_GRENADA = 'America/Grenada',
  AM_GUADELOUPE = 'America/Guadeloupe',
  PA_GUAM = 'Pacific/Guam',
  AM_GUATEMALA = 'America/Guatemala',
  EU_GUERNSEY = 'Europe/Guernsey',
  AF_CONAKRY = 'Africa/Conakry',
  AF_BISSAU = 'Africa/Bissau',
  AM_GUYANA = 'America/Guyana',
  AM_PORT_AU_PRINCE = 'America/Port-au-Prince',
  EU_VATICAN = 'Europe/Vatican',
  AM_TEGUCIGALPA = 'America/Tegucigalpa',
  AS_HONG_KONG = 'Asia/Hong_Kong',
  EU_BUDAPEST = 'Europe/Budapest',
  AT_REYKJAVIK = 'Atlantic/Reykjavik',
  AS_KOLKATA = 'Asia/Kolkata',
  AS_JAKARTA = 'Asia/Jakarta',
  AS_JAYAPURA = 'Asia/Jayapura',
  AS_MAKASSAR = 'Asia/Makassar',
  AS_PONTIANAK = 'Asia/Pontianak',
  AS_TEHRAN = 'Asia/Tehran',
  AS_BAGHDAD = 'Asia/Baghdad',
  EU_DUBLIN = 'Europe/Dublin',
  EU_ISLE_OF_MAN = 'Europe/Isle_of_Man',
  AS_JERUSALEM = 'Asia/Jerusalem',
  EU_ROME = 'Europe/Rome',
  AM_JAMAICA = 'America/Jamaica',
  AS_TOKYO = 'Asia/Tokyo',
  EU_JERSEY = 'Europe/Jersey',
  AS_AMMAN = 'Asia/Amman',
  AS_ALMATY = 'Asia/Almaty',
  AS_AQTAU = 'Asia/Aqtau',
  AS_AQTOBE = 'Asia/Aqtobe',
  AS_ATYRAU = 'Asia/Atyrau',
  AS_ORAL = 'Asia/Oral',
  AS_QOSTANAY = 'Asia/Qostanay',
  AS_QYZYLORDA = 'Asia/Qyzylorda',
  AF_NAIROBI = 'Africa/Nairobi',
  PA_KANTON = 'Pacific/Kanton',
  PA_KIRITIMATI = 'Pacific/Kiritimati',
  PA_TARAWA = 'Pacific/Tarawa',
  AS_PYONGYANG = 'Asia/Pyongyang',
  AS_SEOUL = 'Asia/Seoul',
  AS_KUWAIT = 'Asia/Kuwait',
  AS_BISHKEK = 'Asia/Bishkek',
  AS_VIENTIANE = 'Asia/Vientiane',
  EU_RIGA = 'Europe/Riga',
  AS_BEIRUT = 'Asia/Beirut',
  AF_MASERU = 'Africa/Maseru',
  AF_MONROVIA = 'Africa/Monrovia',
  AF_TRIPOLI = 'Africa/Tripoli',
  EU_VADUZ = 'Europe/Vaduz',
  EU_VILNIUS = 'Europe/Vilnius',
  EU_LUXEMBOURG = 'Europe/Luxembourg',
  AS_MACAU = 'Asia/Macau',
  EU_SKOPJE = 'Europe/Skopje',
  ID_ANTANANARIVO = 'Indian/Antananarivo',
  AF_BLANTYRE = 'Africa/Blantyre',
  AS_KUALA_LUMPUR = 'Asia/Kuala_Lumpur',
  AS_KUCHING = 'Asia/Kuching',
  ID_MALDIVES = 'Indian/Maldives',
  AF_BAMAKO = 'Africa/Bamako',
  EU_MALTA = 'Europe/Malta',
  PA_KWAJALEIN = 'Pacific/Kwajalein',
  PA_MAJURO = 'Pacific/Majuro',
  AM_MARTINIQUE = 'America/Martinique',
  AF_NOUAKCHOTT = 'Africa/Nouakchott',
  ID_MAURITIUS = 'Indian/Mauritius',
  ID_MAYOTTE = 'Indian/Mayotte',
  AM_BAHIA_BANDERAS = 'America/Bahia_Banderas',
  AM_CANCUN = 'America/Cancun',
  AM_CHIHUAHUA = 'America/Chihuahua',
  AM_CIUDAD_JUAREZ = 'America/Ciudad_Juarez',
  AM_HERMOSILLO = 'America/Hermosillo',
  AM_MATAMOROS = 'America/Matamoros',
  AM_MAZATLAN = 'America/Mazatlan',
  AM_MERIDA = 'America/Merida',
  AM_MEXICO_CITY = 'America/Mexico_City',
  AM_MONTERREY = 'America/Monterrey',
  AM_OJINAGA = 'America/Ojinaga',
  AM_TIJUANA = 'America/Tijuana',
  PA_CHUUK = 'Pacific/Chuuk',
  PA_KOSRAE = 'Pacific/Kosrae',
  PA_POHNPEI = 'Pacific/Pohnpei',
  EU_CHISINAU = 'Europe/Chisinau',
  EU_MONACO = 'Europe/Monaco',
  AS_CHOIBALSAN = 'Asia/Choibalsan',
  AS_HOVD = 'Asia/Hovd',
  AS_ULAANBAATAR = 'Asia/Ulaanbaatar',
  EU_PODGORICA = 'Europe/Podgorica',
  AM_MONTSERRAT = 'America/Montserrat',
  AF_CASABLANCA = 'Africa/Casablanca',
  AF_EL_AAIUN = 'Africa/El_Aaiun',
  AF_MAPUTO = 'Africa/Maputo',
  AS_YANGON = 'Asia/Yangon',
  AF_WINDHOEK = 'Africa/Windhoek',
  PA_NAURU = 'Pacific/Nauru',
  AS_KATHMANDU = 'Asia/Kathmandu',
  EU_AMSTERDAM = 'Europe/Amsterdam',
  PA_NOUMEA = 'Pacific/Noumea',
  PA_AUCKLAND = 'Pacific/Auckland',
  PA_CHATHAM = 'Pacific/Chatham',
  AM_MANAGUA = 'America/Managua',
  AF_NIAMEY = 'Africa/Niamey',
  AF_LAGOS = 'Africa/Lagos',
  PA_NIUE = 'Pacific/Niue',
  PA_NORFOLK = 'Pacific/Norfolk',
  PA_SAIPAN = 'Pacific/Saipan',
  EU_OSLO = 'Europe/Oslo',
  AS_MUSCAT = 'Asia/Muscat',
  AS_KARACHI = 'Asia/Karachi',
  PA_PALAU = 'Pacific/Palau',
  AS_GAZA = 'Asia/Gaza',
  AS_HEBRON = 'Asia/Hebron',
  AM_PANAMA = 'America/Panama',
  PA_BOUGAINVILLE = 'Pacific/Bougainville',
  PA_PORT_MORESBY = 'Pacific/Port_Moresby',
  AM_ASUNCION = 'America/Asuncion',
  AM_LIMA = 'America/Lima',
  AS_MANILA = 'Asia/Manila',
  PA_PITCAIRN = 'Pacific/Pitcairn',
  EU_WARSAW = 'Europe/Warsaw',
  AT_AZORES = 'Atlantic/Azores',
  AT_MADEIRA = 'Atlantic/Madeira',
  EU_LISBON = 'Europe/Lisbon',
  AM_PUERTO_RICO = 'America/Puerto_Rico',
  AS_QATAR = 'Asia/Qatar',
  EU_BUCHAREST = 'Europe/Bucharest',
  AS_ANADYR = 'Asia/Anadyr',
  AS_BARNAUL = 'Asia/Barnaul',
  AS_CHITA = 'Asia/Chita',
  AS_IRKUTSK = 'Asia/Irkutsk',
  AS_KAMCHATKA = 'Asia/Kamchatka',
  AS_KHANDYGA = 'Asia/Khandyga',
  AS_KRASNOYARSK = 'Asia/Krasnoyarsk',
  AS_MAGADAN = 'Asia/Magadan',
  AS_NOVOKUZNETSK = 'Asia/Novokuznetsk',
  AS_NOVOSIBIRSK = 'Asia/Novosibirsk',
  AS_OMSK = 'Asia/Omsk',
  AS_SAKHALIN = 'Asia/Sakhalin',
  AS_SREDNEKOLYMSK = 'Asia/Srednekolymsk',
  AS_TOMSK = 'Asia/Tomsk',
  AS_UST_NERA = 'Asia/Ust-Nera',
  AS_VLADIVOSTOK = 'Asia/Vladivostok',
  AS_YAKUTSK = 'Asia/Yakutsk',
  AS_YEKATERINBURG = 'Asia/Yekaterinburg',
  EU_ASTRAKHAN = 'Europe/Astrakhan',
  EU_KALININGRAD = 'Europe/Kaliningrad',
  EU_KIROV = 'Europe/Kirov',
  EU_MOSCOW = 'Europe/Moscow',
  EU_SAMARA = 'Europe/Samara',
  EU_SARATOV = 'Europe/Saratov',
  EU_ULYANOVSK = 'Europe/Ulyanovsk',
  EU_VOLGOGRAD = 'Europe/Volgograd',
  AF_KIGALI = 'Africa/Kigali',
  ID_REUNION = 'Indian/Reunion',
  AM_ST_BARTHELEMY = 'America/St_Barthelemy',
  AT_ST_HELENA = 'Atlantic/St_Helena',
  AM_ST_KITTS = 'America/St_Kitts',
  AM_ST_LUCIA = 'America/St_Lucia',
  AM_MARIGOT = 'America/Marigot',
  AM_MIQUELON = 'America/Miquelon',
  AM_ST_VINCENT = 'America/St_Vincent',
  PA_APIA = 'Pacific/Apia',
  EU_SAN_MARINO = 'Europe/San_Marino',
  AF_SAO_TOME = 'Africa/Sao_Tome',
  AS_RIYADH = 'Asia/Riyadh',
  AF_DAKAR = 'Africa/Dakar',
  EU_BELGRADE = 'Europe/Belgrade',
  ID_MAHE = 'Indian/Mahe',
  AF_FREETOWN = 'Africa/Freetown',
  AS_SINGAPORE = 'Asia/Singapore',
  AM_LOWER_PRINCES = 'America/Lower_Princes',
  EU_BRATISLAVA = 'Europe/Bratislava',
  EU_LJUBLJANA = 'Europe/Ljubljana',
  PA_GUADALCANAL = 'Pacific/Guadalcanal',
  AF_MOGADISHU = 'Africa/Mogadishu',
  AF_JOHANNESBURG = 'Africa/Johannesburg',
  AT_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
  AF_JUBA = 'Africa/Juba',
  AF_CEUTA = 'Africa/Ceuta',
  AT_CANARY = 'Atlantic/Canary',
  EU_MADRID = 'Europe/Madrid',
  AS_COLOMBO = 'Asia/Colombo',
  AF_KHARTOUM = 'Africa/Khartoum',
  AM_PARAMARIBO = 'America/Paramaribo',
  AR_LONGYEARBYEN = 'Arctic/Longyearbyen',
  AF_MBABANE = 'Africa/Mbabane',
  EU_STOCKHOLM = 'Europe/Stockholm',
  EU_ZURICH = 'Europe/Zurich',
  AS_DAMASCUS = 'Asia/Damascus',
  AS_TAIPEI = 'Asia/Taipei',
  AS_DUSHANBE = 'Asia/Dushanbe',
  AF_DAR_ES_SALAAM = 'Africa/Dar_es_Salaam',
  AS_BANGKOK = 'Asia/Bangkok',
  AS_DILI = 'Asia/Dili',
  AF_LOME = 'Africa/Lome',
  PA_FAKAOFO = 'Pacific/Fakaofo',
  PA_TONGATAPU = 'Pacific/Tongatapu',
  AM_PORT_OF_SPAIN = 'America/Port_of_Spain',
  AF_TUNIS = 'Africa/Tunis',
  EU_ISTANBUL = 'Europe/Istanbul',
  AS_ASHGABAT = 'Asia/Ashgabat',
  AM_GRAND_TURK = 'America/Grand_Turk',
  PA_FUNAFUTI = 'Pacific/Funafuti',
  AF_KAMPALA = 'Africa/Kampala',
  EU_KYIV = 'Europe/Kyiv',
  EU_SIMFEROPOL = 'Europe/Simferopol',
  AS_DUBAI = 'Asia/Dubai',
  EU_LONDON = 'Europe/London',
  AM_ADAK = 'America/Adak',
  AM_ANCHORAGE = 'America/Anchorage',
  AM_BOISE = 'America/Boise',
  AM_CHICAGO = 'America/Chicago',
  AM_DENVER = 'America/Denver',
  AM_DETROIT = 'America/Detroit',
  AM_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
  AM_INDIANA_KNOX = 'America/Indiana/Knox',
  AM_INDIANA_MARENGO = 'America/Indiana/Marengo',
  AM_INDIANA_PETERSBURG = 'America/Indiana/Petersburg',
  AM_INDIANA_TELL_CITY = 'America/Indiana/Tell_City',
  AM_INDIANA_VEVAY = 'America/Indiana/Vevay',
  AM_INDIANA_VINCENNES = 'America/Indiana/Vincennes',
  AM_INDIANA_WINAMAC = 'America/Indiana/Winamac',
  AM_JUNEAU = 'America/Juneau',
  AM_KENTUCKY_LOUISVILLE = 'America/Kentucky/Louisville',
  AM_KENTUCKY_MONTICELLO = 'America/Kentucky/Monticello',
  AM_LOS_ANGELES = 'America/Los_Angeles',
  AM_MENOMINEE = 'America/Menominee',
  AM_METLAKATLA = 'America/Metlakatla',
  AM_NEW_YORK = 'America/New_York',
  AM_NOME = 'America/Nome',
  AM_NORTH_DAKOTA_BEULAH = 'America/North_Dakota/Beulah',
  AM_NORTH_DAKOTA_CENTER = 'America/North_Dakota/Center',
  AM_NORTH_DAKOTA_NEW_SALEM = 'America/North_Dakota/New_Salem',
  AM_PHOENIX = 'America/Phoenix',
  AM_SITKA = 'America/Sitka',
  AM_YAKUTAT = 'America/Yakutat',
  PA_HONOLULU = 'Pacific/Honolulu',
  PA_MIDWAY = 'Pacific/Midway',
  PA_WAKE = 'Pacific/Wake',
  AM_MONTEVIDEO = 'America/Montevideo',
  AS_SAMARKAND = 'Asia/Samarkand',
  AS_TASHKENT = 'Asia/Tashkent',
  PA_EFATE = 'Pacific/Efate',
  AM_CARACAS = 'America/Caracas',
  AS_HO_CHI_MINH = 'Asia/Ho_Chi_Minh',
  AM_TORTOLA = 'America/Tortola',
  AM_ST_THOMAS = 'America/St_Thomas',
  PA_WALLIS = 'Pacific/Wallis',
  AS_ADEN = 'Asia/Aden',
  AF_LUSAKA = 'Africa/Lusaka',
  AF_HARARE = 'Africa/Harare',
  EU_MARIEHAMN = 'Europe/Mariehamn'
}

export type TimeZoneInfoType = {
  timeZone: TimeZoneEnum
  countryCode: string
  countryName: string
  offset: string
}

export const TimeZoneInfoMap = new Map<TimeZoneEnum, TimeZoneInfoType>([
  [
    TimeZoneEnum.BROWSER_TIME,
    {
      timeZone: TimeZoneEnum.BROWSER_TIME,
      countryCode: 'BROWSER_TIME',
      countryName: 'Browser Time',
      offset: ''
    }
  ],
  [
    TimeZoneEnum.UTC,
    { timeZone: TimeZoneEnum.UTC, countryCode: 'UT', countryName: 'Coordinated Universal Time', offset: '' }
  ],
  [
    TimeZoneEnum.AS_KABUL,
    { timeZone: TimeZoneEnum.AS_KABUL, countryCode: 'AF', countryName: 'Afghanistan', offset: '+04:30' }
  ],
  [
    TimeZoneEnum.EUROPE_TIRANE,
    { timeZone: TimeZoneEnum.EUROPE_TIRANE, countryCode: 'AL', countryName: 'Albania', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_ALGIERS,
    { timeZone: TimeZoneEnum.AF_ALGIERS, countryCode: 'DZ', countryName: 'Algeria', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.PA_PAGO_PAGO,
    { timeZone: TimeZoneEnum.PA_PAGO_PAGO, countryCode: 'AS', countryName: 'American Samoa', offset: '-11:00' }
  ],
  [
    TimeZoneEnum.EU_ANDORRA,
    { timeZone: TimeZoneEnum.EU_ANDORRA, countryCode: 'AD', countryName: 'Andorra', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_LUANDA,
    { timeZone: TimeZoneEnum.AF_LUANDA, countryCode: 'AO', countryName: 'Angola', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_ANGUILLA,
    { timeZone: TimeZoneEnum.AM_ANGUILLA, countryCode: 'AI', countryName: 'Anguilla', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AN_CASEY,
    { timeZone: TimeZoneEnum.AN_CASEY, countryCode: 'AQ', countryName: 'Antarctica', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AN_DAVIS,
    { timeZone: TimeZoneEnum.AN_DAVIS, countryCode: 'AQ', countryName: 'Antarctica', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AN_DUMONTDURVILLE,
    { timeZone: TimeZoneEnum.AN_DUMONTDURVILLE, countryCode: 'AQ', countryName: 'Antarctica', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.AN_MAWSON,
    { timeZone: TimeZoneEnum.AN_MAWSON, countryCode: 'AQ', countryName: 'Antarctica', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AN_MCMURDO,
    { timeZone: TimeZoneEnum.AN_MCMURDO, countryCode: 'AQ', countryName: 'Antarctica', offset: '+13:00' }
  ],
  [
    TimeZoneEnum.AN_PALMER,
    { timeZone: TimeZoneEnum.AN_PALMER, countryCode: 'AQ', countryName: 'Antarctica', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AN_ROTHERA,
    { timeZone: TimeZoneEnum.AN_ROTHERA, countryCode: 'AQ', countryName: 'Antarctica', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AN_SYOWA,
    { timeZone: TimeZoneEnum.AN_SYOWA, countryCode: 'AQ', countryName: 'Antarctica', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AN_TROLL,
    { timeZone: TimeZoneEnum.AN_TROLL, countryCode: 'AQ', countryName: 'Antarctica', offset: '' }
  ],
  [
    TimeZoneEnum.AN_VOSTOK,
    { timeZone: TimeZoneEnum.AN_VOSTOK, countryCode: 'AQ', countryName: 'Antarctica', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AM_ANTIGUA,
    { timeZone: TimeZoneEnum.AM_ANTIGUA, countryCode: 'AG', countryName: 'Antigua and Barbuda', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_BUENOS_AIRES,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_BUENOS_AIRES, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_CATAMARCA,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_CATAMARCA, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_CORDOBA,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_CORDOBA, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_JUJUY,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_JUJUY, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_LA_RIOJA,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_LA_RIOJA, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_MENDOZA,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_MENDOZA, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_RIO_GALLEGOS,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_RIO_GALLEGOS, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_SALTA,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_SALTA, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_SAN_JUAN,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_SAN_JUAN, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_SAN_LUIS,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_SAN_LUIS, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_TUCUMAN,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_TUCUMAN, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_ARGENTINA_USHUAIA,
    { timeZone: TimeZoneEnum.AM_ARGENTINA_USHUAIA, countryCode: 'AR', countryName: 'Argentina', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AS_YEREVAN,
    { timeZone: TimeZoneEnum.AS_YEREVAN, countryCode: 'AM', countryName: 'Armenia', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.AM_ARUBA,
    { timeZone: TimeZoneEnum.AM_ARUBA, countryCode: 'AW', countryName: 'Aruba', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AN_MACQUARIE,
    { timeZone: TimeZoneEnum.AN_MACQUARIE, countryCode: 'AU', countryName: 'Australia', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AU_ADELAIDE,
    { timeZone: TimeZoneEnum.AU_ADELAIDE, countryCode: 'AU', countryName: 'Australia', offset: '+10:30' }
  ],
  [
    TimeZoneEnum.AU_BRISBANE,
    { timeZone: TimeZoneEnum.AU_BRISBANE, countryCode: 'AU', countryName: 'Australia', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.AU_BROKEN_HILL,
    { timeZone: TimeZoneEnum.AU_BROKEN_HILL, countryCode: 'AU', countryName: 'Australia', offset: '+10:30' }
  ],
  [
    TimeZoneEnum.AU_DARWIN,
    { timeZone: TimeZoneEnum.AU_DARWIN, countryCode: 'AU', countryName: 'Australia', offset: '+09:30' }
  ],
  [
    TimeZoneEnum.AU_EUCLA,
    { timeZone: TimeZoneEnum.AU_EUCLA, countryCode: 'AU', countryName: 'Australia', offset: '+08:45' }
  ],
  [
    TimeZoneEnum.AU_HOBART,
    { timeZone: TimeZoneEnum.AU_HOBART, countryCode: 'AU', countryName: 'Australia', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AU_LINDEMAN,
    { timeZone: TimeZoneEnum.AU_LINDEMAN, countryCode: 'AU', countryName: 'Australia', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.AU_LORD_HOWE,
    { timeZone: TimeZoneEnum.AU_LORD_HOWE, countryCode: 'AU', countryName: 'Australia', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AU_MELBOURNE,
    { timeZone: TimeZoneEnum.AU_MELBOURNE, countryCode: 'AU', countryName: 'Australia', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AU_PERTH,
    { timeZone: TimeZoneEnum.AU_PERTH, countryCode: 'AU', countryName: 'Australia', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AU_SYDNEY,
    { timeZone: TimeZoneEnum.AU_SYDNEY, countryCode: 'AU', countryName: 'Australia', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.EU_VIENNA,
    { timeZone: TimeZoneEnum.EU_VIENNA, countryCode: 'AT', countryName: 'Austria', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AS_BAKU,
    { timeZone: TimeZoneEnum.AS_BAKU, countryCode: 'AZ', countryName: 'Azerbaijan', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.AM_NASSAU,
    { timeZone: TimeZoneEnum.AM_NASSAU, countryCode: 'BS', countryName: 'Bahamas', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AS_BAHRAIN,
    { timeZone: TimeZoneEnum.AS_BAHRAIN, countryCode: 'BH', countryName: 'Bahrain', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AS_DHAKA,
    { timeZone: TimeZoneEnum.AS_DHAKA, countryCode: 'BD', countryName: 'Bangladesh', offset: '+06:00' }
  ],
  [
    TimeZoneEnum.AM_BARBADOS,
    { timeZone: TimeZoneEnum.AM_BARBADOS, countryCode: 'BB', countryName: 'Barbados', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.EU_MINSK,
    { timeZone: TimeZoneEnum.EU_MINSK, countryCode: 'BY', countryName: 'Belarus', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.EU_BRUSSELS,
    { timeZone: TimeZoneEnum.EU_BRUSSELS, countryCode: 'BE', countryName: 'Belgium', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_BELIZE,
    { timeZone: TimeZoneEnum.AM_BELIZE, countryCode: 'BZ', countryName: 'Belize', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AF_PORTO_NOVO,
    { timeZone: TimeZoneEnum.AF_PORTO_NOVO, countryCode: 'BJ', countryName: 'Benin', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AT_BERMUDA,
    { timeZone: TimeZoneEnum.AT_BERMUDA, countryCode: 'BM', countryName: 'Bermuda', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AS_THIMPHU,
    { timeZone: TimeZoneEnum.AS_THIMPHU, countryCode: 'BT', countryName: 'Bhutan', offset: '+06:00' }
  ],
  [
    TimeZoneEnum.AM_LA_PAZ,
    {
      timeZone: TimeZoneEnum.AM_LA_PAZ,
      countryCode: 'BO',
      countryName: 'Bolivia, Plurinational State of',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.AM_KRALENDIJK,
    {
      timeZone: TimeZoneEnum.AM_KRALENDIJK,
      countryCode: 'BQ',
      countryName: 'Bonaire, Sint Eustatius and Saba',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.EU_SARAJEVO,
    { timeZone: TimeZoneEnum.EU_SARAJEVO, countryCode: 'BA', countryName: 'Bosnia and Herzegovina', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_GABORONE,
    { timeZone: TimeZoneEnum.AF_GABORONE, countryCode: 'BW', countryName: 'Botswana', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AM_ARAGUAINA,
    { timeZone: TimeZoneEnum.AM_ARAGUAINA, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_BAHIA,
    { timeZone: TimeZoneEnum.AM_BAHIA, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_BELEM,
    { timeZone: TimeZoneEnum.AM_BELEM, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_BOA_VISTA,
    { timeZone: TimeZoneEnum.AM_BOA_VISTA, countryCode: 'BR', countryName: 'Brazil', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_CAMPO_GRANDE,
    { timeZone: TimeZoneEnum.AM_CAMPO_GRANDE, countryCode: 'BR', countryName: 'Brazil', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_CUIABA,
    { timeZone: TimeZoneEnum.AM_CUIABA, countryCode: 'BR', countryName: 'Brazil', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_EIRUNEPE,
    { timeZone: TimeZoneEnum.AM_EIRUNEPE, countryCode: 'BR', countryName: 'Brazil', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_FORTALEZA,
    { timeZone: TimeZoneEnum.AM_FORTALEZA, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_MACEIO,
    { timeZone: TimeZoneEnum.AM_MACEIO, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_MANAUS,
    { timeZone: TimeZoneEnum.AM_MANAUS, countryCode: 'BR', countryName: 'Brazil', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_NORONHA,
    { timeZone: TimeZoneEnum.AM_NORONHA, countryCode: 'BR', countryName: 'Brazil', offset: '-02:00' }
  ],
  [
    TimeZoneEnum.AM_PORTO_VELHO,
    { timeZone: TimeZoneEnum.AM_PORTO_VELHO, countryCode: 'BR', countryName: 'Brazil', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_RECIFE,
    { timeZone: TimeZoneEnum.AM_RECIFE, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_RIO_BRANCO,
    { timeZone: TimeZoneEnum.AM_RIO_BRANCO, countryCode: 'BR', countryName: 'Brazil', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_SANTAREM,
    { timeZone: TimeZoneEnum.AM_SANTAREM, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_SAO_PAULO,
    { timeZone: TimeZoneEnum.AM_SAO_PAULO, countryCode: 'BR', countryName: 'Brazil', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.ID_CHAGOS,
    {
      timeZone: TimeZoneEnum.ID_CHAGOS,
      countryCode: 'IO',
      countryName: 'British Indian Ocean Territory',
      offset: '+06:00'
    }
  ],
  [
    TimeZoneEnum.AS_BRUNEI,
    { timeZone: TimeZoneEnum.AS_BRUNEI, countryCode: 'BN', countryName: 'Brunei Darussalam', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.EU_SOFIA,
    { timeZone: TimeZoneEnum.EU_SOFIA, countryCode: 'BG', countryName: 'Bulgaria', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AF_OUAGADOUGOU,
    { timeZone: TimeZoneEnum.AF_OUAGADOUGOU, countryCode: 'BF', countryName: 'Burkina Faso', offset: '' }
  ],
  [
    TimeZoneEnum.AF_BUJUMBURA,
    { timeZone: TimeZoneEnum.AF_BUJUMBURA, countryCode: 'BI', countryName: 'Burundi', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_PHNOM_PENH,
    { timeZone: TimeZoneEnum.AS_PHNOM_PENH, countryCode: 'KH', countryName: 'Cambodia', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AF_DOUALA,
    { timeZone: TimeZoneEnum.AF_DOUALA, countryCode: 'CM', countryName: 'Cameroon', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_ATIKOKAN,
    { timeZone: TimeZoneEnum.AM_ATIKOKAN, countryCode: 'CA', countryName: 'Canada', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_BLANC_SABLON,
    { timeZone: TimeZoneEnum.AM_BLANC_SABLON, countryCode: 'CA', countryName: 'Canada', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_CAMBRIDGE_BAY,
    { timeZone: TimeZoneEnum.AM_CAMBRIDGE_BAY, countryCode: 'CA', countryName: 'Canada', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_CRESTON,
    { timeZone: TimeZoneEnum.AM_CRESTON, countryCode: 'CA', countryName: 'Canada', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_DAWSON,
    { timeZone: TimeZoneEnum.AM_DAWSON, countryCode: 'CA', countryName: 'Canada', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_DAWSON_CREEK,
    { timeZone: TimeZoneEnum.AM_DAWSON_CREEK, countryCode: 'CA', countryName: 'Canada', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_EDMONTON,
    { timeZone: TimeZoneEnum.AM_EDMONTON, countryCode: 'CA', countryName: 'Canada', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_FORT_NELSON,
    { timeZone: TimeZoneEnum.AM_FORT_NELSON, countryCode: 'CA', countryName: 'Canada', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_GLACE_BAY,
    { timeZone: TimeZoneEnum.AM_GLACE_BAY, countryCode: 'CA', countryName: 'Canada', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_GOOSE_BAY,
    { timeZone: TimeZoneEnum.AM_GOOSE_BAY, countryCode: 'CA', countryName: 'Canada', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_HALIFAX,
    { timeZone: TimeZoneEnum.AM_HALIFAX, countryCode: 'CA', countryName: 'Canada', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_INUVIK,
    { timeZone: TimeZoneEnum.AM_INUVIK, countryCode: 'CA', countryName: 'Canada', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_IQALUIT,
    { timeZone: TimeZoneEnum.AM_IQALUIT, countryCode: 'CA', countryName: 'Canada', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_MONCTON,
    { timeZone: TimeZoneEnum.AM_MONCTON, countryCode: 'CA', countryName: 'Canada', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_RANKIN_INLET,
    { timeZone: TimeZoneEnum.AM_RANKIN_INLET, countryCode: 'CA', countryName: 'Canada', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_REGINA,
    { timeZone: TimeZoneEnum.AM_REGINA, countryCode: 'CA', countryName: 'Canada', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_RESOLUTE,
    { timeZone: TimeZoneEnum.AM_RESOLUTE, countryCode: 'CA', countryName: 'Canada', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_ST_JOHNS,
    { timeZone: TimeZoneEnum.AM_ST_JOHNS, countryCode: 'CA', countryName: 'Canada', offset: '-02:30' }
  ],
  [
    TimeZoneEnum.AM_SWIFT_CURRENT,
    { timeZone: TimeZoneEnum.AM_SWIFT_CURRENT, countryCode: 'CA', countryName: 'Canada', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_TORONTO,
    { timeZone: TimeZoneEnum.AM_TORONTO, countryCode: 'CA', countryName: 'Canada', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_VANCOUVER,
    { timeZone: TimeZoneEnum.AM_VANCOUVER, countryCode: 'CA', countryName: 'Canada', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_WHITEHORSE,
    { timeZone: TimeZoneEnum.AM_WHITEHORSE, countryCode: 'CA', countryName: 'Canada', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_WINNIPEG,
    { timeZone: TimeZoneEnum.AM_WINNIPEG, countryCode: 'CA', countryName: 'Canada', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AT_CAPE_VERDE,
    { timeZone: TimeZoneEnum.AT_CAPE_VERDE, countryCode: 'CV', countryName: 'Cape Verde', offset: '-01:00' }
  ],
  [
    TimeZoneEnum.AM_CAYMAN,
    { timeZone: TimeZoneEnum.AM_CAYMAN, countryCode: 'KY', countryName: 'Cayman Islands', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AF_BANGUI,
    { timeZone: TimeZoneEnum.AF_BANGUI, countryCode: 'CF', countryName: 'Central African Republic', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_NDJAMENA,
    { timeZone: TimeZoneEnum.AF_NDJAMENA, countryCode: 'TD', countryName: 'Chad', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_PUNTA_ARENAS,
    { timeZone: TimeZoneEnum.AM_PUNTA_ARENAS, countryCode: 'CL', countryName: 'Chile', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_SANTIAGO,
    { timeZone: TimeZoneEnum.AM_SANTIAGO, countryCode: 'CL', countryName: 'Chile', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.PA_EASTER,
    { timeZone: TimeZoneEnum.PA_EASTER, countryCode: 'CL', countryName: 'Chile', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AS_SHANGHAI,
    { timeZone: TimeZoneEnum.AS_SHANGHAI, countryCode: 'CN', countryName: 'China', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AS_URUMQI,
    { timeZone: TimeZoneEnum.AS_URUMQI, countryCode: 'CN', countryName: 'China', offset: '+06:00' }
  ],
  [
    TimeZoneEnum.ID_CHRISTMAS,
    { timeZone: TimeZoneEnum.ID_CHRISTMAS, countryCode: 'CX', countryName: 'Christmas Island', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.ID_COCOS,
    { timeZone: TimeZoneEnum.ID_COCOS, countryCode: 'CC', countryName: 'Cocos (Keeling) Islands', offset: '+06:30' }
  ],
  [
    TimeZoneEnum.AM_BOGOTA,
    { timeZone: TimeZoneEnum.AM_BOGOTA, countryCode: 'CO', countryName: 'Colombia', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.ID_COMORO,
    { timeZone: TimeZoneEnum.ID_COMORO, countryCode: 'KM', countryName: 'Comoros', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AF_BRAZZAVILLE,
    { timeZone: TimeZoneEnum.AF_BRAZZAVILLE, countryCode: 'CG', countryName: 'Congo', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_KINSHASA,
    {
      timeZone: TimeZoneEnum.AF_KINSHASA,
      countryCode: 'CD',
      countryName: 'Congo, the Democratic Republic of the',
      offset: '+01:00'
    }
  ],
  [
    TimeZoneEnum.AF_LUBUMBASHI,
    {
      timeZone: TimeZoneEnum.AF_LUBUMBASHI,
      countryCode: 'CD',
      countryName: 'Congo,the Democratic Republic of the',
      offset: '+02:00'
    }
  ],
  [
    TimeZoneEnum.PA_RAROTONGA,
    { timeZone: TimeZoneEnum.PA_RAROTONGA, countryCode: 'CK', countryName: 'Cook Islands', offset: '-10:00' }
  ],
  [
    TimeZoneEnum.AM_COSTA_RICA,
    { timeZone: TimeZoneEnum.AM_COSTA_RICA, countryCode: 'CR', countryName: 'Costa Rica', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.EU_ZAGREB,
    { timeZone: TimeZoneEnum.EU_ZAGREB, countryCode: 'HR', countryName: 'Croatia', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_HAVANA,
    { timeZone: TimeZoneEnum.AM_HAVANA, countryCode: 'CU', countryName: 'Cuba', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_CURACAO,
    { timeZone: TimeZoneEnum.AM_CURACAO, countryCode: 'CW', countryName: 'Curaçao', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AS_FAMAGUSTA,
    { timeZone: TimeZoneEnum.AS_FAMAGUSTA, countryCode: 'CY', countryName: 'Cyprus', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_NICOSIA,
    { timeZone: TimeZoneEnum.AS_NICOSIA, countryCode: 'CY', countryName: 'Cyprus', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_PRAGUE,
    { timeZone: TimeZoneEnum.EU_PRAGUE, countryCode: 'CZ', countryName: 'Czech Republic', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_ABJIDJAN,
    { timeZone: TimeZoneEnum.AF_ABJIDJAN, countryCode: 'CI', countryName: "Côte d'Ivoire", offset: '' }
  ],
  [
    TimeZoneEnum.EU_COPENHAGEN,
    { timeZone: TimeZoneEnum.EU_COPENHAGEN, countryCode: 'DK', countryName: 'Denmark', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_DJIBOUTI,
    { timeZone: TimeZoneEnum.AF_DJIBOUTI, countryCode: 'DJ', countryName: 'Djibouti', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AM_DOMINICA,
    { timeZone: TimeZoneEnum.AM_DOMINICA, countryCode: 'DM', countryName: 'Dominica', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_SANTO_DOMINGO,
    { timeZone: TimeZoneEnum.AM_SANTO_DOMINGO, countryCode: 'DO', countryName: 'Dominican Republic', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_GUAYAQUIL,
    { timeZone: TimeZoneEnum.AM_GUAYAQUIL, countryCode: 'EC', countryName: 'Ecuador', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.PA_GALAPAGOS,
    { timeZone: TimeZoneEnum.PA_GALAPAGOS, countryCode: 'EC', countryName: 'Ecuador', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AF_CAIRO,
    { timeZone: TimeZoneEnum.AF_CAIRO, countryCode: 'EG', countryName: 'Egypt', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AM_EL_SALVADOR,
    { timeZone: TimeZoneEnum.AM_EL_SALVADOR, countryCode: 'SV', countryName: 'El Salvador', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AF_MALABO,
    { timeZone: TimeZoneEnum.AF_MALABO, countryCode: 'GQ', countryName: 'Equatorial Guinea', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_ASMARA,
    { timeZone: TimeZoneEnum.AF_ASMARA, countryCode: 'ER', countryName: 'Eritrea', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.EU_TALLINN,
    { timeZone: TimeZoneEnum.EU_TALLINN, countryCode: 'EE', countryName: 'Estonia', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AF_ADDIS_ABABA,
    { timeZone: TimeZoneEnum.AF_ADDIS_ABABA, countryCode: 'ET', countryName: 'Ethiopia', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AT_STANLEY,
    {
      timeZone: TimeZoneEnum.AT_STANLEY,
      countryCode: 'FK',
      countryName: 'Falkland Islands (Malvinas)',
      offset: '-03:00'
    }
  ],
  [
    TimeZoneEnum.AT_FAROE,
    { timeZone: TimeZoneEnum.AT_FAROE, countryCode: 'FO', countryName: 'Faroe Islands', offset: '' }
  ],
  [TimeZoneEnum.PA_FIJI, { timeZone: TimeZoneEnum.PA_FIJI, countryCode: 'FJ', countryName: 'Fiji', offset: '+12:00' }],
  [
    TimeZoneEnum.EU_HELSINKI,
    { timeZone: TimeZoneEnum.EU_HELSINKI, countryCode: 'FI', countryName: 'Finland', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_PARIS,
    { timeZone: TimeZoneEnum.EU_PARIS, countryCode: 'FR', countryName: 'France', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_CAYENNE,
    { timeZone: TimeZoneEnum.AM_CAYENNE, countryCode: 'GF', countryName: 'French Guiana', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.PA_GAMBIER,
    { timeZone: TimeZoneEnum.PA_GAMBIER, countryCode: 'PF', countryName: 'French Polynesia', offset: '-09:00' }
  ],
  [
    TimeZoneEnum.PA_MARQUESAS,
    { timeZone: TimeZoneEnum.PA_MARQUESAS, countryCode: 'PF', countryName: 'French Polynesia', offset: '-09:30' }
  ],
  [
    TimeZoneEnum.PA_TAHITI,
    { timeZone: TimeZoneEnum.PA_TAHITI, countryCode: 'PF', countryName: 'French Polynesia', offset: '-10:00' }
  ],
  [
    TimeZoneEnum.ID_KERGUELEN,
    {
      timeZone: TimeZoneEnum.ID_KERGUELEN,
      countryCode: 'TF',
      countryName: 'French Southern Territories',
      offset: '+05:00'
    }
  ],
  [
    TimeZoneEnum.AF_LIBREVILLE,
    { timeZone: TimeZoneEnum.AF_LIBREVILLE, countryCode: 'GA', countryName: 'Gabon', offset: '+01:00' }
  ],
  [TimeZoneEnum.AF_BANJUL, { timeZone: TimeZoneEnum.AF_BANJUL, countryCode: 'GM', countryName: 'Gambia', offset: '' }],
  [
    TimeZoneEnum.AS_TBILISI,
    { timeZone: TimeZoneEnum.AS_TBILISI, countryCode: 'GE', countryName: 'Georgia', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.EU_BERLIN,
    { timeZone: TimeZoneEnum.EU_BERLIN, countryCode: 'DE', countryName: 'Germany', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.EU_BUSINGEN,
    { timeZone: TimeZoneEnum.EU_BUSINGEN, countryCode: 'DE', countryName: 'Germany', offset: '+01:00' }
  ],
  [TimeZoneEnum.AF_ACCRA, { timeZone: TimeZoneEnum.AF_ACCRA, countryCode: 'GH', countryName: 'Ghana', offset: '' }],
  [
    TimeZoneEnum.EU_GIBRALTAR,
    { timeZone: TimeZoneEnum.EU_GIBRALTAR, countryCode: 'GI', countryName: 'Gibraltar', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.EU_ATHENS,
    { timeZone: TimeZoneEnum.EU_ATHENS, countryCode: 'GR', countryName: 'Greece', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AM_DANMARKSHAVN,
    { timeZone: TimeZoneEnum.AM_DANMARKSHAVN, countryCode: 'GL', countryName: 'Greenland', offset: '' }
  ],
  [
    TimeZoneEnum.AM_NUUK,
    { timeZone: TimeZoneEnum.AM_NUUK, countryCode: 'GL', countryName: 'Greenland', offset: '-02:00' }
  ],
  [
    TimeZoneEnum.AM_SCORESBYSUND,
    { timeZone: TimeZoneEnum.AM_SCORESBYSUND, countryCode: 'GL', countryName: 'Greenland', offset: '-01:00' }
  ],
  [
    TimeZoneEnum.AM_THULE,
    { timeZone: TimeZoneEnum.AM_THULE, countryCode: 'GL', countryName: 'Greenland', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AM_GRENADA,
    { timeZone: TimeZoneEnum.AM_GRENADA, countryCode: 'GD', countryName: 'Grenada', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_GUADELOUPE,
    { timeZone: TimeZoneEnum.AM_GUADELOUPE, countryCode: 'GP', countryName: 'Guadeloupe', offset: '-04:00' }
  ],
  [TimeZoneEnum.PA_GUAM, { timeZone: TimeZoneEnum.PA_GUAM, countryCode: 'GU', countryName: 'Guam', offset: '+10:00' }],
  [
    TimeZoneEnum.AM_GUATEMALA,
    { timeZone: TimeZoneEnum.AM_GUATEMALA, countryCode: 'GT', countryName: 'Guatemala', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.EU_GUERNSEY,
    { timeZone: TimeZoneEnum.EU_GUERNSEY, countryCode: 'GG', countryName: 'Guernsey', offset: '' }
  ],
  [
    TimeZoneEnum.AF_CONAKRY,
    { timeZone: TimeZoneEnum.AF_CONAKRY, countryCode: 'GN', countryName: 'Guinea', offset: '' }
  ],
  [
    TimeZoneEnum.AF_BISSAU,
    { timeZone: TimeZoneEnum.AF_BISSAU, countryCode: 'GW', countryName: 'Guinea-Bissau', offset: '' }
  ],
  [
    TimeZoneEnum.AM_GUYANA,
    { timeZone: TimeZoneEnum.AM_GUYANA, countryCode: 'GY', countryName: 'Guyana', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_PORT_AU_PRINCE,
    { timeZone: TimeZoneEnum.AM_PORT_AU_PRINCE, countryCode: 'HT', countryName: 'Haiti', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.EU_VATICAN,
    {
      timeZone: TimeZoneEnum.EU_VATICAN,
      countryCode: 'VA',
      countryName: 'Holy See (Vatican City State)',
      offset: '+01:00'
    }
  ],
  [
    TimeZoneEnum.AM_TEGUCIGALPA,
    { timeZone: TimeZoneEnum.AM_TEGUCIGALPA, countryCode: 'HN', countryName: 'Honduras', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AS_HONG_KONG,
    { timeZone: TimeZoneEnum.AS_HONG_KONG, countryCode: 'HK', countryName: 'Hong Kong', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.EU_BUDAPEST,
    { timeZone: TimeZoneEnum.EU_BUDAPEST, countryCode: 'HU', countryName: 'Hungary', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AT_REYKJAVIK,
    { timeZone: TimeZoneEnum.AT_REYKJAVIK, countryCode: 'IS', countryName: 'Iceland', offset: '' }
  ],
  [
    TimeZoneEnum.AS_KOLKATA,
    { timeZone: TimeZoneEnum.AS_KOLKATA, countryCode: 'IN', countryName: 'India', offset: '+05:30' }
  ],
  [
    TimeZoneEnum.AS_JAKARTA,
    { timeZone: TimeZoneEnum.AS_JAKARTA, countryCode: 'ID', countryName: 'Indonesia', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_JAYAPURA,
    { timeZone: TimeZoneEnum.AS_JAYAPURA, countryCode: 'ID', countryName: 'Indonesia', offset: '+09:00' }
  ],
  [
    TimeZoneEnum.AS_MAKASSAR,
    { timeZone: TimeZoneEnum.AS_MAKASSAR, countryCode: 'ID', countryName: 'Indonesia', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AS_PONTIANAK,
    { timeZone: TimeZoneEnum.AS_PONTIANAK, countryCode: 'ID', countryName: 'Indonesia', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_TEHRAN,
    { timeZone: TimeZoneEnum.AS_TEHRAN, countryCode: 'IR', countryName: 'Iran, Islamic Republic of', offset: '+03:30' }
  ],
  [
    TimeZoneEnum.AS_BAGHDAD,
    { timeZone: TimeZoneEnum.AS_BAGHDAD, countryCode: 'IQ', countryName: 'Iraq', offset: '+03:00' }
  ],
  [TimeZoneEnum.EU_DUBLIN, { timeZone: TimeZoneEnum.EU_DUBLIN, countryCode: 'IE', countryName: 'Ireland', offset: '' }],
  [
    TimeZoneEnum.EU_ISLE_OF_MAN,
    { timeZone: TimeZoneEnum.EU_ISLE_OF_MAN, countryCode: 'IM', countryName: 'Isle of Man', offset: '' }
  ],
  [
    TimeZoneEnum.AS_JERUSALEM,
    { timeZone: TimeZoneEnum.AS_JERUSALEM, countryCode: 'IL', countryName: 'Israel', offset: '+02:00' }
  ],
  [TimeZoneEnum.EU_ROME, { timeZone: TimeZoneEnum.EU_ROME, countryCode: 'IT', countryName: 'Italy', offset: '+01:00' }],
  [
    TimeZoneEnum.AM_JAMAICA,
    { timeZone: TimeZoneEnum.AM_JAMAICA, countryCode: 'JM', countryName: 'Jamaica', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AS_TOKYO,
    { timeZone: TimeZoneEnum.AS_TOKYO, countryCode: 'JP', countryName: 'Japan', offset: '+09:00' }
  ],
  [TimeZoneEnum.EU_JERSEY, { timeZone: TimeZoneEnum.EU_JERSEY, countryCode: 'JE', countryName: 'Jersey', offset: '' }],
  [
    TimeZoneEnum.AS_AMMAN,
    { timeZone: TimeZoneEnum.AS_AMMAN, countryCode: 'JO', countryName: 'Jordan', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AS_ALMATY,
    { timeZone: TimeZoneEnum.AS_ALMATY, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_AQTAU,
    { timeZone: TimeZoneEnum.AS_AQTAU, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_AQTOBE,
    { timeZone: TimeZoneEnum.AS_AQTOBE, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_ATYRAU,
    { timeZone: TimeZoneEnum.AS_ATYRAU, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_ORAL,
    { timeZone: TimeZoneEnum.AS_ORAL, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_QOSTANAY,
    { timeZone: TimeZoneEnum.AS_QOSTANAY, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_QYZYLORDA,
    { timeZone: TimeZoneEnum.AS_QYZYLORDA, countryCode: 'KZ', countryName: 'Kazakhstan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AF_NAIROBI,
    { timeZone: TimeZoneEnum.AF_NAIROBI, countryCode: 'KE', countryName: 'Kenya', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.PA_KANTON,
    { timeZone: TimeZoneEnum.PA_KANTON, countryCode: 'KI', countryName: 'Kiribati', offset: '+13:00' }
  ],
  [
    TimeZoneEnum.PA_KIRITIMATI,
    { timeZone: TimeZoneEnum.PA_KIRITIMATI, countryCode: 'KI', countryName: 'Kiribati', offset: '+14:00' }
  ],
  [
    TimeZoneEnum.PA_TARAWA,
    { timeZone: TimeZoneEnum.PA_TARAWA, countryCode: 'KI', countryName: 'Kiribati', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.AS_PYONGYANG,
    {
      timeZone: TimeZoneEnum.AS_PYONGYANG,
      countryCode: 'KP',
      countryName: "Korea, Democratic People's Republic of",
      offset: '+09:00'
    }
  ],
  [
    TimeZoneEnum.AS_SEOUL,
    { timeZone: TimeZoneEnum.AS_SEOUL, countryCode: 'KR', countryName: 'Korea, Republic of', offset: '+09:00' }
  ],
  [
    TimeZoneEnum.AS_KUWAIT,
    { timeZone: TimeZoneEnum.AS_KUWAIT, countryCode: 'KW', countryName: 'Kuwait', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AS_BISHKEK,
    { timeZone: TimeZoneEnum.AS_BISHKEK, countryCode: 'KG', countryName: 'Kyrgyzstan', offset: '+06:00' }
  ],
  [
    TimeZoneEnum.AS_VIENTIANE,
    {
      timeZone: TimeZoneEnum.AS_VIENTIANE,
      countryCode: 'LA',
      countryName: "Lao People's Democratic Republic",
      offset: '+07:00'
    }
  ],
  [
    TimeZoneEnum.EU_RIGA,
    { timeZone: TimeZoneEnum.EU_RIGA, countryCode: 'LV', countryName: 'Latvia', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_BEIRUT,
    { timeZone: TimeZoneEnum.AS_BEIRUT, countryCode: 'LB', countryName: 'Lebanon', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AF_MASERU,
    { timeZone: TimeZoneEnum.AF_MASERU, countryCode: 'LS', countryName: 'Lesotho', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AF_MONROVIA,
    { timeZone: TimeZoneEnum.AF_MONROVIA, countryCode: 'LR', countryName: 'Liberia', offset: '' }
  ],
  [
    TimeZoneEnum.AF_TRIPOLI,
    { timeZone: TimeZoneEnum.AF_TRIPOLI, countryCode: 'LY', countryName: 'Libya', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_VADUZ,
    { timeZone: TimeZoneEnum.EU_VADUZ, countryCode: 'LI', countryName: 'Liechtenstein', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.EU_VILNIUS,
    { timeZone: TimeZoneEnum.EU_VILNIUS, countryCode: 'LT', countryName: 'Lithuania', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_LUXEMBOURG,
    { timeZone: TimeZoneEnum.EU_LUXEMBOURG, countryCode: 'LU', countryName: 'Luxembourg', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AS_MACAU,
    { timeZone: TimeZoneEnum.AS_MACAU, countryCode: 'MO', countryName: 'Macao', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.EU_SKOPJE,
    {
      timeZone: TimeZoneEnum.EU_SKOPJE,
      countryCode: 'MK',
      countryName: 'Macedonia,the Former Yugoslav Republic of',
      offset: '+01:00'
    }
  ],
  [
    TimeZoneEnum.ID_ANTANANARIVO,
    { timeZone: TimeZoneEnum.ID_ANTANANARIVO, countryCode: 'MG', countryName: 'Madagascar', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AF_BLANTYRE,
    { timeZone: TimeZoneEnum.AF_BLANTYRE, countryCode: 'MW', countryName: 'Malawi', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_KUALA_LUMPUR,
    { timeZone: TimeZoneEnum.AS_KUALA_LUMPUR, countryCode: 'MY', countryName: 'Malaysia', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AS_KUCHING,
    { timeZone: TimeZoneEnum.AS_KUCHING, countryCode: 'MY', countryName: 'Malaysia', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.ID_MALDIVES,
    { timeZone: TimeZoneEnum.ID_MALDIVES, countryCode: 'MV', countryName: 'Maldives', offset: '+05:00' }
  ],
  [TimeZoneEnum.AF_BAMAKO, { timeZone: TimeZoneEnum.AF_BAMAKO, countryCode: 'ML', countryName: 'Mali', offset: '' }],
  [
    TimeZoneEnum.EU_MALTA,
    { timeZone: TimeZoneEnum.EU_MALTA, countryCode: 'MT', countryName: 'Malta', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.PA_KWAJALEIN,
    { timeZone: TimeZoneEnum.PA_KWAJALEIN, countryCode: 'MH', countryName: 'Marshall Islands', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.PA_MAJURO,
    { timeZone: TimeZoneEnum.PA_MAJURO, countryCode: 'MH', countryName: 'Marshall Islands', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.AM_MARTINIQUE,
    { timeZone: TimeZoneEnum.AM_MARTINIQUE, countryCode: 'MQ', countryName: 'Martinique', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AF_NOUAKCHOTT,
    { timeZone: TimeZoneEnum.AF_NOUAKCHOTT, countryCode: 'MR', countryName: 'Mauritania', offset: '' }
  ],
  [
    TimeZoneEnum.ID_MAURITIUS,
    { timeZone: TimeZoneEnum.ID_MAURITIUS, countryCode: 'MU', countryName: 'Mauritius', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.ID_MAYOTTE,
    { timeZone: TimeZoneEnum.ID_MAYOTTE, countryCode: 'YT', countryName: 'Mayotte', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AM_BAHIA_BANDERAS,
    { timeZone: TimeZoneEnum.AM_BAHIA_BANDERAS, countryCode: 'MX', countryName: 'Mexico', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_CANCUN,
    { timeZone: TimeZoneEnum.AM_CANCUN, countryCode: 'MX', countryName: 'Mexico', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_CHIHUAHUA,
    { timeZone: TimeZoneEnum.AM_CHIHUAHUA, countryCode: 'MX', countryName: 'Mexico', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_CIUDAD_JUAREZ,
    { timeZone: TimeZoneEnum.AM_CIUDAD_JUAREZ, countryCode: 'MX', countryName: 'Mexico', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_HERMOSILLO,
    { timeZone: TimeZoneEnum.AM_HERMOSILLO, countryCode: 'MX', countryName: 'Mexico', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_MATAMOROS,
    { timeZone: TimeZoneEnum.AM_MATAMOROS, countryCode: 'MX', countryName: 'Mexico', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_MAZATLAN,
    { timeZone: TimeZoneEnum.AM_MAZATLAN, countryCode: 'MX', countryName: 'Mexico', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_MERIDA,
    { timeZone: TimeZoneEnum.AM_MERIDA, countryCode: 'MX', countryName: 'Mexico', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_MEXICO_CITY,
    { timeZone: TimeZoneEnum.AM_MEXICO_CITY, countryCode: 'MX', countryName: 'Mexico', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_MONTERREY,
    { timeZone: TimeZoneEnum.AM_MONTERREY, countryCode: 'MX', countryName: 'Mexico', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_OJINAGA,
    { timeZone: TimeZoneEnum.AM_OJINAGA, countryCode: 'MX', countryName: 'Mexico', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_TIJUANA,
    { timeZone: TimeZoneEnum.AM_TIJUANA, countryCode: 'MX', countryName: 'Mexico', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.PA_CHUUK,
    {
      timeZone: TimeZoneEnum.PA_CHUUK,
      countryCode: 'FM',
      countryName: 'Micronesia, Federated States of',
      offset: '+10:00'
    }
  ],
  [
    TimeZoneEnum.PA_KOSRAE,
    {
      timeZone: TimeZoneEnum.PA_KOSRAE,
      countryCode: 'FM',
      countryName: 'Micronesia, Federated States of',
      offset: '+11:00'
    }
  ],
  [
    TimeZoneEnum.PA_POHNPEI,
    {
      timeZone: TimeZoneEnum.PA_POHNPEI,
      countryCode: 'FM',
      countryName: 'Micronesia, Federated States of',
      offset: '+11:00'
    }
  ],
  [
    TimeZoneEnum.EU_CHISINAU,
    { timeZone: TimeZoneEnum.EU_CHISINAU, countryCode: 'MD', countryName: 'Moldova, Republic of', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_MONACO,
    { timeZone: TimeZoneEnum.EU_MONACO, countryCode: 'MC', countryName: 'Monaco', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AS_CHOIBALSAN,
    { timeZone: TimeZoneEnum.AS_CHOIBALSAN, countryCode: 'MN', countryName: 'Mongolia', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AS_HOVD,
    { timeZone: TimeZoneEnum.AS_HOVD, countryCode: 'MN', countryName: 'Mongolia', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_ULAANBAATAR,
    { timeZone: TimeZoneEnum.AS_ULAANBAATAR, countryCode: 'MN', countryName: 'Mongolia', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.EU_PODGORICA,
    { timeZone: TimeZoneEnum.EU_PODGORICA, countryCode: 'ME', countryName: 'Montenegro', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AM_MONTSERRAT,
    { timeZone: TimeZoneEnum.AM_MONTSERRAT, countryCode: 'MS', countryName: 'Montserrat', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AF_CASABLANCA,
    { timeZone: TimeZoneEnum.AF_CASABLANCA, countryCode: 'MA', countryName: 'Morocco', offset: '' }
  ],
  [
    TimeZoneEnum.AF_EL_AAIUN,
    { timeZone: TimeZoneEnum.AF_EL_AAIUN, countryCode: 'MA', countryName: 'Morocco', offset: '' }
  ],
  [
    TimeZoneEnum.AF_MAPUTO,
    { timeZone: TimeZoneEnum.AF_MAPUTO, countryCode: 'MZ', countryName: 'Mozambique', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_YANGON,
    { timeZone: TimeZoneEnum.AS_YANGON, countryCode: 'MM', countryName: 'Myanmar', offset: '+06:30' }
  ],
  [
    TimeZoneEnum.AF_WINDHOEK,
    { timeZone: TimeZoneEnum.AF_WINDHOEK, countryCode: 'NA', countryName: 'Namibia', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.PA_NAURU,
    { timeZone: TimeZoneEnum.PA_NAURU, countryCode: 'NR', countryName: 'Nauru', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.AS_KATHMANDU,
    { timeZone: TimeZoneEnum.AS_KATHMANDU, countryCode: 'NP', countryName: 'Nepal', offset: '+05:45' }
  ],
  [
    TimeZoneEnum.EU_AMSTERDAM,
    { timeZone: TimeZoneEnum.EU_AMSTERDAM, countryCode: 'NL', countryName: 'Netherlands', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.PA_NOUMEA,
    { timeZone: TimeZoneEnum.PA_NOUMEA, countryCode: 'NC', countryName: 'New Caledonia', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.PA_AUCKLAND,
    { timeZone: TimeZoneEnum.PA_AUCKLAND, countryCode: 'NZ', countryName: 'New Zealand', offset: '+13:00' }
  ],
  [
    TimeZoneEnum.PA_CHATHAM,
    { timeZone: TimeZoneEnum.PA_CHATHAM, countryCode: 'NZ', countryName: 'New Zealand', offset: '+13:45' }
  ],
  [
    TimeZoneEnum.AM_MANAGUA,
    { timeZone: TimeZoneEnum.AM_MANAGUA, countryCode: 'NI', countryName: 'Nicaragua', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AF_NIAMEY,
    { timeZone: TimeZoneEnum.AF_NIAMEY, countryCode: 'NE', countryName: 'Niger', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_LAGOS,
    { timeZone: TimeZoneEnum.AF_LAGOS, countryCode: 'NG', countryName: 'Nigeria', offset: '+01:00' }
  ],
  [TimeZoneEnum.PA_NIUE, { timeZone: TimeZoneEnum.PA_NIUE, countryCode: 'NU', countryName: 'Niue', offset: '-11:00' }],
  [
    TimeZoneEnum.PA_NORFOLK,
    { timeZone: TimeZoneEnum.PA_NORFOLK, countryCode: 'NF', countryName: 'Norfolk Island', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.PA_SAIPAN,
    { timeZone: TimeZoneEnum.PA_SAIPAN, countryCode: 'MP', countryName: 'Northern Mariana Islands', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.EU_OSLO,
    { timeZone: TimeZoneEnum.EU_OSLO, countryCode: 'NO', countryName: 'Norway', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AS_MUSCAT,
    { timeZone: TimeZoneEnum.AS_MUSCAT, countryCode: 'OM', countryName: 'Oman', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.AS_KARACHI,
    { timeZone: TimeZoneEnum.AS_KARACHI, countryCode: 'PK', countryName: 'Pakistan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.PA_PALAU,
    { timeZone: TimeZoneEnum.PA_PALAU, countryCode: 'PW', countryName: 'Palau', offset: '+09:00' }
  ],
  [
    TimeZoneEnum.AS_GAZA,
    { timeZone: TimeZoneEnum.AS_GAZA, countryCode: 'PS', countryName: 'Palestine, State of', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_HEBRON,
    { timeZone: TimeZoneEnum.AS_HEBRON, countryCode: 'PS', countryName: 'Palestine, State of', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AM_PANAMA,
    { timeZone: TimeZoneEnum.AM_PANAMA, countryCode: 'PA', countryName: 'Panama', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.PA_BOUGAINVILLE,
    { timeZone: TimeZoneEnum.PA_BOUGAINVILLE, countryCode: 'PG', countryName: 'Papua New Guinea', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.PA_PORT_MORESBY,
    { timeZone: TimeZoneEnum.PA_PORT_MORESBY, countryCode: 'PG', countryName: 'Papua New Guinea', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.AM_ASUNCION,
    { timeZone: TimeZoneEnum.AM_ASUNCION, countryCode: 'PY', countryName: 'Paraguay', offset: '-03:00' }
  ],
  [TimeZoneEnum.AM_LIMA, { timeZone: TimeZoneEnum.AM_LIMA, countryCode: 'PE', countryName: 'Peru', offset: '-05:00' }],
  [
    TimeZoneEnum.AS_MANILA,
    { timeZone: TimeZoneEnum.AS_MANILA, countryCode: 'PH', countryName: 'Philippines', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.PA_PITCAIRN,
    { timeZone: TimeZoneEnum.PA_PITCAIRN, countryCode: 'PN', countryName: 'Pitcairn', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.EU_WARSAW,
    { timeZone: TimeZoneEnum.EU_WARSAW, countryCode: 'PL', countryName: 'Poland', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AT_AZORES,
    { timeZone: TimeZoneEnum.AT_AZORES, countryCode: 'PT', countryName: 'Portugal', offset: '-01:00' }
  ],
  [
    TimeZoneEnum.AT_MADEIRA,
    { timeZone: TimeZoneEnum.AT_MADEIRA, countryCode: 'PT', countryName: 'Portugal', offset: '' }
  ],
  [
    TimeZoneEnum.EU_LISBON,
    { timeZone: TimeZoneEnum.EU_LISBON, countryCode: 'PT', countryName: 'Portugal', offset: '' }
  ],
  [
    TimeZoneEnum.AM_PUERTO_RICO,
    { timeZone: TimeZoneEnum.AM_PUERTO_RICO, countryCode: 'PR', countryName: 'Puerto Rico', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AS_QATAR,
    { timeZone: TimeZoneEnum.AS_QATAR, countryCode: 'QA', countryName: 'Qatar', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.EU_BUCHAREST,
    { timeZone: TimeZoneEnum.EU_BUCHAREST, countryCode: 'RO', countryName: 'Romania', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AS_ANADYR,
    { timeZone: TimeZoneEnum.AS_ANADYR, countryCode: 'RU', countryName: 'Russian Federation', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.AS_BARNAUL,
    { timeZone: TimeZoneEnum.AS_BARNAUL, countryCode: 'RU', countryName: 'Russian Federation', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_CHITA,
    { timeZone: TimeZoneEnum.AS_CHITA, countryCode: 'RU', countryName: 'Russian Federation', offset: '+09:00' }
  ],
  [
    TimeZoneEnum.AS_IRKUTSK,
    { timeZone: TimeZoneEnum.AS_IRKUTSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AS_KAMCHATKA,
    { timeZone: TimeZoneEnum.AS_KAMCHATKA, countryCode: 'RU', countryName: 'Russian Federation', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.AS_KHANDYGA,
    { timeZone: TimeZoneEnum.AS_KHANDYGA, countryCode: 'RU', countryName: 'Russian Federation', offset: '+09:00' }
  ],
  [
    TimeZoneEnum.AS_KRASNOYARSK,
    { timeZone: TimeZoneEnum.AS_KRASNOYARSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_MAGADAN,
    { timeZone: TimeZoneEnum.AS_MAGADAN, countryCode: 'RU', countryName: 'Russian Federation', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AS_NOVOKUZNETSK,
    { timeZone: TimeZoneEnum.AS_NOVOKUZNETSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_NOVOSIBIRSK,
    { timeZone: TimeZoneEnum.AS_NOVOSIBIRSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_OMSK,
    { timeZone: TimeZoneEnum.AS_OMSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+06:00' }
  ],
  [
    TimeZoneEnum.AS_SAKHALIN,
    { timeZone: TimeZoneEnum.AS_SAKHALIN, countryCode: 'RU', countryName: 'Russian Federation', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AS_SREDNEKOLYMSK,
    { timeZone: TimeZoneEnum.AS_SREDNEKOLYMSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AS_TOMSK,
    { timeZone: TimeZoneEnum.AS_TOMSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_UST_NERA,
    { timeZone: TimeZoneEnum.AS_UST_NERA, countryCode: 'RU', countryName: 'Russian Federation', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.AS_VLADIVOSTOK,
    { timeZone: TimeZoneEnum.AS_VLADIVOSTOK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+10:00' }
  ],
  [
    TimeZoneEnum.AS_YAKUTSK,
    { timeZone: TimeZoneEnum.AS_YAKUTSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+09:00' }
  ],
  [
    TimeZoneEnum.AS_YEKATERINBURG,
    { timeZone: TimeZoneEnum.AS_YEKATERINBURG, countryCode: 'RU', countryName: 'Russian Federation', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.EU_ASTRAKHAN,
    { timeZone: TimeZoneEnum.EU_ASTRAKHAN, countryCode: 'RU', countryName: 'Russian Federation', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.EU_KALININGRAD,
    { timeZone: TimeZoneEnum.EU_KALININGRAD, countryCode: 'RU', countryName: 'Russian Federation', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_KIROV,
    { timeZone: TimeZoneEnum.EU_KIROV, countryCode: 'RU', countryName: 'Russian Federation', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.EU_MOSCOW,
    { timeZone: TimeZoneEnum.EU_MOSCOW, countryCode: 'RU', countryName: 'Russian Federation', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.EU_SAMARA,
    { timeZone: TimeZoneEnum.EU_SAMARA, countryCode: 'RU', countryName: 'Russian Federation', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.EU_SARATOV,
    { timeZone: TimeZoneEnum.EU_SARATOV, countryCode: 'RU', countryName: 'Russian Federation', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.EU_ULYANOVSK,
    { timeZone: TimeZoneEnum.EU_ULYANOVSK, countryCode: 'RU', countryName: 'Russian Federation', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.EU_VOLGOGRAD,
    { timeZone: TimeZoneEnum.EU_VOLGOGRAD, countryCode: 'RU', countryName: 'Russian Federation', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AF_KIGALI,
    { timeZone: TimeZoneEnum.AF_KIGALI, countryCode: 'RW', countryName: 'Rwanda', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.ID_REUNION,
    { timeZone: TimeZoneEnum.ID_REUNION, countryCode: 'RE', countryName: 'Réunion', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.AM_ST_BARTHELEMY,
    { timeZone: TimeZoneEnum.AM_ST_BARTHELEMY, countryCode: 'BL', countryName: 'Saint Barthélemy', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AT_ST_HELENA,
    {
      timeZone: TimeZoneEnum.AT_ST_HELENA,
      countryCode: 'SH',
      countryName: 'Saint Helena, Ascension and Tristan da Cunha',
      offset: ''
    }
  ],
  [
    TimeZoneEnum.AM_ST_KITTS,
    { timeZone: TimeZoneEnum.AM_ST_KITTS, countryCode: 'KN', countryName: 'Saint Kitts and Nevis', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_ST_LUCIA,
    { timeZone: TimeZoneEnum.AM_ST_LUCIA, countryCode: 'LC', countryName: 'Saint Lucia', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_MARIGOT,
    {
      timeZone: TimeZoneEnum.AM_MARIGOT,
      countryCode: 'MF',
      countryName: 'Saint Martin (French part)',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.AM_MIQUELON,
    {
      timeZone: TimeZoneEnum.AM_MIQUELON,
      countryCode: 'PM',
      countryName: 'Saint Pierre and Miquelon',
      offset: '-02:00'
    }
  ],
  [
    TimeZoneEnum.AM_ST_VINCENT,
    {
      timeZone: TimeZoneEnum.AM_ST_VINCENT,
      countryCode: 'VC',
      countryName: 'Saint Vincent and the Grenadines',
      offset: '-04:00'
    }
  ],
  [TimeZoneEnum.PA_APIA, { timeZone: TimeZoneEnum.PA_APIA, countryCode: 'WS', countryName: 'Samoa', offset: '+13:00' }],
  [
    TimeZoneEnum.EU_SAN_MARINO,
    { timeZone: TimeZoneEnum.EU_SAN_MARINO, countryCode: 'SM', countryName: 'San Marino', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AF_SAO_TOME,
    { timeZone: TimeZoneEnum.AF_SAO_TOME, countryCode: 'ST', countryName: 'Sao Tome and Principe', offset: '' }
  ],
  [
    TimeZoneEnum.AS_RIYADH,
    { timeZone: TimeZoneEnum.AS_RIYADH, countryCode: 'SA', countryName: 'Saudi Arabia', offset: '+03:00' }
  ],
  [TimeZoneEnum.AF_DAKAR, { timeZone: TimeZoneEnum.AF_DAKAR, countryCode: 'SN', countryName: 'Senegal', offset: '' }],
  [
    TimeZoneEnum.EU_BELGRADE,
    { timeZone: TimeZoneEnum.EU_BELGRADE, countryCode: 'RS', countryName: 'Serbia', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.ID_MAHE,
    { timeZone: TimeZoneEnum.ID_MAHE, countryCode: 'SC', countryName: 'Seychelles', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.AF_FREETOWN,
    { timeZone: TimeZoneEnum.AF_FREETOWN, countryCode: 'SL', countryName: 'Sierra Leone', offset: '' }
  ],
  [
    TimeZoneEnum.AS_SINGAPORE,
    { timeZone: TimeZoneEnum.AS_SINGAPORE, countryCode: 'SG', countryName: 'Singapore', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AM_LOWER_PRINCES,
    {
      timeZone: TimeZoneEnum.AM_LOWER_PRINCES,
      countryCode: 'SX',
      countryName: 'Sint Maarten (Dutch part)',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.EU_BRATISLAVA,
    { timeZone: TimeZoneEnum.EU_BRATISLAVA, countryCode: 'SK', countryName: 'Slovakia', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.EU_LJUBLJANA,
    { timeZone: TimeZoneEnum.EU_LJUBLJANA, countryCode: 'SI', countryName: 'Slovenia', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.PA_GUADALCANAL,
    { timeZone: TimeZoneEnum.PA_GUADALCANAL, countryCode: 'SB', countryName: 'Solomon Islands', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AF_MOGADISHU,
    { timeZone: TimeZoneEnum.AF_MOGADISHU, countryCode: 'SO', countryName: 'Somalia', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AF_JOHANNESBURG,
    { timeZone: TimeZoneEnum.AF_JOHANNESBURG, countryCode: 'ZA', countryName: 'South Africa', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AT_SOUTH_GEORGIA,
    {
      timeZone: TimeZoneEnum.AT_SOUTH_GEORGIA,
      countryCode: 'GS',
      countryName: 'South Georgia and the South Sandwich Islands',
      offset: '-02:00'
    }
  ],
  [
    TimeZoneEnum.AF_JUBA,
    { timeZone: TimeZoneEnum.AF_JUBA, countryCode: 'SS', countryName: 'South Sudan', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AF_CEUTA,
    { timeZone: TimeZoneEnum.AF_CEUTA, countryCode: 'ES', countryName: 'Spain', offset: '+01:00' }
  ],
  [TimeZoneEnum.AT_CANARY, { timeZone: TimeZoneEnum.AT_CANARY, countryCode: 'ES', countryName: 'Spain', offset: '' }],
  [
    TimeZoneEnum.EU_MADRID,
    { timeZone: TimeZoneEnum.EU_MADRID, countryCode: 'ES', countryName: 'Spain', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AS_COLOMBO,
    { timeZone: TimeZoneEnum.AS_COLOMBO, countryCode: 'LK', countryName: 'Sri Lanka', offset: '+05:30' }
  ],
  [
    TimeZoneEnum.AF_KHARTOUM,
    { timeZone: TimeZoneEnum.AF_KHARTOUM, countryCode: 'SD', countryName: 'Sudan', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AM_PARAMARIBO,
    { timeZone: TimeZoneEnum.AM_PARAMARIBO, countryCode: 'SR', countryName: 'Suriname', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AR_LONGYEARBYEN,
    {
      timeZone: TimeZoneEnum.AR_LONGYEARBYEN,
      countryCode: 'SJ',
      countryName: 'Svalbard and Jan Mayen',
      offset: '+01:00'
    }
  ],
  [
    TimeZoneEnum.AF_MBABANE,
    { timeZone: TimeZoneEnum.AF_MBABANE, countryCode: 'SZ', countryName: 'Swaziland', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_STOCKHOLM,
    { timeZone: TimeZoneEnum.EU_STOCKHOLM, countryCode: 'SE', countryName: 'Sweden', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.EU_ZURICH,
    { timeZone: TimeZoneEnum.EU_ZURICH, countryCode: 'CH', countryName: 'Switzerland', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.AS_DAMASCUS,
    { timeZone: TimeZoneEnum.AS_DAMASCUS, countryCode: 'SY', countryName: 'Syrian Arab Republic', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AS_TAIPEI,
    { timeZone: TimeZoneEnum.AS_TAIPEI, countryCode: 'TW', countryName: 'Taiwan, Province of China', offset: '+08:00' }
  ],
  [
    TimeZoneEnum.AS_DUSHANBE,
    { timeZone: TimeZoneEnum.AS_DUSHANBE, countryCode: 'TJ', countryName: 'Tajikistan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AF_DAR_ES_SALAAM,
    {
      timeZone: TimeZoneEnum.AF_DAR_ES_SALAAM,
      countryCode: 'TZ',
      countryName: 'Tanzania, United Republic of',
      offset: '+03:00'
    }
  ],
  [
    TimeZoneEnum.AS_BANGKOK,
    { timeZone: TimeZoneEnum.AS_BANGKOK, countryCode: 'TH', countryName: 'Thailand', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AS_DILI,
    { timeZone: TimeZoneEnum.AS_DILI, countryCode: 'TL', countryName: 'Timor-Leste', offset: '+09:00' }
  ],
  [TimeZoneEnum.AF_LOME, { timeZone: TimeZoneEnum.AF_LOME, countryCode: 'TG', countryName: 'Togo', offset: '' }],
  [
    TimeZoneEnum.PA_FAKAOFO,
    { timeZone: TimeZoneEnum.PA_FAKAOFO, countryCode: 'TK', countryName: 'Tokelau', offset: '+13:00' }
  ],
  [
    TimeZoneEnum.PA_TONGATAPU,
    { timeZone: TimeZoneEnum.PA_TONGATAPU, countryCode: 'TO', countryName: 'Tonga', offset: '+13:00' }
  ],
  [
    TimeZoneEnum.AM_PORT_OF_SPAIN,
    { timeZone: TimeZoneEnum.AM_PORT_OF_SPAIN, countryCode: 'TT', countryName: 'Trinidad and Tobago', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AF_TUNIS,
    { timeZone: TimeZoneEnum.AF_TUNIS, countryCode: 'TN', countryName: 'Tunisia', offset: '+01:00' }
  ],
  [
    TimeZoneEnum.EU_ISTANBUL,
    { timeZone: TimeZoneEnum.EU_ISTANBUL, countryCode: 'TR', countryName: 'Turkey', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AS_ASHGABAT,
    { timeZone: TimeZoneEnum.AS_ASHGABAT, countryCode: 'TM', countryName: 'Turkmenistan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AM_GRAND_TURK,
    {
      timeZone: TimeZoneEnum.AM_GRAND_TURK,
      countryCode: 'TC',
      countryName: 'Turks and Caicos Islands',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.PA_FUNAFUTI,
    { timeZone: TimeZoneEnum.PA_FUNAFUTI, countryCode: 'TV', countryName: 'Tuvalu', offset: '+12:00' }
  ],
  [
    TimeZoneEnum.AF_KAMPALA,
    { timeZone: TimeZoneEnum.AF_KAMPALA, countryCode: 'UG', countryName: 'Uganda', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.EU_KYIV,
    { timeZone: TimeZoneEnum.EU_KYIV, countryCode: 'UA', countryName: 'Ukraine', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_SIMFEROPOL,
    { timeZone: TimeZoneEnum.EU_SIMFEROPOL, countryCode: 'UA', countryName: 'Ukraine', offset: '+03:00' }
  ],
  [
    TimeZoneEnum.AS_DUBAI,
    { timeZone: TimeZoneEnum.AS_DUBAI, countryCode: 'AE', countryName: 'United Arab Emirates', offset: '+04:00' }
  ],
  [
    TimeZoneEnum.EU_LONDON,
    { timeZone: TimeZoneEnum.EU_LONDON, countryCode: 'GB', countryName: 'United Kingdom', offset: '' }
  ],
  [
    TimeZoneEnum.AM_ADAK,
    { timeZone: TimeZoneEnum.AM_ADAK, countryCode: 'US', countryName: 'United States', offset: '-09:00' }
  ],
  [
    TimeZoneEnum.AM_ANCHORAGE,
    { timeZone: TimeZoneEnum.AM_ANCHORAGE, countryCode: 'US', countryName: 'United States', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.AM_BOISE,
    { timeZone: TimeZoneEnum.AM_BOISE, countryCode: 'US', countryName: 'United States', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_CHICAGO,
    { timeZone: TimeZoneEnum.AM_CHICAGO, countryCode: 'US', countryName: 'United States', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_DENVER,
    { timeZone: TimeZoneEnum.AM_DENVER, countryCode: 'US', countryName: 'United States', offset: '-06:00' }
  ],
  [
    TimeZoneEnum.AM_DETROIT,
    { timeZone: TimeZoneEnum.AM_DETROIT, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_INDIANAPOLIS,
    {
      timeZone: TimeZoneEnum.AM_INDIANA_INDIANAPOLIS,
      countryCode: 'US',
      countryName: 'United States',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.AM_INDIANA_KNOX,
    { timeZone: TimeZoneEnum.AM_INDIANA_KNOX, countryCode: 'US', countryName: 'United States', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_MARENGO,
    { timeZone: TimeZoneEnum.AM_INDIANA_MARENGO, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_PETERSBURG,
    { timeZone: TimeZoneEnum.AM_INDIANA_MARENGO, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_TELL_CITY,
    { timeZone: TimeZoneEnum.AM_INDIANA_TELL_CITY, countryCode: 'US', countryName: 'United States', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_VEVAY,
    { timeZone: TimeZoneEnum.AM_INDIANA_VEVAY, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_VINCENNES,
    { timeZone: TimeZoneEnum.AM_INDIANA_VINCENNES, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_INDIANA_WINAMAC,
    { timeZone: TimeZoneEnum.AM_INDIANA_WINAMAC, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_JUNEAU,
    { timeZone: TimeZoneEnum.AM_JUNEAU, countryCode: 'US', countryName: 'United States', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.AM_KENTUCKY_LOUISVILLE,
    { timeZone: TimeZoneEnum.AM_KENTUCKY_LOUISVILLE, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_KENTUCKY_MONTICELLO,
    { timeZone: TimeZoneEnum.AM_KENTUCKY_MONTICELLO, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_LOS_ANGELES,
    { timeZone: TimeZoneEnum.AM_LOS_ANGELES, countryCode: 'US', countryName: 'United States', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_MENOMINEE,
    { timeZone: TimeZoneEnum.AM_MENOMINEE, countryCode: 'US', countryName: 'United States', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_METLAKATLA,
    { timeZone: TimeZoneEnum.AM_METLAKATLA, countryCode: 'US', countryName: 'United States', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.AM_NEW_YORK,
    { timeZone: TimeZoneEnum.AM_NEW_YORK, countryCode: 'US', countryName: 'United States', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_NOME,
    { timeZone: TimeZoneEnum.AM_NOME, countryCode: 'US', countryName: 'United States', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.AM_NORTH_DAKOTA_BEULAH,
    { timeZone: TimeZoneEnum.AM_NORTH_DAKOTA_BEULAH, countryCode: 'US', countryName: 'United States', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_NORTH_DAKOTA_CENTER,
    { timeZone: TimeZoneEnum.AM_NORTH_DAKOTA_CENTER, countryCode: 'US', countryName: 'United States', offset: '-05:00' }
  ],
  [
    TimeZoneEnum.AM_NORTH_DAKOTA_NEW_SALEM,
    {
      timeZone: TimeZoneEnum.AM_NORTH_DAKOTA_NEW_SALEM,
      countryCode: 'US',
      countryName: 'United States',
      offset: '-05:00'
    }
  ],
  [
    TimeZoneEnum.AM_PHOENIX,
    { timeZone: TimeZoneEnum.AM_PHOENIX, countryCode: 'US', countryName: 'United States', offset: '-07:00' }
  ],
  [
    TimeZoneEnum.AM_SITKA,
    { timeZone: TimeZoneEnum.AM_SITKA, countryCode: 'US', countryName: 'United States', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.AM_YAKUTAT,
    { timeZone: TimeZoneEnum.AM_YAKUTAT, countryCode: 'US', countryName: 'United States', offset: '-08:00' }
  ],
  [
    TimeZoneEnum.PA_HONOLULU,
    { timeZone: TimeZoneEnum.PA_HONOLULU, countryCode: 'US', countryName: 'United States', offset: '-10:00' }
  ],
  [
    TimeZoneEnum.PA_MIDWAY,
    {
      timeZone: TimeZoneEnum.PA_MIDWAY,
      countryCode: 'UM',
      countryName: 'United States Minor Outlying Islands',
      offset: '-11:00'
    }
  ],
  [
    TimeZoneEnum.PA_WAKE,
    {
      timeZone: TimeZoneEnum.PA_WAKE,
      countryCode: 'UM',
      countryName: 'United States Minor Outlying Islands',
      offset: '+12:00'
    }
  ],
  [
    TimeZoneEnum.AM_MONTEVIDEO,
    { timeZone: TimeZoneEnum.AM_MONTEVIDEO, countryCode: 'UY', countryName: 'Uruguay', offset: '-03:00' }
  ],
  [
    TimeZoneEnum.AS_SAMARKAND,
    { timeZone: TimeZoneEnum.AS_SAMARKAND, countryCode: 'UZ', countryName: 'Uzbekistan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.AS_TASHKENT,
    { timeZone: TimeZoneEnum.AS_TASHKENT, countryCode: 'UZ', countryName: 'Uzbekistan', offset: '+05:00' }
  ],
  [
    TimeZoneEnum.PA_EFATE,
    { timeZone: TimeZoneEnum.PA_EFATE, countryCode: 'VU', countryName: 'Vanuatu', offset: '+11:00' }
  ],
  [
    TimeZoneEnum.AM_CARACAS,
    {
      timeZone: TimeZoneEnum.AM_CARACAS,
      countryCode: 'VE',
      countryName: 'Venezuela, Bolivarian Republic of',
      offset: '-04:00'
    }
  ],
  [
    TimeZoneEnum.AS_HO_CHI_MINH,
    { timeZone: TimeZoneEnum.AS_HO_CHI_MINH, countryCode: 'VN', countryName: 'Viet Nam', offset: '+07:00' }
  ],
  [
    TimeZoneEnum.AM_TORTOLA,
    { timeZone: TimeZoneEnum.AM_TORTOLA, countryCode: 'VG', countryName: 'Virgin Islands, British', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.AM_ST_THOMAS,
    { timeZone: TimeZoneEnum.AM_ST_THOMAS, countryCode: 'VI', countryName: 'Virgin Islands, U.S.', offset: '-04:00' }
  ],
  [
    TimeZoneEnum.PA_WALLIS,
    { timeZone: TimeZoneEnum.PA_WALLIS, countryCode: 'WF', countryName: 'Wallis and Futuna', offset: '+12:00' }
  ],
  [TimeZoneEnum.AS_ADEN, { timeZone: TimeZoneEnum.AS_ADEN, countryCode: 'YE', countryName: 'Yemen', offset: '+03:00' }],
  [
    TimeZoneEnum.AF_LUSAKA,
    { timeZone: TimeZoneEnum.AF_LUSAKA, countryCode: 'ZM', countryName: 'Zambia', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.AF_HARARE,
    { timeZone: TimeZoneEnum.AF_HARARE, countryCode: 'ZW', countryName: 'Zimbabwe', offset: '+02:00' }
  ],
  [
    TimeZoneEnum.EU_MARIEHAMN,
    { timeZone: TimeZoneEnum.EU_MARIEHAMN, countryCode: 'AX', countryName: 'Åland Islands', offset: '+02:00' }
  ]
])
