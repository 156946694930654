import { ErrorType } from '@/api/mutator/custom-instance'

// todo 추후 {msg: "", code: ""} 같은 object 형식으로 변경 예정 with 하진수. (현재 공수로는 백엔드 response 전부 갈아엎긴 힘들다고 판단)
const onAlert = (error: ErrorType<unknown>) => {
  const errorMsg = getMessage(error)
  if (errorMsg) {
    alert(errorMsg)
  }
}

const getMessage = (error: ErrorType<unknown>) => {
  if (!error || !error?.response?.data) return
  const data = error.response.data

  if (Array.isArray(data) && data?.[0]) {
    const msg = data[0]
    if (typeof msg === 'string') {
      return msg
    } else {
      return JSON.stringify(msg)
    }
  }

  if (typeof data === 'string') {
    return data
  }

  return JSON.stringify(data)
}

const getDetailMessage = (error: ErrorType<unknown>) => {
  if (!error || !error?.response?.data) return
  const data = error.response.data

  if (Array.isArray(data) && data?.[0]) {
    const msg = data[0]
    if (typeof msg === 'object') {
      return msg.detail
    }
  }

  if (typeof data === 'object') {
    return Object.entries(data).find(([key]) => key === 'detail')?.[1]
  }

  return JSON.stringify(data)
}

const isValidUrl = (urlString: string) => {
  try {
    new URL(urlString)
    return true
  } catch {
    return false
  }
}

const ErrorUtils = {
  onAlert,
  getMessage,
  getDetailMessage,
  isValidUrl
}

export default ErrorUtils
