/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CALLBACK` - 콜백
 * `NOTIFICATION` - 알림
 * `ROBOT_STATUS` - 로봇 상태
 */
export type WebhookTypeEnum = (typeof WebhookTypeEnum)[keyof typeof WebhookTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookTypeEnum = {
  CALLBACK: 'CALLBACK',
  NOTIFICATION: 'NOTIFICATION',
  ROBOT_STATUS: 'ROBOT_STATUS'
} as const
