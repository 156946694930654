/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `HEAT` - 열화상 카메라
 * `NORMAL` - 일반 카메라
 */
export type ChannelEnum = (typeof ChannelEnum)[keyof typeof ChannelEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChannelEnum = {
  HEAT: 'HEAT',
  NORMAL: 'NORMAL'
} as const
