import React, { ReactNode, useEffect, useMemo } from 'react'
import { ApmBase } from '@elastic/apm-rum'
import { afterFrame } from '@elastic/apm-rum-core'
import { useRouter } from 'next/router'
import AuthContainer from '@/containers/common/AuthContainer'

const ApmTransaction = ({ children, apm }: { children: ReactNode; apm: ApmBase }) => {
  const router = useRouter()

  const { userInfo } = AuthContainer.useContainer()
  apm.setUserContext({ id: userInfo?.id, username: userInfo?.email })

  const transaction = useMemo(() => {
    if (!apm) return
    return apm.startTransaction(router.asPath, 'route-change', {
      managed: true,
      canReuse: true
    })
  }, [apm, router.asPath])

  useEffect(() => {
    if (!transaction) return
    afterFrame(() => transaction && (transaction as any)?.detectFinish())
    return () => {
      /**
       * Incase the transaction is never ended, we check if the transaction
       * can be closed during unmount phase
       *
       * We call detectFinish instead of forcefully ending the transaction
       * since it could be a redirect route and we might prematurely close
       * the currently running transaction
       */
      transaction && (transaction as any)?.detectFinish()
    }
  }, [transaction])

  return <>{children}</>
}

export default ApmTransaction
