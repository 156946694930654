/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CALIBRATION_PHASE_ERROR` - 캘리브레이션 단계가 잘못되었습니다.
 */
export type RobotCalibrationPhaseError400ExceptionCodeEnum =
  (typeof RobotCalibrationPhaseError400ExceptionCodeEnum)[keyof typeof RobotCalibrationPhaseError400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RobotCalibrationPhaseError400ExceptionCodeEnum = {
  CALIBRATION_PHASE_ERROR: 'CALIBRATION_PHASE_ERROR'
} as const
