/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ADMIN` - 통합 관리자
 * `CAMPING_MANAGER` - 캠핑장 관리자
 * `COMPANY_ADMIN` - 회사 관리자
 * `CONTROLLER` - 현장 조종자
 * `CONTROL_CENTER_MANAGER` - 관제 센터 매니저
 * `NONE` - 기본값
 * `PATROL_MANAGER` - 순찰 관리자
 * `SITE_ADMIN` - 사이트 관리자
 * `SITE_SETUP_MANAGER` - 사이트 셋업 매니저
 */
export type UserTypeEnum = (typeof UserTypeEnum)[keyof typeof UserTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTypeEnum = {
  ADMIN: 'ADMIN',
  CAMPING_MANAGER: 'CAMPING_MANAGER',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  CONTROLLER: 'CONTROLLER',
  CONTROL_CENTER_MANAGER: 'CONTROL_CENTER_MANAGER',
  NONE: 'NONE',
  PATROL_MANAGER: 'PATROL_MANAGER',
  SITE_ADMIN: 'SITE_ADMIN',
  SITE_SETUP_MANAGER: 'SITE_SETUP_MANAGER'
} as const
