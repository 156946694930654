/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type ScenarioTypesListCategoriesItem =
  (typeof ScenarioTypesListCategoriesItem)[keyof typeof ScenarioTypesListCategoriesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioTypesListCategoriesItem = {
  CUSTOM: 'CUSTOM',
  DELIVERY: 'DELIVERY',
  DELIVERY_ROTATE: 'DELIVERY_ROTATE',
  DISMISS: 'DISMISS',
  KT_DELIVERY: 'KT_DELIVERY',
  KT_PATROL: 'KT_PATROL',
  LIT_PATROL: 'LIT_PATROL',
  NB_ORDER_DELIVERY: 'NB_ORDER_DELIVERY',
  NB_ORDER_DELIVERY_DOCUMENT: 'NB_ORDER_DELIVERY_DOCUMENT',
  OPENAPI_DELIVERY: 'OPENAPI_DELIVERY',
  PATROL: 'PATROL',
  READY: 'READY',
  TEST: 'TEST'
} as const
