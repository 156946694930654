/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `SAME_FILE_NAME` - 같은 파일 이름이 존재합니다.
 * `NOT_GZIP_FILE` - GZIP 파일이 아닙니다. 파일을 확인해주세요.
 * `DISCONNECTED_PATH` - 경로가 끊어졌습니다.
 * `SITE_MAP_SOME_BUILDING_IDS_INVALID` - 건물 ID 중 일부가 유효하지 않습니다.
 * `SITE_MAP_SOME_BUILDING_IDS_DO_NOT_EXIST` - 건물 ID 중 일부가 데이터베이스에 존재하지 않습니다.
 * `WAYS_HAVE_OVERLAPPING_SEGMENTS` - 서로 다른 길이 겹치는 부분이 있습니다.
 */
export type SiteGlobalPathUpload400ExceptionCodeEnum =
  (typeof SiteGlobalPathUpload400ExceptionCodeEnum)[keyof typeof SiteGlobalPathUpload400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteGlobalPathUpload400ExceptionCodeEnum = {
  SAME_FILE_NAME: 'SAME_FILE_NAME',
  NOT_GZIP_FILE: 'NOT_GZIP_FILE',
  DISCONNECTED_PATH: 'DISCONNECTED_PATH',
  SITE_MAP_SOME_BUILDING_IDS_INVALID: 'SITE_MAP_SOME_BUILDING_IDS_INVALID',
  SITE_MAP_SOME_BUILDING_IDS_DO_NOT_EXIST: 'SITE_MAP_SOME_BUILDING_IDS_DO_NOT_EXIST',
  WAYS_HAVE_OVERLAPPING_SEGMENTS: 'WAYS_HAVE_OVERLAPPING_SEGMENTS'
} as const
