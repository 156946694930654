/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ACCEPTED` - 수락
 * `CANCELED` - 취소
 * `CANCEL_IN_PROGRESS` - 취소 중
 * `DONE` - 완료
 * `ERROR` - 오류
 * `IN_PROGRESS` - 진행 중
 * `REQUEST` - 요청
 */
export type SequenceStatusEnum = (typeof SequenceStatusEnum)[keyof typeof SequenceStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SequenceStatusEnum = {
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CANCEL_IN_PROGRESS: 'CANCEL_IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  REQUEST: 'REQUEST'
} as const
