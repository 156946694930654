/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CCTV` - CCTV
 * `GAS_SENSOR` - 가스 탐지기
 * `HIKVISION_CCTV` - 하이크비전 CCTV
 */
export type AddonTypesEnum = (typeof AddonTypesEnum)[keyof typeof AddonTypesEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddonTypesEnum = {
  CCTV: 'CCTV',
  GAS_SENSOR: 'GAS_SENSOR',
  HIKVISION_CCTV: 'HIKVISION_CCTV'
} as const
