/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ELEVATOR_NOT_FOUND` - 엘리베이터를 찾을 수 없습니다.
 */
export type ElevatorRetrieve404ExceptionCodeEnum =
  (typeof ElevatorRetrieve404ExceptionCodeEnum)[keyof typeof ElevatorRetrieve404ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElevatorRetrieve404ExceptionCodeEnum = {
  ELEVATOR_NOT_FOUND: 'ELEVATOR_NOT_FOUND'
} as const
