/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `1` - 매우 낮음
 * `2` - 낮음
 * `3` - 보통
 * `4` - 높음
 * `5` - 매우 높음
 */
export type SimplificationLevelEnum = (typeof SimplificationLevelEnum)[keyof typeof SimplificationLevelEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimplificationLevelEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5
} as const
