/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type ScenarioTypesListOrderByItem =
  (typeof ScenarioTypesListOrderByItem)[keyof typeof ScenarioTypesListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioTypesListOrderByItem = {
  '-category': '-category',
  '-created_at': '-created_at',
  '-id': '-id',
  '-name': '-name',
  category: 'category',
  created_at: 'created_at',
  id: 'id',
  name: 'name'
} as const
