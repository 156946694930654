/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `FAN` - 팬
 * `HEATER` - 히터
 * `LIGHT` - 조명
 * `WIPER` - 와이퍼
 */
export type RobotPtzAuxReqTypeEnum = (typeof RobotPtzAuxReqTypeEnum)[keyof typeof RobotPtzAuxReqTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RobotPtzAuxReqTypeEnum = {
  FAN: 'FAN',
  HEATER: 'HEATER',
  LIGHT: 'LIGHT',
  WIPER: 'WIPER'
} as const
