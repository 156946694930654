/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ROBOT_NOT_IN_MONITORING_LIST` - 모니터링 리스트에 로봇이 없습니다.
 */
export type MonitoringEStop400ExceptionCodeEnum =
  (typeof MonitoringEStop400ExceptionCodeEnum)[keyof typeof MonitoringEStop400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringEStop400ExceptionCodeEnum = {
  ROBOT_NOT_IN_MONITORING_LIST: 'ROBOT_NOT_IN_MONITORING_LIST'
} as const
