/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type UsersListOrderByItem = (typeof UsersListOrderByItem)[keyof typeof UsersListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersListOrderByItem = {
  '-date_joined': '-date_joined',
  '-id': '-id',
  '-name': '-name',
  '-user_type': '-user_type',
  '-username': '-username',
  date_joined: 'date_joined',
  id: 'id',
  name: 'name',
  user_type: 'user_type',
  username: 'username'
} as const
