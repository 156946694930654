/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type NotificationTypeCategoriesListOrderByItem =
  (typeof NotificationTypeCategoriesListOrderByItem)[keyof typeof NotificationTypeCategoriesListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationTypeCategoriesListOrderByItem = {
  '-id': '-id',
  '-name': '-name',
  '-name_en': '-name_en',
  id: 'id',
  name: 'name',
  name_en: 'name_en'
} as const
