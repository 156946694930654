/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * 적재함 상태

* `OPEN` - OPEN
* `CLOSE` - CLOSE
 */
export type CargoStatusChangedReqCargoStatus =
  (typeof CargoStatusChangedReqCargoStatus)[keyof typeof CargoStatusChangedReqCargoStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoStatusChangedReqCargoStatus = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE'
} as const
