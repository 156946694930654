/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `AUTO` - 오토 캘리브레이션
 * `ONLINE` - 온라인 캘리브레이션
 */
export type CalibrationTypeEnum = (typeof CalibrationTypeEnum)[keyof typeof CalibrationTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalibrationTypeEnum = {
  AUTO: 'AUTO',
  ONLINE: 'ONLINE'
} as const
