/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type UsersListUserTypesItem = (typeof UsersListUserTypesItem)[keyof typeof UsersListUserTypesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersListUserTypesItem = {
  ADMIN: 'ADMIN',
  CAMPING_MANAGER: 'CAMPING_MANAGER',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  CONTROLLER: 'CONTROLLER',
  CONTROL_CENTER_MANAGER: 'CONTROL_CENTER_MANAGER',
  NONE: 'NONE',
  PATROL_MANAGER: 'PATROL_MANAGER',
  SITE_ADMIN: 'SITE_ADMIN',
  SITE_SETUP_MANAGER: 'SITE_SETUP_MANAGER'
} as const
