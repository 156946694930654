/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `USER_PIN_NOT_VALID` - USER_PIN_NOT_VALID
 * `PIN_INVALID_COUNT_EXCEEDED` - PIN_INVALID_COUNT_EXCEEDED
 */
export type UserVerifyPin400ExceptionCodeEnum =
  (typeof UserVerifyPin400ExceptionCodeEnum)[keyof typeof UserVerifyPin400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserVerifyPin400ExceptionCodeEnum = {
  USER_PIN_NOT_VALID: 'USER_PIN_NOT_VALID',
  PIN_INVALID_COUNT_EXCEEDED: 'PIN_INVALID_COUNT_EXCEEDED'
} as const
