/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `delivering` - 배달
 * `patrol` - 순회
 */
export type TaskCodeEnum = (typeof TaskCodeEnum)[keyof typeof TaskCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskCodeEnum = {
  delivering: 'delivering',
  patrol: 'patrol'
} as const
