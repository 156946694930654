/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `SITE_MAP_NOT_FOUND` - SITE MAP을 찾을 수 없습니다.
 * `NOT_GZIP_FILE` - GZIP 파일이 아닙니다. 파일을 확인해주세요.
 * `OSM_FILE_NOT_FOUND` - OSM 파일을 찾을 수 없습니다.
 * `DISCONNECTED_PATH` - 경로가 끊어졌습니다.
 * `SITE_MAP_SOME_BUILDING_IDS_INVALID` - 건물 ID 중 일부가 유효하지 않습니다.
 * `SITE_MAP_SOME_BUILDING_IDS_DO_NOT_EXIST` - 건물 ID 중 일부가 데이터베이스에 존재하지 않습니다.
 * `WAYS_HAVE_OVERLAPPING_SEGMENTS` - 서로 다른 길이 겹치는 부분이 있습니다.
 * `MAP_ALREADY_MODIFIED` - 다른 사람이 이미 수정했습니다.
 */
export type SiteGlobalPathUpdate400ExceptionCodeEnum =
  (typeof SiteGlobalPathUpdate400ExceptionCodeEnum)[keyof typeof SiteGlobalPathUpdate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteGlobalPathUpdate400ExceptionCodeEnum = {
  SITE_MAP_NOT_FOUND: 'SITE_MAP_NOT_FOUND',
  NOT_GZIP_FILE: 'NOT_GZIP_FILE',
  OSM_FILE_NOT_FOUND: 'OSM_FILE_NOT_FOUND',
  DISCONNECTED_PATH: 'DISCONNECTED_PATH',
  SITE_MAP_SOME_BUILDING_IDS_INVALID: 'SITE_MAP_SOME_BUILDING_IDS_INVALID',
  SITE_MAP_SOME_BUILDING_IDS_DO_NOT_EXIST: 'SITE_MAP_SOME_BUILDING_IDS_DO_NOT_EXIST',
  WAYS_HAVE_OVERLAPPING_SEGMENTS: 'WAYS_HAVE_OVERLAPPING_SEGMENTS',
  MAP_ALREADY_MODIFIED: 'MAP_ALREADY_MODIFIED'
} as const
