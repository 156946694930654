/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `DRIVING_USER_ALREADY_EXISTS` - 이미 관제개입한 유저가 있습니다.
 */
export type MonitoringInterveneReady400ExceptionCodeEnum =
  (typeof MonitoringInterveneReady400ExceptionCodeEnum)[keyof typeof MonitoringInterveneReady400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringInterveneReady400ExceptionCodeEnum = {
  DRIVING_USER_ALREADY_EXISTS: 'DRIVING_USER_ALREADY_EXISTS'
} as const
