/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ROBOT_NOT_FOUND` - 로봇을 찾을 수 없습니다.
 */
export type MonitoringEStop404ExceptionCodeEnum =
  (typeof MonitoringEStop404ExceptionCodeEnum)[keyof typeof MonitoringEStop404ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringEStop404ExceptionCodeEnum = {
  ROBOT_NOT_FOUND: 'ROBOT_NOT_FOUND'
} as const
