export enum IconNamesEnum {
  Apple = 'apple',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Ban = 'ban',
  Basket = 'basket',
  BatteryFair = 'battery-fair',
  BellAlt = 'bell-alt',
  CalendarPen = 'calendar-pen',
  Calendar = 'calendar',
  Camera = 'camera',
  Card = 'card',
  CargoOpen = 'cargo-open',
  ChartGantt = 'chart-gantt',
  Check = 'check',
  CheckboxChecked = 'checkbox-checked',
  CheckboxUnchecked = 'checkbox-unchecked',
  ChevronDown = 'chevron-down',
  ChevronLeftDouble = 'chevron-left-double',
  ChevronLeft = 'chevron-left',
  ChevronRightDouble = 'chevron-right-double',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  CircleQuestion = 'circle-question',
  Circuit = 'circuit',
  ClipboardList = 'clipboard-list',
  Cloche = 'cloche',
  Clock = 'clock',
  CloseSmall = 'close-small',
  Close = 'close',
  CloudUpload = 'cloud-upload',
  Coins = 'coins',
  Combine = 'combine',
  Crescent = 'crescent',
  Crosshair = 'crosshair',
  Cursoralt = 'cursoralt',
  Cursoraltnodeadd = 'cursoraltnodeadd',
  Cursorhandalt = 'cursorhandalt',
  Cursorpennode = 'cursorpennode',
  DashboardCustomize = 'dashboard-customize',
  Delete = 'delete',
  DoorOpen = 'door-open',
  Download = 'download',
  EyeSlash = 'eye-slash',
  Eye = 'eye',
  FileList = 'file-list',
  Fix = 'fix',
  Flipbackward = 'flipbackward',
  Flipforward = 'flipforward',
  Gamingpad = 'gamingpad',
  Gear = 'gear',
  GoogleMapDisabled = 'google-map-disabled',
  Handle = 'handle',
  HomeFilledFalse = 'home-filled-false',
  HomeFilledTrue = 'home-filled-true',
  IconBoltWired = 'icon-bolt-wired',
  IconBoltWireless = 'icon-bolt-wireless',
  Information = 'information',
  Kakao = 'kakao',
  LabelFalseSizeSStateDisabledCheckSelected = 'label-false-size-s-state-disabled-check-selected',
  LabelFalseSizeSStateDisabledCheckUnselected = 'label-false-size-s-state-disabled-check-unselected',
  LabelFalseSizeSStateEnabledCheckSelected = 'label-false-size-s-state-enabled-check-selected',
  LabelFalseSizeSStateEnabledCheckUnselected = 'label-false-size-s-state-enabled-check-unselected',
  LabelFalseSizeSStateHoverCheckSelected = 'label-false-size-s-state-hover-check-selected',
  LabelFalseSizeSStateHoverCheckUnselected = 'label-false-size-s-state-hover-check-unselected',
  LabelFalseSizeSStatePressedCheckSelected = 'label-false-size-s-state-pressed-check-selected',
  LabelFalseSizeSStatePressedCheckUnselected = 'label-false-size-s-state-pressed-check-unselected',
  Layer = 'layer',
  LightEmergency = 'light-emergency',
  LocationArrowFilledFalse = 'location-arrow-filled-false',
  LocationArrowFilledTrue = 'location-arrow-filled-true',
  LocationArrowSlant = 'location-arrow-slant',
  LocationCrosshairs = 'location-crosshairs',
  LocationFilledFalse = 'location-filled-false',
  LocationFilledTrue = 'location-filled-true',
  LocationPinFair = 'location-pin-fair',
  LocationPin = 'location-pin',
  Loop = 'loop',
  MapLocationPin = 'map-location-pin',
  MapPinSlash = 'map-pin-slash',
  MapPin = 'map-pin',
  Maximize = 'maximize',
  Microphone = 'microphone',
  Minimize = 'minimize',
  Minus = 'minus',
  Monitor = 'monitor',
  Move = 'move',
  Music = 'music',
  NaverMapDisabled = 'naver-map-disabled',
  NaverMap = 'naver-map',
  NeubilityLogo = 'neubility-logo',
  Nodeadd = 'nodeadd',
  OrderListFilledFalse = 'order-list-filled-false',
  OrderListFilledTrue = 'order-list-filled-true',
  Pen = 'pen',
  Pensquare = 'pensquare',
  Phone = 'phone',
  Play = 'play',
  Plus = 'plus',
  QrScan = 'qr-scan',
  RadioChecked = 'radio-checked',
  RadioFalseHoverFalsePressedFalseDisabledFalse = 'radio-false-hover-false-pressed-false-disabled-false',
  RadioFalseHoverFalsePressedFalseDisabledTrue = 'radio-false-hover-false-pressed-false-disabled-true',
  RadioFalseHoverFalsePressedTrueDisabledFalse = 'radio-false-hover-false-pressed-true-disabled-false',
  RadioFalseHoverTruePressedFalseDisabledFalse = 'radio-false-hover-true-pressed-false-disabled-false',
  RadioTrueHoverFalsePressedFalseDisabledFalse = 'radio-true-hover-false-pressed-false-disabled-false',
  RadioTrueHoverFalsePressedFalseDisabledTrue = 'radio-true-hover-false-pressed-false-disabled-true',
  RadioTrueHoverFalsePressedTrueDisabledFalse = 'radio-true-hover-false-pressed-true-disabled-false',
  RadioTrueHoverTruePressedFalseDisabledFalse = 'radio-true-hover-true-pressed-false-disabled-false',
  RadioUnchecked = 'radio-unchecked',
  RefreshAll = 'refresh-all',
  Refresh = 'refresh',
  RoundCheckFalseHoverFalsePressedFalseDisabledFalse = 'round-check-false-hover-false-pressed-false-disabled-false',
  RoundCheckFalseHoverFalsePressedFalseDisabledTrue = 'round-check-false-hover-false-pressed-false-disabled-true',
  RoundCheckFalseHoverFalsePressedTrueDisabledFalse = 'round-check-false-hover-false-pressed-true-disabled-false',
  RoundCheckFalseHoverTruePressedFalseDisabledFalse = 'round-check-false-hover-true-pressed-false-disabled-false',
  RoundCheckTrueHoverFalsePressedFalseDisabledFalse = 'round-check-true-hover-false-pressed-false-disabled-false',
  RoundCheckTrueHoverFalsePressedFalseDisabledTrue = 'round-check-true-hover-false-pressed-false-disabled-true',
  RoundCheckTrueHoverFalsePressedTrueDisabledFalse = 'round-check-true-hover-false-pressed-true-disabled-false',
  RoundCheckTrueHoverTruePressedFalseDisabledFalse = 'round-check-true-hover-true-pressed-false-disabled-false',
  Route = 'route',
  Scissors = 'scissors',
  ScreenUser = 'screen-user',
  Search = 'search',
  SignalFair = 'signal-fair',
  SignalGood = 'signal-good',
  SignalPoor = 'signal-poor',
  SignalWeak = 'signal-weak',
  SmileFilledFalse = 'smile-filled-false',
  SmileFilledTrue = 'smile-filled-true',
  Stop = 'stop',
  Sun = 'sun',
  SwitchOff = 'switch-off',
  SwitchOn = 'switch-on',
  TowerBroadcastSlash = 'tower-broadcast-slash',
  Trash = 'trash',
  TriangleExclamation = 'triangle-exclamation',
  Union = 'union',
  Upload = 'upload',
  UserAlt = 'user-alt',
  VideoSlash = 'video-slash',
  VolumeMax = 'volume-max',
  Wifi = 'wifi'
}
