/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `DRIVING_AGENT` - 드라이빙 요원
 * `NOT_ASSIGNED` - 미배정
 * `ON_SITE` - 현장 요원
 */
export type ResolutionTypeEnum = (typeof ResolutionTypeEnum)[keyof typeof ResolutionTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolutionTypeEnum = {
  DRIVING_AGENT: 'DRIVING_AGENT',
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  ON_SITE: 'ON_SITE'
} as const
