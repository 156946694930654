/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `0` - 심각
 * `1` - 오류
 * `2` - 경고
 * `3` - 주시
 * `4` - 정보
 */
export type LevelEnum = (typeof LevelEnum)[keyof typeof LevelEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LevelEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4
} as const
