import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { createContainer } from 'unstated-next'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { TimeZoneEnum, TimeZoneInfoMap, TimeZoneInfoType } from '@/constants/timeZone'
import DataUtils from '@/utils/design-system/dataUtils'
import { DateFormatForDisplay, DateStrType, DateUtils } from '@/utils/design-system/dateUtils'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'

export type GetTimeZoneDateStringType = (date?: DateStrType, format?: DateFormatForDisplay) => string

const TimeZoneContainer = createContainer(() => {
  const { t } = useTranslation(I18nNamespaceEnum.Common)
  const [userTimeZone, setUserTimeZone] = useState<TimeZoneEnum>(TimeZoneEnum.UTC)

  useEffect(() => {
    /**
     * 사용자 타임존 초기화
     *  1. LocalStorage에 저장된 타임존 정보가 있으면 해당 정보를 사용
     *  2. 아니면 브라우저 타임존을 사용
     */
    const initUserTimeZone = () => {
      const timeZoneToInit =
        LocalStorage.getItem<TimeZoneEnum>(LocalStorageKeyEnum.UserTimeZone) ?? getBrowserTimeZone()
      setUserTimeZone(timeZoneToInit)
    }
    initUserTimeZone()
  }, [])

  /**
   * 타임존 리턴
   * @private
   * @param {TimeZoneEnum} timeZone
   * @returns {TimeZoneInfoType | undefined}
   */
  const _getTimeZoneInfo = (timeZone: TimeZoneEnum): TimeZoneInfoType | undefined => {
    return TimeZoneInfoMap.get(timeZone)
  }

  /**
   * 브라우저 타임존 리턴
   * @returns {TimeZoneEnum} 브라우저의 타임존 리턴
   */
  const getBrowserTimeZone = (): TimeZoneEnum => {
    const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
    const tzEnumKey = DataUtils.getKeyByValue(TimeZoneEnum, browserTZ) ?? TimeZoneEnum.UTC
    return TimeZoneEnum[tzEnumKey as keyof typeof TimeZoneEnum]
  }

  /**
   * 브라우저 타임존 정보 리턴
   * @returns {TimeZoneInfoType | undefined}
   */
  const getBrowserTimeZoneInfo = (): TimeZoneInfoType | undefined => {
    return _getTimeZoneInfo(getBrowserTimeZone())
  }

  /**
   * 회원의 현재 타임존 정보 리턴
   * @returns {TimeZoneInfoType | undefined} 현재 설정된 회원의 타임존 정보
   */
  const getUserTimeZoneInfo = (): TimeZoneInfoType | undefined => {
    return _getTimeZoneInfo(userTimeZone)
  }

  /**
   * 새로운 타임존 LocalStorage 저장
   * @param {TimeZoneEnum} newTimeZone
   */
  const saveUserTimeZone = (newTimeZone: TimeZoneEnum) => {
    const newTimeZoneToSet = newTimeZone === TimeZoneEnum.BROWSER_TIME ? getBrowserTimeZone() : newTimeZone
    setUserTimeZone(newTimeZoneToSet)
    LocalStorage.setItem(LocalStorageKeyEnum.UserTimeZone, newTimeZoneToSet)
  }

  /**
   * 회원 타임존의 utcOffsetString을 분으로 변경. date 문자열을 변경할 때 마다 호출되기 때문에 useMemo 사용.
   * @private
   * @return 분으로 변경된 회원 타임존의 offset
   */
  const userTimeZoneUtcOffsetMinute = () => {
    const utcOffsetString = getUserTimeZoneInfo()?.offset
    return DateUtils.toUtcOffsetMinute(utcOffsetString)
  }

  /**
   * 현재 타임존을 기준으로 날짜를 가져옴
   * @param {DateStrType} date
   * @returns {string} 현재 타임존 기준 날짜 문자열
   */
  const getTimeZoneDateString: GetTimeZoneDateStringType = (
    date?: DateStrType,
    format?: DateFormatForDisplay
  ): string => {
    return DateUtils.getUtcDateString(date, userTimeZoneUtcOffsetMinute(), format) ?? '-'
  }

  return {
    t,
    userTimeZoneUtcOffsetMinute,
    saveUserTimeZone,
    getTimeZoneDateString,
    getBrowserTimeZone,
    getUserTimeZoneInfo,
    getBrowserTimeZoneInfo
  }
})

export default TimeZoneContainer
