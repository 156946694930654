import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import Router from 'next/router'
import { CamelCase } from 'type-fest'
import { tokenRefreshCreate } from '@/api/generated/hooks'
import { StatusEnum } from '@/api/generated/types'
import TimeoutError from '@/components/design-system/error/TimeoutError'
import { QueryKeys, Routes } from '@/constants/routes'
import EnvUtils from '@/utils/design-system/envUtils'
import { PromiseLocker } from '@/utils/design-system/promiseLocker'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

export const ASK_ACCESS_TOKEN_URL = '/token/refresh/'
export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.API_DOMAIN }) // use your own URL here or environment variable
const getAccessToken = () => {
  if (EnvUtils.isServer()) return
  const accessToken = LocalStorage.getItem<string>(LocalStorageKeyEnum.AccessToken)
  if (!accessToken) return
  return accessToken
}

export interface TaskResultOption {
  retryCount: number
  timeoutMs: number
  repeatIntervalMs: number
}

/**
 *
 * @param url
 * @param option
 * @throws TimeoutError
 * @throws MaxRetryError
 */
export const getTaskResult = async <TRes extends { status: StatusEnum }>(
  url: string,
  option?: TaskResultOption
): Promise<TRes> => {
  const { retryCount = 3, timeoutMs = 60 * 1000, repeatIntervalMs = 3 * 1000 } = option || {}
  let retryCountNow = retryCount
  const startAtMs = Date.now()
  while (startAtMs + timeoutMs > Date.now()) {
    try {
      const res = await customInstance<TRes>({ url, method: 'GET' }).then((res) => res)
      if (res.status === StatusEnum.SUCCESS || res.status === StatusEnum.FAILURE) {
        return res
      }
    } catch (e) {
      retryCountNow -= 1
      if (retryCountNow === 0) {
        throw e
      }
    }
    await new Promise((resolve) => setTimeout(resolve, repeatIntervalMs))
  }
  throw new TimeoutError(`timeoutMs: ${timeoutMs}, startAtMs: ${startAtMs}, now: ${Date.now()}`)
}

const promiseLock = PromiseLocker()

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const accessToken = getAccessToken()
  const source = Axios.CancelToken.source()

  const instanceConfig = {
    ...options,
    ...config,
    cancelToken: source.token,
    withCredentials: true,
    formSerializer: {
      dots: true
    },
    headers: {
      ...config?.headers,
      ...options?.headers,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(Router.locale && { ['Accept-Language']: Router.locale })
    },
    paramsSerializer: (params: Record<string, any>) => {
      const searchParams = new URLSearchParams()
      for (const key of Object.keys(params)) {
        const param = params[key]
        if (Array.isArray(param)) {
          searchParams.append(key, param.join(','))
        } else {
          searchParams.append(key, param)
        }
      }
      return searchParams.toString()
    }
  }
  return AXIOS_INSTANCE(instanceConfig).then(({ data }) => data)
}

AXIOS_INSTANCE.interceptors.response.use(
  function (response) {
    return response
  },
  async (error) => {
    // accessToken 만료
    if (error.response.status === 403 && !EnvUtils.isServer()) {
      // 여러 api 요청이 accessToken 만료가 되었을 때  promiseLock 으로 한번만 accessToken 요청
      if (promiseLock.lock()) {
        LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
        const tokenRes = await tokenRefreshCreate()
        LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, tokenRes.accessToken)
        promiseLock.release()
      } else {
        await promiseLock.waitUnLock()
      }
    }
    // refreshToken 만료
    if (error.response.config.url === ASK_ACCESS_TOKEN_URL && error.response.status === 401 && !EnvUtils.isServer()) {
      SessionStorage.setItem(SessionStorageKeyEnum.BEFORE_REDIRECT_URL, Router.asPath)
      LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
      Router.replace({
        pathname: Routes.Login,
        query: {
          [QueryKeys.RefreshExpired]: true
        }
      })
    }
    return Promise.reject(error)
  }
)

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>

// In case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
export type BodyType<BodyData> = CamelCase<BodyData>

export enum QueryStatusEnum {
  ERROR = 'error',
  LOADING = 'loading',
  SUCCESS = 'success'
}

/**
 * Idle: mutation을 한번도 보낸적 없는 상태
 */
export enum MutateStatusEnum {
  IDLE = 'idle',
  ERROR = 'error',
  LOADING = 'loading',
  SUCCESS = 'success'
}
