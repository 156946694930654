/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ktdelivery` - KT 로봇 배송
 * `ktpatrol` - KT 로봇 순회
 */
export type MissionCodeEnum = (typeof MissionCodeEnum)[keyof typeof MissionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MissionCodeEnum = {
  ktdelivery: 'ktdelivery',
  ktpatrol: 'ktpatrol'
} as const
