/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type MonitoringsIntervenesListResolutionTypeItem =
  (typeof MonitoringsIntervenesListResolutionTypeItem)[keyof typeof MonitoringsIntervenesListResolutionTypeItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringsIntervenesListResolutionTypeItem = {
  DRIVING_AGENT: 'DRIVING_AGENT',
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  ON_SITE: 'ON_SITE'
} as const
