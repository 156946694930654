/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `DRIVING_USER_NOT_IDENTICAL` - 관제개입한 유저가 아닙니다.
 */
export type MonitoringInterveneProceed400ExceptionCodeEnum =
  (typeof MonitoringInterveneProceed400ExceptionCodeEnum)[keyof typeof MonitoringInterveneProceed400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringInterveneProceed400ExceptionCodeEnum = {
  DRIVING_USER_NOT_IDENTICAL: 'DRIVING_USER_NOT_IDENTICAL'
} as const
