/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `NONE` - 주행 중
 * `STOP` - 횡단보도 멈춤
 */
export type CrossingStatusEnum = (typeof CrossingStatusEnum)[keyof typeof CrossingStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrossingStatusEnum = {
  NONE: 'NONE',
  STOP: 'STOP'
} as const
