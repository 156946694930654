/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `AUTONOMOUS` - 자율 주행
 * `CONTROL` - 리모콘
 * `OPERATOR` - 원격 관제
 */
export type ControlStatusEnum = (typeof ControlStatusEnum)[keyof typeof ControlStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlStatusEnum = {
  AUTONOMOUS: 'AUTONOMOUS',
  CONTROL: 'CONTROL',
  OPERATOR: 'OPERATOR'
} as const
