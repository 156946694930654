/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CAMPING` - 캠핑장
 * `CAMPUS` - 캠퍼스
 * `GOLF` - 골프장
 * `URBAN` - 도심
 */
export type AutonomyTypeEnum = (typeof AutonomyTypeEnum)[keyof typeof AutonomyTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AutonomyTypeEnum = {
  CAMPING: 'CAMPING',
  CAMPUS: 'CAMPUS',
  GOLF: 'GOLF',
  URBAN: 'URBAN'
} as const
