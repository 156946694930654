/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `USER_PIN_NOT_VALID` - 유효하지 않은 PIN입니다.
 */
export type ScenarioNonAuthReleaseAlarm400ExceptionCodeEnum =
  (typeof ScenarioNonAuthReleaseAlarm400ExceptionCodeEnum)[keyof typeof ScenarioNonAuthReleaseAlarm400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioNonAuthReleaseAlarm400ExceptionCodeEnum = {
  USER_PIN_NOT_VALID: 'USER_PIN_NOT_VALID'
} as const
