import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Router, { useRouter } from 'next/router'
import { createContainer } from 'unstated-next'
import { useLoginCreate, useLogoutCreate, useUsersInfoRetrieve } from '@/api/generated/hooks'
import { LoginReqRequest, TokenRefreshRes, UserRes } from '@/api/generated/types'
import { ASK_ACCESS_TOKEN_URL, QueryStatusEnum } from '@/api/mutator/custom-instance'
import { QueryKeys, Routes } from '@/constants/routes'
import DataUtils from '@/utils/design-system/dataUtils'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

export enum LoginStatusEnum {
  Login = 'Login',
  Logout = 'Logout',
  Loading = 'Loading',
  Init = 'Init'
}

const TOKEN_REFRESH_QUERY_KEY = [ASK_ACCESS_TOKEN_URL]

// 성수 사이트 입니다. 성수사이트는 영구 결번입니다.
const DEFAULT_SITE_NUMBER = 1

export const askAccessToken = () => {
  // accessToken 발급은 쿠키에 저장된 refreshToken으로 하기에 별도 데이터가 없어도 됩니다.
  return axios.post<TokenRefreshRes>(process.env.API_DOMAIN + ASK_ACCESS_TOKEN_URL, undefined, {
    withCredentials: true,
    headers: {
      ...(Router.locale && { ['Accept-Language']: Router.locale })
    }
  })
}

const useAuthHook = () => {
  const { push, replace, query } = useRouter()
  const [loginStatus, setLoginStatus] = useState<LoginStatusEnum>(LoginStatusEnum.Init)
  const { data: loginConfirmRes, status: loginConfirmStatus } = useQuery({
    queryKey: TOKEN_REFRESH_QUERY_KEY,
    queryFn: askAccessToken,
    retry: 0,
    cacheTime: 0,
    enabled: loginStatus === LoginStatusEnum.Init
  })
  // TODO: 현재 사이트 ID를 이 state만 사용하도록 변경할 것인지 결정 후 수정 필요
  const [siteId, setSiteId] = useState<number>()

  // 유저 프로필
  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    isRefetching: isUserInfoRefetching
  } = useUsersInfoRetrieve({ query: { enabled: loginStatus === LoginStatusEnum.Login } })

  // 로그인
  const { mutateAsync: createLoginMutation, isLoading: isLoginMutationLoading } = useLoginCreate()
  const isLoginLoading =
    isLoginMutationLoading || (loginStatus === LoginStatusEnum.Login && isUserInfoLoading) || isUserInfoRefetching

  const login = async (data: LoginReqRequest) => {
    const loginResponse = await createLoginMutation({ data })
    setLoginStatus(LoginStatusEnum.Login)
    LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, loginResponse.accessToken)
    // 로그인 전에 있던 화면으로 이동
    const redirectUrl = SessionStorage.getItem(SessionStorageKeyEnum.BEFORE_REDIRECT_URL)
    if (redirectUrl) {
      SessionStorage.removeItem(SessionStorageKeyEnum.BEFORE_REDIRECT_URL)
      replace(redirectUrl)
      return
    }
    // 홈에서 바로 로그인 화면으로 진입시
    replace(Routes.Home)
  }

  // 로그아웃
  const { mutateAsync: createLogoutMutation, isLoading: isLogoutLoading } = useLogoutCreate()

  const logout = async () => {
    await createLogoutMutation()
    setSiteId(undefined)
    setLoginStatus(LoginStatusEnum.Logout)
  }

  const clearUserInfoStorage = () => {
    LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
  }

  // app 첫 실행시 accessToken 갱신 시도 시 이뤄지는 과정
  useEffect(() => {
    if (loginConfirmStatus === QueryStatusEnum.SUCCESS) {
      LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, loginConfirmRes.data.accessToken)
      setLoginStatus(LoginStatusEnum.Login)
      return
    }
    if (loginConfirmStatus === QueryStatusEnum.LOADING) {
      setLoginStatus(LoginStatusEnum.Loading)
      return
    }
    if (loginConfirmStatus === QueryStatusEnum.ERROR) {
      setLoginStatus(LoginStatusEnum.Logout)
      clearUserInfoStorage()
      return
    }
  }, [loginConfirmStatus, loginConfirmRes])

  useEffect(() => {
    const logoutFlag = query?.[QueryKeys.LogoutFlag]
    if (loginStatus === LoginStatusEnum.Logout && logoutFlag) {
      /**
       * ⚠️ 주의!
       * 쿼리키가 삭제되었거나 invalidate 처리가 되었을 때 페이지에서 해당 데이터를 렌더링 하는 곳이 있다면
       * 렌더링 되는 값에 대한 쿼리가 곧바로 refetch 됩니다.
       */

      clearUserInfoStorage()
      // Todo: 해당 코드가 언제 실행이 되는지 잘 모르겠습니다. 주석을 살리면 캐시가 날아가는데 실행될 타이밍이 아닌데도 캐시가 날아갑니다.
      // queryClient.clear()
    }
  }, [loginStatus, query])

  useEffect(() => {
    if (userInfo && !siteId) {
      setSiteId(getUserSiteNumber(userInfo))
    }
  }, [userInfo, siteId])

  const routeToHome = () => {
    push(Routes.Home)
  }

  const routeToLogin = () => {
    replace({
      pathname: Routes.Login
    })
  }

  return {
    loginStatus,
    login,
    isLoginLoading,
    userInfo,
    isUserInfoLoading,
    logout,
    isLogoutLoading,
    routeToHome,
    routeToLogin,
    siteId,
    setSiteId
  }
}

const AuthContainer = createContainer(useAuthHook)

export const getUserSiteNumber = (userInfo?: UserRes) => {
  const userSites = userInfo?.userSites
  return DataUtils.isEmpty(userSites) ? DEFAULT_SITE_NUMBER : userSites[0]
}

export default AuthContainer
