/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ABC` - Abnormal Behavior Control
 * `ADS` - Anomaly Detection System
 * `MRM` - Minimal Risk Maneuver
 * `ORA` - Object Registration Alert
 * `OSA` - On-going Service Alert
 * `SDS` - Situation Detection System
 */
export type Type42eEnum = (typeof Type42eEnum)[keyof typeof Type42eEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Type42eEnum = {
  ABC: 'ABC',
  ADS: 'ADS',
  MRM: 'MRM',
  ORA: 'ORA',
  OSA: 'OSA',
  SDS: 'SDS'
} as const
