/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type NodesListOrderByItem = (typeof NodesListOrderByItem)[keyof typeof NodesListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NodesListOrderByItem = {
  '-company': '-company',
  '-created_at': '-created_at',
  '-id': '-id',
  '-is_destination': '-is_destination',
  '-is_origin': '-is_origin',
  '-is_waypoint': '-is_waypoint',
  '-name': '-name',
  '-site': '-site',
  company: 'company',
  created_at: 'created_at',
  id: 'id',
  is_destination: 'is_destination',
  is_origin: 'is_origin',
  is_waypoint: 'is_waypoint',
  name: 'name',
  site: 'site'
} as const
