/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `COMPLETED` - 완료
 * `PREOCCUPIED` - 선점
 * `PROCEEDING` - 진행 중
 * `READY` - 준비
 * `WAITING` - 대기
 */
export type MonitoringStatusEnum = (typeof MonitoringStatusEnum)[keyof typeof MonitoringStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringStatusEnum = {
  COMPLETED: 'COMPLETED',
  PREOCCUPIED: 'PREOCCUPIED',
  PROCEEDING: 'PROCEEDING',
  READY: 'READY',
  WAITING: 'WAITING'
} as const
