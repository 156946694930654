/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { customFormData } from '../mutator/custom-form-data'
import { customInstance } from '../mutator/custom-instance'
import type {
  AsyncTaskRes,
  CompaniesListParams,
  CompanyRes,
  CountriesListParams,
  ElevatorCreate404Exception,
  ElevatorCreateReqRequest,
  ElevatorLineCallReqRequest,
  ElevatorLineCallRes,
  ElevatorLineCancelCallReqRequest,
  ElevatorRes,
  ElevatorRetrieve404Exception,
  ElevatorStatus404Exception,
  ElevatorStatusRes,
  GeosMapsListParams,
  GeosTagCategoriesListParams,
  LoginReqRequest,
  LoginRes,
  MonitoringCreate400Exception,
  MonitoringCreateReqRequest,
  MonitoringEStop400Exception,
  MonitoringEStop404Exception,
  MonitoringInterveneComplete400Exception,
  MonitoringInterveneProceed400Exception,
  MonitoringInterveneReady400Exception,
  MonitoringInterveneRes,
  MonitoringUpdate400Exception,
  MonitoringUpdateReqRequest,
  MonitoringWebQueueRes,
  MonitoringsCreateMonitoringCreateParams,
  MonitoringsEStopCreate403,
  MonitoringsHeartbeatCreate403,
  MonitoringsIntervenesListParams,
  MonitoringsUpdateMonitoringUpdate403,
  MonitoringsUpdateMonitoringUpdateParams,
  NodeCourseRes,
  NodeCoursesListParams,
  NodeCreate400Exception,
  NodeCreateReqRequest,
  NodeRes,
  NodeUpdateReqRequest,
  NodesListParams,
  NotificationTypeCategoriesListParams,
  NotificationTypeCreateReqRequest,
  NotificationTypeRes,
  NotificationTypeUpdateReqRequest,
  NotificationTypesListParams,
  NotificationsListParams,
  NotificationsPatrolsListParams,
  OperatorDisconnectReqRequest,
  OperatorReportControlStatusReqRequest,
  PaginatedCompanyResList,
  PaginatedCountryResList,
  PaginatedMonitoringInterveneResList,
  PaginatedNodeCourseResList,
  PaginatedNodeResList,
  PaginatedNotificationResList,
  PaginatedNotificationTypeCategoryList,
  PaginatedNotificationTypeResList,
  PaginatedPatrolAlarmResList,
  PaginatedRobotResList,
  PaginatedScenarioPatrolDetectionResList,
  PaginatedScenarioResList,
  PaginatedScenarioTypeResList,
  PaginatedServiceResList,
  PaginatedSiteGlobalPathResList,
  PaginatedSiteMapResList,
  PaginatedSiteResList,
  PaginatedSoundResList,
  PaginatedTagCategoryResList,
  PaginatedUserResList,
  PaginatedWebhookResList,
  PatchedSiteGlobalPathPartialUpdateReqRequest,
  PatchedSoundPartialUpdateReqRequest,
  PatrolAlarmPresignedRes,
  RbtControlReqBodyRequest,
  RbtMissionReqBodyRequest,
  ReportOperateReqRequest,
  RobotAddonListRes,
  RobotAgentVersionRes,
  RobotAgentVersionUploadReqRequest,
  RobotAutonomyTypeRes,
  RobotCalibrationAutoCaptureReqRequest,
  RobotCalibrationPhaseError400Exception,
  RobotCalibrationPostProcessReqRequest,
  RobotCalibrationRes,
  RobotCalibrationSetParameterReqRequest,
  RobotCalibrationStart400Exception,
  RobotCalibrationUploadImageReqRequest,
  RobotControlReqBodyRequest,
  RobotCreateReqBodyRequest,
  RobotDetailRes,
  RobotEnvironmentRes,
  RobotGasSeonsorOnRes,
  RobotNightModeReqRequest,
  RobotPresetRes,
  RobotPtzAuxReqRequest,
  RobotPtzFocusReqRequest,
  RobotPtzIrisReqRequest,
  RobotPtzMoveReqRequest,
  RobotSetAdasReqRequest,
  RobotSettingsReqRequest,
  RobotSettingsRes,
  RobotUpdate400Exception,
  RobotUpdateIsConnectingReqBodyRequest,
  RobotUpdateReqBodyRequest,
  RobotWarningLightReqRequest,
  RobotWebQueueReqRequest,
  RobotWebQueueRes,
  RobotsImportFromExcelCreateBody,
  RobotsListParams,
  RobotsSettingsRetrieveParams,
  RobotsWarningLightUpdate200,
  ScenarioCreateReqRequest,
  ScenarioDetailRes,
  ScenarioManagerUpdateReqRequest,
  ScenarioNonAuthReleaseAlarm400Exception,
  ScenarioNonAuthReleaseAlarmReqRequest,
  ScenarioPatrolAlarmRes,
  ScenarioReleaseAlarmReqRequest,
  ScenarioRes,
  ScenarioTypeCreateReqRequest,
  ScenarioTypeRes,
  ScenarioTypeUpdateReqRequest,
  ScenarioTypesListParams,
  ScenarioUnclaimed400Exception,
  ScenariosListParams,
  ScenariosPatrolsDetectionsListParams,
  ServiceCreateReqRequest,
  ServiceRes,
  ServiceUpdateReqRequest,
  ServicesListParams,
  SiteCreateReqRequest,
  SiteGlobalPathAsyncTaskStatusRes,
  SiteGlobalPathBatchDeleteReqRequest,
  SiteGlobalPathCreateReqRequest,
  SiteGlobalPathPreviewRes,
  SiteGlobalPathRes,
  SiteGlobalPathSaveAsync400Exception,
  SiteGlobalPathSaveReqRequest,
  SiteGlobalPathUpdate400Exception,
  SiteGlobalPathUpdateReqRequest,
  SiteGlobalPathUpload400Exception,
  SiteGlobalPathUploadReqRequest,
  SiteGraphGenerateReqRequest,
  SiteGraphGenerateRes,
  SiteMapCreateReqRequest,
  SiteMapDetailRes,
  SiteMapRes,
  SitePatrolSettings,
  SitePatrolSettingsRequest,
  SiteRes,
  SiteUpdateReqRequest,
  SiteUpdateRobotsReqRequest,
  SiteUpdateUsersReqRequest,
  SitesGlobalMapCreateBody,
  SitesGlobalPathsListParams,
  SitesGraphCreateBody,
  SitesListParams,
  SitesWebhooksListParams,
  SoundCreateReqRequest,
  SoundPlayReqRequest,
  SoundRes,
  SoundStopReqRequest,
  SoundTextToSpeechReqRequest,
  SoundsListParams,
  SoundsOperatorListParams,
  TokenRefreshRes,
  UserCreateReqRequest,
  UserRes,
  UserRetrieveRes,
  UserUpdateReqRequest,
  UserVerifyPin400Exception,
  UserVerifyPinReqRequest,
  UsersListParams,
  WayPauseReqRequest,
  WayPauseRes,
  WayRes,
  WayTagGroupsReqRequest,
  WebhookCreateReqRequest,
  WebhookHyundaiRobotStatusReqRequest,
  WebhookRes,
  WebhookUpdateReqRequest
} from './types'
import type { ErrorType, BodyType } from '../mutator/custom-instance'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const calibrationsRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RobotCalibrationRes>({ url: `/calibrations/${id}/`, method: 'GET', signal }, options)
}

export const getCalibrationsRetrieveQueryKey = (id: number) => {
  return [`/calibrations/${id}/`] as const
}

export const getCalibrationsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof calibrationsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof calibrationsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCalibrationsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof calibrationsRetrieve>>> = ({ signal }) =>
    calibrationsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof calibrationsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CalibrationsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof calibrationsRetrieve>>>
export type CalibrationsRetrieveInfiniteQueryError = ErrorType<unknown>

export const useCalibrationsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof calibrationsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof calibrationsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCalibrationsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getCalibrationsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof calibrationsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof calibrationsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCalibrationsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof calibrationsRetrieve>>> = ({ signal }) =>
    calibrationsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof calibrationsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CalibrationsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof calibrationsRetrieve>>>
export type CalibrationsRetrieveQueryError = ErrorType<unknown>

export const useCalibrationsRetrieve = <
  TData = Awaited<ReturnType<typeof calibrationsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof calibrationsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCalibrationsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 캘리브레이션 오토 캡쳐
 * @summary 캘리브레이션 오토 캡쳐
 */
export const calibrationsAutoCaptureCreate = (
  id: number,
  robotCalibrationAutoCaptureReqRequest: BodyType<RobotCalibrationAutoCaptureReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotCalibrationRes>(
    {
      url: `/calibrations/${id}/auto-capture/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotCalibrationAutoCaptureReqRequest
    },
    options
  )
}

export const getCalibrationsAutoCaptureCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsAutoCaptureCreate>>,
    TError,
    { id: number; data: BodyType<RobotCalibrationAutoCaptureReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsAutoCaptureCreate>>,
  TError,
  { id: number; data: BodyType<RobotCalibrationAutoCaptureReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calibrationsAutoCaptureCreate>>,
    { id: number; data: BodyType<RobotCalibrationAutoCaptureReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return calibrationsAutoCaptureCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsAutoCaptureCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsAutoCaptureCreate>>
>
export type CalibrationsAutoCaptureCreateMutationBody = BodyType<RobotCalibrationAutoCaptureReqRequest>
export type CalibrationsAutoCaptureCreateMutationError = ErrorType<RobotCalibrationPhaseError400Exception>

/**
 * @summary 캘리브레이션 오토 캡쳐
 */
export const useCalibrationsAutoCaptureCreate = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsAutoCaptureCreate>>,
    TError,
    { id: number; data: BodyType<RobotCalibrationAutoCaptureReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsAutoCaptureCreate>>,
  TError,
  { id: number; data: BodyType<RobotCalibrationAutoCaptureReqRequest> },
  TContext
> => {
  const mutationOptions = getCalibrationsAutoCaptureCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 캘리브레이션 종료
 * @summary 캘리브레이션 종료
 */
export const calibrationsCalibrationEndCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RobotCalibrationRes>({ url: `/calibrations/${id}/calibration-end/`, method: 'POST' }, options)
}

export const getCalibrationsCalibrationEndCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsCalibrationEndCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsCalibrationEndCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof calibrationsCalibrationEndCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return calibrationsCalibrationEndCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsCalibrationEndCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsCalibrationEndCreate>>
>

export type CalibrationsCalibrationEndCreateMutationError = ErrorType<RobotCalibrationPhaseError400Exception>

/**
 * @summary 캘리브레이션 종료
 */
export const useCalibrationsCalibrationEndCreate = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsCalibrationEndCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsCalibrationEndCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getCalibrationsCalibrationEndCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 캘리브레이션 완료
 * @summary 캘리브레이션 완료
 */
export const calibrationsCompleteCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RobotCalibrationRes>({ url: `/calibrations/${id}/complete/`, method: 'POST' }, options)
}

export const getCalibrationsCompleteCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsCompleteCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof calibrationsCompleteCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof calibrationsCompleteCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return calibrationsCompleteCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsCompleteCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsCompleteCreate>>
>

export type CalibrationsCompleteCreateMutationError = ErrorType<RobotCalibrationPhaseError400Exception>

/**
 * @summary 캘리브레이션 완료
 */
export const useCalibrationsCompleteCreate = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsCompleteCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof calibrationsCompleteCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getCalibrationsCompleteCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 캘리브레이션 캡쳐 이미지 삭제
 * @summary 캘리브레이션 캡쳐 이미지 삭제
 */
export const calibrationsDeleteCaptureCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RobotCalibrationRes>({ url: `/calibrations/${id}/delete-capture/`, method: 'POST' }, options)
}

export const getCalibrationsDeleteCaptureCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsDeleteCaptureCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsDeleteCaptureCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof calibrationsDeleteCaptureCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return calibrationsDeleteCaptureCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsDeleteCaptureCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsDeleteCaptureCreate>>
>

export type CalibrationsDeleteCaptureCreateMutationError = ErrorType<unknown>

/**
 * @summary 캘리브레이션 캡쳐 이미지 삭제
 */
export const useCalibrationsDeleteCaptureCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsDeleteCaptureCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsDeleteCaptureCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getCalibrationsDeleteCaptureCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 캘리브레이션 후처리
 * @summary 캘리브레이션 후처리
 */
export const calibrationsPostProcessCreate = (
  id: number,
  robotCalibrationPostProcessReqRequest: BodyType<RobotCalibrationPostProcessReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotCalibrationRes>(
    {
      url: `/calibrations/${id}/post-process/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotCalibrationPostProcessReqRequest
    },
    options
  )
}

export const getCalibrationsPostProcessCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsPostProcessCreate>>,
    TError,
    { id: number; data: BodyType<RobotCalibrationPostProcessReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsPostProcessCreate>>,
  TError,
  { id: number; data: BodyType<RobotCalibrationPostProcessReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calibrationsPostProcessCreate>>,
    { id: number; data: BodyType<RobotCalibrationPostProcessReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return calibrationsPostProcessCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsPostProcessCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsPostProcessCreate>>
>
export type CalibrationsPostProcessCreateMutationBody = BodyType<RobotCalibrationPostProcessReqRequest>
export type CalibrationsPostProcessCreateMutationError = ErrorType<RobotCalibrationPhaseError400Exception>

/**
 * @summary 캘리브레이션 후처리
 */
export const useCalibrationsPostProcessCreate = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsPostProcessCreate>>,
    TError,
    { id: number; data: BodyType<RobotCalibrationPostProcessReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsPostProcessCreate>>,
  TError,
  { id: number; data: BodyType<RobotCalibrationPostProcessReqRequest> },
  TContext
> => {
  const mutationOptions = getCalibrationsPostProcessCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 캘리브레이션 중간 이미지 캡쳐
 * @summary 캘리브레이션 중간 이미지 캡쳐
 */
export const calibrationsRequestCaptureCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RobotCalibrationRes>({ url: `/calibrations/${id}/request-capture/`, method: 'POST' }, options)
}

export const getCalibrationsRequestCaptureCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsRequestCaptureCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsRequestCaptureCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof calibrationsRequestCaptureCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return calibrationsRequestCaptureCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsRequestCaptureCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsRequestCaptureCreate>>
>

export type CalibrationsRequestCaptureCreateMutationError = ErrorType<RobotCalibrationPhaseError400Exception>

/**
 * @summary 캘리브레이션 중간 이미지 캡쳐
 */
export const useCalibrationsRequestCaptureCreate = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsRequestCaptureCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsRequestCaptureCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getCalibrationsRequestCaptureCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 캘리브레이션 파라미터 설정
 * @summary 로봇 캘리브레이션 파라미터 설정
 */
export const calibrationsSetParameterCreate = (
  id: number,
  robotCalibrationSetParameterReqRequest: BodyType<RobotCalibrationSetParameterReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotCalibrationRes>(
    {
      url: `/calibrations/${id}/set-parameter/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotCalibrationSetParameterReqRequest
    },
    options
  )
}

export const getCalibrationsSetParameterCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsSetParameterCreate>>,
    TError,
    { id: number; data: BodyType<RobotCalibrationSetParameterReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsSetParameterCreate>>,
  TError,
  { id: number; data: BodyType<RobotCalibrationSetParameterReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calibrationsSetParameterCreate>>,
    { id: number; data: BodyType<RobotCalibrationSetParameterReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return calibrationsSetParameterCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsSetParameterCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsSetParameterCreate>>
>
export type CalibrationsSetParameterCreateMutationBody = BodyType<RobotCalibrationSetParameterReqRequest>
export type CalibrationsSetParameterCreateMutationError = ErrorType<RobotCalibrationPhaseError400Exception>

/**
 * @summary 로봇 캘리브레이션 파라미터 설정
 */
export const useCalibrationsSetParameterCreate = <
  TError = ErrorType<RobotCalibrationPhaseError400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsSetParameterCreate>>,
    TError,
    { id: number; data: BodyType<RobotCalibrationSetParameterReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsSetParameterCreate>>,
  TError,
  { id: number; data: BodyType<RobotCalibrationSetParameterReqRequest> },
  TContext
> => {
  const mutationOptions = getCalibrationsSetParameterCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 캘리브레이션 중간 이미지 업로드
 * @summary 캘리브레이션 캡쳐 이미지 삭제
 */
export const calibrationsUploadImageCreate = (
  robotCalibrationUploadImageReqRequest: BodyType<RobotCalibrationUploadImageReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/calibrations/upload-image/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotCalibrationUploadImageReqRequest
    },
    options
  )
}

export const getCalibrationsUploadImageCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsUploadImageCreate>>,
    TError,
    { data: BodyType<RobotCalibrationUploadImageReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof calibrationsUploadImageCreate>>,
  TError,
  { data: BodyType<RobotCalibrationUploadImageReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calibrationsUploadImageCreate>>,
    { data: BodyType<RobotCalibrationUploadImageReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return calibrationsUploadImageCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CalibrationsUploadImageCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof calibrationsUploadImageCreate>>
>
export type CalibrationsUploadImageCreateMutationBody = BodyType<RobotCalibrationUploadImageReqRequest>
export type CalibrationsUploadImageCreateMutationError = ErrorType<unknown>

/**
 * @summary 캘리브레이션 캡쳐 이미지 삭제
 */
export const useCalibrationsUploadImageCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calibrationsUploadImageCreate>>,
    TError,
    { data: BodyType<RobotCalibrationUploadImageReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof calibrationsUploadImageCreate>>,
  TError,
  { data: BodyType<RobotCalibrationUploadImageReqRequest> },
  TContext
> => {
  const mutationOptions = getCalibrationsUploadImageCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const companiesList = (
  params?: CompaniesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedCompanyResList>({ url: `/companies/`, method: 'GET', params, signal }, options)
}

export const getCompaniesListQueryKey = (params?: CompaniesListParams) => {
  return [`/companies/`, ...(params ? [params] : [])] as const
}

export const getCompaniesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError = ErrorType<unknown>
>(
  params?: CompaniesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCompaniesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesList>>> = ({ signal }) =>
    companiesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof companiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CompaniesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companiesList>>>
export type CompaniesListInfiniteQueryError = ErrorType<unknown>

export const useCompaniesListInfinite = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError = ErrorType<unknown>
>(
  params?: CompaniesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getCompaniesListQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError = ErrorType<unknown>
>(
  params?: CompaniesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCompaniesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesList>>> = ({ signal }) =>
    companiesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof companiesList>>>
export type CompaniesListQueryError = ErrorType<unknown>

export const useCompaniesList = <TData = Awaited<ReturnType<typeof companiesList>>, TError = ErrorType<unknown>>(
  params?: CompaniesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const companiesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CompanyRes>({ url: `/companies/${id}/`, method: 'GET', signal }, options)
}

export const getCompaniesRetrieveQueryKey = (id: number) => {
  return [`/companies/${id}/`] as const
}

export const getCompaniesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCompaniesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesRetrieve>>> = ({ signal }) =>
    companiesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof companiesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CompaniesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companiesRetrieve>>>
export type CompaniesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useCompaniesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof companiesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getCompaniesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof companiesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCompaniesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesRetrieve>>> = ({ signal }) =>
    companiesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof companiesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CompaniesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companiesRetrieve>>>
export type CompaniesRetrieveQueryError = ErrorType<unknown>

export const useCompaniesRetrieve = <
  TData = Awaited<ReturnType<typeof companiesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof companiesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const countriesList = (
  params?: CountriesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedCountryResList>({ url: `/countries/`, method: 'GET', params, signal }, options)
}

export const getCountriesListQueryKey = (params?: CountriesListParams) => {
  return [`/countries/`, ...(params ? [params] : [])] as const
}

export const getCountriesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof countriesList>>,
  TError = ErrorType<unknown>
>(
  params?: CountriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countriesList>>> = ({ signal }) =>
    countriesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof countriesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CountriesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof countriesList>>>
export type CountriesListInfiniteQueryError = ErrorType<unknown>

export const useCountriesListInfinite = <
  TData = Awaited<ReturnType<typeof countriesList>>,
  TError = ErrorType<unknown>
>(
  params?: CountriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCountriesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getCountriesListQueryOptions = <
  TData = Awaited<ReturnType<typeof countriesList>>,
  TError = ErrorType<unknown>
>(
  params?: CountriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countriesList>>> = ({ signal }) =>
    countriesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof countriesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CountriesListQueryResult = NonNullable<Awaited<ReturnType<typeof countriesList>>>
export type CountriesListQueryError = ErrorType<unknown>

export const useCountriesList = <TData = Awaited<ReturnType<typeof countriesList>>, TError = ErrorType<unknown>>(
  params?: CountriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCountriesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * KT 로봇 제어
 * @summary KT 로봇 제어
 */
export const devicesResourcesRbtControlCreate = (
  deviceId: string,
  rbtControlReqBodyRequest: BodyType<RbtControlReqBodyRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/devices/${deviceId}/resources/rbt_control/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: rbtControlReqBodyRequest
    },
    options
  )
}

export const getDevicesResourcesRbtControlCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesResourcesRbtControlCreate>>,
    TError,
    { deviceId: string; data: BodyType<RbtControlReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof devicesResourcesRbtControlCreate>>,
  TError,
  { deviceId: string; data: BodyType<RbtControlReqBodyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof devicesResourcesRbtControlCreate>>,
    { deviceId: string; data: BodyType<RbtControlReqBodyRequest> }
  > = (props) => {
    const { deviceId, data } = props ?? {}

    return devicesResourcesRbtControlCreate(deviceId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DevicesResourcesRbtControlCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof devicesResourcesRbtControlCreate>>
>
export type DevicesResourcesRbtControlCreateMutationBody = BodyType<RbtControlReqBodyRequest>
export type DevicesResourcesRbtControlCreateMutationError = ErrorType<unknown>

/**
 * @summary KT 로봇 제어
 */
export const useDevicesResourcesRbtControlCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesResourcesRbtControlCreate>>,
    TError,
    { deviceId: string; data: BodyType<RbtControlReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof devicesResourcesRbtControlCreate>>,
  TError,
  { deviceId: string; data: BodyType<RbtControlReqBodyRequest> },
  TContext
> => {
  const mutationOptions = getDevicesResourcesRbtControlCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * KT 미션 할당
 * @summary KT 미션 할당
 */
export const devicesResourcesRbtMissionCreate = (
  deviceId: string,
  rbtMissionReqBodyRequest: BodyType<RbtMissionReqBodyRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/devices/${deviceId}/resources/rbt_mission/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: rbtMissionReqBodyRequest
    },
    options
  )
}

export const getDevicesResourcesRbtMissionCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesResourcesRbtMissionCreate>>,
    TError,
    { deviceId: string; data: BodyType<RbtMissionReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof devicesResourcesRbtMissionCreate>>,
  TError,
  { deviceId: string; data: BodyType<RbtMissionReqBodyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof devicesResourcesRbtMissionCreate>>,
    { deviceId: string; data: BodyType<RbtMissionReqBodyRequest> }
  > = (props) => {
    const { deviceId, data } = props ?? {}

    return devicesResourcesRbtMissionCreate(deviceId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DevicesResourcesRbtMissionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof devicesResourcesRbtMissionCreate>>
>
export type DevicesResourcesRbtMissionCreateMutationBody = BodyType<RbtMissionReqBodyRequest>
export type DevicesResourcesRbtMissionCreateMutationError = ErrorType<unknown>

/**
 * @summary KT 미션 할당
 */
export const useDevicesResourcesRbtMissionCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesResourcesRbtMissionCreate>>,
    TError,
    { deviceId: string; data: BodyType<RbtMissionReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof devicesResourcesRbtMissionCreate>>,
  TError,
  { deviceId: string; data: BodyType<RbtMissionReqBodyRequest> },
  TContext
> => {
  const mutationOptions = getDevicesResourcesRbtMissionCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엘리베이터 요청
 * @summary 엘리베이터 요청
 */
export const geosElevatorLinesCallCreate = (
  elevatorLineCallReqRequest: BodyType<ElevatorLineCallReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ElevatorLineCallRes>(
    {
      url: `/geos/elevator-lines/call/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: elevatorLineCallReqRequest
    },
    options
  )
}

export const getGeosElevatorLinesCallCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorLinesCallCreate>>,
    TError,
    { data: BodyType<ElevatorLineCallReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosElevatorLinesCallCreate>>,
  TError,
  { data: BodyType<ElevatorLineCallReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosElevatorLinesCallCreate>>,
    { data: BodyType<ElevatorLineCallReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return geosElevatorLinesCallCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosElevatorLinesCallCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof geosElevatorLinesCallCreate>>
>
export type GeosElevatorLinesCallCreateMutationBody = BodyType<ElevatorLineCallReqRequest>
export type GeosElevatorLinesCallCreateMutationError = ErrorType<unknown>

/**
 * @summary 엘리베이터 요청
 */
export const useGeosElevatorLinesCallCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorLinesCallCreate>>,
    TError,
    { data: BodyType<ElevatorLineCallReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosElevatorLinesCallCreate>>,
  TError,
  { data: BodyType<ElevatorLineCallReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosElevatorLinesCallCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엘리베이터 요청 취소
 * @summary 엘리베이터 요청 취소
 */
export const geosElevatorLinesCancelCreate = (
  elevatorLineCancelCallReqRequest: BodyType<ElevatorLineCancelCallReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/geos/elevator-lines/cancel/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: elevatorLineCancelCallReqRequest
    },
    options
  )
}

export const getGeosElevatorLinesCancelCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorLinesCancelCreate>>,
    TError,
    { data: BodyType<ElevatorLineCancelCallReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosElevatorLinesCancelCreate>>,
  TError,
  { data: BodyType<ElevatorLineCancelCallReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosElevatorLinesCancelCreate>>,
    { data: BodyType<ElevatorLineCancelCallReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return geosElevatorLinesCancelCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosElevatorLinesCancelCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof geosElevatorLinesCancelCreate>>
>
export type GeosElevatorLinesCancelCreateMutationBody = BodyType<ElevatorLineCancelCallReqRequest>
export type GeosElevatorLinesCancelCreateMutationError = ErrorType<unknown>

/**
 * @summary 엘리베이터 요청 취소
 */
export const useGeosElevatorLinesCancelCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorLinesCancelCreate>>,
    TError,
    { data: BodyType<ElevatorLineCancelCallReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosElevatorLinesCancelCreate>>,
  TError,
  { data: BodyType<ElevatorLineCancelCallReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosElevatorLinesCancelCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엘리베이터 로봇 상태 전송
 * @summary 엘리베이터 로봇 상태 전송
 */
export const geosElevatorLinesRobotStatusCreate = (
  elevatorLineCancelCallReqRequest: BodyType<ElevatorLineCancelCallReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/geos/elevator-lines/robot-status/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: elevatorLineCancelCallReqRequest
    },
    options
  )
}

export const getGeosElevatorLinesRobotStatusCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorLinesRobotStatusCreate>>,
    TError,
    { data: BodyType<ElevatorLineCancelCallReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosElevatorLinesRobotStatusCreate>>,
  TError,
  { data: BodyType<ElevatorLineCancelCallReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosElevatorLinesRobotStatusCreate>>,
    { data: BodyType<ElevatorLineCancelCallReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return geosElevatorLinesRobotStatusCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosElevatorLinesRobotStatusCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof geosElevatorLinesRobotStatusCreate>>
>
export type GeosElevatorLinesRobotStatusCreateMutationBody = BodyType<ElevatorLineCancelCallReqRequest>
export type GeosElevatorLinesRobotStatusCreateMutationError = ErrorType<unknown>

/**
 * @summary 엘리베이터 로봇 상태 전송
 */
export const useGeosElevatorLinesRobotStatusCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorLinesRobotStatusCreate>>,
    TError,
    { data: BodyType<ElevatorLineCancelCallReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosElevatorLinesRobotStatusCreate>>,
  TError,
  { data: BodyType<ElevatorLineCancelCallReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosElevatorLinesRobotStatusCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엘리베이터 등록
 * @summary 엘리베이터 등록
 */
export const geosElevatorsCreate = (
  elevatorCreateReqRequest: BodyType<ElevatorCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ElevatorRes>(
    {
      url: `/geos/elevators/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: elevatorCreateReqRequest
    },
    options
  )
}

export const getGeosElevatorsCreateMutationOptions = <
  TError = ErrorType<ElevatorCreate404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorsCreate>>,
    TError,
    { data: BodyType<ElevatorCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosElevatorsCreate>>,
  TError,
  { data: BodyType<ElevatorCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosElevatorsCreate>>,
    { data: BodyType<ElevatorCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return geosElevatorsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosElevatorsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof geosElevatorsCreate>>>
export type GeosElevatorsCreateMutationBody = BodyType<ElevatorCreateReqRequest>
export type GeosElevatorsCreateMutationError = ErrorType<ElevatorCreate404Exception>

/**
 * @summary 엘리베이터 등록
 */
export const useGeosElevatorsCreate = <TError = ErrorType<ElevatorCreate404Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorsCreate>>,
    TError,
    { data: BodyType<ElevatorCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosElevatorsCreate>>,
  TError,
  { data: BodyType<ElevatorCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosElevatorsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엘리베이터 상세 조회
 * @summary 엘리베이터 상세 조회
 */
export const geosElevatorsRetrieve = (
  elevatorNumber: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ElevatorRes>({ url: `/geos/elevators/${elevatorNumber}/`, method: 'GET', signal }, options)
}

export const getGeosElevatorsRetrieveQueryKey = (elevatorNumber: string) => {
  return [`/geos/elevators/${elevatorNumber}/`] as const
}

export const getGeosElevatorsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof geosElevatorsRetrieve>>,
  TError = ErrorType<ElevatorRetrieve404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosElevatorsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosElevatorsRetrieveQueryKey(elevatorNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosElevatorsRetrieve>>> = ({ signal }) =>
    geosElevatorsRetrieve(elevatorNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!elevatorNumber, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof geosElevatorsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosElevatorsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof geosElevatorsRetrieve>>>
export type GeosElevatorsRetrieveInfiniteQueryError = ErrorType<ElevatorRetrieve404Exception>

/**
 * @summary 엘리베이터 상세 조회
 */
export const useGeosElevatorsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof geosElevatorsRetrieve>>,
  TError = ErrorType<ElevatorRetrieve404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosElevatorsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosElevatorsRetrieveInfiniteQueryOptions(elevatorNumber, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGeosElevatorsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof geosElevatorsRetrieve>>,
  TError = ErrorType<ElevatorRetrieve404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosElevatorsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosElevatorsRetrieveQueryKey(elevatorNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosElevatorsRetrieve>>> = ({ signal }) =>
    geosElevatorsRetrieve(elevatorNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!elevatorNumber, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof geosElevatorsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosElevatorsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof geosElevatorsRetrieve>>>
export type GeosElevatorsRetrieveQueryError = ErrorType<ElevatorRetrieve404Exception>

/**
 * @summary 엘리베이터 상세 조회
 */
export const useGeosElevatorsRetrieve = <
  TData = Awaited<ReturnType<typeof geosElevatorsRetrieve>>,
  TError = ErrorType<ElevatorRetrieve404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosElevatorsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosElevatorsRetrieveQueryOptions(elevatorNumber, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 엘리베이터 상태 조회
 * @summary 엘리베이터 상태 조회
 */
export const geosElevatorsStatusRetrieve = (
  elevatorNumber: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ElevatorStatusRes>(
    { url: `/geos/elevators/${elevatorNumber}/status/`, method: 'GET', signal },
    options
  )
}

export const getGeosElevatorsStatusRetrieveQueryKey = (elevatorNumber: string) => {
  return [`/geos/elevators/${elevatorNumber}/status/`] as const
}

export const getGeosElevatorsStatusRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>,
  TError = ErrorType<ElevatorStatus404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosElevatorsStatusRetrieveQueryKey(elevatorNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>> = ({ signal }) =>
    geosElevatorsStatusRetrieve(elevatorNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!elevatorNumber, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosElevatorsStatusRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>
>
export type GeosElevatorsStatusRetrieveInfiniteQueryError = ErrorType<ElevatorStatus404Exception>

/**
 * @summary 엘리베이터 상태 조회
 */
export const useGeosElevatorsStatusRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>,
  TError = ErrorType<ElevatorStatus404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosElevatorsStatusRetrieveInfiniteQueryOptions(elevatorNumber, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGeosElevatorsStatusRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>,
  TError = ErrorType<ElevatorStatus404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosElevatorsStatusRetrieveQueryKey(elevatorNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>> = ({ signal }) =>
    geosElevatorsStatusRetrieve(elevatorNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!elevatorNumber, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosElevatorsStatusRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>
>
export type GeosElevatorsStatusRetrieveQueryError = ErrorType<ElevatorStatus404Exception>

/**
 * @summary 엘리베이터 상태 조회
 */
export const useGeosElevatorsStatusRetrieve = <
  TData = Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>,
  TError = ErrorType<ElevatorStatus404Exception>
>(
  elevatorNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosElevatorsStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosElevatorsStatusRetrieveQueryOptions(elevatorNumber, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 원격 콜 요청에 대한 ROBOT 상태 이벤트 웹훅
 * @summary EV 로봇 상태 이벤트 웹훅
 */
export const geosElevatorsWebhooksHyundaiRobotStatusCreate = (
  webhookHyundaiRobotStatusReqRequest: BodyType<WebhookHyundaiRobotStatusReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/geos/elevators/webhooks/hyundai/robot-status/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: webhookHyundaiRobotStatusReqRequest
    },
    options
  )
}

export const getGeosElevatorsWebhooksHyundaiRobotStatusCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorsWebhooksHyundaiRobotStatusCreate>>,
    TError,
    { data: BodyType<WebhookHyundaiRobotStatusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosElevatorsWebhooksHyundaiRobotStatusCreate>>,
  TError,
  { data: BodyType<WebhookHyundaiRobotStatusReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosElevatorsWebhooksHyundaiRobotStatusCreate>>,
    { data: BodyType<WebhookHyundaiRobotStatusReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return geosElevatorsWebhooksHyundaiRobotStatusCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosElevatorsWebhooksHyundaiRobotStatusCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof geosElevatorsWebhooksHyundaiRobotStatusCreate>>
>
export type GeosElevatorsWebhooksHyundaiRobotStatusCreateMutationBody = BodyType<WebhookHyundaiRobotStatusReqRequest>
export type GeosElevatorsWebhooksHyundaiRobotStatusCreateMutationError = ErrorType<unknown>

/**
 * @summary EV 로봇 상태 이벤트 웹훅
 */
export const useGeosElevatorsWebhooksHyundaiRobotStatusCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosElevatorsWebhooksHyundaiRobotStatusCreate>>,
    TError,
    { data: BodyType<WebhookHyundaiRobotStatusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosElevatorsWebhooksHyundaiRobotStatusCreate>>,
  TError,
  { data: BodyType<WebhookHyundaiRobotStatusReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosElevatorsWebhooksHyundaiRobotStatusCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 지도 목록 조회
 * @summary 사이트 지도 목록 조회
 */
export const geosMapsList = (
  params?: GeosMapsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSiteMapResList>({ url: `/geos/maps/`, method: 'GET', params, signal }, options)
}

export const getGeosMapsListQueryKey = (params?: GeosMapsListParams) => {
  return [`/geos/maps/`, ...(params ? [params] : [])] as const
}

export const getGeosMapsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof geosMapsList>>,
  TError = ErrorType<unknown>
>(
  params?: GeosMapsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosMapsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosMapsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosMapsList>>> = ({ signal }) =>
    geosMapsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof geosMapsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosMapsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof geosMapsList>>>
export type GeosMapsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 지도 목록 조회
 */
export const useGeosMapsListInfinite = <TData = Awaited<ReturnType<typeof geosMapsList>>, TError = ErrorType<unknown>>(
  params?: GeosMapsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosMapsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosMapsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGeosMapsListQueryOptions = <
  TData = Awaited<ReturnType<typeof geosMapsList>>,
  TError = ErrorType<unknown>
>(
  params?: GeosMapsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosMapsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosMapsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosMapsList>>> = ({ signal }) =>
    geosMapsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof geosMapsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosMapsListQueryResult = NonNullable<Awaited<ReturnType<typeof geosMapsList>>>
export type GeosMapsListQueryError = ErrorType<unknown>

/**
 * @summary 사이트 지도 목록 조회
 */
export const useGeosMapsList = <TData = Awaited<ReturnType<typeof geosMapsList>>, TError = ErrorType<unknown>>(
  params?: GeosMapsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosMapsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosMapsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 지도 생성
 * @summary 사이트 지도 생성
 */
export const geosMapsCreate = (
  siteMapCreateReqRequest: BodyType<SiteMapCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteMapRes>(
    {
      url: `/geos/maps/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteMapCreateReqRequest
    },
    options
  )
}

export const getGeosMapsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsCreate>>,
    TError,
    { data: BodyType<SiteMapCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosMapsCreate>>,
  TError,
  { data: BodyType<SiteMapCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosMapsCreate>>,
    { data: BodyType<SiteMapCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return geosMapsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosMapsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof geosMapsCreate>>>
export type GeosMapsCreateMutationBody = BodyType<SiteMapCreateReqRequest>
export type GeosMapsCreateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 지도 생성
 */
export const useGeosMapsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsCreate>>,
    TError,
    { data: BodyType<SiteMapCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosMapsCreate>>,
  TError,
  { data: BodyType<SiteMapCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosMapsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 웨이 취득 시작
 * @summary 웨이 취득 시작
 */
export const geosMapsWaysCreate = (
  mapPk: number,
  wayTagGroupsReqRequest: BodyType<WayTagGroupsReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WayRes>(
    {
      url: `/geos/maps/${mapPk}/ways/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: wayTagGroupsReqRequest
    },
    options
  )
}

export const getGeosMapsWaysCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysCreate>>,
    TError,
    { mapPk: number; data: BodyType<WayTagGroupsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosMapsWaysCreate>>,
  TError,
  { mapPk: number; data: BodyType<WayTagGroupsReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosMapsWaysCreate>>,
    { mapPk: number; data: BodyType<WayTagGroupsReqRequest> }
  > = (props) => {
    const { mapPk, data } = props ?? {}

    return geosMapsWaysCreate(mapPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosMapsWaysCreateMutationResult = NonNullable<Awaited<ReturnType<typeof geosMapsWaysCreate>>>
export type GeosMapsWaysCreateMutationBody = BodyType<WayTagGroupsReqRequest>
export type GeosMapsWaysCreateMutationError = ErrorType<unknown>

/**
 * @summary 웨이 취득 시작
 */
export const useGeosMapsWaysCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysCreate>>,
    TError,
    { mapPk: number; data: BodyType<WayTagGroupsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosMapsWaysCreate>>,
  TError,
  { mapPk: number; data: BodyType<WayTagGroupsReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosMapsWaysCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 웨이 정보
 * @summary 웨이 정보
 */
export const geosMapsWaysRetrieve = (
  mapPk: number,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<WayRes>({ url: `/geos/maps/${mapPk}/ways/${id}/`, method: 'GET', signal }, options)
}

export const getGeosMapsWaysRetrieveQueryKey = (mapPk: number, id: number) => {
  return [`/geos/maps/${mapPk}/ways/${id}/`] as const
}

export const getGeosMapsWaysRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof geosMapsWaysRetrieve>>,
  TError = ErrorType<unknown>
>(
  mapPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosMapsWaysRetrieveQueryKey(mapPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>> = ({ signal }) =>
    geosMapsWaysRetrieve(mapPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(mapPk && id), ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof geosMapsWaysRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosMapsWaysRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>>
export type GeosMapsWaysRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 웨이 정보
 */
export const useGeosMapsWaysRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof geosMapsWaysRetrieve>>,
  TError = ErrorType<unknown>
>(
  mapPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosMapsWaysRetrieveInfiniteQueryOptions(mapPk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGeosMapsWaysRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof geosMapsWaysRetrieve>>,
  TError = ErrorType<unknown>
>(
  mapPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosMapsWaysRetrieveQueryKey(mapPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>> = ({ signal }) =>
    geosMapsWaysRetrieve(mapPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(mapPk && id), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof geosMapsWaysRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosMapsWaysRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>>
export type GeosMapsWaysRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 웨이 정보
 */
export const useGeosMapsWaysRetrieve = <
  TData = Awaited<ReturnType<typeof geosMapsWaysRetrieve>>,
  TError = ErrorType<unknown>
>(
  mapPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosMapsWaysRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosMapsWaysRetrieveQueryOptions(mapPk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 웨이 취득 완료
 * @summary 웨이 취득 완료
 */
export const geosMapsWaysCompleteCreate = (
  mapPk: number,
  id: number,
  wayTagGroupsReqRequest: BodyType<WayTagGroupsReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WayRes>(
    {
      url: `/geos/maps/${mapPk}/ways/${id}/complete/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: wayTagGroupsReqRequest
    },
    options
  )
}

export const getGeosMapsWaysCompleteCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysCompleteCreate>>,
    TError,
    { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosMapsWaysCompleteCreate>>,
  TError,
  { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosMapsWaysCompleteCreate>>,
    { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> }
  > = (props) => {
    const { mapPk, id, data } = props ?? {}

    return geosMapsWaysCompleteCreate(mapPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosMapsWaysCompleteCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof geosMapsWaysCompleteCreate>>
>
export type GeosMapsWaysCompleteCreateMutationBody = BodyType<WayTagGroupsReqRequest>
export type GeosMapsWaysCompleteCreateMutationError = ErrorType<unknown>

/**
 * @summary 웨이 취득 완료
 */
export const useGeosMapsWaysCompleteCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysCompleteCreate>>,
    TError,
    { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosMapsWaysCompleteCreate>>,
  TError,
  { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosMapsWaysCompleteCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 웨이 취득 일시중지/재개
 * @summary 웨이 취득 일시중지/재개
 */
export const geosMapsWaysPauseCreate = (
  mapPk: number,
  id: number,
  wayPauseReqRequest: BodyType<WayPauseReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WayPauseRes>(
    {
      url: `/geos/maps/${mapPk}/ways/${id}/pause/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: wayPauseReqRequest
    },
    options
  )
}

export const getGeosMapsWaysPauseCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysPauseCreate>>,
    TError,
    { mapPk: number; id: number; data: BodyType<WayPauseReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosMapsWaysPauseCreate>>,
  TError,
  { mapPk: number; id: number; data: BodyType<WayPauseReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosMapsWaysPauseCreate>>,
    { mapPk: number; id: number; data: BodyType<WayPauseReqRequest> }
  > = (props) => {
    const { mapPk, id, data } = props ?? {}

    return geosMapsWaysPauseCreate(mapPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosMapsWaysPauseCreateMutationResult = NonNullable<Awaited<ReturnType<typeof geosMapsWaysPauseCreate>>>
export type GeosMapsWaysPauseCreateMutationBody = BodyType<WayPauseReqRequest>
export type GeosMapsWaysPauseCreateMutationError = ErrorType<unknown>

/**
 * @summary 웨이 취득 일시중지/재개
 */
export const useGeosMapsWaysPauseCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysPauseCreate>>,
    TError,
    { mapPk: number; id: number; data: BodyType<WayPauseReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosMapsWaysPauseCreate>>,
  TError,
  { mapPk: number; id: number; data: BodyType<WayPauseReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosMapsWaysPauseCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 웨이 태그 업데이트
 * @summary 웨이 태그 업데이트
 */
export const geosMapsWaysTagsUpdate = (
  mapPk: number,
  id: number,
  wayTagGroupsReqRequest: BodyType<WayTagGroupsReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WayRes>(
    {
      url: `/geos/maps/${mapPk}/ways/${id}/tags/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: wayTagGroupsReqRequest
    },
    options
  )
}

export const getGeosMapsWaysTagsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysTagsUpdate>>,
    TError,
    { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof geosMapsWaysTagsUpdate>>,
  TError,
  { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof geosMapsWaysTagsUpdate>>,
    { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> }
  > = (props) => {
    const { mapPk, id, data } = props ?? {}

    return geosMapsWaysTagsUpdate(mapPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosMapsWaysTagsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof geosMapsWaysTagsUpdate>>>
export type GeosMapsWaysTagsUpdateMutationBody = BodyType<WayTagGroupsReqRequest>
export type GeosMapsWaysTagsUpdateMutationError = ErrorType<unknown>

/**
 * @summary 웨이 태그 업데이트
 */
export const useGeosMapsWaysTagsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof geosMapsWaysTagsUpdate>>,
    TError,
    { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof geosMapsWaysTagsUpdate>>,
  TError,
  { mapPk: number; id: number; data: BodyType<WayTagGroupsReqRequest> },
  TContext
> => {
  const mutationOptions = getGeosMapsWaysTagsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 지도 상세 조회
 * @summary 사이트 지도 상세 조회
 */
export const geosMapsRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteMapDetailRes>({ url: `/geos/maps/${id}/`, method: 'GET', signal }, options)
}

export const getGeosMapsRetrieveQueryKey = (id: number) => {
  return [`/geos/maps/${id}/`] as const
}

export const getGeosMapsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof geosMapsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosMapsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosMapsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosMapsRetrieve>>> = ({ signal }) =>
    geosMapsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof geosMapsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosMapsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof geosMapsRetrieve>>>
export type GeosMapsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 지도 상세 조회
 */
export const useGeosMapsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof geosMapsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosMapsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosMapsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGeosMapsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof geosMapsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosMapsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosMapsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosMapsRetrieve>>> = ({ signal }) =>
    geosMapsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof geosMapsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosMapsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof geosMapsRetrieve>>>
export type GeosMapsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사이트 지도 상세 조회
 */
export const useGeosMapsRetrieve = <TData = Awaited<ReturnType<typeof geosMapsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosMapsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosMapsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 지도 생성 완료
 * @summary 사이트 지도 생성 완료
 */
export const geosMapsCompleteCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<SiteMapRes>({ url: `/geos/maps/${id}/complete/`, method: 'POST' }, options)
}

export const getGeosMapsCompleteCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof geosMapsCompleteCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof geosMapsCompleteCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof geosMapsCompleteCreate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return geosMapsCompleteCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GeosMapsCompleteCreateMutationResult = NonNullable<Awaited<ReturnType<typeof geosMapsCompleteCreate>>>

export type GeosMapsCompleteCreateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 지도 생성 완료
 */
export const useGeosMapsCompleteCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof geosMapsCompleteCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof geosMapsCompleteCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getGeosMapsCompleteCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 태그 카테고리 목록 조회
 * @summary 태그 카테고리 목록 조회
 */
export const geosTagCategoriesList = (
  params?: GeosTagCategoriesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedTagCategoryResList>(
    { url: `/geos/tag-categories/`, method: 'GET', params, signal },
    options
  )
}

export const getGeosTagCategoriesListQueryKey = (params?: GeosTagCategoriesListParams) => {
  return [`/geos/tag-categories/`, ...(params ? [params] : [])] as const
}

export const getGeosTagCategoriesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof geosTagCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: GeosTagCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosTagCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosTagCategoriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosTagCategoriesList>>> = ({ signal }) =>
    geosTagCategoriesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof geosTagCategoriesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosTagCategoriesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof geosTagCategoriesList>>>
export type GeosTagCategoriesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 태그 카테고리 목록 조회
 */
export const useGeosTagCategoriesListInfinite = <
  TData = Awaited<ReturnType<typeof geosTagCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: GeosTagCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof geosTagCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosTagCategoriesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGeosTagCategoriesListQueryOptions = <
  TData = Awaited<ReturnType<typeof geosTagCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: GeosTagCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosTagCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGeosTagCategoriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof geosTagCategoriesList>>> = ({ signal }) =>
    geosTagCategoriesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof geosTagCategoriesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GeosTagCategoriesListQueryResult = NonNullable<Awaited<ReturnType<typeof geosTagCategoriesList>>>
export type GeosTagCategoriesListQueryError = ErrorType<unknown>

/**
 * @summary 태그 카테고리 목록 조회
 */
export const useGeosTagCategoriesList = <
  TData = Awaited<ReturnType<typeof geosTagCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: GeosTagCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof geosTagCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeosTagCategoriesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 

권한: 누구나
 * @summary 로그인
 */
export const loginCreate = (
  loginReqRequest: BodyType<LoginReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<LoginRes>(
    { url: `/login/`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: loginReqRequest },
    options
  )
}

export const getLoginCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCreate>>,
    TError,
    { data: BodyType<LoginReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginCreate>>,
  TError,
  { data: BodyType<LoginReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginCreate>>, { data: BodyType<LoginReqRequest> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return loginCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type LoginCreateMutationResult = NonNullable<Awaited<ReturnType<typeof loginCreate>>>
export type LoginCreateMutationBody = BodyType<LoginReqRequest>
export type LoginCreateMutationError = ErrorType<unknown>

/**
 * @summary 로그인
 */
export const useLoginCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCreate>>,
    TError,
    { data: BodyType<LoginReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof loginCreate>>,
  TError,
  { data: BodyType<LoginReqRequest> },
  TContext
> => {
  const mutationOptions = getLoginCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로그아웃
 * @summary 로그아웃
 */
export const logoutCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/logout/`, method: 'POST' }, options)
}

export const getLogoutCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logoutCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof logoutCreate>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof logoutCreate>>, void> = () => {
    return logoutCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type LogoutCreateMutationResult = NonNullable<Awaited<ReturnType<typeof logoutCreate>>>

export type LogoutCreateMutationError = ErrorType<unknown>

/**
 * @summary 로그아웃
 */
export const useLogoutCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logoutCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof logoutCreate>>, TError, void, TContext> => {
  const mutationOptions = getLogoutCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 모니터링 생성
 * @summary 모니터링 생성
 */
export const monitoringsCreateMonitoringCreate = (
  monitoringCreateReqRequest: BodyType<MonitoringCreateReqRequest>,
  params?: MonitoringsCreateMonitoringCreateParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PaginatedRobotResList>(
    {
      url: `/monitorings/create-monitoring/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: monitoringCreateReqRequest,
      params
    },
    options
  )
}

export const getMonitoringsCreateMonitoringCreateMutationOptions = <
  TError = ErrorType<MonitoringCreate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsCreateMonitoringCreate>>,
    TError,
    { data: BodyType<MonitoringCreateReqRequest>; params?: MonitoringsCreateMonitoringCreateParams },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof monitoringsCreateMonitoringCreate>>,
  TError,
  { data: BodyType<MonitoringCreateReqRequest>; params?: MonitoringsCreateMonitoringCreateParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monitoringsCreateMonitoringCreate>>,
    { data: BodyType<MonitoringCreateReqRequest>; params?: MonitoringsCreateMonitoringCreateParams }
  > = (props) => {
    const { data, params } = props ?? {}

    return monitoringsCreateMonitoringCreate(data, params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsCreateMonitoringCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsCreateMonitoringCreate>>
>
export type MonitoringsCreateMonitoringCreateMutationBody = BodyType<MonitoringCreateReqRequest>
export type MonitoringsCreateMonitoringCreateMutationError = ErrorType<MonitoringCreate400Exception>

/**
 * @summary 모니터링 생성
 */
export const useMonitoringsCreateMonitoringCreate = <
  TError = ErrorType<MonitoringCreate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsCreateMonitoringCreate>>,
    TError,
    { data: BodyType<MonitoringCreateReqRequest>; params?: MonitoringsCreateMonitoringCreateParams },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof monitoringsCreateMonitoringCreate>>,
  TError,
  { data: BodyType<MonitoringCreateReqRequest>; params?: MonitoringsCreateMonitoringCreateParams },
  TContext
> => {
  const mutationOptions = getMonitoringsCreateMonitoringCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 모니터링 삭제
 * @summary 모니터링 삭제
 */
export const monitoringsDeleteMonitoringDestroy = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/monitorings/delete-monitoring/`, method: 'DELETE' }, options)
}

export const getMonitoringsDeleteMonitoringDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof monitoringsDeleteMonitoringDestroy>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof monitoringsDeleteMonitoringDestroy>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof monitoringsDeleteMonitoringDestroy>>, void> = () => {
    return monitoringsDeleteMonitoringDestroy(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsDeleteMonitoringDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsDeleteMonitoringDestroy>>
>

export type MonitoringsDeleteMonitoringDestroyMutationError = ErrorType<unknown>

/**
 * @summary 모니터링 삭제
 */
export const useMonitoringsDeleteMonitoringDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof monitoringsDeleteMonitoringDestroy>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof monitoringsDeleteMonitoringDestroy>>, TError, void, TContext> => {
  const mutationOptions = getMonitoringsDeleteMonitoringDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 유저요청으로 인한 긴급 정지
 * @summary 유저요청으로 인한 긴급 정지
 */
export const monitoringsEStopCreate = (robotId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/monitorings/e-stop/${robotId}/`, method: 'POST' }, options)
}

export const getMonitoringsEStopCreateMutationOptions = <
  TError = ErrorType<MonitoringEStop400Exception | MonitoringsEStopCreate403 | MonitoringEStop404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsEStopCreate>>,
    TError,
    { robotId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof monitoringsEStopCreate>>, TError, { robotId: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof monitoringsEStopCreate>>, { robotId: string }> = (
    props
  ) => {
    const { robotId } = props ?? {}

    return monitoringsEStopCreate(robotId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsEStopCreateMutationResult = NonNullable<Awaited<ReturnType<typeof monitoringsEStopCreate>>>

export type MonitoringsEStopCreateMutationError = ErrorType<
  MonitoringEStop400Exception | MonitoringsEStopCreate403 | MonitoringEStop404Exception
>

/**
 * @summary 유저요청으로 인한 긴급 정지
 */
export const useMonitoringsEStopCreate = <
  TError = ErrorType<MonitoringEStop400Exception | MonitoringsEStopCreate403 | MonitoringEStop404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsEStopCreate>>,
    TError,
    { robotId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof monitoringsEStopCreate>>, TError, { robotId: string }, TContext> => {
  const mutationOptions = getMonitoringsEStopCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 모니터링 하트비트
 * @summary 모니터링 하트비트
 */
export const monitoringsHeartbeatCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/monitorings/heartbeat/`, method: 'POST' }, options)
}

export const getMonitoringsHeartbeatCreateMutationOptions = <
  TError = ErrorType<MonitoringsHeartbeatCreate403>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof monitoringsHeartbeatCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof monitoringsHeartbeatCreate>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof monitoringsHeartbeatCreate>>, void> = () => {
    return monitoringsHeartbeatCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsHeartbeatCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsHeartbeatCreate>>
>

export type MonitoringsHeartbeatCreateMutationError = ErrorType<MonitoringsHeartbeatCreate403>

/**
 * @summary 모니터링 하트비트
 */
export const useMonitoringsHeartbeatCreate = <
  TError = ErrorType<MonitoringsHeartbeatCreate403>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof monitoringsHeartbeatCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof monitoringsHeartbeatCreate>>, TError, void, TContext> => {
  const mutationOptions = getMonitoringsHeartbeatCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const monitoringsIntervenesList = (
  params?: MonitoringsIntervenesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedMonitoringInterveneResList>(
    { url: `/monitorings/intervenes/`, method: 'GET', params, signal },
    options
  )
}

export const getMonitoringsIntervenesListQueryKey = (params?: MonitoringsIntervenesListParams) => {
  return [`/monitorings/intervenes/`, ...(params ? [params] : [])] as const
}

export const getMonitoringsIntervenesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesList>>,
  TError = ErrorType<unknown>
>(
  params?: MonitoringsIntervenesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getMonitoringsIntervenesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof monitoringsIntervenesList>>> = ({ signal }) =>
    monitoringsIntervenesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type MonitoringsIntervenesListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesList>>
>
export type MonitoringsIntervenesListInfiniteQueryError = ErrorType<unknown>

export const useMonitoringsIntervenesListInfinite = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesList>>,
  TError = ErrorType<unknown>
>(
  params?: MonitoringsIntervenesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMonitoringsIntervenesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getMonitoringsIntervenesListQueryOptions = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesList>>,
  TError = ErrorType<unknown>
>(
  params?: MonitoringsIntervenesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getMonitoringsIntervenesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof monitoringsIntervenesList>>> = ({ signal }) =>
    monitoringsIntervenesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type MonitoringsIntervenesListQueryResult = NonNullable<Awaited<ReturnType<typeof monitoringsIntervenesList>>>
export type MonitoringsIntervenesListQueryError = ErrorType<unknown>

export const useMonitoringsIntervenesList = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesList>>,
  TError = ErrorType<unknown>
>(
  params?: MonitoringsIntervenesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMonitoringsIntervenesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 관제개입 완료상태 전환
 * @summary 관제개입 완료상태 전환
 */
export const monitoringsIntervenesCompleteCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MonitoringInterveneRes>(
    { url: `/monitorings/intervenes/${id}/complete/`, method: 'POST' },
    options
  )
}

export const getMonitoringsIntervenesCompleteCreateMutationOptions = <
  TError = ErrorType<MonitoringInterveneComplete400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesCompleteCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof monitoringsIntervenesCompleteCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monitoringsIntervenesCompleteCreate>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return monitoringsIntervenesCompleteCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsIntervenesCompleteCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesCompleteCreate>>
>

export type MonitoringsIntervenesCompleteCreateMutationError = ErrorType<MonitoringInterveneComplete400Exception>

/**
 * @summary 관제개입 완료상태 전환
 */
export const useMonitoringsIntervenesCompleteCreate = <
  TError = ErrorType<MonitoringInterveneComplete400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesCompleteCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof monitoringsIntervenesCompleteCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getMonitoringsIntervenesCompleteCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제개입 처리상태 전환
 * @summary 관제개입 처리상태 전환
 */
export const monitoringsIntervenesProceedCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MonitoringInterveneRes>(
    { url: `/monitorings/intervenes/${id}/proceed/`, method: 'POST' },
    options
  )
}

export const getMonitoringsIntervenesProceedCreateMutationOptions = <
  TError = ErrorType<MonitoringInterveneProceed400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesProceedCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof monitoringsIntervenesProceedCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof monitoringsIntervenesProceedCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return monitoringsIntervenesProceedCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsIntervenesProceedCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesProceedCreate>>
>

export type MonitoringsIntervenesProceedCreateMutationError = ErrorType<MonitoringInterveneProceed400Exception>

/**
 * @summary 관제개입 처리상태 전환
 */
export const useMonitoringsIntervenesProceedCreate = <
  TError = ErrorType<MonitoringInterveneProceed400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesProceedCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof monitoringsIntervenesProceedCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getMonitoringsIntervenesProceedCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제개입 준비상태 전환
 * @summary 관제개입 준비상태 전환
 */
export const monitoringsIntervenesReadyCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MonitoringInterveneRes>(
    { url: `/monitorings/intervenes/${id}/ready/`, method: 'POST' },
    options
  )
}

export const getMonitoringsIntervenesReadyCreateMutationOptions = <
  TError = ErrorType<MonitoringInterveneReady400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesReadyCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof monitoringsIntervenesReadyCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof monitoringsIntervenesReadyCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return monitoringsIntervenesReadyCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsIntervenesReadyCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesReadyCreate>>
>

export type MonitoringsIntervenesReadyCreateMutationError = ErrorType<MonitoringInterveneReady400Exception>

/**
 * @summary 관제개입 준비상태 전환
 */
export const useMonitoringsIntervenesReadyCreate = <
  TError = ErrorType<MonitoringInterveneReady400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesReadyCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof monitoringsIntervenesReadyCreate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getMonitoringsIntervenesReadyCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 드라이빙 요원 관제개입페이지 웹 큐 생성
 * @summary 드라이빙 요원 관제개입페이지 웹 큐 생성
 */
export const monitoringsIntervenesDriverMosquitoCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MonitoringWebQueueRes>(
    { url: `/monitorings/intervenes/driver/mosquito/`, method: 'POST' },
    options
  )
}

export const getMonitoringsIntervenesDriverMosquitoCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesDriverMosquitoCreate>>,
    TError,
    void,
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof monitoringsIntervenesDriverMosquitoCreate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monitoringsIntervenesDriverMosquitoCreate>>,
    void
  > = () => {
    return monitoringsIntervenesDriverMosquitoCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsIntervenesDriverMosquitoCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesDriverMosquitoCreate>>
>

export type MonitoringsIntervenesDriverMosquitoCreateMutationError = ErrorType<unknown>

/**
 * @summary 드라이빙 요원 관제개입페이지 웹 큐 생성
 */
export const useMonitoringsIntervenesDriverMosquitoCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesDriverMosquitoCreate>>,
    TError,
    void,
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof monitoringsIntervenesDriverMosquitoCreate>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getMonitoringsIntervenesDriverMosquitoCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제개입 리스트 웹 큐 생성
 * @summary 관제개입 리스트 웹 큐 생성
 */
export const monitoringsIntervenesMosquitoCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MonitoringWebQueueRes>({ url: `/monitorings/intervenes/mosquito/`, method: 'POST' }, options)
}

export const getMonitoringsIntervenesMosquitoCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof monitoringsIntervenesMosquitoCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof monitoringsIntervenesMosquitoCreate>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof monitoringsIntervenesMosquitoCreate>>, void> = () => {
    return monitoringsIntervenesMosquitoCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsIntervenesMosquitoCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesMosquitoCreate>>
>

export type MonitoringsIntervenesMosquitoCreateMutationError = ErrorType<unknown>

/**
 * @summary 관제개입 리스트 웹 큐 생성
 */
export const useMonitoringsIntervenesMosquitoCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof monitoringsIntervenesMosquitoCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof monitoringsIntervenesMosquitoCreate>>, TError, void, TContext> => {
  const mutationOptions = getMonitoringsIntervenesMosquitoCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제개입중인 드라이빙 요원의 할당카드 조회
 * @summary 관제개입중인 드라이빙 요원의 할당카드 조회
 */
export const monitoringsIntervenesPreoccupiedRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<MonitoringInterveneRes>(
    { url: `/monitorings/intervenes/preoccupied/`, method: 'GET', signal },
    options
  )
}

export const getMonitoringsIntervenesPreoccupiedRetrieveQueryKey = () => {
  return [`/monitorings/intervenes/preoccupied/`] as const
}

export const getMonitoringsIntervenesPreoccupiedRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getMonitoringsIntervenesPreoccupiedRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>> = ({ signal }) =>
    monitoringsIntervenesPreoccupiedRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type MonitoringsIntervenesPreoccupiedRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>
>
export type MonitoringsIntervenesPreoccupiedRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 관제개입중인 드라이빙 요원의 할당카드 조회
 */
export const useMonitoringsIntervenesPreoccupiedRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMonitoringsIntervenesPreoccupiedRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getMonitoringsIntervenesPreoccupiedRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getMonitoringsIntervenesPreoccupiedRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>> = ({ signal }) =>
    monitoringsIntervenesPreoccupiedRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type MonitoringsIntervenesPreoccupiedRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>
>
export type MonitoringsIntervenesPreoccupiedRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 관제개입중인 드라이빙 요원의 할당카드 조회
 */
export const useMonitoringsIntervenesPreoccupiedRetrieve = <
  TData = Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof monitoringsIntervenesPreoccupiedRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMonitoringsIntervenesPreoccupiedRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 모니터링 수정
 * @summary 모니터링 수정
 */
export const monitoringsUpdateMonitoringUpdate = (
  monitoringUpdateReqRequest: BodyType<MonitoringUpdateReqRequest>,
  params?: MonitoringsUpdateMonitoringUpdateParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PaginatedRobotResList>(
    {
      url: `/monitorings/update-monitoring/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: monitoringUpdateReqRequest,
      params
    },
    options
  )
}

export const getMonitoringsUpdateMonitoringUpdateMutationOptions = <
  TError = ErrorType<MonitoringUpdate400Exception | MonitoringsUpdateMonitoringUpdate403>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsUpdateMonitoringUpdate>>,
    TError,
    { data: BodyType<MonitoringUpdateReqRequest>; params?: MonitoringsUpdateMonitoringUpdateParams },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof monitoringsUpdateMonitoringUpdate>>,
  TError,
  { data: BodyType<MonitoringUpdateReqRequest>; params?: MonitoringsUpdateMonitoringUpdateParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monitoringsUpdateMonitoringUpdate>>,
    { data: BodyType<MonitoringUpdateReqRequest>; params?: MonitoringsUpdateMonitoringUpdateParams }
  > = (props) => {
    const { data, params } = props ?? {}

    return monitoringsUpdateMonitoringUpdate(data, params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type MonitoringsUpdateMonitoringUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof monitoringsUpdateMonitoringUpdate>>
>
export type MonitoringsUpdateMonitoringUpdateMutationBody = BodyType<MonitoringUpdateReqRequest>
export type MonitoringsUpdateMonitoringUpdateMutationError = ErrorType<
  MonitoringUpdate400Exception | MonitoringsUpdateMonitoringUpdate403
>

/**
 * @summary 모니터링 수정
 */
export const useMonitoringsUpdateMonitoringUpdate = <
  TError = ErrorType<MonitoringUpdate400Exception | MonitoringsUpdateMonitoringUpdate403>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monitoringsUpdateMonitoringUpdate>>,
    TError,
    { data: BodyType<MonitoringUpdateReqRequest>; params?: MonitoringsUpdateMonitoringUpdateParams },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof monitoringsUpdateMonitoringUpdate>>,
  TError,
  { data: BodyType<MonitoringUpdateReqRequest>; params?: MonitoringsUpdateMonitoringUpdateParams },
  TContext
> => {
  const mutationOptions = getMonitoringsUpdateMonitoringUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const nodeCoursesList = (
  params?: NodeCoursesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedNodeCourseResList>({ url: `/node-courses/`, method: 'GET', params, signal }, options)
}

export const getNodeCoursesListQueryKey = (params?: NodeCoursesListParams) => {
  return [`/node-courses/`, ...(params ? [params] : [])] as const
}

export const getNodeCoursesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nodeCoursesList>>,
  TError = ErrorType<unknown>
>(
  params?: NodeCoursesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodeCoursesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodeCoursesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodeCoursesList>>> = ({ signal }) =>
    nodeCoursesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof nodeCoursesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodeCoursesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nodeCoursesList>>>
export type NodeCoursesListInfiniteQueryError = ErrorType<unknown>

export const useNodeCoursesListInfinite = <
  TData = Awaited<ReturnType<typeof nodeCoursesList>>,
  TError = ErrorType<unknown>
>(
  params?: NodeCoursesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodeCoursesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodeCoursesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNodeCoursesListQueryOptions = <
  TData = Awaited<ReturnType<typeof nodeCoursesList>>,
  TError = ErrorType<unknown>
>(
  params?: NodeCoursesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodeCoursesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodeCoursesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodeCoursesList>>> = ({ signal }) =>
    nodeCoursesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof nodeCoursesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodeCoursesListQueryResult = NonNullable<Awaited<ReturnType<typeof nodeCoursesList>>>
export type NodeCoursesListQueryError = ErrorType<unknown>

export const useNodeCoursesList = <TData = Awaited<ReturnType<typeof nodeCoursesList>>, TError = ErrorType<unknown>>(
  params?: NodeCoursesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodeCoursesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodeCoursesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const nodeCoursesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NodeCourseRes>({ url: `/node-courses/${id}/`, method: 'GET', signal }, options)
}

export const getNodeCoursesRetrieveQueryKey = (id: number) => {
  return [`/node-courses/${id}/`] as const
}

export const getNodeCoursesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nodeCoursesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodeCoursesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodeCoursesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodeCoursesRetrieve>>> = ({ signal }) =>
    nodeCoursesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof nodeCoursesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodeCoursesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nodeCoursesRetrieve>>>
export type NodeCoursesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useNodeCoursesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof nodeCoursesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodeCoursesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodeCoursesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNodeCoursesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof nodeCoursesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodeCoursesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodeCoursesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodeCoursesRetrieve>>> = ({ signal }) =>
    nodeCoursesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof nodeCoursesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodeCoursesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof nodeCoursesRetrieve>>>
export type NodeCoursesRetrieveQueryError = ErrorType<unknown>

export const useNodeCoursesRetrieve = <
  TData = Awaited<ReturnType<typeof nodeCoursesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodeCoursesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodeCoursesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const nodesList = (
  params?: NodesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedNodeResList>({ url: `/nodes/`, method: 'GET', params, signal }, options)
}

export const getNodesListQueryKey = (params?: NodesListParams) => {
  return [`/nodes/`, ...(params ? [params] : [])] as const
}

export const getNodesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nodesList>>,
  TError = ErrorType<unknown>
>(
  params?: NodesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesList>>> = ({ signal }) =>
    nodesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof nodesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nodesList>>>
export type NodesListInfiniteQueryError = ErrorType<unknown>

export const useNodesListInfinite = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(
  params?: NodesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNodesListQueryOptions = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(
  params?: NodesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesList>>> = ({ signal }) =>
    nodesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof nodesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodesListQueryResult = NonNullable<Awaited<ReturnType<typeof nodesList>>>
export type NodesListQueryError = ErrorType<unknown>

export const useNodesList = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(
  params?: NodesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Node 생성
 * @summary Node 생성
 */
export const nodesCreate = (
  nodeCreateReqRequest: BodyType<NodeCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NodeRes>(
    { url: `/nodes/`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: nodeCreateReqRequest },
    options
  )
}

export const getNodesCreateMutationOptions = <
  TError = ErrorType<NodeCreate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof nodesCreate>>,
    TError,
    { data: BodyType<NodeCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof nodesCreate>>,
  TError,
  { data: BodyType<NodeCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof nodesCreate>>,
    { data: BodyType<NodeCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return nodesCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NodesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof nodesCreate>>>
export type NodesCreateMutationBody = BodyType<NodeCreateReqRequest>
export type NodesCreateMutationError = ErrorType<NodeCreate400Exception>

/**
 * @summary Node 생성
 */
export const useNodesCreate = <TError = ErrorType<NodeCreate400Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof nodesCreate>>,
    TError,
    { data: BodyType<NodeCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof nodesCreate>>,
  TError,
  { data: BodyType<NodeCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getNodesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const nodesRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<NodeRes>({ url: `/nodes/${id}/`, method: 'GET', signal }, options)
}

export const getNodesRetrieveQueryKey = (id: number) => {
  return [`/nodes/${id}/`] as const
}

export const getNodesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nodesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesRetrieve>>> = ({ signal }) =>
    nodesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof nodesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nodesRetrieve>>>
export type NodesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useNodesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof nodesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNodesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof nodesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesRetrieve>>> = ({ signal }) =>
    nodesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof nodesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NodesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof nodesRetrieve>>>
export type NodesRetrieveQueryError = ErrorType<unknown>

export const useNodesRetrieve = <TData = Awaited<ReturnType<typeof nodesRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Node 정보 수정
 * @summary Node 정보 수정
 */
export const nodesUpdate = (
  id: number,
  nodeUpdateReqRequest: BodyType<NodeUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NodeRes>(
    {
      url: `/nodes/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: nodeUpdateReqRequest
    },
    options
  )
}

export const getNodesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof nodesUpdate>>,
    TError,
    { id: number; data: BodyType<NodeUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof nodesUpdate>>,
  TError,
  { id: number; data: BodyType<NodeUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof nodesUpdate>>,
    { id: number; data: BodyType<NodeUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return nodesUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NodesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof nodesUpdate>>>
export type NodesUpdateMutationBody = BodyType<NodeUpdateReqRequest>
export type NodesUpdateMutationError = ErrorType<unknown>

/**
 * @summary Node 정보 수정
 */
export const useNodesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof nodesUpdate>>,
    TError,
    { id: number; data: BodyType<NodeUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof nodesUpdate>>,
  TError,
  { id: number; data: BodyType<NodeUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getNodesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const notificationTypeCategoriesList = (
  params?: NotificationTypeCategoriesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedNotificationTypeCategoryList>(
    { url: `/notification-type-categories/`, method: 'GET', params, signal },
    options
  )
}

export const getNotificationTypeCategoriesListQueryKey = (params?: NotificationTypeCategoriesListParams) => {
  return [`/notification-type-categories/`, ...(params ? [params] : [])] as const
}

export const getNotificationTypeCategoriesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTypeCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypeCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationTypeCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationTypeCategoriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTypeCategoriesList>>> = ({ signal }) =>
    notificationTypeCategoriesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationTypeCategoriesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationTypeCategoriesListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTypeCategoriesList>>
>
export type NotificationTypeCategoriesListInfiniteQueryError = ErrorType<unknown>

export const useNotificationTypeCategoriesListInfinite = <
  TData = Awaited<ReturnType<typeof notificationTypeCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypeCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationTypeCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationTypeCategoriesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNotificationTypeCategoriesListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTypeCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypeCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationTypeCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationTypeCategoriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTypeCategoriesList>>> = ({ signal }) =>
    notificationTypeCategoriesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTypeCategoriesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationTypeCategoriesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTypeCategoriesList>>
>
export type NotificationTypeCategoriesListQueryError = ErrorType<unknown>

export const useNotificationTypeCategoriesList = <
  TData = Awaited<ReturnType<typeof notificationTypeCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypeCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationTypeCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationTypeCategoriesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const notificationTypesList = (
  params?: NotificationTypesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedNotificationTypeResList>(
    { url: `/notification-types/`, method: 'GET', params, signal },
    options
  )
}

export const getNotificationTypesListQueryKey = (params?: NotificationTypesListParams) => {
  return [`/notification-types/`, ...(params ? [params] : [])] as const
}

export const getNotificationTypesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationTypesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTypesList>>> = ({ signal }) =>
    notificationTypesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationTypesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationTypesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof notificationTypesList>>>
export type NotificationTypesListInfiniteQueryError = ErrorType<unknown>

export const useNotificationTypesListInfinite = <
  TData = Awaited<ReturnType<typeof notificationTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationTypesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNotificationTypesListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationTypesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTypesList>>> = ({ signal }) =>
    notificationTypesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTypesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof notificationTypesList>>>
export type NotificationTypesListQueryError = ErrorType<unknown>

export const useNotificationTypesList = <
  TData = Awaited<ReturnType<typeof notificationTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationTypesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationTypesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 알림 타입 생성
 * @summary 알림 타입 생성
 */
export const notificationTypesCreate = (
  notificationTypeCreateReqRequest: BodyType<NotificationTypeCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NotificationTypeRes>(
    {
      url: `/notification-types/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: notificationTypeCreateReqRequest
    },
    options
  )
}

export const getNotificationTypesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTypesCreate>>,
    TError,
    { data: BodyType<NotificationTypeCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTypesCreate>>,
  TError,
  { data: BodyType<NotificationTypeCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTypesCreate>>,
    { data: BodyType<NotificationTypeCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return notificationTypesCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationTypesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof notificationTypesCreate>>>
export type NotificationTypesCreateMutationBody = BodyType<NotificationTypeCreateReqRequest>
export type NotificationTypesCreateMutationError = ErrorType<unknown>

/**
 * @summary 알림 타입 생성
 */
export const useNotificationTypesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTypesCreate>>,
    TError,
    { data: BodyType<NotificationTypeCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTypesCreate>>,
  TError,
  { data: BodyType<NotificationTypeCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getNotificationTypesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const notificationTypesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NotificationTypeRes>({ url: `/notification-types/${id}/`, method: 'GET', signal }, options)
}

export const getNotificationTypesRetrieveQueryKey = (id: number) => {
  return [`/notification-types/${id}/`] as const
}

export const getNotificationTypesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationTypesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTypesRetrieve>>> = ({ signal }) =>
    notificationTypesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationTypesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationTypesRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationTypesRetrieve>>
>
export type NotificationTypesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useNotificationTypesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof notificationTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationTypesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNotificationTypesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationTypesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationTypesRetrieve>>> = ({ signal }) =>
    notificationTypesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationTypesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof notificationTypesRetrieve>>>
export type NotificationTypesRetrieveQueryError = ErrorType<unknown>

export const useNotificationTypesRetrieve = <
  TData = Awaited<ReturnType<typeof notificationTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationTypesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 알림 타입 수정
 * @summary 알림 타입 수정
 */
export const notificationTypesUpdate = (
  id: number,
  notificationTypeUpdateReqRequest: BodyType<NotificationTypeUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NotificationTypeRes>(
    {
      url: `/notification-types/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: notificationTypeUpdateReqRequest
    },
    options
  )
}

export const getNotificationTypesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTypesUpdate>>,
    TError,
    { id: number; data: BodyType<NotificationTypeUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationTypesUpdate>>,
  TError,
  { id: number; data: BodyType<NotificationTypeUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationTypesUpdate>>,
    { id: number; data: BodyType<NotificationTypeUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return notificationTypesUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationTypesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof notificationTypesUpdate>>>
export type NotificationTypesUpdateMutationBody = BodyType<NotificationTypeUpdateReqRequest>
export type NotificationTypesUpdateMutationError = ErrorType<unknown>

/**
 * @summary 알림 타입 수정
 */
export const useNotificationTypesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationTypesUpdate>>,
    TError,
    { id: number; data: BodyType<NotificationTypeUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationTypesUpdate>>,
  TError,
  { id: number; data: BodyType<NotificationTypeUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getNotificationTypesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 발생한 알림 목록 조회
 * @summary 알림 목록 조회
 */
export const notificationsList = (
  params?: NotificationsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedNotificationResList>(
    { url: `/notifications/`, method: 'GET', params, signal },
    options
  )
}

export const getNotificationsListQueryKey = (params?: NotificationsListParams) => {
  return [`/notifications/`, ...(params ? [params] : [])] as const
}

export const getNotificationsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsList>>> = ({ signal }) =>
    notificationsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsList>>>
export type NotificationsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 알림 목록 조회
 */
export const useNotificationsListInfinite = <
  TData = Awaited<ReturnType<typeof notificationsList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNotificationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsList>>> = ({ signal }) =>
    notificationsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsListQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsList>>>
export type NotificationsListQueryError = ErrorType<unknown>

/**
 * @summary 알림 목록 조회
 */
export const useNotificationsList = <
  TData = Awaited<ReturnType<typeof notificationsList>>,
  TError = ErrorType<unknown>
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 순찰 알림 목록 조회
 * @summary 순찰 알림 목록 조회
 */
export const notificationsPatrolsList = (
  params: NotificationsPatrolsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedPatrolAlarmResList>(
    { url: `/notifications/patrols/`, method: 'GET', params, signal },
    options
  )
}

export const getNotificationsPatrolsListQueryKey = (params: NotificationsPatrolsListParams) => {
  return [`/notifications/patrols/`, ...(params ? [params] : [])] as const
}

export const getNotificationsPatrolsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsPatrolsList>>,
  TError = ErrorType<unknown>
>(
  params: NotificationsPatrolsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationsPatrolsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsPatrolsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsPatrolsList>>> = ({ signal }) =>
    notificationsPatrolsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationsPatrolsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsPatrolsListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPatrolsList>>
>
export type NotificationsPatrolsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 순찰 알림 목록 조회
 */
export const useNotificationsPatrolsListInfinite = <
  TData = Awaited<ReturnType<typeof notificationsPatrolsList>>,
  TError = ErrorType<unknown>
>(
  params: NotificationsPatrolsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationsPatrolsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsPatrolsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNotificationsPatrolsListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsPatrolsList>>,
  TError = ErrorType<unknown>
>(
  params: NotificationsPatrolsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationsPatrolsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsPatrolsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsPatrolsList>>> = ({ signal }) =>
    notificationsPatrolsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsPatrolsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsPatrolsListQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsPatrolsList>>>
export type NotificationsPatrolsListQueryError = ErrorType<unknown>

/**
 * @summary 순찰 알림 목록 조회
 */
export const useNotificationsPatrolsList = <
  TData = Awaited<ReturnType<typeof notificationsPatrolsList>>,
  TError = ErrorType<unknown>
>(
  params: NotificationsPatrolsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof notificationsPatrolsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsPatrolsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 임시 비디오 URL 생성
 * @summary 임시 비디오 URL 생성
 */
export const notificationsPatrolsDownloadVideoCreate = (
  alarmId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PatrolAlarmPresignedRes>(
    { url: `/notifications/patrols/${alarmId}/download-video/`, method: 'POST' },
    options
  )
}

export const getNotificationsPatrolsDownloadVideoCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPatrolsDownloadVideoCreate>>,
    TError,
    { alarmId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsPatrolsDownloadVideoCreate>>,
  TError,
  { alarmId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsPatrolsDownloadVideoCreate>>,
    { alarmId: string }
  > = (props) => {
    const { alarmId } = props ?? {}

    return notificationsPatrolsDownloadVideoCreate(alarmId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsPatrolsDownloadVideoCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPatrolsDownloadVideoCreate>>
>

export type NotificationsPatrolsDownloadVideoCreateMutationError = ErrorType<unknown>

/**
 * @summary 임시 비디오 URL 생성
 */
export const useNotificationsPatrolsDownloadVideoCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPatrolsDownloadVideoCreate>>,
    TError,
    { alarmId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsPatrolsDownloadVideoCreate>>,
  TError,
  { alarmId: string },
  TContext
> => {
  const mutationOptions = getNotificationsPatrolsDownloadVideoCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 수동 비디오 업로드
 * @summary 수동 비디오 업로드
 */
export const notificationsPatrolsUploadVideoUpdate = (
  alarmId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>({ url: `/notifications/patrols/${alarmId}/upload-video/`, method: 'PUT' }, options)
}

export const getNotificationsPatrolsUploadVideoUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPatrolsUploadVideoUpdate>>,
    TError,
    { alarmId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsPatrolsUploadVideoUpdate>>,
  TError,
  { alarmId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsPatrolsUploadVideoUpdate>>,
    { alarmId: string }
  > = (props) => {
    const { alarmId } = props ?? {}

    return notificationsPatrolsUploadVideoUpdate(alarmId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsPatrolsUploadVideoUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPatrolsUploadVideoUpdate>>
>

export type NotificationsPatrolsUploadVideoUpdateMutationError = ErrorType<unknown>

/**
 * @summary 수동 비디오 업로드
 */
export const useNotificationsPatrolsUploadVideoUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPatrolsUploadVideoUpdate>>,
    TError,
    { alarmId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsPatrolsUploadVideoUpdate>>,
  TError,
  { alarmId: string },
  TContext
> => {
  const mutationOptions = getNotificationsPatrolsUploadVideoUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제 연결 해제
 * @summary 관제 연결 해제
 */
export const operatorsDisconnectCreate = (
  operatorDisconnectReqRequest: BodyType<OperatorDisconnectReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/operators/disconnect/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: operatorDisconnectReqRequest
    },
    options
  )
}

export const getOperatorsDisconnectCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof operatorsDisconnectCreate>>,
    TError,
    { data: BodyType<OperatorDisconnectReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof operatorsDisconnectCreate>>,
  TError,
  { data: BodyType<OperatorDisconnectReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof operatorsDisconnectCreate>>,
    { data: BodyType<OperatorDisconnectReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return operatorsDisconnectCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OperatorsDisconnectCreateMutationResult = NonNullable<Awaited<ReturnType<typeof operatorsDisconnectCreate>>>
export type OperatorsDisconnectCreateMutationBody = BodyType<OperatorDisconnectReqRequest>
export type OperatorsDisconnectCreateMutationError = ErrorType<unknown>

/**
 * @summary 관제 연결 해제
 */
export const useOperatorsDisconnectCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof operatorsDisconnectCreate>>,
    TError,
    { data: BodyType<OperatorDisconnectReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof operatorsDisconnectCreate>>,
  TError,
  { data: BodyType<OperatorDisconnectReqRequest> },
  TContext
> => {
  const mutationOptions = getOperatorsDisconnectCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제 상태 변경 보고
 * @summary 관제 상태 변경 보고
 */
export const operatorsReportControlStatusCreate = (
  operatorReportControlStatusReqRequest: BodyType<OperatorReportControlStatusReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/operators/report-control-status/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: operatorReportControlStatusReqRequest
    },
    options
  )
}

export const getOperatorsReportControlStatusCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof operatorsReportControlStatusCreate>>,
    TError,
    { data: BodyType<OperatorReportControlStatusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof operatorsReportControlStatusCreate>>,
  TError,
  { data: BodyType<OperatorReportControlStatusReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof operatorsReportControlStatusCreate>>,
    { data: BodyType<OperatorReportControlStatusReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return operatorsReportControlStatusCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OperatorsReportControlStatusCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof operatorsReportControlStatusCreate>>
>
export type OperatorsReportControlStatusCreateMutationBody = BodyType<OperatorReportControlStatusReqRequest>
export type OperatorsReportControlStatusCreateMutationError = ErrorType<unknown>

/**
 * @summary 관제 상태 변경 보고
 */
export const useOperatorsReportControlStatusCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof operatorsReportControlStatusCreate>>,
    TError,
    { data: BodyType<OperatorReportControlStatusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof operatorsReportControlStatusCreate>>,
  TError,
  { data: BodyType<OperatorReportControlStatusReqRequest> },
  TContext
> => {
  const mutationOptions = getOperatorsReportControlStatusCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * for health check
 */
export const pingRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<string>({ url: `/ping/`, method: 'GET', signal }, options)
}

export const getPingRetrieveQueryKey = () => {
  return [`/ping/`] as const
}

export const getPingRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof pingRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPingRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pingRetrieve>>> = ({ signal }) =>
    pingRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pingRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof pingRetrieve>>>
export type PingRetrieveInfiniteQueryError = ErrorType<unknown>

export const usePingRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof pingRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPingRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getPingRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof pingRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPingRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pingRetrieve>>> = ({ signal }) =>
    pingRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pingRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pingRetrieve>>>
export type PingRetrieveQueryError = ErrorType<unknown>

export const usePingRetrieve = <
  TData = Awaited<ReturnType<typeof pingRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPingRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const robotsList = (
  params?: RobotsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedRobotResList>({ url: `/robots/`, method: 'GET', params, signal }, options)
}

export const getRobotsListQueryKey = (params?: RobotsListParams) => {
  return [`/robots/`, ...(params ? [params] : [])] as const
}

export const getRobotsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsList>>,
  TError = ErrorType<unknown>
>(
  params?: RobotsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsList>>> = ({ signal }) =>
    robotsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof robotsList>>>
export type RobotsListInfiniteQueryError = ErrorType<unknown>

export const useRobotsListInfinite = <TData = Awaited<ReturnType<typeof robotsList>>, TError = ErrorType<unknown>>(
  params?: RobotsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsListQueryOptions = <TData = Awaited<ReturnType<typeof robotsList>>, TError = ErrorType<unknown>>(
  params?: RobotsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsList>>> = ({ signal }) =>
    robotsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsListQueryResult = NonNullable<Awaited<ReturnType<typeof robotsList>>>
export type RobotsListQueryError = ErrorType<unknown>

export const useRobotsList = <TData = Awaited<ReturnType<typeof robotsList>>, TError = ErrorType<unknown>>(
  params?: RobotsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇 생성
 * @summary 로봇 생성
 */
export const robotsCreate = (
  robotCreateReqBodyRequest: BodyType<RobotCreateReqBodyRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotDetailRes>(
    {
      url: `/robots/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotCreateReqBodyRequest
    },
    options
  )
}

export const getRobotsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsCreate>>,
    TError,
    { data: BodyType<RobotCreateReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsCreate>>,
  TError,
  { data: BodyType<RobotCreateReqBodyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsCreate>>,
    { data: BodyType<RobotCreateReqBodyRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return robotsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsCreate>>>
export type RobotsCreateMutationBody = BodyType<RobotCreateReqBodyRequest>
export type RobotsCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 생성
 */
export const useRobotsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsCreate>>,
    TError,
    { data: BodyType<RobotCreateReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsCreate>>,
  TError,
  { data: BodyType<RobotCreateReqBodyRequest> },
  TContext
> => {
  const mutationOptions = getRobotsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 개별 조회
 * @summary 로봇 개별 조회
 */
export const robotsRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<RobotDetailRes>({ url: `/robots/${id}/`, method: 'GET', signal }, options)
}

export const getRobotsRetrieveQueryKey = (id: number) => {
  return [`/robots/${id}/`] as const
}

export const getRobotsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsRetrieve>>> = ({ signal }) =>
    robotsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof robotsRetrieve>>>
export type RobotsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 로봇 개별 조회
 */
export const useRobotsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsRetrieve>>> = ({ signal }) =>
    robotsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsRetrieve>>>
export type RobotsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 로봇 개별 조회
 */
export const useRobotsRetrieve = <TData = Awaited<ReturnType<typeof robotsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇 정보 업데이트
 * @summary 로봇 정보 업데이트
 */
export const robotsUpdate = (
  id: number,
  robotUpdateReqBodyRequest: BodyType<RobotUpdateReqBodyRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotDetailRes>(
    {
      url: `/robots/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotUpdateReqBodyRequest
    },
    options
  )
}

export const getRobotsUpdateMutationOptions = <
  TError = ErrorType<RobotUpdate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsUpdate>>,
    TError,
    { id: number; data: BodyType<RobotUpdateReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsUpdate>>,
  TError,
  { id: number; data: BodyType<RobotUpdateReqBodyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsUpdate>>,
    { id: number; data: BodyType<RobotUpdateReqBodyRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsUpdate>>>
export type RobotsUpdateMutationBody = BodyType<RobotUpdateReqBodyRequest>
export type RobotsUpdateMutationError = ErrorType<RobotUpdate400Exception>

/**
 * @summary 로봇 정보 업데이트
 */
export const useRobotsUpdate = <TError = ErrorType<RobotUpdate400Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsUpdate>>,
    TError,
    { id: number; data: BodyType<RobotUpdateReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsUpdate>>,
  TError,
  { id: number; data: BodyType<RobotUpdateReqBodyRequest> },
  TContext
> => {
  const mutationOptions = getRobotsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 웹 큐 생성 요청
 * @summary 로봇 웹 큐 생성 요청
 */
export const robotsControlUpdate = (
  id: number,
  robotControlReqBodyRequest: BodyType<RobotControlReqBodyRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/control/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotControlReqBodyRequest
    },
    options
  )
}

export const getRobotsControlUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsControlUpdate>>,
    TError,
    { id: number; data: BodyType<RobotControlReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsControlUpdate>>,
  TError,
  { id: number; data: BodyType<RobotControlReqBodyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsControlUpdate>>,
    { id: number; data: BodyType<RobotControlReqBodyRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsControlUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsControlUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsControlUpdate>>>
export type RobotsControlUpdateMutationBody = BodyType<RobotControlReqBodyRequest>
export type RobotsControlUpdateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 웹 큐 생성 요청
 */
export const useRobotsControlUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsControlUpdate>>,
    TError,
    { id: number; data: BodyType<RobotControlReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsControlUpdate>>,
  TError,
  { id: number; data: BodyType<RobotControlReqBodyRequest> },
  TContext
> => {
  const mutationOptions = getRobotsControlUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 횡단보도 멈춤 시 주행 재개
 * @summary 횡단보도 멈춤 시 주행 재개
 */
export const robotsCrossingResumeCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/crossing-resume/`, method: 'POST' }, options)
}

export const getRobotsCrossingResumeCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsCrossingResumeCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsCrossingResumeCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsCrossingResumeCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return robotsCrossingResumeCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsCrossingResumeCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof robotsCrossingResumeCreate>>
>

export type RobotsCrossingResumeCreateMutationError = ErrorType<unknown>

/**
 * @summary 횡단보도 멈춤 시 주행 재개
 */
export const useRobotsCrossingResumeCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsCrossingResumeCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsCrossingResumeCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsCrossingResumeCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 야간 모드 토글
 * @summary 로봇 야간 모드 토글
 */
export const robotsNightModeCreate = (
  id: number,
  robotNightModeReqRequest: BodyType<RobotNightModeReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/night-mode/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotNightModeReqRequest
    },
    options
  )
}

export const getRobotsNightModeCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsNightModeCreate>>,
    TError,
    { id: number; data: BodyType<RobotNightModeReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsNightModeCreate>>,
  TError,
  { id: number; data: BodyType<RobotNightModeReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsNightModeCreate>>,
    { id: number; data: BodyType<RobotNightModeReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsNightModeCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsNightModeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsNightModeCreate>>>
export type RobotsNightModeCreateMutationBody = BodyType<RobotNightModeReqRequest>
export type RobotsNightModeCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 야간 모드 토글
 */
export const useRobotsNightModeCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsNightModeCreate>>,
    TError,
    { id: number; data: BodyType<RobotNightModeReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsNightModeCreate>>,
  TError,
  { id: number; data: BodyType<RobotNightModeReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsNightModeCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * CCTV 주변 장치
 * @summary CCTV 주변 장치
 */
export const robotsPtzAuxUpdate = (
  id: number,
  robotPtzAuxReqRequest: BodyType<RobotPtzAuxReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/ptz/aux/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotPtzAuxReqRequest
    },
    options
  )
}

export const getRobotsPtzAuxUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzAuxUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzAuxReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsPtzAuxUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzAuxReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsPtzAuxUpdate>>,
    { id: number; data: BodyType<RobotPtzAuxReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsPtzAuxUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsPtzAuxUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsPtzAuxUpdate>>>
export type RobotsPtzAuxUpdateMutationBody = BodyType<RobotPtzAuxReqRequest>
export type RobotsPtzAuxUpdateMutationError = ErrorType<unknown>

/**
 * @summary CCTV 주변 장치
 */
export const useRobotsPtzAuxUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzAuxUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzAuxReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsPtzAuxUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzAuxReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsPtzAuxUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * CCTV 초점
 * @summary CCTV 초점
 */
export const robotsPtzFocusUpdate = (
  id: number,
  robotPtzFocusReqRequest: BodyType<RobotPtzFocusReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/ptz/focus/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotPtzFocusReqRequest
    },
    options
  )
}

export const getRobotsPtzFocusUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzFocusUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzFocusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsPtzFocusUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzFocusReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsPtzFocusUpdate>>,
    { id: number; data: BodyType<RobotPtzFocusReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsPtzFocusUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsPtzFocusUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsPtzFocusUpdate>>>
export type RobotsPtzFocusUpdateMutationBody = BodyType<RobotPtzFocusReqRequest>
export type RobotsPtzFocusUpdateMutationError = ErrorType<unknown>

/**
 * @summary CCTV 초점
 */
export const useRobotsPtzFocusUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzFocusUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzFocusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsPtzFocusUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzFocusReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsPtzFocusUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * CCTV 조리개
 * @summary CCTV 조리개
 */
export const robotsPtzIrisUpdate = (
  id: number,
  robotPtzIrisReqRequest: BodyType<RobotPtzIrisReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/ptz/iris/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotPtzIrisReqRequest
    },
    options
  )
}

export const getRobotsPtzIrisUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzIrisUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzIrisReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsPtzIrisUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzIrisReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsPtzIrisUpdate>>,
    { id: number; data: BodyType<RobotPtzIrisReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsPtzIrisUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsPtzIrisUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsPtzIrisUpdate>>>
export type RobotsPtzIrisUpdateMutationBody = BodyType<RobotPtzIrisReqRequest>
export type RobotsPtzIrisUpdateMutationError = ErrorType<unknown>

/**
 * @summary CCTV 조리개
 */
export const useRobotsPtzIrisUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzIrisUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzIrisReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsPtzIrisUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzIrisReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsPtzIrisUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * CCTV 이동
 * @summary CCTV 이동
 */
export const robotsPtzMoveUpdate = (
  id: number,
  robotPtzMoveReqRequest: BodyType<RobotPtzMoveReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/ptz/move/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotPtzMoveReqRequest
    },
    options
  )
}

export const getRobotsPtzMoveUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzMoveUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzMoveReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsPtzMoveUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzMoveReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsPtzMoveUpdate>>,
    { id: number; data: BodyType<RobotPtzMoveReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsPtzMoveUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsPtzMoveUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsPtzMoveUpdate>>>
export type RobotsPtzMoveUpdateMutationBody = BodyType<RobotPtzMoveReqRequest>
export type RobotsPtzMoveUpdateMutationError = ErrorType<unknown>

/**
 * @summary CCTV 이동
 */
export const useRobotsPtzMoveUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPtzMoveUpdate>>,
    TError,
    { id: number; data: BodyType<RobotPtzMoveReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsPtzMoveUpdate>>,
  TError,
  { id: number; data: BodyType<RobotPtzMoveReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsPtzMoveUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * PTZ 프리셋 상태
 * @summary PTZ 프리셋 상태
 */
export const robotsPtzPresetRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RobotPresetRes>({ url: `/robots/${id}/ptz/preset/`, method: 'GET', signal }, options)
}

export const getRobotsPtzPresetRetrieveQueryKey = (id: number) => {
  return [`/robots/${id}/ptz/preset/`] as const
}

export const getRobotsPtzPresetRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsPtzPresetRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>> = ({ signal }) =>
    robotsPtzPresetRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsPtzPresetRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>
>
export type RobotsPtzPresetRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary PTZ 프리셋 상태
 */
export const useRobotsPtzPresetRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsPtzPresetRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsPtzPresetRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsPtzPresetRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>> = ({ signal }) =>
    robotsPtzPresetRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsPtzPresetRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>>
export type RobotsPtzPresetRetrieveQueryError = ErrorType<unknown>

/**
 * @summary PTZ 프리셋 상태
 */
export const useRobotsPtzPresetRetrieve = <
  TData = Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsPtzPresetRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsPtzPresetRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * CCTV 이동 리셋
 * @summary CCTV 이동 리셋
 */
export const robotsPtzResetUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/ptz/reset/`, method: 'PUT' }, options)
}

export const getRobotsPtzResetUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsPtzResetUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsPtzResetUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsPtzResetUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return robotsPtzResetUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsPtzResetUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsPtzResetUpdate>>>

export type RobotsPtzResetUpdateMutationError = ErrorType<unknown>

/**
 * @summary CCTV 이동 리셋
 */
export const useRobotsPtzResetUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsPtzResetUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsPtzResetUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsPtzResetUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 주행 데이터 기록
 * @deprecated
 * @summary 로봇 주행 데이터 기록
 */
export const robotsRecordReportCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/record-report/`, method: 'POST' }, options)
}

export const getRobotsRecordReportCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsRecordReportCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsRecordReportCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsRecordReportCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return robotsRecordReportCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsRecordReportCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsRecordReportCreate>>>

export type RobotsRecordReportCreateMutationError = ErrorType<unknown>

/**
 * @deprecated
 * @summary 로봇 주행 데이터 기록
 */
export const useRobotsRecordReportCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsRecordReportCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsRecordReportCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsRecordReportCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관제 연결 보고
 * @summary 관제 연결 보고
 */
export const robotsReportOperateCreate = (
  id: number,
  reportOperateReqRequest: BodyType<ReportOperateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${id}/report-operate/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportOperateReqRequest
    },
    options
  )
}

export const getRobotsReportOperateCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsReportOperateCreate>>,
    TError,
    { id: number; data: BodyType<ReportOperateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsReportOperateCreate>>,
  TError,
  { id: number; data: BodyType<ReportOperateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsReportOperateCreate>>,
    { id: number; data: BodyType<ReportOperateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsReportOperateCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsReportOperateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsReportOperateCreate>>>
export type RobotsReportOperateCreateMutationBody = BodyType<ReportOperateReqRequest>
export type RobotsReportOperateCreateMutationError = ErrorType<unknown>

/**
 * @summary 관제 연결 보고
 */
export const useRobotsReportOperateCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsReportOperateCreate>>,
    TError,
    { id: number; data: BodyType<ReportOperateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsReportOperateCreate>>,
  TError,
  { id: number; data: BodyType<ReportOperateReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsReportOperateCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 재시작
 * @summary 로봇 재시작
 */
export const robotsRestartRobotCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/restart-robot/`, method: 'POST' }, options)
}

export const getRobotsRestartRobotCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsRestartRobotCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsRestartRobotCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsRestartRobotCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return robotsRestartRobotCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsRestartRobotCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsRestartRobotCreate>>>

export type RobotsRestartRobotCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 재시작
 */
export const useRobotsRestartRobotCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsRestartRobotCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsRestartRobotCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsRestartRobotCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 WebRTC 재시작
 * @summary 로봇 WebRTC 재시작
 */
export const robotsRestartWebrtcCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/restart-webrtc/`, method: 'POST' }, options)
}

export const getRobotsRestartWebrtcCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsRestartWebrtcCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsRestartWebrtcCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsRestartWebrtcCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return robotsRestartWebrtcCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsRestartWebrtcCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsRestartWebrtcCreate>>>

export type RobotsRestartWebrtcCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 WebRTC 재시작
 */
export const useRobotsRestartWebrtcCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsRestartWebrtcCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsRestartWebrtcCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsRestartWebrtcCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 가스 수동 탐지 스테이터스
 * @summary 가스 수동 탐지 스테이터스
 */
export const robotsSensorGasRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RobotGasSeonsorOnRes>({ url: `/robots/${id}/sensor/gas/`, method: 'GET', signal }, options)
}

export const getRobotsSensorGasRetrieveQueryKey = (id: number) => {
  return [`/robots/${id}/sensor/gas/`] as const
}

export const getRobotsSensorGasRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsSensorGasRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsSensorGasRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>> = ({ signal }) =>
    robotsSensorGasRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsSensorGasRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsSensorGasRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof robotsSensorGasRetrieve>>
>
export type RobotsSensorGasRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 가스 수동 탐지 스테이터스
 */
export const useRobotsSensorGasRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsSensorGasRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsSensorGasRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsSensorGasRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsSensorGasRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsSensorGasRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>> = ({ signal }) =>
    robotsSensorGasRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsSensorGasRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsSensorGasRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>>
export type RobotsSensorGasRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 가스 수동 탐지 스테이터스
 */
export const useRobotsSensorGasRetrieve = <
  TData = Awaited<ReturnType<typeof robotsSensorGasRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsSensorGasRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsSensorGasRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 가스 센서 수동 감지
 * @summary 가스 센서 수동 감지
 */
export const robotsSensorGasUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/sensor/gas/`, method: 'PUT' }, options)
}

export const getRobotsSensorGasUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsSensorGasUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsSensorGasUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsSensorGasUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return robotsSensorGasUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsSensorGasUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsSensorGasUpdate>>>

export type RobotsSensorGasUpdateMutationError = ErrorType<unknown>

/**
 * @summary 가스 센서 수동 감지
 */
export const useRobotsSensorGasUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsSensorGasUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsSensorGasUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsSensorGasUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 캘리브레이션 시작
 * @summary 로봇 캘리브레이션 시작
 */
export const robotsStartCalibrationCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RobotCalibrationRes>({ url: `/robots/${id}/start-calibration/`, method: 'POST' }, options)
}

export const getRobotsStartCalibrationCreateMutationOptions = <
  TError = ErrorType<RobotCalibrationStart400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsStartCalibrationCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsStartCalibrationCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsStartCalibrationCreate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return robotsStartCalibrationCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsStartCalibrationCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof robotsStartCalibrationCreate>>
>

export type RobotsStartCalibrationCreateMutationError = ErrorType<RobotCalibrationStart400Exception>

/**
 * @summary 로봇 캘리브레이션 시작
 */
export const useRobotsStartCalibrationCreate = <
  TError = ErrorType<RobotCalibrationStart400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsStartCalibrationCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsStartCalibrationCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsStartCalibrationCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 경광등 켜고 끄기
 * @summary 경광등
 */
export const robotsWarningLightUpdate = (
  id: number,
  robotWarningLightReqRequest: BodyType<RobotWarningLightReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotsWarningLightUpdate200>(
    {
      url: `/robots/${id}/warning-light/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotWarningLightReqRequest
    },
    options
  )
}

export const getRobotsWarningLightUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsWarningLightUpdate>>,
    TError,
    { id: number; data: BodyType<RobotWarningLightReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsWarningLightUpdate>>,
  TError,
  { id: number; data: BodyType<RobotWarningLightReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsWarningLightUpdate>>,
    { id: number; data: BodyType<RobotWarningLightReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return robotsWarningLightUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsWarningLightUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsWarningLightUpdate>>>
export type RobotsWarningLightUpdateMutationBody = BodyType<RobotWarningLightReqRequest>
export type RobotsWarningLightUpdateMutationError = ErrorType<unknown>

/**
 * @summary 경광등
 */
export const useRobotsWarningLightUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsWarningLightUpdate>>,
    TError,
    { id: number; data: BodyType<RobotWarningLightReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsWarningLightUpdate>>,
  TError,
  { id: number; data: BodyType<RobotWarningLightReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsWarningLightUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 와이파이 끄기
 * @summary 로봇 와이파이 끄기
 */
export const robotsWifiOffUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/wifi/off/`, method: 'PUT' }, options)
}

export const getRobotsWifiOffUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsWifiOffUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsWifiOffUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsWifiOffUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return robotsWifiOffUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsWifiOffUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsWifiOffUpdate>>>

export type RobotsWifiOffUpdateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 와이파이 끄기
 */
export const useRobotsWifiOffUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsWifiOffUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsWifiOffUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsWifiOffUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 와이파이 켜기
 * @summary 로봇 와이파이 켜기
 */
export const robotsWifiOnUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/robots/${id}/wifi/on/`, method: 'PUT' }, options)
}

export const getRobotsWifiOnUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsWifiOnUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof robotsWifiOnUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsWifiOnUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return robotsWifiOnUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsWifiOnUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsWifiOnUpdate>>>

export type RobotsWifiOnUpdateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 와이파이 켜기
 */
export const useRobotsWifiOnUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof robotsWifiOnUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof robotsWifiOnUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getRobotsWifiOnUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇에서 호출하는 자율 주행 유형 정보
 * @summary 로봇 자율 주행 유형 정보 조회
 */
export const robotsAutonomyTypeRetrieve = (
  serialNumber: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RobotAutonomyTypeRes>(
    { url: `/robots/${serialNumber}/autonomy-type/`, method: 'GET', signal },
    options
  )
}

export const getRobotsAutonomyTypeRetrieveQueryKey = (serialNumber: string) => {
  return [`/robots/${serialNumber}/autonomy-type/`] as const
}

export const getRobotsAutonomyTypeRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsAutonomyTypeRetrieveQueryKey(serialNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>> = ({ signal }) =>
    robotsAutonomyTypeRetrieve(serialNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!serialNumber, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsAutonomyTypeRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>
>
export type RobotsAutonomyTypeRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 로봇 자율 주행 유형 정보 조회
 */
export const useRobotsAutonomyTypeRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsAutonomyTypeRetrieveInfiniteQueryOptions(serialNumber, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsAutonomyTypeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsAutonomyTypeRetrieveQueryKey(serialNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>> = ({ signal }) =>
    robotsAutonomyTypeRetrieve(serialNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!serialNumber, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsAutonomyTypeRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>>
export type RobotsAutonomyTypeRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 로봇 자율 주행 유형 정보 조회
 */
export const useRobotsAutonomyTypeRetrieve = <
  TData = Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsAutonomyTypeRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsAutonomyTypeRetrieveQueryOptions(serialNumber, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇에서 호출하는 해당 로봇의 환경 정보
 * @summary 로봇 환경 정보
 */
export const robotsEnvironmentRetrieve = (
  serialNumber: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RobotEnvironmentRes>(
    { url: `/robots/${serialNumber}/environment/`, method: 'GET', signal },
    options
  )
}

export const getRobotsEnvironmentRetrieveQueryKey = (serialNumber: string) => {
  return [`/robots/${serialNumber}/environment/`] as const
}

export const getRobotsEnvironmentRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsEnvironmentRetrieveQueryKey(serialNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>> = ({ signal }) =>
    robotsEnvironmentRetrieve(serialNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!serialNumber, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsEnvironmentRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>
>
export type RobotsEnvironmentRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 로봇 환경 정보
 */
export const useRobotsEnvironmentRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsEnvironmentRetrieveInfiniteQueryOptions(serialNumber, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsEnvironmentRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsEnvironmentRetrieveQueryKey(serialNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>> = ({ signal }) =>
    robotsEnvironmentRetrieve(serialNumber, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!serialNumber, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsEnvironmentRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>>
export type RobotsEnvironmentRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 로봇 환경 정보
 */
export const useRobotsEnvironmentRetrieve = <
  TData = Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsEnvironmentRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsEnvironmentRetrieveQueryOptions(serialNumber, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 순찰 로봇에서 이벤트 발생 시 전달 받는 API
 * @summary 순찰 로봇 감지 이벤트
 */
export const robotsPatrolsAlarmCreate = (serialNumber: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/robots/${serialNumber}/patrols/alarm/`, method: 'POST' }, options)
}

export const getRobotsPatrolsAlarmCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPatrolsAlarmCreate>>,
    TError,
    { serialNumber: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsPatrolsAlarmCreate>>,
  TError,
  { serialNumber: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof robotsPatrolsAlarmCreate>>, { serialNumber: string }> = (
    props
  ) => {
    const { serialNumber } = props ?? {}

    return robotsPatrolsAlarmCreate(serialNumber, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsPatrolsAlarmCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsPatrolsAlarmCreate>>>

export type RobotsPatrolsAlarmCreateMutationError = ErrorType<unknown>

/**
 * @summary 순찰 로봇 감지 이벤트
 */
export const useRobotsPatrolsAlarmCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsPatrolsAlarmCreate>>,
    TError,
    { serialNumber: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsPatrolsAlarmCreate>>,
  TError,
  { serialNumber: string },
  TContext
> => {
  const mutationOptions = getRobotsPatrolsAlarmCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 ADAS 활성화
 * @summary 로봇 ADAS 활성화
 */
export const robotsSetAdasUpdate = (
  serialNumber: string,
  robotSetAdasReqRequest: BodyType<RobotSetAdasReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/${serialNumber}/set-adas/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: robotSetAdasReqRequest
    },
    options
  )
}

export const getRobotsSetAdasUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsSetAdasUpdate>>,
    TError,
    { serialNumber: string; data: BodyType<RobotSetAdasReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsSetAdasUpdate>>,
  TError,
  { serialNumber: string; data: BodyType<RobotSetAdasReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsSetAdasUpdate>>,
    { serialNumber: string; data: BodyType<RobotSetAdasReqRequest> }
  > = (props) => {
    const { serialNumber, data } = props ?? {}

    return robotsSetAdasUpdate(serialNumber, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsSetAdasUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsSetAdasUpdate>>>
export type RobotsSetAdasUpdateMutationBody = BodyType<RobotSetAdasReqRequest>
export type RobotsSetAdasUpdateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 ADAS 활성화
 */
export const useRobotsSetAdasUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsSetAdasUpdate>>,
    TError,
    { serialNumber: string; data: BodyType<RobotSetAdasReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsSetAdasUpdate>>,
  TError,
  { serialNumber: string; data: BodyType<RobotSetAdasReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsSetAdasUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇에서 부팅시 호출하는 환경 정보
 * @summary 로봇에서 부팅시 호출하는 환경 정보
 */
export const robotsSettingsRetrieve = (
  serialNumber: string,
  params?: RobotsSettingsRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RobotSettingsRes>(
    { url: `/robots/${serialNumber}/settings/`, method: 'GET', params, signal },
    options
  )
}

export const getRobotsSettingsRetrieveQueryKey = (serialNumber: string, params?: RobotsSettingsRetrieveParams) => {
  return [`/robots/${serialNumber}/settings/`, ...(params ? [params] : [])] as const
}

export const getRobotsSettingsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  params?: RobotsSettingsRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsSettingsRetrieveQueryKey(serialNumber, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsSettingsRetrieve>>> = ({ signal }) =>
    robotsSettingsRetrieve(serialNumber, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!serialNumber, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsSettingsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsSettingsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof robotsSettingsRetrieve>>>
export type RobotsSettingsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 로봇에서 부팅시 호출하는 환경 정보
 */
export const useRobotsSettingsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  params?: RobotsSettingsRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsSettingsRetrieveInfiniteQueryOptions(serialNumber, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsSettingsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  params?: RobotsSettingsRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsSettingsRetrieveQueryKey(serialNumber, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsSettingsRetrieve>>> = ({ signal }) =>
    robotsSettingsRetrieve(serialNumber, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!serialNumber, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsSettingsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsSettingsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsSettingsRetrieve>>>
export type RobotsSettingsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 로봇에서 부팅시 호출하는 환경 정보
 */
export const useRobotsSettingsRetrieve = <
  TData = Awaited<ReturnType<typeof robotsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  serialNumber: string,
  params?: RobotsSettingsRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof robotsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsSettingsRetrieveQueryOptions(serialNumber, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇에서 부팅시 호출하는 환경 설정 생성
 * @summary 로봇 환경 설정 생성
 */
export const robotsSettingsCreate = (
  serialNumber: string,
  robotSettingsReqRequest: BodyType<RobotSettingsReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotSettingsRes>(
    {
      url: `/robots/${serialNumber}/settings/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotSettingsReqRequest
    },
    options
  )
}

export const getRobotsSettingsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsSettingsCreate>>,
    TError,
    { serialNumber: string; data: BodyType<RobotSettingsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsSettingsCreate>>,
  TError,
  { serialNumber: string; data: BodyType<RobotSettingsReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsSettingsCreate>>,
    { serialNumber: string; data: BodyType<RobotSettingsReqRequest> }
  > = (props) => {
    const { serialNumber, data } = props ?? {}

    return robotsSettingsCreate(serialNumber, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsSettingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsSettingsCreate>>>
export type RobotsSettingsCreateMutationBody = BodyType<RobotSettingsReqRequest>
export type RobotsSettingsCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 환경 설정 생성
 */
export const useRobotsSettingsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsSettingsCreate>>,
    TError,
    { serialNumber: string; data: BodyType<RobotSettingsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsSettingsCreate>>,
  TError,
  { serialNumber: string; data: BodyType<RobotSettingsReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsSettingsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 애드온 목록
 * @summary 애드온 목록
 */
export const robotsAddonsRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<RobotAddonListRes>({ url: `/robots/addons/`, method: 'GET', signal }, options)
}

export const getRobotsAddonsRetrieveQueryKey = () => {
  return [`/robots/addons/`] as const
}

export const getRobotsAddonsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsAddonsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsAddonsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsAddonsRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsAddonsRetrieve>>> = ({ signal }) =>
    robotsAddonsRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof robotsAddonsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsAddonsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof robotsAddonsRetrieve>>>
export type RobotsAddonsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 애드온 목록
 */
export const useRobotsAddonsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof robotsAddonsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof robotsAddonsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsAddonsRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getRobotsAddonsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof robotsAddonsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof robotsAddonsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRobotsAddonsRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof robotsAddonsRetrieve>>> = ({ signal }) =>
    robotsAddonsRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof robotsAddonsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RobotsAddonsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof robotsAddonsRetrieve>>>
export type RobotsAddonsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 애드온 목록
 */
export const useRobotsAddonsRetrieve = <
  TData = Awaited<ReturnType<typeof robotsAddonsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof robotsAddonsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRobotsAddonsRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇 에이전트 업로드
 * @summary 로봇 에이전트 업로드
 */
export const robotsAgentVersionUpdate = (
  robotAgentVersionUploadReqRequest: BodyType<RobotAgentVersionUploadReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(robotAgentVersionUploadReqRequest)
  return customInstance<RobotAgentVersionRes>(
    {
      url: `/robots/agent-version/`,
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getRobotsAgentVersionUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsAgentVersionUpdate>>,
    TError,
    { data: BodyType<RobotAgentVersionUploadReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsAgentVersionUpdate>>,
  TError,
  { data: BodyType<RobotAgentVersionUploadReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsAgentVersionUpdate>>,
    { data: BodyType<RobotAgentVersionUploadReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return robotsAgentVersionUpdate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsAgentVersionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsAgentVersionUpdate>>>
export type RobotsAgentVersionUpdateMutationBody = BodyType<RobotAgentVersionUploadReqRequest>
export type RobotsAgentVersionUpdateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 에이전트 업로드
 */
export const useRobotsAgentVersionUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsAgentVersionUpdate>>,
    TError,
    { data: BodyType<RobotAgentVersionUploadReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsAgentVersionUpdate>>,
  TError,
  { data: BodyType<RobotAgentVersionUploadReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsAgentVersionUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇을 Excel 파일로 import 합니다.
 * @summary Excel로 로봇 import
 */
export const robotsImportFromExcelCreate = (
  robotsImportFromExcelCreateBody: BodyType<RobotsImportFromExcelCreateBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(robotsImportFromExcelCreateBody)
  return customInstance<void>(
    {
      url: `/robots/import_from_excel/`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getRobotsImportFromExcelCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsImportFromExcelCreate>>,
    TError,
    { data: BodyType<RobotsImportFromExcelCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsImportFromExcelCreate>>,
  TError,
  { data: BodyType<RobotsImportFromExcelCreateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsImportFromExcelCreate>>,
    { data: BodyType<RobotsImportFromExcelCreateBody> }
  > = (props) => {
    const { data } = props ?? {}

    return robotsImportFromExcelCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsImportFromExcelCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof robotsImportFromExcelCreate>>
>
export type RobotsImportFromExcelCreateMutationBody = BodyType<RobotsImportFromExcelCreateBody>
export type RobotsImportFromExcelCreateMutationError = ErrorType<void>

/**
 * @summary Excel로 로봇 import
 */
export const useRobotsImportFromExcelCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsImportFromExcelCreate>>,
    TError,
    { data: BodyType<RobotsImportFromExcelCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsImportFromExcelCreate>>,
  TError,
  { data: BodyType<RobotsImportFromExcelCreateBody> },
  TContext
> => {
  const mutationOptions = getRobotsImportFromExcelCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 웹 큐 생성 요청
 * @summary 로봇 웹 큐 생성 요청
 */
export const robotsMosquitoCreate = (
  robotWebQueueReqRequest: BodyType<RobotWebQueueReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<RobotWebQueueRes>(
    {
      url: `/robots/mosquito/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotWebQueueReqRequest
    },
    options
  )
}

export const getRobotsMosquitoCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsMosquitoCreate>>,
    TError,
    { data: BodyType<RobotWebQueueReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsMosquitoCreate>>,
  TError,
  { data: BodyType<RobotWebQueueReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsMosquitoCreate>>,
    { data: BodyType<RobotWebQueueReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return robotsMosquitoCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsMosquitoCreateMutationResult = NonNullable<Awaited<ReturnType<typeof robotsMosquitoCreate>>>
export type RobotsMosquitoCreateMutationBody = BodyType<RobotWebQueueReqRequest>
export type RobotsMosquitoCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 웹 큐 생성 요청
 */
export const useRobotsMosquitoCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsMosquitoCreate>>,
    TError,
    { data: BodyType<RobotWebQueueReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsMosquitoCreate>>,
  TError,
  { data: BodyType<RobotWebQueueReqRequest> },
  TContext
> => {
  const mutationOptions = getRobotsMosquitoCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇에서 호출하는 해당 로봇의 연결상태를 업데이트하는 API
 * @summary 로봇 연결 상태 업데이트
 */
export const robotsXavierConnectionStatusCreate = (
  robotUpdateIsConnectingReqBodyRequest: BodyType<RobotUpdateIsConnectingReqBodyRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/robots/xavier-connection-status/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: robotUpdateIsConnectingReqBodyRequest
    },
    options
  )
}

export const getRobotsXavierConnectionStatusCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsXavierConnectionStatusCreate>>,
    TError,
    { data: BodyType<RobotUpdateIsConnectingReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof robotsXavierConnectionStatusCreate>>,
  TError,
  { data: BodyType<RobotUpdateIsConnectingReqBodyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof robotsXavierConnectionStatusCreate>>,
    { data: BodyType<RobotUpdateIsConnectingReqBodyRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return robotsXavierConnectionStatusCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RobotsXavierConnectionStatusCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof robotsXavierConnectionStatusCreate>>
>
export type RobotsXavierConnectionStatusCreateMutationBody = BodyType<RobotUpdateIsConnectingReqBodyRequest>
export type RobotsXavierConnectionStatusCreateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 연결 상태 업데이트
 */
export const useRobotsXavierConnectionStatusCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof robotsXavierConnectionStatusCreate>>,
    TError,
    { data: BodyType<RobotUpdateIsConnectingReqBodyRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof robotsXavierConnectionStatusCreate>>,
  TError,
  { data: BodyType<RobotUpdateIsConnectingReqBodyRequest> },
  TContext
> => {
  const mutationOptions = getRobotsXavierConnectionStatusCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 

권한: 통합 관리자 또는 회사 관리자 또는 사이트 관리자 또는 사이트 셋업 매니저 또는 캠핑장 관리자 또는 순찰 관리자 또는 관제센터 매니저
 * @summary 시나리오 타입 조회
 */
export const scenarioTypesList = (
  params?: ScenarioTypesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedScenarioTypeResList>(
    { url: `/scenario_types/`, method: 'GET', params, signal },
    options
  )
}

export const getScenarioTypesListQueryKey = (params?: ScenarioTypesListParams) => {
  return [`/scenario_types/`, ...(params ? [params] : [])] as const
}

export const getScenarioTypesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenarioTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenarioTypesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenarioTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenarioTypesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenarioTypesList>>> = ({ signal }) =>
    scenarioTypesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenarioTypesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenarioTypesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof scenarioTypesList>>>
export type ScenarioTypesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 시나리오 타입 조회
 */
export const useScenarioTypesListInfinite = <
  TData = Awaited<ReturnType<typeof scenarioTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenarioTypesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenarioTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenarioTypesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenarioTypesListQueryOptions = <
  TData = Awaited<ReturnType<typeof scenarioTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenarioTypesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenarioTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenarioTypesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenarioTypesList>>> = ({ signal }) =>
    scenarioTypesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenarioTypesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenarioTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof scenarioTypesList>>>
export type ScenarioTypesListQueryError = ErrorType<unknown>

/**
 * @summary 시나리오 타입 조회
 */
export const useScenarioTypesList = <
  TData = Awaited<ReturnType<typeof scenarioTypesList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenarioTypesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenarioTypesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenarioTypesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 

권한: 통합 관리자
 * @summary 시나리오 타입 생성
 */
export const scenarioTypesCreate = (
  scenarioTypeCreateReqRequest: BodyType<ScenarioTypeCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ScenarioTypeRes>(
    {
      url: `/scenario_types/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: scenarioTypeCreateReqRequest
    },
    options
  )
}

export const getScenarioTypesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenarioTypesCreate>>,
    TError,
    { data: BodyType<ScenarioTypeCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenarioTypesCreate>>,
  TError,
  { data: BodyType<ScenarioTypeCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scenarioTypesCreate>>,
    { data: BodyType<ScenarioTypeCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return scenarioTypesCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenarioTypesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof scenarioTypesCreate>>>
export type ScenarioTypesCreateMutationBody = BodyType<ScenarioTypeCreateReqRequest>
export type ScenarioTypesCreateMutationError = ErrorType<unknown>

/**
 * @summary 시나리오 타입 생성
 */
export const useScenarioTypesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenarioTypesCreate>>,
    TError,
    { data: BodyType<ScenarioTypeCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenarioTypesCreate>>,
  TError,
  { data: BodyType<ScenarioTypeCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getScenarioTypesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const scenarioTypesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ScenarioTypeRes>({ url: `/scenario_types/${id}/`, method: 'GET', signal }, options)
}

export const getScenarioTypesRetrieveQueryKey = (id: number) => {
  return [`/scenario_types/${id}/`] as const
}

export const getScenarioTypesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenarioTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenarioTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenarioTypesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenarioTypesRetrieve>>> = ({ signal }) =>
    scenarioTypesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenarioTypesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenarioTypesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof scenarioTypesRetrieve>>>
export type ScenarioTypesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useScenarioTypesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof scenarioTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenarioTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenarioTypesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenarioTypesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof scenarioTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenarioTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenarioTypesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenarioTypesRetrieve>>> = ({ signal }) =>
    scenarioTypesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenarioTypesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenarioTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof scenarioTypesRetrieve>>>
export type ScenarioTypesRetrieveQueryError = ErrorType<unknown>

export const useScenarioTypesRetrieve = <
  TData = Awaited<ReturnType<typeof scenarioTypesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenarioTypesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenarioTypesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 

권한: 통합 관리자
 * @summary 시나리오 타입 업데이트
 */
export const scenarioTypesUpdate = (
  id: number,
  scenarioTypeUpdateReqRequest: BodyType<ScenarioTypeUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ScenarioTypeRes>(
    {
      url: `/scenario_types/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: scenarioTypeUpdateReqRequest
    },
    options
  )
}

export const getScenarioTypesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenarioTypesUpdate>>,
    TError,
    { id: number; data: BodyType<ScenarioTypeUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenarioTypesUpdate>>,
  TError,
  { id: number; data: BodyType<ScenarioTypeUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scenarioTypesUpdate>>,
    { id: number; data: BodyType<ScenarioTypeUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return scenarioTypesUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenarioTypesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof scenarioTypesUpdate>>>
export type ScenarioTypesUpdateMutationBody = BodyType<ScenarioTypeUpdateReqRequest>
export type ScenarioTypesUpdateMutationError = ErrorType<unknown>

/**
 * @summary 시나리오 타입 업데이트
 */
export const useScenarioTypesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenarioTypesUpdate>>,
    TError,
    { id: number; data: BodyType<ScenarioTypeUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenarioTypesUpdate>>,
  TError,
  { id: number; data: BodyType<ScenarioTypeUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getScenarioTypesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const scenariosList = (
  params?: ScenariosListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedScenarioResList>({ url: `/scenarios/`, method: 'GET', params, signal }, options)
}

export const getScenariosListQueryKey = (params?: ScenariosListParams) => {
  return [`/scenarios/`, ...(params ? [params] : [])] as const
}

export const getScenariosListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenariosListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosList>>> = ({ signal }) =>
    scenariosList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenariosList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof scenariosList>>>
export type ScenariosListInfiniteQueryError = ErrorType<unknown>

export const useScenariosListInfinite = <
  TData = Awaited<ReturnType<typeof scenariosList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenariosListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenariosListQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosList>>,
  TError = ErrorType<unknown>
>(
  params?: ScenariosListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosList>>> = ({ signal }) =>
    scenariosList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenariosList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosListQueryResult = NonNullable<Awaited<ReturnType<typeof scenariosList>>>
export type ScenariosListQueryError = ErrorType<unknown>

export const useScenariosList = <TData = Awaited<ReturnType<typeof scenariosList>>, TError = ErrorType<unknown>>(
  params?: ScenariosListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const scenariosRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ScenarioRes>({ url: `/scenarios/${id}/`, method: 'GET', signal }, options)
}

export const getScenariosRetrieveQueryKey = (id: number) => {
  return [`/scenarios/${id}/`] as const
}

export const getScenariosRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosRetrieve>>> = ({ signal }) =>
    scenariosRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenariosRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof scenariosRetrieve>>>
export type ScenariosRetrieveInfiniteQueryError = ErrorType<unknown>

export const useScenariosRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof scenariosRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenariosRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosRetrieve>>> = ({ signal }) =>
    scenariosRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenariosRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof scenariosRetrieve>>>
export type ScenariosRetrieveQueryError = ErrorType<unknown>

export const useScenariosRetrieve = <
  TData = Awaited<ReturnType<typeof scenariosRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 시나리오를 취소합니다. 임무를 중단하고 대기장소로 돌아갑니다.
 * @summary 시나리오 취소
 */
export const scenariosCancelUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ScenarioRes>({ url: `/scenarios/${id}/cancel/`, method: 'PUT' }, options)
}

export const getScenariosCancelUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenariosCancelUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof scenariosCancelUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof scenariosCancelUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return scenariosCancelUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosCancelUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof scenariosCancelUpdate>>>

export type ScenariosCancelUpdateMutationError = ErrorType<unknown>

/**
 * @summary 시나리오 취소
 */
export const useScenariosCancelUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenariosCancelUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof scenariosCancelUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getScenariosCancelUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 시나리오를 강제 종료합니다. 로봇이 제자리에 멈춥니다.
 * @summary 시나리오 강제 종료
 */
export const scenariosCompleteCreate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ScenarioRes>({ url: `/scenarios/${id}/complete/`, method: 'POST' }, options)
}

export const getScenariosCompleteCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenariosCompleteCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof scenariosCompleteCreate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof scenariosCompleteCreate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return scenariosCompleteCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosCompleteCreateMutationResult = NonNullable<Awaited<ReturnType<typeof scenariosCompleteCreate>>>

export type ScenariosCompleteCreateMutationError = ErrorType<unknown>

/**
 * @summary 시나리오 강제 종료
 */
export const useScenariosCompleteCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenariosCompleteCreate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof scenariosCompleteCreate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getScenariosCompleteCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 시나리오 정보 조회
 * @summary 시나리오 정보 조회
 */
export const scenariosDetailRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ScenarioDetailRes>({ url: `/scenarios/${id}/detail/`, method: 'GET', signal }, options)
}

export const getScenariosDetailRetrieveQueryKey = (id: number) => {
  return [`/scenarios/${id}/detail/`] as const
}

export const getScenariosDetailRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosDetailRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosDetailRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosDetailRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosDetailRetrieve>>> = ({ signal }) =>
    scenariosDetailRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenariosDetailRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosDetailRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof scenariosDetailRetrieve>>
>
export type ScenariosDetailRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 시나리오 정보 조회
 */
export const useScenariosDetailRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof scenariosDetailRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosDetailRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosDetailRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenariosDetailRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosDetailRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosDetailRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosDetailRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosDetailRetrieve>>> = ({ signal }) =>
    scenariosDetailRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenariosDetailRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosDetailRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof scenariosDetailRetrieve>>>
export type ScenariosDetailRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 시나리오 정보 조회
 */
export const useScenariosDetailRetrieve = <
  TData = Awaited<ReturnType<typeof scenariosDetailRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosDetailRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosDetailRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 시나리오 담당자를 수정합니다. 반드시 지정될 모든 담당자를 넣어야합니다.
 * @summary 시나리오 담당자 수정
 */
export const scenariosManagersUpdate = (
  id: number,
  scenarioManagerUpdateReqRequest: BodyType<ScenarioManagerUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/scenarios/${id}/managers/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: scenarioManagerUpdateReqRequest
    },
    options
  )
}

export const getScenariosManagersUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosManagersUpdate>>,
    TError,
    { id: number; data: BodyType<ScenarioManagerUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenariosManagersUpdate>>,
  TError,
  { id: number; data: BodyType<ScenarioManagerUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scenariosManagersUpdate>>,
    { id: number; data: BodyType<ScenarioManagerUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return scenariosManagersUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosManagersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof scenariosManagersUpdate>>>
export type ScenariosManagersUpdateMutationBody = BodyType<ScenarioManagerUpdateReqRequest>
export type ScenariosManagersUpdateMutationError = ErrorType<unknown>

/**
 * @summary 시나리오 담당자 수정
 */
export const useScenariosManagersUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosManagersUpdate>>,
    TError,
    { id: number; data: BodyType<ScenarioManagerUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenariosManagersUpdate>>,
  TError,
  { id: number; data: BodyType<ScenarioManagerUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getScenariosManagersUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 시나리오 최근 알림, 우선순위가 있으면 우선순위 위주, 이후 최신 순
 * @summary 시나리오 최근 알림
 */
export const scenariosPatrolsAlarmRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ScenarioPatrolAlarmRes>(
    { url: `/scenarios/${id}/patrols/alarm/`, method: 'GET', signal },
    options
  )
}

export const getScenariosPatrolsAlarmRetrieveQueryKey = (id: number) => {
  return [`/scenarios/${id}/patrols/alarm/`] as const
}

export const getScenariosPatrolsAlarmRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosPatrolsAlarmRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>> = ({ signal }) =>
    scenariosPatrolsAlarmRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosPatrolsAlarmRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>
>
export type ScenariosPatrolsAlarmRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 시나리오 최근 알림
 */
export const useScenariosPatrolsAlarmRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosPatrolsAlarmRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenariosPatrolsAlarmRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosPatrolsAlarmRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>> = ({ signal }) =>
    scenariosPatrolsAlarmRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosPatrolsAlarmRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>
>
export type ScenariosPatrolsAlarmRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 시나리오 최근 알림
 */
export const useScenariosPatrolsAlarmRetrieve = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsAlarmRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosPatrolsAlarmRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * LIT 순찰 시 이력
 * @summary LIT 순찰 이력
 */
export const scenariosPatrolsDetectionsList = (
  id: number,
  params?: ScenariosPatrolsDetectionsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedScenarioPatrolDetectionResList>(
    { url: `/scenarios/${id}/patrols/detections/`, method: 'GET', params, signal },
    options
  )
}

export const getScenariosPatrolsDetectionsListQueryKey = (
  id: number,
  params?: ScenariosPatrolsDetectionsListParams
) => {
  return [`/scenarios/${id}/patrols/detections/`, ...(params ? [params] : [])] as const
}

export const getScenariosPatrolsDetectionsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: ScenariosPatrolsDetectionsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosPatrolsDetectionsListQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>> = ({ signal }) =>
    scenariosPatrolsDetectionsList(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosPatrolsDetectionsListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>
>
export type ScenariosPatrolsDetectionsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary LIT 순찰 이력
 */
export const useScenariosPatrolsDetectionsListInfinite = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: ScenariosPatrolsDetectionsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosPatrolsDetectionsListInfiniteQueryOptions(id, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getScenariosPatrolsDetectionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: ScenariosPatrolsDetectionsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getScenariosPatrolsDetectionsListQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>> = ({ signal }) =>
    scenariosPatrolsDetectionsList(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ScenariosPatrolsDetectionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>
>
export type ScenariosPatrolsDetectionsListQueryError = ErrorType<unknown>

/**
 * @summary LIT 순찰 이력
 */
export const useScenariosPatrolsDetectionsList = <
  TData = Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: ScenariosPatrolsDetectionsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof scenariosPatrolsDetectionsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getScenariosPatrolsDetectionsListQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 탐지 순회 이상 발생 시 이상 상황 해제
 * @summary 이상 상황 해제
 */
export const scenariosPatrolsReleaseAlarmCreate = (
  id: number,
  scenarioReleaseAlarmReqRequest: BodyType<ScenarioReleaseAlarmReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/scenarios/${id}/patrols/release-alarm/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: scenarioReleaseAlarmReqRequest
    },
    options
  )
}

export const getScenariosPatrolsReleaseAlarmCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmCreate>>,
    TError,
    { id: number; data: BodyType<ScenarioReleaseAlarmReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmCreate>>,
  TError,
  { id: number; data: BodyType<ScenarioReleaseAlarmReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmCreate>>,
    { id: number; data: BodyType<ScenarioReleaseAlarmReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return scenariosPatrolsReleaseAlarmCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosPatrolsReleaseAlarmCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmCreate>>
>
export type ScenariosPatrolsReleaseAlarmCreateMutationBody = BodyType<ScenarioReleaseAlarmReqRequest>
export type ScenariosPatrolsReleaseAlarmCreateMutationError = ErrorType<unknown>

/**
 * @summary 이상 상황 해제
 */
export const useScenariosPatrolsReleaseAlarmCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmCreate>>,
    TError,
    { id: number; data: BodyType<ScenarioReleaseAlarmReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmCreate>>,
  TError,
  { id: number; data: BodyType<ScenarioReleaseAlarmReqRequest> },
  TContext
> => {
  const mutationOptions = getScenariosPatrolsReleaseAlarmCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 탐지 순회 이상 발생 시 비인증 이상 상황 해제(알림톡 용도)
 * @summary 비인증 이상 상황 해제
 */
export const scenariosPatrolsReleaseAlarmNonAuthCreate = (
  id: number,
  scenarioNonAuthReleaseAlarmReqRequest: BodyType<ScenarioNonAuthReleaseAlarmReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/scenarios/${id}/patrols/release-alarm/non-auth/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: scenarioNonAuthReleaseAlarmReqRequest
    },
    options
  )
}

export const getScenariosPatrolsReleaseAlarmNonAuthCreateMutationOptions = <
  TError = ErrorType<ScenarioNonAuthReleaseAlarm400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmNonAuthCreate>>,
    TError,
    { id: number; data: BodyType<ScenarioNonAuthReleaseAlarmReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmNonAuthCreate>>,
  TError,
  { id: number; data: BodyType<ScenarioNonAuthReleaseAlarmReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmNonAuthCreate>>,
    { id: number; data: BodyType<ScenarioNonAuthReleaseAlarmReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return scenariosPatrolsReleaseAlarmNonAuthCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosPatrolsReleaseAlarmNonAuthCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmNonAuthCreate>>
>
export type ScenariosPatrolsReleaseAlarmNonAuthCreateMutationBody = BodyType<ScenarioNonAuthReleaseAlarmReqRequest>
export type ScenariosPatrolsReleaseAlarmNonAuthCreateMutationError = ErrorType<ScenarioNonAuthReleaseAlarm400Exception>

/**
 * @summary 비인증 이상 상황 해제
 */
export const useScenariosPatrolsReleaseAlarmNonAuthCreate = <
  TError = ErrorType<ScenarioNonAuthReleaseAlarm400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmNonAuthCreate>>,
    TError,
    { id: number; data: BodyType<ScenarioNonAuthReleaseAlarmReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenariosPatrolsReleaseAlarmNonAuthCreate>>,
  TError,
  { id: number; data: BodyType<ScenarioNonAuthReleaseAlarmReqRequest> },
  TContext
> => {
  const mutationOptions = getScenariosPatrolsReleaseAlarmNonAuthCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 배달 미수령 처리
 * @summary 배달 미수령 처리
 */
export const scenariosUnclaimedUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/scenarios/${id}/unclaimed/`, method: 'PUT' }, options)
}

export const getScenariosUnclaimedUpdateMutationOptions = <
  TError = ErrorType<ScenarioUnclaimed400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenariosUnclaimedUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof scenariosUnclaimedUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof scenariosUnclaimedUpdate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return scenariosUnclaimedUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosUnclaimedUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof scenariosUnclaimedUpdate>>>

export type ScenariosUnclaimedUpdateMutationError = ErrorType<ScenarioUnclaimed400Exception>

/**
 * @summary 배달 미수령 처리
 */
export const useScenariosUnclaimedUpdate = <
  TError = ErrorType<ScenarioUnclaimed400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenariosUnclaimedUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof scenariosUnclaimedUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getScenariosUnclaimedUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 시나리오 배차
 * @summary 시나리오 배차
 */
export const scenariosDispatchCreate = (
  scenarioCreateReqRequest: BodyType<ScenarioCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ScenarioRes>(
    {
      url: `/scenarios/dispatch/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: scenarioCreateReqRequest
    },
    options
  )
}

export const getScenariosDispatchCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosDispatchCreate>>,
    TError,
    { data: BodyType<ScenarioCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenariosDispatchCreate>>,
  TError,
  { data: BodyType<ScenarioCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scenariosDispatchCreate>>,
    { data: BodyType<ScenarioCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return scenariosDispatchCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenariosDispatchCreateMutationResult = NonNullable<Awaited<ReturnType<typeof scenariosDispatchCreate>>>
export type ScenariosDispatchCreateMutationBody = BodyType<ScenarioCreateReqRequest>
export type ScenariosDispatchCreateMutationError = ErrorType<unknown>

/**
 * @summary 시나리오 배차
 */
export const useScenariosDispatchCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenariosDispatchCreate>>,
    TError,
    { data: BodyType<ScenarioCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenariosDispatchCreate>>,
  TError,
  { data: BodyType<ScenarioCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getScenariosDispatchCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * MOVE Scene을 도착 처리합니다.
 * @summary Scene 도착 처리
 */
export const scenesArriveUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/scenes/${id}/arrive/`, method: 'PUT' }, options)
}

export const getScenesArriveUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenesArriveUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof scenesArriveUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof scenesArriveUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return scenesArriveUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenesArriveUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof scenesArriveUpdate>>>

export type ScenesArriveUpdateMutationError = ErrorType<void>

/**
 * @summary Scene 도착 처리
 */
export const useScenesArriveUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenesArriveUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof scenesArriveUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getScenesArriveUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const scenesEndUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/scenes/${id}/end/`, method: 'PUT' }, options)
}

export const getScenesEndUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenesEndUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof scenesEndUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof scenesEndUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return scenesEndUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenesEndUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof scenesEndUpdate>>>

export type ScenesEndUpdateMutationError = ErrorType<void>

export const useScenesEndUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof scenesEndUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof scenesEndUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getScenesEndUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 탐지 순회 이상 발생 시 정밀 수행 코스 진입
 * @summary 정밀 탐지 수행
 */
export const scenesStartPreciseDetectionUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/scenes/${id}/start-precise-detection/`, method: 'PUT' }, options)
}

export const getScenesStartPreciseDetectionUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenesStartPreciseDetectionUpdate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof scenesStartPreciseDetectionUpdate>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof scenesStartPreciseDetectionUpdate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return scenesStartPreciseDetectionUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ScenesStartPreciseDetectionUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scenesStartPreciseDetectionUpdate>>
>

export type ScenesStartPreciseDetectionUpdateMutationError = ErrorType<unknown>

/**
 * @summary 정밀 탐지 수행
 */
export const useScenesStartPreciseDetectionUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof scenesStartPreciseDetectionUpdate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof scenesStartPreciseDetectionUpdate>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getScenesStartPreciseDetectionUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 시퀀스를 강제 종료합니다. 다음 시퀀스를 진행합니다.
 * @summary 시퀀스 강제 종료
 */
export const sequencesCompleteUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/sequences/${id}/complete/`, method: 'PUT' }, options)
}

export const getSequencesCompleteUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sequencesCompleteUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof sequencesCompleteUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sequencesCompleteUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return sequencesCompleteUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SequencesCompleteUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sequencesCompleteUpdate>>>

export type SequencesCompleteUpdateMutationError = ErrorType<unknown>

/**
 * @summary 시퀀스 강제 종료
 */
export const useSequencesCompleteUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sequencesCompleteUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof sequencesCompleteUpdate>>, TError, { id: number }, TContext> => {
  const mutationOptions = getSequencesCompleteUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const servicesList = (
  params?: ServicesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedServiceResList>({ url: `/services/`, method: 'GET', params, signal }, options)
}

export const getServicesListQueryKey = (params?: ServicesListParams) => {
  return [`/services/`, ...(params ? [params] : [])] as const
}

export const getServicesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof servicesList>>,
  TError = ErrorType<unknown>
>(
  params?: ServicesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof servicesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getServicesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof servicesList>>> = ({ signal }) =>
    servicesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof servicesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ServicesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof servicesList>>>
export type ServicesListInfiniteQueryError = ErrorType<unknown>

export const useServicesListInfinite = <TData = Awaited<ReturnType<typeof servicesList>>, TError = ErrorType<unknown>>(
  params?: ServicesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof servicesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServicesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getServicesListQueryOptions = <
  TData = Awaited<ReturnType<typeof servicesList>>,
  TError = ErrorType<unknown>
>(
  params?: ServicesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof servicesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getServicesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof servicesList>>> = ({ signal }) =>
    servicesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof servicesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ServicesListQueryResult = NonNullable<Awaited<ReturnType<typeof servicesList>>>
export type ServicesListQueryError = ErrorType<unknown>

export const useServicesList = <TData = Awaited<ReturnType<typeof servicesList>>, TError = ErrorType<unknown>>(
  params?: ServicesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof servicesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServicesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 서비스 생성
 * @summary 서비스 생성
 */
export const servicesCreate = (
  serviceCreateReqRequest: BodyType<ServiceCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ServiceRes>(
    {
      url: `/services/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: serviceCreateReqRequest
    },
    options
  )
}

export const getServicesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof servicesCreate>>,
    TError,
    { data: BodyType<ServiceCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof servicesCreate>>,
  TError,
  { data: BodyType<ServiceCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof servicesCreate>>,
    { data: BodyType<ServiceCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return servicesCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ServicesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof servicesCreate>>>
export type ServicesCreateMutationBody = BodyType<ServiceCreateReqRequest>
export type ServicesCreateMutationError = ErrorType<unknown>

/**
 * @summary 서비스 생성
 */
export const useServicesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof servicesCreate>>,
    TError,
    { data: BodyType<ServiceCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof servicesCreate>>,
  TError,
  { data: BodyType<ServiceCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getServicesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const servicesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ServiceRes>({ url: `/services/${id}/`, method: 'GET', signal }, options)
}

export const getServicesRetrieveQueryKey = (id: number) => {
  return [`/services/${id}/`] as const
}

export const getServicesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof servicesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof servicesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getServicesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof servicesRetrieve>>> = ({ signal }) =>
    servicesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof servicesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ServicesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof servicesRetrieve>>>
export type ServicesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useServicesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof servicesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof servicesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServicesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getServicesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof servicesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof servicesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getServicesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof servicesRetrieve>>> = ({ signal }) =>
    servicesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof servicesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ServicesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof servicesRetrieve>>>
export type ServicesRetrieveQueryError = ErrorType<unknown>

export const useServicesRetrieve = <TData = Awaited<ReturnType<typeof servicesRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof servicesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServicesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 서비스 업데이트
 * @summary 서비스 업데이트
 */
export const servicesUpdate = (
  id: number,
  serviceUpdateReqRequest: BodyType<ServiceUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ServiceRes>(
    {
      url: `/services/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: serviceUpdateReqRequest
    },
    options
  )
}

export const getServicesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof servicesUpdate>>,
    TError,
    { id: number; data: BodyType<ServiceUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof servicesUpdate>>,
  TError,
  { id: number; data: BodyType<ServiceUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof servicesUpdate>>,
    { id: number; data: BodyType<ServiceUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return servicesUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ServicesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof servicesUpdate>>>
export type ServicesUpdateMutationBody = BodyType<ServiceUpdateReqRequest>
export type ServicesUpdateMutationError = ErrorType<unknown>

/**
 * @summary 서비스 업데이트
 */
export const useServicesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof servicesUpdate>>,
    TError,
    { id: number; data: BodyType<ServiceUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof servicesUpdate>>,
  TError,
  { id: number; data: BodyType<ServiceUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getServicesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const sitesList = (
  params?: SitesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSiteResList>({ url: `/sites/`, method: 'GET', params, signal }, options)
}

export const getSitesListQueryKey = (params?: SitesListParams) => {
  return [`/sites/`, ...(params ? [params] : [])] as const
}

export const getSitesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesList>>,
  TError = ErrorType<unknown>
>(
  params?: SitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) =>
    sitesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListInfiniteQueryError = ErrorType<unknown>

export const useSitesListInfinite = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesListQueryOptions = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) =>
    sitesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListQueryError = ErrorType<unknown>

export const useSitesList = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 생성
 */
export const sitesCreate = (
  siteCreateReqRequest: BodyType<SiteCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteRes>(
    { url: `/sites/`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: siteCreateReqRequest },
    options
  )
}

export const getSitesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesCreate>>,
    TError,
    { data: BodyType<SiteCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesCreate>>,
  TError,
  { data: BodyType<SiteCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesCreate>>,
    { data: BodyType<SiteCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return sitesCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesCreate>>>
export type SitesCreateMutationBody = BodyType<SiteCreateReqRequest>
export type SitesCreateMutationError = ErrorType<unknown>

export const useSitesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesCreate>>,
    TError,
    { data: BodyType<SiteCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesCreate>>,
  TError,
  { data: BodyType<SiteCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const sitesRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<SiteRes>({ url: `/sites/${id}/`, method: 'GET', signal }, options)
}

export const getSitesRetrieveQueryKey = (id: number) => {
  return [`/sites/${id}/`] as const
}

export const getSitesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesRetrieve>>> = ({ signal }) =>
    sitesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesRetrieve>>>
export type SitesRetrieveInfiniteQueryError = ErrorType<unknown>

export const useSitesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesRetrieve>>> = ({ signal }) =>
    sitesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesRetrieve>>>
export type SitesRetrieveQueryError = ErrorType<unknown>

export const useSitesRetrieve = <TData = Awaited<ReturnType<typeof sitesRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 정보 수정
 * @summary 사이트 정보 수정
 */
export const sitesUpdate = (
  id: number,
  siteUpdateReqRequest: BodyType<SiteUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteRes>(
    {
      url: `/sites/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: siteUpdateReqRequest
    },
    options
  )
}

export const getSitesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesUpdate>>,
    TError,
    { id: number; data: BodyType<SiteUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesUpdate>>,
  TError,
  { id: number; data: BodyType<SiteUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesUpdate>>,
    { id: number; data: BodyType<SiteUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesUpdate>>>
export type SitesUpdateMutationBody = BodyType<SiteUpdateReqRequest>
export type SitesUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 정보 수정
 */
export const useSitesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesUpdate>>,
    TError,
    { id: number; data: BodyType<SiteUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesUpdate>>,
  TError,
  { id: number; data: BodyType<SiteUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트에 로봇이 이동 가능한 경로를 나타내는 OSM XML 파일을 업로드합니다.
 * @summary 사이트 OSM XML 업로드
 */
export const sitesGlobalMapCreate = (
  id: number,
  sitesGlobalMapCreateBody: BodyType<SitesGlobalMapCreateBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(sitesGlobalMapCreateBody)
  return customInstance<SiteRes>(
    {
      url: `/sites/${id}/global-map/`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getSitesGlobalMapCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalMapCreate>>,
    TError,
    { id: number; data: BodyType<SitesGlobalMapCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalMapCreate>>,
  TError,
  { id: number; data: BodyType<SitesGlobalMapCreateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalMapCreate>>,
    { id: number; data: BodyType<SitesGlobalMapCreateBody> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesGlobalMapCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalMapCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesGlobalMapCreate>>>
export type SitesGlobalMapCreateMutationBody = BodyType<SitesGlobalMapCreateBody>
export type SitesGlobalMapCreateMutationError = ErrorType<void>

/**
 * @summary 사이트 OSM XML 업로드
 */
export const useSitesGlobalMapCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalMapCreate>>,
    TError,
    { id: number; data: BodyType<SitesGlobalMapCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalMapCreate>>,
  TError,
  { id: number; data: BodyType<SitesGlobalMapCreateBody> },
  TContext
> => {
  const mutationOptions = getSitesGlobalMapCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트에 KT graphsync를 위한 JSON 파일을 업로드 합니다.
 * @summary 사이트에 GeoJSON 업로드
 */
export const sitesGraphCreate = (
  id: number,
  sitesGraphCreateBody: BodyType<SitesGraphCreateBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(sitesGraphCreateBody)
  return customInstance<SiteRes>(
    { url: `/sites/${id}/graph/`, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getSitesGraphCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGraphCreate>>,
    TError,
    { id: number; data: BodyType<SitesGraphCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGraphCreate>>,
  TError,
  { id: number; data: BodyType<SitesGraphCreateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGraphCreate>>,
    { id: number; data: BodyType<SitesGraphCreateBody> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesGraphCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGraphCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesGraphCreate>>>
export type SitesGraphCreateMutationBody = BodyType<SitesGraphCreateBody>
export type SitesGraphCreateMutationError = ErrorType<void>

/**
 * @summary 사이트에 GeoJSON 업로드
 */
export const useSitesGraphCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGraphCreate>>,
    TError,
    { id: number; data: BodyType<SitesGraphCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGraphCreate>>,
  TError,
  { id: number; data: BodyType<SitesGraphCreateBody> },
  TContext
> => {
  const mutationOptions = getSitesGraphCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트의 노드 정보를 활용해서 graph 파일을 만들어냅니다. graph 파일은 KT에서 사용하는 POI 확인용 JSON 파일입니다.
 * @summary 사이트 노드로 graph 생성
 */
export const sitesGraphGenerateCreate = (
  id: number,
  siteGraphGenerateReqRequest: BodyType<SiteGraphGenerateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteGraphGenerateRes>(
    {
      url: `/sites/${id}/graph/generate/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteGraphGenerateReqRequest
    },
    options
  )
}

export const getSitesGraphGenerateCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGraphGenerateCreate>>,
    TError,
    { id: number; data: BodyType<SiteGraphGenerateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGraphGenerateCreate>>,
  TError,
  { id: number; data: BodyType<SiteGraphGenerateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGraphGenerateCreate>>,
    { id: number; data: BodyType<SiteGraphGenerateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesGraphGenerateCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGraphGenerateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesGraphGenerateCreate>>>
export type SitesGraphGenerateCreateMutationBody = BodyType<SiteGraphGenerateReqRequest>
export type SitesGraphGenerateCreateMutationError = ErrorType<void>

/**
 * @summary 사이트 노드로 graph 생성
 */
export const useSitesGraphGenerateCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGraphGenerateCreate>>,
    TError,
    { id: number; data: BodyType<SiteGraphGenerateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGraphGenerateCreate>>,
  TError,
  { id: number; data: BodyType<SiteGraphGenerateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGraphGenerateCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 LIT 가스, 객체 감지, 온도 확인
 * @summary 사이트 LIT 순찰 설정
 */
export const sitesPatrolsSettingsRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SitePatrolSettings>({ url: `/sites/${id}/patrols/settings/`, method: 'GET', signal }, options)
}

export const getSitesPatrolsSettingsRetrieveQueryKey = (id: number) => {
  return [`/sites/${id}/patrols/settings/`] as const
}

export const getSitesPatrolsSettingsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesPatrolsSettingsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>> = ({ signal }) =>
    sitesPatrolsSettingsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesPatrolsSettingsRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>
>
export type SitesPatrolsSettingsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 LIT 순찰 설정
 */
export const useSitesPatrolsSettingsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesPatrolsSettingsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesPatrolsSettingsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesPatrolsSettingsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>> = ({ signal }) =>
    sitesPatrolsSettingsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesPatrolsSettingsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>
>
export type SitesPatrolsSettingsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사이트 LIT 순찰 설정
 */
export const useSitesPatrolsSettingsRetrieve = <
  TData = Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesPatrolsSettingsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesPatrolsSettingsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 LIT 가스, 객체 감지, 온도 설정
 * @summary 사이트 LIT 순찰 설정
 */
export const sitesPatrolsSettingsUpdate = (
  id: number,
  sitePatrolSettingsRequest: BodyType<SitePatrolSettingsRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SitePatrolSettings>(
    {
      url: `/sites/${id}/patrols/settings/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: sitePatrolSettingsRequest
    },
    options
  )
}

export const getSitesPatrolsSettingsUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesPatrolsSettingsUpdate>>,
    TError,
    { id: number; data: BodyType<SitePatrolSettingsRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesPatrolsSettingsUpdate>>,
  TError,
  { id: number; data: BodyType<SitePatrolSettingsRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesPatrolsSettingsUpdate>>,
    { id: number; data: BodyType<SitePatrolSettingsRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesPatrolsSettingsUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesPatrolsSettingsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesPatrolsSettingsUpdate>>
>
export type SitesPatrolsSettingsUpdateMutationBody = BodyType<SitePatrolSettingsRequest>
export type SitesPatrolsSettingsUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 LIT 순찰 설정
 */
export const useSitesPatrolsSettingsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesPatrolsSettingsUpdate>>,
    TError,
    { id: number; data: BodyType<SitePatrolSettingsRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesPatrolsSettingsUpdate>>,
  TError,
  { id: number; data: BodyType<SitePatrolSettingsRequest> },
  TContext
> => {
  const mutationOptions = getSitesPatrolsSettingsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트에 로봇을 할당, 포함되지 않은 로봇은 제거됩니다.
 * @summary 사이트에 로봇을 할당
 */
export const sitesRobotsUpdate = (
  id: number,
  siteUpdateRobotsReqRequest: BodyType<SiteUpdateRobotsReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteRes>(
    {
      url: `/sites/${id}/robots/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: siteUpdateRobotsReqRequest
    },
    options
  )
}

export const getSitesRobotsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesRobotsUpdate>>,
    TError,
    { id: number; data: BodyType<SiteUpdateRobotsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesRobotsUpdate>>,
  TError,
  { id: number; data: BodyType<SiteUpdateRobotsReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesRobotsUpdate>>,
    { id: number; data: BodyType<SiteUpdateRobotsReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesRobotsUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesRobotsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesRobotsUpdate>>>
export type SitesRobotsUpdateMutationBody = BodyType<SiteUpdateRobotsReqRequest>
export type SitesRobotsUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트에 로봇을 할당
 */
export const useSitesRobotsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesRobotsUpdate>>,
    TError,
    { id: number; data: BodyType<SiteUpdateRobotsReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesRobotsUpdate>>,
  TError,
  { id: number; data: BodyType<SiteUpdateRobotsReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesRobotsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 관리자 지정
 * @summary 사이트 관리자 지정
 */
export const sitesUsersUpdate = (
  id: number,
  siteUpdateUsersReqRequest: BodyType<SiteUpdateUsersReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteRes>(
    {
      url: `/sites/${id}/users/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: siteUpdateUsersReqRequest
    },
    options
  )
}

export const getSitesUsersUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesUsersUpdate>>,
    TError,
    { id: number; data: BodyType<SiteUpdateUsersReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesUsersUpdate>>,
  TError,
  { id: number; data: BodyType<SiteUpdateUsersReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesUsersUpdate>>,
    { id: number; data: BodyType<SiteUpdateUsersReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesUsersUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesUsersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesUsersUpdate>>>
export type SitesUsersUpdateMutationBody = BodyType<SiteUpdateUsersReqRequest>
export type SitesUsersUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 관리자 지정
 */
export const useSitesUsersUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesUsersUpdate>>,
    TError,
    { id: number; data: BodyType<SiteUpdateUsersReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesUsersUpdate>>,
  TError,
  { id: number; data: BodyType<SiteUpdateUsersReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesUsersUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 목록 조회
 * @summary 사이트 글로벌 패스 목록 조회
 */
export const sitesGlobalPathsList = (
  sitePk: number | null,
  params?: SitesGlobalPathsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSiteGlobalPathResList>(
    { url: `/sites/${sitePk}/global-paths/`, method: 'GET', params, signal },
    options
  )
}

export const getSitesGlobalPathsListQueryKey = (sitePk: number | null, params?: SitesGlobalPathsListParams) => {
  return [`/sites/${sitePk}/global-paths/`, ...(params ? [params] : [])] as const
}

export const getSitesGlobalPathsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesGlobalPathsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsListQueryKey(sitePk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsList>>> = ({ signal }) =>
    sitesGlobalPathsList(sitePk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!sitePk, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesGlobalPathsList>>>
export type SitesGlobalPathsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 목록 조회
 */
export const useSitesGlobalPathsListInfinite = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesGlobalPathsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsListInfiniteQueryOptions(sitePk, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesGlobalPathsListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesGlobalPathsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsListQueryKey(sitePk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsList>>> = ({ signal }) =>
    sitesGlobalPathsList(sitePk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!sitePk, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesGlobalPathsList>>>
export type SitesGlobalPathsListQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 목록 조회
 */
export const useSitesGlobalPathsList = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesGlobalPathsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsListQueryOptions(sitePk, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇이 주행한 사이트 글로벌 패스 생성
 * @summary 사이트 글로벌 패스 생성
 */
export const sitesGlobalPathsCreate = (
  sitePk: number | null,
  siteGlobalPathCreateReqRequest: BodyType<SiteGlobalPathCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteGlobalPathRes>(
    {
      url: `/sites/${sitePk}/global-paths/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteGlobalPathCreateReqRequest
    },
    options
  )
}

export const getSitesGlobalPathsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsCreate>>,
    { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> }
  > = (props) => {
    const { sitePk, data } = props ?? {}

    return sitesGlobalPathsCreate(sitePk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesGlobalPathsCreate>>>
export type SitesGlobalPathsCreateMutationBody = BodyType<SiteGlobalPathCreateReqRequest>
export type SitesGlobalPathsCreateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 생성
 */
export const useSitesGlobalPathsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 상세 조회
 * @summary 사이트 글로벌 패스 상세 조회
 */
export const sitesGlobalPathsRetrieve = (
  sitePk: number | null,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteGlobalPathRes>(
    { url: `/sites/${sitePk}/global-paths/${id}/`, method: 'GET', signal },
    options
  )
}

export const getSitesGlobalPathsRetrieveQueryKey = (sitePk: number | null, id: number) => {
  return [`/sites/${sitePk}/global-paths/${id}/`] as const
}

export const getSitesGlobalPathsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsRetrieveQueryKey(sitePk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsRetrieve(sitePk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && id), ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>
>
export type SitesGlobalPathsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 상세 조회
 */
export const useSitesGlobalPathsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsRetrieveInfiniteQueryOptions(sitePk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesGlobalPathsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsRetrieveQueryKey(sitePk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsRetrieve(sitePk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && id), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>>
export type SitesGlobalPathsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 상세 조회
 */
export const useSitesGlobalPathsRetrieve = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsRetrieveQueryOptions(sitePk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로봇이 주행한 사이트 글로벌 패스 정보 부분 수정
 * @summary 사이트 글로벌 패스 부분 수정
 */
export const sitesGlobalPathsPartialUpdate = (
  sitePk: number | null,
  id: number,
  patchedSiteGlobalPathPartialUpdateReqRequest: BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteGlobalPathRes>(
    {
      url: `/sites/${sitePk}/global-paths/${id}/`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSiteGlobalPathPartialUpdateReqRequest
    },
    options
  )
}

export const getSitesGlobalPathsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsPartialUpdate>>,
    TError,
    { sitePk: number | null; id: number; data: BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsPartialUpdate>>,
  TError,
  { sitePk: number | null; id: number; data: BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsPartialUpdate>>,
    { sitePk: number | null; id: number; data: BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest> }
  > = (props) => {
    const { sitePk, id, data } = props ?? {}

    return sitesGlobalPathsPartialUpdate(sitePk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsPartialUpdate>>
>
export type SitesGlobalPathsPartialUpdateMutationBody = BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest>
export type SitesGlobalPathsPartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 부분 수정
 */
export const useSitesGlobalPathsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsPartialUpdate>>,
    TError,
    { sitePk: number | null; id: number; data: BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsPartialUpdate>>,
  TError,
  { sitePk: number | null; id: number; data: BodyType<PatchedSiteGlobalPathPartialUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 삭제
 * @summary 사이트 글로벌 패스 삭제
 */
export const sitesGlobalPathsDestroy = (
  sitePk: number | null,
  id: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>({ url: `/sites/${sitePk}/global-paths/${id}/`, method: 'DELETE' }, options)
}

export const getSitesGlobalPathsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsDestroy>>,
    TError,
    { sitePk: number | null; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsDestroy>>,
  TError,
  { sitePk: number | null; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsDestroy>>,
    { sitePk: number | null; id: number }
  > = (props) => {
    const { sitePk, id } = props ?? {}

    return sitesGlobalPathsDestroy(sitePk, id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof sitesGlobalPathsDestroy>>>

export type SitesGlobalPathsDestroyMutationError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 삭제
 */
export const useSitesGlobalPathsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsDestroy>>,
    TError,
    { sitePk: number | null; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsDestroy>>,
  TError,
  { sitePk: number | null; id: number },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장
 */
export const sitesGlobalPathsUpdateAsyncCreate = (
  sitePk: number | null,
  id: number,
  siteGlobalPathUpdateReqRequest: BodyType<SiteGlobalPathUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AsyncTaskRes>(
    {
      url: `/sites/${sitePk}/global-paths/${id}/update/async/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteGlobalPathUpdateReqRequest
    },
    options
  )
}

export const getSitesGlobalPathsUpdateAsyncCreateMutationOptions = <
  TError = ErrorType<SiteGlobalPathUpdate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncCreate>>,
    TError,
    { sitePk: number | null; id: number; data: BodyType<SiteGlobalPathUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncCreate>>,
  TError,
  { sitePk: number | null; id: number; data: BodyType<SiteGlobalPathUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncCreate>>,
    { sitePk: number | null; id: number; data: BodyType<SiteGlobalPathUpdateReqRequest> }
  > = (props) => {
    const { sitePk, id, data } = props ?? {}

    return sitesGlobalPathsUpdateAsyncCreate(sitePk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsUpdateAsyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncCreate>>
>
export type SitesGlobalPathsUpdateAsyncCreateMutationBody = BodyType<SiteGlobalPathUpdateReqRequest>
export type SitesGlobalPathsUpdateAsyncCreateMutationError = ErrorType<SiteGlobalPathUpdate400Exception>

/**
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장
 */
export const useSitesGlobalPathsUpdateAsyncCreate = <
  TError = ErrorType<SiteGlobalPathUpdate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncCreate>>,
    TError,
    { sitePk: number | null; id: number; data: BodyType<SiteGlobalPathUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncCreate>>,
  TError,
  { sitePk: number | null; id: number; data: BodyType<SiteGlobalPathUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsUpdateAsyncCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장 태스크 상태 조회
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장 태스크 상태 조회
 */
export const sitesGlobalPathsUpdateAsyncRetrieve = (
  sitePk: number | null,
  id: number,
  task: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteGlobalPathAsyncTaskStatusRes>(
    { url: `/sites/${sitePk}/global-paths/${id}/update/async/${task}/`, method: 'GET', signal },
    options
  )
}

export const getSitesGlobalPathsUpdateAsyncRetrieveQueryKey = (sitePk: number | null, id: number, task: string) => {
  return [`/sites/${sitePk}/global-paths/${id}/update/async/${task}/`] as const
}

export const getSitesGlobalPathsUpdateAsyncRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  task: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsUpdateAsyncRetrieveQueryKey(sitePk, id, task)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsUpdateAsyncRetrieve(sitePk, id, task, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && id && task), ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsUpdateAsyncRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>
>
export type SitesGlobalPathsUpdateAsyncRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장 태스크 상태 조회
 */
export const useSitesGlobalPathsUpdateAsyncRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  task: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsUpdateAsyncRetrieveInfiniteQueryOptions(sitePk, id, task, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesGlobalPathsUpdateAsyncRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  task: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsUpdateAsyncRetrieveQueryKey(sitePk, id, task)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsUpdateAsyncRetrieve(sitePk, id, task, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && id && task), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsUpdateAsyncRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>
>
export type SitesGlobalPathsUpdateAsyncRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 기존 파일에 저장 태스크 상태 조회
 */
export const useSitesGlobalPathsUpdateAsyncRetrieve = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  task: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUpdateAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsUpdateAsyncRetrieveQueryOptions(sitePk, id, task, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 글로벌 패스 일괄 삭제
 * @summary 사이트 글로벌 패스 일괄 삭제
 */
export const sitesGlobalPathsBatchDeleteCreate = (
  sitePk: number | null,
  siteGlobalPathBatchDeleteReqRequest: BodyType<SiteGlobalPathBatchDeleteReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/sites/${sitePk}/global-paths/batch-delete/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteGlobalPathBatchDeleteReqRequest
    },
    options
  )
}

export const getSitesGlobalPathsBatchDeleteCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsBatchDeleteCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathBatchDeleteReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsBatchDeleteCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathBatchDeleteReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsBatchDeleteCreate>>,
    { sitePk: number | null; data: BodyType<SiteGlobalPathBatchDeleteReqRequest> }
  > = (props) => {
    const { sitePk, data } = props ?? {}

    return sitesGlobalPathsBatchDeleteCreate(sitePk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsBatchDeleteCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsBatchDeleteCreate>>
>
export type SitesGlobalPathsBatchDeleteCreateMutationBody = BodyType<SiteGlobalPathBatchDeleteReqRequest>
export type SitesGlobalPathsBatchDeleteCreateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 일괄 삭제
 */
export const useSitesGlobalPathsBatchDeleteCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsBatchDeleteCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathBatchDeleteReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsBatchDeleteCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathBatchDeleteReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsBatchDeleteCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇이 주행한 사이트 글로벌 패스 미리보기
 * @summary 사이트 글로벌 패스 미리보기
 */
export const sitesGlobalPathsPreviewCreate = (
  sitePk: number | null,
  siteGlobalPathCreateReqRequest: BodyType<SiteGlobalPathCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SiteGlobalPathPreviewRes>(
    {
      url: `/sites/${sitePk}/global-paths/preview/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteGlobalPathCreateReqRequest
    },
    options
  )
}

export const getSitesGlobalPathsPreviewCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsPreviewCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsPreviewCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsPreviewCreate>>,
    { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> }
  > = (props) => {
    const { sitePk, data } = props ?? {}

    return sitesGlobalPathsPreviewCreate(sitePk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsPreviewCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsPreviewCreate>>
>
export type SitesGlobalPathsPreviewCreateMutationBody = BodyType<SiteGlobalPathCreateReqRequest>
export type SitesGlobalPathsPreviewCreateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 미리보기
 */
export const useSitesGlobalPathsPreviewCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsPreviewCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsPreviewCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsPreviewCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장
 */
export const sitesGlobalPathsSaveAsyncCreate = (
  sitePk: number | null,
  siteGlobalPathSaveReqRequest: BodyType<SiteGlobalPathSaveReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AsyncTaskRes>(
    {
      url: `/sites/${sitePk}/global-paths/save/async/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: siteGlobalPathSaveReqRequest
    },
    options
  )
}

export const getSitesGlobalPathsSaveAsyncCreateMutationOptions = <
  TError = ErrorType<SiteGlobalPathSaveAsync400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathSaveReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathSaveReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncCreate>>,
    { sitePk: number | null; data: BodyType<SiteGlobalPathSaveReqRequest> }
  > = (props) => {
    const { sitePk, data } = props ?? {}

    return sitesGlobalPathsSaveAsyncCreate(sitePk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsSaveAsyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncCreate>>
>
export type SitesGlobalPathsSaveAsyncCreateMutationBody = BodyType<SiteGlobalPathSaveReqRequest>
export type SitesGlobalPathsSaveAsyncCreateMutationError = ErrorType<SiteGlobalPathSaveAsync400Exception>

/**
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장
 */
export const useSitesGlobalPathsSaveAsyncCreate = <
  TError = ErrorType<SiteGlobalPathSaveAsync400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathSaveReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathSaveReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsSaveAsyncCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장 태스크 상태 조회
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장 태스크 상태 조회
 */
export const sitesGlobalPathsSaveAsyncRetrieve = (
  sitePk: number | null,
  task: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteGlobalPathAsyncTaskStatusRes>(
    { url: `/sites/${sitePk}/global-paths/save/async/${task}/`, method: 'GET', signal },
    options
  )
}

export const getSitesGlobalPathsSaveAsyncRetrieveQueryKey = (sitePk: number | null, task: string) => {
  return [`/sites/${sitePk}/global-paths/save/async/${task}/`] as const
}

export const getSitesGlobalPathsSaveAsyncRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsSaveAsyncRetrieveQueryKey(sitePk, task)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsSaveAsyncRetrieve(sitePk, task, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && task), ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsSaveAsyncRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>
>
export type SitesGlobalPathsSaveAsyncRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장 태스크 상태 조회
 */
export const useSitesGlobalPathsSaveAsyncRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsSaveAsyncRetrieveInfiniteQueryOptions(sitePk, task, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesGlobalPathsSaveAsyncRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsSaveAsyncRetrieveQueryKey(sitePk, task)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsSaveAsyncRetrieve(sitePk, task, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && task), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsSaveAsyncRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>
>
export type SitesGlobalPathsSaveAsyncRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 주행 경로 편집 비동기 새 파일로 저장 태스크 상태 조회
 */
export const useSitesGlobalPathsSaveAsyncRetrieve = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsSaveAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsSaveAsyncRetrieveQueryOptions(sitePk, task, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 글로벌 패스 파일 비동기 업로드
 * @summary 사이트 글로벌 패스 파일 비동기 업로드
 */
export const sitesGlobalPathsUploadAsyncCreate = (
  sitePk: number | null,
  siteGlobalPathUploadReqRequest: BodyType<SiteGlobalPathUploadReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(siteGlobalPathUploadReqRequest)
  return customInstance<AsyncTaskRes>(
    {
      url: `/sites/${sitePk}/global-paths/upload/async/`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getSitesGlobalPathsUploadAsyncCreateMutationOptions = <
  TError = ErrorType<SiteGlobalPathUpload400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathUploadReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathUploadReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncCreate>>,
    { sitePk: number | null; data: BodyType<SiteGlobalPathUploadReqRequest> }
  > = (props) => {
    const { sitePk, data } = props ?? {}

    return sitesGlobalPathsUploadAsyncCreate(sitePk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesGlobalPathsUploadAsyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncCreate>>
>
export type SitesGlobalPathsUploadAsyncCreateMutationBody = BodyType<SiteGlobalPathUploadReqRequest>
export type SitesGlobalPathsUploadAsyncCreateMutationError = ErrorType<SiteGlobalPathUpload400Exception>

/**
 * @summary 사이트 글로벌 패스 파일 비동기 업로드
 */
export const useSitesGlobalPathsUploadAsyncCreate = <
  TError = ErrorType<SiteGlobalPathUpload400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<SiteGlobalPathUploadReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<SiteGlobalPathUploadReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesGlobalPathsUploadAsyncCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 글로벌 패스 파일 비동기 업로드 태스크 상태 조회
 * @summary 사이트 글로벌 패스 파일 비동기 업로드 태스크 상태 조회
 */
export const sitesGlobalPathsUploadAsyncRetrieve = (
  sitePk: number | null,
  task: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteGlobalPathAsyncTaskStatusRes>(
    { url: `/sites/${sitePk}/global-paths/upload/async/${task}/`, method: 'GET', signal },
    options
  )
}

export const getSitesGlobalPathsUploadAsyncRetrieveQueryKey = (sitePk: number | null, task: string) => {
  return [`/sites/${sitePk}/global-paths/upload/async/${task}/`] as const
}

export const getSitesGlobalPathsUploadAsyncRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsUploadAsyncRetrieveQueryKey(sitePk, task)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsUploadAsyncRetrieve(sitePk, task, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && task), ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsUploadAsyncRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>
>
export type SitesGlobalPathsUploadAsyncRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 파일 비동기 업로드 태스크 상태 조회
 */
export const useSitesGlobalPathsUploadAsyncRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsUploadAsyncRetrieveInfiniteQueryOptions(sitePk, task, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesGlobalPathsUploadAsyncRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGlobalPathsUploadAsyncRetrieveQueryKey(sitePk, task)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>> = ({ signal }) =>
    sitesGlobalPathsUploadAsyncRetrieve(sitePk, task, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && task), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesGlobalPathsUploadAsyncRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>
>
export type SitesGlobalPathsUploadAsyncRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사이트 글로벌 패스 파일 비동기 업로드 태스크 상태 조회
 */
export const useSitesGlobalPathsUploadAsyncRetrieve = <
  TData = Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  task: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesGlobalPathsUploadAsyncRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesGlobalPathsUploadAsyncRetrieveQueryOptions(sitePk, task, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesWebhooksList = (
  sitePk: number | null,
  params?: SitesWebhooksListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedWebhookResList>(
    { url: `/sites/${sitePk}/webhooks/`, method: 'GET', params, signal },
    options
  )
}

export const getSitesWebhooksListQueryKey = (sitePk: number | null, params?: SitesWebhooksListParams) => {
  return [`/sites/${sitePk}/webhooks/`, ...(params ? [params] : [])] as const
}

export const getSitesWebhooksListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesWebhooksList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesWebhooksListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesWebhooksList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesWebhooksListQueryKey(sitePk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesWebhooksList>>> = ({ signal }) =>
    sitesWebhooksList(sitePk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!sitePk, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesWebhooksList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesWebhooksListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksList>>>
export type SitesWebhooksListInfiniteQueryError = ErrorType<unknown>

export const useSitesWebhooksListInfinite = <
  TData = Awaited<ReturnType<typeof sitesWebhooksList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesWebhooksListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesWebhooksList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesWebhooksListInfiniteQueryOptions(sitePk, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesWebhooksListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesWebhooksList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesWebhooksListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesWebhooksList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesWebhooksListQueryKey(sitePk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesWebhooksList>>> = ({ signal }) =>
    sitesWebhooksList(sitePk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!sitePk, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesWebhooksList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesWebhooksListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksList>>>
export type SitesWebhooksListQueryError = ErrorType<unknown>

export const useSitesWebhooksList = <
  TData = Awaited<ReturnType<typeof sitesWebhooksList>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  params?: SitesWebhooksListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesWebhooksList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesWebhooksListQueryOptions(sitePk, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 웹훅 등록
 * @summary 사이트 웹훅 등록
 */
export const sitesWebhooksCreate = (
  sitePk: number | null,
  webhookCreateReqRequest: BodyType<WebhookCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WebhookRes>(
    {
      url: `/sites/${sitePk}/webhooks/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: webhookCreateReqRequest
    },
    options
  )
}

export const getSitesWebhooksCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesWebhooksCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<WebhookCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesWebhooksCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<WebhookCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesWebhooksCreate>>,
    { sitePk: number | null; data: BodyType<WebhookCreateReqRequest> }
  > = (props) => {
    const { sitePk, data } = props ?? {}

    return sitesWebhooksCreate(sitePk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesWebhooksCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksCreate>>>
export type SitesWebhooksCreateMutationBody = BodyType<WebhookCreateReqRequest>
export type SitesWebhooksCreateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 웹훅 등록
 */
export const useSitesWebhooksCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesWebhooksCreate>>,
    TError,
    { sitePk: number | null; data: BodyType<WebhookCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesWebhooksCreate>>,
  TError,
  { sitePk: number | null; data: BodyType<WebhookCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesWebhooksCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const sitesWebhooksRetrieve = (
  sitePk: number | null,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<WebhookRes>({ url: `/sites/${sitePk}/webhooks/${id}/`, method: 'GET', signal }, options)
}

export const getSitesWebhooksRetrieveQueryKey = (sitePk: number | null, id: number) => {
  return [`/sites/${sitePk}/webhooks/${id}/`] as const
}

export const getSitesWebhooksRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesWebhooksRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesWebhooksRetrieveQueryKey(sitePk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>> = ({ signal }) =>
    sitesWebhooksRetrieve(sitePk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && id), ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sitesWebhooksRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesWebhooksRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>>
export type SitesWebhooksRetrieveInfiniteQueryError = ErrorType<unknown>

export const useSitesWebhooksRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesWebhooksRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesWebhooksRetrieveInfiniteQueryOptions(sitePk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesWebhooksRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesWebhooksRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesWebhooksRetrieveQueryKey(sitePk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>> = ({ signal }) =>
    sitesWebhooksRetrieve(sitePk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(sitePk && id), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesWebhooksRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesWebhooksRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>>
export type SitesWebhooksRetrieveQueryError = ErrorType<unknown>

export const useSitesWebhooksRetrieve = <
  TData = Awaited<ReturnType<typeof sitesWebhooksRetrieve>>,
  TError = ErrorType<unknown>
>(
  sitePk: number | null,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesWebhooksRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesWebhooksRetrieveQueryOptions(sitePk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 웹훅 수정
 * @summary 사이트 웹훅 수정
 */
export const sitesWebhooksUpdate = (
  sitePk: number | null,
  id: number,
  webhookUpdateReqRequest: BodyType<WebhookUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WebhookRes>(
    {
      url: `/sites/${sitePk}/webhooks/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: webhookUpdateReqRequest
    },
    options
  )
}

export const getSitesWebhooksUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesWebhooksUpdate>>,
    TError,
    { sitePk: number | null; id: number; data: BodyType<WebhookUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesWebhooksUpdate>>,
  TError,
  { sitePk: number | null; id: number; data: BodyType<WebhookUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesWebhooksUpdate>>,
    { sitePk: number | null; id: number; data: BodyType<WebhookUpdateReqRequest> }
  > = (props) => {
    const { sitePk, id, data } = props ?? {}

    return sitesWebhooksUpdate(sitePk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesWebhooksUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksUpdate>>>
export type SitesWebhooksUpdateMutationBody = BodyType<WebhookUpdateReqRequest>
export type SitesWebhooksUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 웹훅 수정
 */
export const useSitesWebhooksUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesWebhooksUpdate>>,
    TError,
    { sitePk: number | null; id: number; data: BodyType<WebhookUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesWebhooksUpdate>>,
  TError,
  { sitePk: number | null; id: number; data: BodyType<WebhookUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getSitesWebhooksUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사이트 웹훅 삭제
 * @summary 사이트 웹훅 삭제
 */
export const sitesWebhooksDestroy = (
  sitePk: number | null,
  id: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>({ url: `/sites/${sitePk}/webhooks/${id}/`, method: 'DELETE' }, options)
}

export const getSitesWebhooksDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesWebhooksDestroy>>,
    TError,
    { sitePk: number | null; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesWebhooksDestroy>>,
  TError,
  { sitePk: number | null; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesWebhooksDestroy>>,
    { sitePk: number | null; id: number }
  > = (props) => {
    const { sitePk, id } = props ?? {}

    return sitesWebhooksDestroy(sitePk, id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesWebhooksDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof sitesWebhooksDestroy>>>

export type SitesWebhooksDestroyMutationError = ErrorType<unknown>

/**
 * @summary 사이트 웹훅 삭제
 */
export const useSitesWebhooksDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesWebhooksDestroy>>,
    TError,
    { sitePk: number | null; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesWebhooksDestroy>>,
  TError,
  { sitePk: number | null; id: number },
  TContext
> => {
  const mutationOptions = getSitesWebhooksDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}

export const soundsList = (
  params?: SoundsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSoundResList>({ url: `/sounds/`, method: 'GET', params, signal }, options)
}

export const getSoundsListQueryKey = (params?: SoundsListParams) => {
  return [`/sounds/`, ...(params ? [params] : [])] as const
}

export const getSoundsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof soundsList>>,
  TError = ErrorType<unknown>
>(
  params?: SoundsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof soundsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSoundsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof soundsList>>> = ({ signal }) =>
    soundsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof soundsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SoundsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof soundsList>>>
export type SoundsListInfiniteQueryError = ErrorType<unknown>

export const useSoundsListInfinite = <TData = Awaited<ReturnType<typeof soundsList>>, TError = ErrorType<unknown>>(
  params?: SoundsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof soundsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSoundsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSoundsListQueryOptions = <TData = Awaited<ReturnType<typeof soundsList>>, TError = ErrorType<unknown>>(
  params?: SoundsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof soundsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSoundsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof soundsList>>> = ({ signal }) =>
    soundsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof soundsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SoundsListQueryResult = NonNullable<Awaited<ReturnType<typeof soundsList>>>
export type SoundsListQueryError = ErrorType<unknown>

export const useSoundsList = <TData = Awaited<ReturnType<typeof soundsList>>, TError = ErrorType<unknown>>(
  params?: SoundsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof soundsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSoundsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 음원 생성
 * @summary 음원 생성
 */
export const soundsCreate = (
  soundCreateReqRequest: BodyType<SoundCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(soundCreateReqRequest)
  return customInstance<SoundRes>(
    { url: `/sounds/`, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getSoundsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsCreate>>,
    TError,
    { data: BodyType<SoundCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof soundsCreate>>,
  TError,
  { data: BodyType<SoundCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof soundsCreate>>,
    { data: BodyType<SoundCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return soundsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SoundsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof soundsCreate>>>
export type SoundsCreateMutationBody = BodyType<SoundCreateReqRequest>
export type SoundsCreateMutationError = ErrorType<unknown>

/**
 * @summary 음원 생성
 */
export const useSoundsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsCreate>>,
    TError,
    { data: BodyType<SoundCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof soundsCreate>>,
  TError,
  { data: BodyType<SoundCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getSoundsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const soundsRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<SoundRes>({ url: `/sounds/${id}/`, method: 'GET', signal }, options)
}

export const getSoundsRetrieveQueryKey = (id: number) => {
  return [`/sounds/${id}/`] as const
}

export const getSoundsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof soundsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof soundsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSoundsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof soundsRetrieve>>> = ({ signal }) =>
    soundsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof soundsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SoundsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof soundsRetrieve>>>
export type SoundsRetrieveInfiniteQueryError = ErrorType<unknown>

export const useSoundsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof soundsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof soundsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSoundsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSoundsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof soundsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof soundsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSoundsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof soundsRetrieve>>> = ({ signal }) =>
    soundsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof soundsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SoundsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof soundsRetrieve>>>
export type SoundsRetrieveQueryError = ErrorType<unknown>

export const useSoundsRetrieve = <TData = Awaited<ReturnType<typeof soundsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof soundsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSoundsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 음원 수정
 * @summary 음원 생성
 */
export const soundsUpdate = (
  id: number,
  soundCreateReqRequest: BodyType<SoundCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(soundCreateReqRequest)
  return customInstance<SoundRes>(
    { url: `/sounds/${id}/`, method: 'PUT', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getSoundsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsUpdate>>,
    TError,
    { id: number; data: BodyType<SoundCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof soundsUpdate>>,
  TError,
  { id: number; data: BodyType<SoundCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof soundsUpdate>>,
    { id: number; data: BodyType<SoundCreateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return soundsUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SoundsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof soundsUpdate>>>
export type SoundsUpdateMutationBody = BodyType<SoundCreateReqRequest>
export type SoundsUpdateMutationError = ErrorType<unknown>

/**
 * @summary 음원 생성
 */
export const useSoundsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsUpdate>>,
    TError,
    { id: number; data: BodyType<SoundCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof soundsUpdate>>,
  TError,
  { id: number; data: BodyType<SoundCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getSoundsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 음원 부분 수정
 * @summary 음원 생성
 */
export const soundsPartialUpdate = (
  id: number,
  patchedSoundPartialUpdateReqRequest: BodyType<PatchedSoundPartialUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(patchedSoundPartialUpdateReqRequest)
  return customInstance<SoundRes>(
    { url: `/sounds/${id}/`, method: 'PATCH', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getSoundsPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedSoundPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof soundsPartialUpdate>>,
  TError,
  { id: number; data: BodyType<PatchedSoundPartialUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof soundsPartialUpdate>>,
    { id: number; data: BodyType<PatchedSoundPartialUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return soundsPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SoundsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof soundsPartialUpdate>>>
export type SoundsPartialUpdateMutationBody = BodyType<PatchedSoundPartialUpdateReqRequest>
export type SoundsPartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 음원 생성
 */
export const useSoundsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedSoundPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof soundsPartialUpdate>>,
  TError,
  { id: number; data: BodyType<PatchedSoundPartialUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getSoundsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 원격조종 모드에서 보여줄 음원 목록
 * @summary 음원 목록
 */
export const soundsOperatorList = (
  params: SoundsOperatorListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SoundRes[]>({ url: `/sounds/operator/`, method: 'GET', params, signal }, options)
}

export const getSoundsOperatorListQueryKey = (params: SoundsOperatorListParams) => {
  return [`/sounds/operator/`, ...(params ? [params] : [])] as const
}

export const getSoundsOperatorListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof soundsOperatorList>>,
  TError = ErrorType<unknown>
>(
  params: SoundsOperatorListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof soundsOperatorList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSoundsOperatorListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof soundsOperatorList>>> = ({ signal }) =>
    soundsOperatorList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof soundsOperatorList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SoundsOperatorListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof soundsOperatorList>>>
export type SoundsOperatorListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 음원 목록
 */
export const useSoundsOperatorListInfinite = <
  TData = Awaited<ReturnType<typeof soundsOperatorList>>,
  TError = ErrorType<unknown>
>(
  params: SoundsOperatorListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof soundsOperatorList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSoundsOperatorListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSoundsOperatorListQueryOptions = <
  TData = Awaited<ReturnType<typeof soundsOperatorList>>,
  TError = ErrorType<unknown>
>(
  params: SoundsOperatorListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof soundsOperatorList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSoundsOperatorListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof soundsOperatorList>>> = ({ signal }) =>
    soundsOperatorList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof soundsOperatorList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SoundsOperatorListQueryResult = NonNullable<Awaited<ReturnType<typeof soundsOperatorList>>>
export type SoundsOperatorListQueryError = ErrorType<unknown>

/**
 * @summary 음원 목록
 */
export const useSoundsOperatorList = <
  TData = Awaited<ReturnType<typeof soundsOperatorList>>,
  TError = ErrorType<unknown>
>(
  params: SoundsOperatorListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof soundsOperatorList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSoundsOperatorListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 음원 재생
 * @summary 음원 재생
 */
export const soundsPlayCreate = (
  soundPlayReqRequest: BodyType<SoundPlayReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/sounds/play/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: soundPlayReqRequest
    },
    options
  )
}

export const getSoundsPlayCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsPlayCreate>>,
    TError,
    { data: BodyType<SoundPlayReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof soundsPlayCreate>>,
  TError,
  { data: BodyType<SoundPlayReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof soundsPlayCreate>>,
    { data: BodyType<SoundPlayReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return soundsPlayCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SoundsPlayCreateMutationResult = NonNullable<Awaited<ReturnType<typeof soundsPlayCreate>>>
export type SoundsPlayCreateMutationBody = BodyType<SoundPlayReqRequest>
export type SoundsPlayCreateMutationError = ErrorType<unknown>

/**
 * @summary 음원 재생
 */
export const useSoundsPlayCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsPlayCreate>>,
    TError,
    { data: BodyType<SoundPlayReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof soundsPlayCreate>>,
  TError,
  { data: BodyType<SoundPlayReqRequest> },
  TContext
> => {
  const mutationOptions = getSoundsPlayCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 음원 재생 중지
 * @summary 음원 재생 중지
 */
export const soundsStopCreate = (
  soundStopReqRequest: BodyType<SoundStopReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/sounds/stop/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: soundStopReqRequest
    },
    options
  )
}

export const getSoundsStopCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsStopCreate>>,
    TError,
    { data: BodyType<SoundStopReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof soundsStopCreate>>,
  TError,
  { data: BodyType<SoundStopReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof soundsStopCreate>>,
    { data: BodyType<SoundStopReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return soundsStopCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SoundsStopCreateMutationResult = NonNullable<Awaited<ReturnType<typeof soundsStopCreate>>>
export type SoundsStopCreateMutationBody = BodyType<SoundStopReqRequest>
export type SoundsStopCreateMutationError = ErrorType<unknown>

/**
 * @summary 음원 재생 중지
 */
export const useSoundsStopCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsStopCreate>>,
    TError,
    { data: BodyType<SoundStopReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof soundsStopCreate>>,
  TError,
  { data: BodyType<SoundStopReqRequest> },
  TContext
> => {
  const mutationOptions = getSoundsStopCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * TTS 전송
 * @summary TTS 전송
 */
export const soundsTtsCreate = (
  soundTextToSpeechReqRequest: BodyType<SoundTextToSpeechReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/sounds/tts/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: soundTextToSpeechReqRequest
    },
    options
  )
}

export const getSoundsTtsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsTtsCreate>>,
    TError,
    { data: BodyType<SoundTextToSpeechReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof soundsTtsCreate>>,
  TError,
  { data: BodyType<SoundTextToSpeechReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof soundsTtsCreate>>,
    { data: BodyType<SoundTextToSpeechReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return soundsTtsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type SoundsTtsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof soundsTtsCreate>>>
export type SoundsTtsCreateMutationBody = BodyType<SoundTextToSpeechReqRequest>
export type SoundsTtsCreateMutationError = ErrorType<unknown>

/**
 * @summary TTS 전송
 */
export const useSoundsTtsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof soundsTtsCreate>>,
    TError,
    { data: BodyType<SoundTextToSpeechReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof soundsTtsCreate>>,
  TError,
  { data: BodyType<SoundTextToSpeechReqRequest> },
  TContext
> => {
  const mutationOptions = getSoundsTtsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로그인 토큰 갱신
 * @summary 로그인 토큰 갱신
 */
export const tokenRefreshCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<TokenRefreshRes>({ url: `/token/refresh/`, method: 'POST' }, options)
}

export const getTokenRefreshCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof tokenRefreshCreate>>, void> = () => {
    return tokenRefreshCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type TokenRefreshCreateMutationResult = NonNullable<Awaited<ReturnType<typeof tokenRefreshCreate>>>

export type TokenRefreshCreateMutationError = ErrorType<unknown>

/**
 * @summary 로그인 토큰 갱신
 */
export const useTokenRefreshCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, void, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, void, TContext> => {
  const mutationOptions = getTokenRefreshCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

export const usersList = (
  params?: UsersListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedUserResList>({ url: `/users/`, method: 'GET', params, signal }, options)
}

export const getUsersListQueryKey = (params?: UsersListParams) => {
  return [`/users/`, ...(params ? [params] : [])] as const
}

export const getUsersListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof usersList>>,
  TError = ErrorType<unknown>
>(
  params?: UsersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersList>>> = ({ signal }) =>
    usersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof usersList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersList>>>
export type UsersListInfiniteQueryError = ErrorType<unknown>

export const useUsersListInfinite = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(
  params?: UsersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersListQueryOptions = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(
  params?: UsersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersList>>> = ({ signal }) =>
    usersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersListQueryResult = NonNullable<Awaited<ReturnType<typeof usersList>>>
export type UsersListQueryError = ErrorType<unknown>

export const useUsersList = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(
  params?: UsersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 

권한: 통합 관리자
 * @summary 사용자 정보 상세 조회
 */
export const usersRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserRetrieveRes>({ url: `/users/${id}/`, method: 'GET', signal }, options)
}

export const getUsersRetrieveQueryKey = (id: number) => {
  return [`/users/${id}/`] as const
}

export const getUsersRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof usersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersRetrieve>>> = ({ signal }) =>
    usersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof usersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersRetrieve>>>
export type UsersRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사용자 정보 상세 조회
 */
export const useUsersRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersRetrieve>>> = ({ signal }) =>
    usersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersRetrieve>>>
export type UsersRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사용자 정보 상세 조회
 */
export const useUsersRetrieve = <TData = Awaited<ReturnType<typeof usersRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 

권한: 통합 관리자
 * @summary 사용자 정보 수정
 */
export const usersUpdate = (
  id: number,
  userUpdateReqRequest: BodyType<UserUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserRes>(
    {
      url: `/users/${id}/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userUpdateReqRequest
    },
    options
  )
}

export const getUsersUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdate>>,
    TError,
    { id: number; data: BodyType<UserUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersUpdate>>,
  TError,
  { id: number; data: BodyType<UserUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersUpdate>>,
    { id: number; data: BodyType<UserUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return usersUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersUpdate>>>
export type UsersUpdateMutationBody = BodyType<UserUpdateReqRequest>
export type UsersUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사용자 정보 수정
 */
export const useUsersUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdate>>,
    TError,
    { id: number; data: BodyType<UserUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof usersUpdate>>,
  TError,
  { id: number; data: BodyType<UserUpdateReqRequest> },
  TContext
> => {
  const mutationOptions = getUsersUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 

권한: 누구나
 * @summary PIN 확인
 */
export const usersVerifyPinCreate = (
  id: number,
  userVerifyPinReqRequest: BodyType<UserVerifyPinReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/users/${id}/verify-pin/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userVerifyPinReqRequest
    },
    options
  )
}

export const getUsersVerifyPinCreateMutationOptions = <
  TError = ErrorType<UserVerifyPin400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersVerifyPinCreate>>,
    TError,
    { id: number; data: BodyType<UserVerifyPinReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersVerifyPinCreate>>,
  TError,
  { id: number; data: BodyType<UserVerifyPinReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersVerifyPinCreate>>,
    { id: number; data: BodyType<UserVerifyPinReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return usersVerifyPinCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersVerifyPinCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersVerifyPinCreate>>>
export type UsersVerifyPinCreateMutationBody = BodyType<UserVerifyPinReqRequest>
export type UsersVerifyPinCreateMutationError = ErrorType<UserVerifyPin400Exception>

/**
 * @summary PIN 확인
 */
export const useUsersVerifyPinCreate = <TError = ErrorType<UserVerifyPin400Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersVerifyPinCreate>>,
    TError,
    { id: number; data: BodyType<UserVerifyPinReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof usersVerifyPinCreate>>,
  TError,
  { id: number; data: BodyType<UserVerifyPinReqRequest> },
  TContext
> => {
  const mutationOptions = getUsersVerifyPinCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 

권한: 통합 관리자
 * @summary 관리자 생성
 */
export const usersCreateAdminCreate = (
  userCreateReqRequest: BodyType<UserCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserRes>(
    {
      url: `/users/create-admin/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userCreateReqRequest
    },
    options
  )
}

export const getUsersCreateAdminCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersCreateAdminCreate>>,
    TError,
    { data: BodyType<UserCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersCreateAdminCreate>>,
  TError,
  { data: BodyType<UserCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersCreateAdminCreate>>,
    { data: BodyType<UserCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return usersCreateAdminCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersCreateAdminCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersCreateAdminCreate>>>
export type UsersCreateAdminCreateMutationBody = BodyType<UserCreateReqRequest>
export type UsersCreateAdminCreateMutationError = ErrorType<unknown>

/**
 * @summary 관리자 생성
 */
export const useUsersCreateAdminCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersCreateAdminCreate>>,
    TError,
    { data: BodyType<UserCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof usersCreateAdminCreate>>,
  TError,
  { data: BodyType<UserCreateReqRequest> },
  TContext
> => {
  const mutationOptions = getUsersCreateAdminCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 

권한: 로그인한 사용자
 * @summary 사용자 정보 조회
 */
export const usersInfoRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserRes>({ url: `/users/info/`, method: 'GET', signal }, options)
}

export const getUsersInfoRetrieveQueryKey = () => {
  return [`/users/info/`] as const
}

export const getUsersInfoRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersInfoRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersInfoRetrieve>>> = ({ signal }) =>
    usersInfoRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof usersInfoRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersInfoRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersInfoRetrieve>>>
export type UsersInfoRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사용자 정보 조회
 */
export const useUsersInfoRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersInfoRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersInfoRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersInfoRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersInfoRetrieve>>> = ({ signal }) =>
    usersInfoRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersInfoRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersInfoRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersInfoRetrieve>>>
export type UsersInfoRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 사용자 정보 조회
 */
export const useUsersInfoRetrieve = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersInfoRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
