/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `DONE` - 완료
 * `ERROR` - 오류
 * `IN_PROGRESS` - 진행 중
 */
export type CalibrationStatusEnum = (typeof CalibrationStatusEnum)[keyof typeof CalibrationStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalibrationStatusEnum = {
  DONE: 'DONE',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS'
} as const
