export enum I18nNamespaceEnum {
  Common = 'common',
  Login = 'login',
  User = 'user',
  Menu = 'menu',
  MobilePath = 'mobile-path',
  Monitoring = 'monitoring',
  Remote = 'remote',
  RemoteMultiple = 'remote-multiple',
  Node = 'node',
  Notification = 'notification',
  Register = 'register',
  Robot = 'robot',
  Site = 'site',
  Sound = 'sound',
  Patrol = 'patrol'
}

/**
 * 현재 사용하는 Locale
 */

export enum LocaleEnum {
  KOREAN = 'ko',
  // 국가마다 영어가 살짝씩 다른데 그때에는 국가코드로 구별하는 것으로 이야기 하였습니다.
  ENGLISH = 'en'
}

export enum CurrencyEnum {
  USD = 'USD', // U.S. dollar
  EUR = 'EUR', // Euro
  JPY = 'JPY', // Japanese yen
  GBP = 'GBP', // Sterling
  CNY = 'CNY', // Renminbi
  AUD = 'AUD', // Australian dollar
  CAD = 'CAD', // Canadian dollar
  CHF = 'CHF', // Swiss franc
  HKD = 'HKD', // Hong Kong dollar
  SGD = 'SGD', // Singapore dollar
  SEK = 'SEK', // Swedish krona
  KRW = 'KRW', // South Korean won
  NOK = 'NOK', // Norwegian krone
  NZD = 'NZD', // New Zealand dollar
  INR = 'INR', // Indian rupee
  MXN = 'MXN', // Mexican peso
  TWD = 'TWD', // New Taiwan dollar
  ZAR = 'ZAR', // South African rand
  BRL = 'BRL', // Brazilian real
  DKK = 'DKK', // Danish krone
  PLN = 'PLN', // Polish złoty
  THB = 'THB', // Thai baht
  ILS = 'ILS', // Israeli new shekel
  IDR = 'IDR', // Indonesian rupiah
  CZK = 'CZK', // Czech koruna
  AED = 'AED', // UAE dirham
  TRY = 'TRY', // Turkish lira
  HUF = 'HUF', // Hungarian forint
  CLP = 'CLP', // Chilean peso
  SAR = 'SAR', // Saudi riyal
  PHP = 'PHP', // Philippine peso
  MYR = 'MYR', // Malaysian ringgit
  COP = 'COP', // Colombian peso
  RUB = 'RUB', // Russian ruble
  RON = 'RON', // Romanian leu
  PEN = 'PEN', // Peruvian sol
  BHD = 'BHD', // Bahraini dinar
  BGN = 'BGN', // Bulgarian lev
  ARS = 'ARS' // Argentine peso
}

export const LocaleCurrencyMap = new Map<LocaleEnum, CurrencyEnum>([
  [LocaleEnum.ENGLISH, CurrencyEnum.USD],
  [LocaleEnum.KOREAN, CurrencyEnum.KRW]
])

export const LocaleDateFormatMap = new Map<LocaleEnum, string>([
  [LocaleEnum.ENGLISH, LocaleEnum.ENGLISH],
  [LocaleEnum.KOREAN, LocaleEnum.KOREAN]
])

export const CurrencyStrMap = new Map<CurrencyEnum, string>([
  [CurrencyEnum.USD, 'US$'], // U.S. dollar
  [CurrencyEnum.EUR, '€'], // Euro
  [CurrencyEnum.JPY, '¥ / 円'], // Japanese yen
  [CurrencyEnum.GBP, '£'], // Sterling
  [CurrencyEnum.CNY, '¥ / 元'], // Renminbi
  [CurrencyEnum.AUD, 'A$'], // Australian dollar
  [CurrencyEnum.CAD, 'C$'], // Canadian dollar
  [CurrencyEnum.CHF, 'CHF'], // Swiss franc
  [CurrencyEnum.HKD, 'HK$'], // Hong Kong dollar
  [CurrencyEnum.SGD, 'S$'], // Singapore dollar
  [CurrencyEnum.SEK, 'kr'], // Swedish krona
  [CurrencyEnum.KRW, '원'], // South Korean won
  [CurrencyEnum.NOK, 'kr'], // Norwegian krone
  [CurrencyEnum.NZD, 'NZ$'], // New Zealand dollar
  [CurrencyEnum.INR, '₹'], // Indian rupee
  [CurrencyEnum.MXN, '$'], // Mexican peso
  [CurrencyEnum.TWD, 'NT$'], // New Taiwan dollar
  [CurrencyEnum.ZAR, 'R'], // South African rand
  [CurrencyEnum.BRL, 'R$'], // Brazilian real
  [CurrencyEnum.DKK, 'kr'], // Danish krone
  [CurrencyEnum.PLN, 'zł'], // Polish złoty
  [CurrencyEnum.THB, '฿'], // Thai baht
  [CurrencyEnum.ILS, '₪'], // Israeli new shekel
  [CurrencyEnum.IDR, 'Rp'], // Indonesian rupiah
  [CurrencyEnum.CZK, 'Kč'], // Czech koruna
  [CurrencyEnum.AED, 'د.إ'], // UAE dirham
  [CurrencyEnum.TRY, '₺'], // Turkish lira
  [CurrencyEnum.HUF, 'Ft'], // Hungarian forint
  [CurrencyEnum.CLP, 'CLP$'], // Chilean peso
  [CurrencyEnum.SAR, 'SR' /*'﷼'*/], // Saudi riyal
  [CurrencyEnum.PHP, '₱'], // Philippine peso
  [CurrencyEnum.MYR, 'RM'], // Malaysian ringgit
  [CurrencyEnum.COP, 'COL$'], // Colombian peso
  [CurrencyEnum.RUB, '₽'], // Russian ruble
  [CurrencyEnum.RON, 'L'], // Romanian leu
  [CurrencyEnum.PEN, 'S/'], // Peruvian sol
  [CurrencyEnum.BHD, '.د.ب'], // Bahraini dinar
  [CurrencyEnum.BGN, 'BGN'], // Bulgarian lev
  [CurrencyEnum.ARS, 'ARG$'] // Argentine peso
])

/**
 * 전체 Locale
 */
// export enum LocaleEnum {
//   Afrikaans='af',
//   Afrikaans_Namibia='af-NA',
//   Afrikaans_SouthAfrica='af-ZA',
//   Aghem='agq',
//   Aghem_Cameroon='agq-CM',
//   Akan='ak',
//   Akan_Ghana='ak-GH',
//   Amharic='am',
//   Amharic_Ethiopia='am-ET',
//   Arabic='ar',
//   Arabic_World='ar-001',
//   Arabic_UnitedArabEmirates='ar-AE',
//   Arabic_Bahrain='ar-BH',
//   Arabic_Djibouti='ar-DJ',
//   Arabic_Algeria='ar-DZ',
//   Arabic_Egypt='ar-EG',
//   Arabic_WesternSahara='ar-EH',
//   Arabic_Eritrea='ar-ER',
//   Arabic_Israel='ar-IL',
//   Arabic_Iraq='ar-IQ',
//   Arabic_Jordan='ar-JO',
//   Arabic_Comoros='ar-KM',
//   Arabic_Kuwait='ar-KW',
//   Arabic_Lebanon='ar-LB',
//   Arabic_Libya='ar-LY',
//   Arabic_Morocco='ar-MA',
//   Arabic_Mauritania='ar-MR',
//   Arabic_Oman='ar-OM',
//   Arabic_PalestinianTerritories='ar-PS',
//   Arabic_Qatar='ar-QA',
//   Arabic_SaudiArabia='ar-SA',
//   Arabic_Sudan='ar-SD',
//   Arabic_Somalia='ar-SO',
//   Arabic_SouthSudan='ar-SS',
//   Arabic_Syria='ar-SY',
//   Arabic_Chad='ar-TD',
//   Arabic_Tunisia='ar-TN',
//   Arabic_Yemen='ar-YE',
//   Assamese='as',
//   Assamese_India='as-IN',
//   Asu='asa',
//   Asu_Tanzania='asa-TZ',
//   Azerbaijani='az',
//   Azerbaijani_Cyrillic='az-Cyrl',
//   Azerbaijani_CyrillicAzerbaijan='az-Cyrl-AZ',
//   Azerbaijani_Latin='az-Latn',
//   Azerbaijani_LatinAzerbaijan='az-Latn-AZ',
//   Basaa='bas',
//   Basaa_Cameroon='bas-CM',
//   Belarusian='be',
//   Belarusian_Belarus='be-BY',
//   Bemba='bem',
//   Bemba_Zambia='bem-ZM',
//   Bena='bez',
//   Bena_Tanzania='bez-TZ',
//   Bulgarian='bg',
//   Bulgarian_Bulgaria='bg-BG',
//   Bambara='bm',
//   Bambara_Latin='bm-Latn',
//   Bambara_LatinMali='bm-Latn-ML',
//   Bengali='bn',
//   Bengali_Bangladesh='bn-BD',
//   Bengali_India='bn-IN',
//   Tibetan='bo',
//   Tibetan_China='bo-CN',
//   Tibetan_India='bo-IN',
//   Breton='br',
//   Breton_France='br-FR',
//   Bodo='brx',
//   Bodo_India='brx-IN',
//   Bosnian='bs',
//   Bosnian_Cyrillic='bs-Cyrl',
//   Bosnian_CyrillicBosniaHerzegovina='bs-Cyrl-BA',
//   Bosnian_Latin='bs-Latn',
//   Bosnian_LatinBosniaHerzegovina='bs-Latn-BA',
//   Catalan='ca',
//   Catalan_Andorra='ca-AD',
//   Catalan_Spain='ca-ES',
//   Catalan_France='ca-FR',
//   Catalan_Italy='ca-IT',
//   Chiga='cgg',
//   Chiga_Uganda='cgg-UG',
//   Cherokee='chr',
//   Cherokee_UnitedStates='chr-US',
//   Central_Kurdish='ckb',
//   Central_KurdishIraq='ckb-IQ',
//   Central_KurdishIran='ckb-IR',
//   Czech='cs',
//   Czech_CzechRepublic='cs-CZ',
//   Welsh='cy',
//   Welsh_UnitedKingdom='cy-GB',
//   Danish='da',
//   Danish_Denmark='da-DK',
//   Danish_Greenland='da-GL',
//   Taita='dav',
//   Taita_Kenya='dav-KE',
//   German='de',
//   German_Austria='de-AT',
//   German_Belgium='de-BE',
//   German_Switzerland='de-CH',
//   German_Germany='de-DE',
//   German_Liechtenstein='de-LI',
//   German_Luxembourg='de-LU',
//   Zarma='dje',
//   Zarma_Niger='dje-NE',
//   Lower_Sorbian='dsb',
//   Lower_SorbianGermany='dsb-DE',
//   Duala='dua',
//   Duala_Cameroon='dua-CM',
//   Jola_Fonyi='dyo',
//   Jola_FonyiSenegal='dyo-SN',
//   Dzongkha='dz',
//   Dzongkha_Bhutan='dz-BT',
//   Embu='ebu',
//   Embu_Kenya='ebu-KE',
//   Ewe='ee',
//   Ewe_Ghana='ee-GH',
//   Ewe_Togo='ee-TG',
//   Greek='el',
//   Greek_Cyprus='el-CY',
//   Greek_Greece='el-GR',
//   English='en',
//   English_World='en-001',
//   English_Europe='en-150',
//   English_Andorra='en-AD',
//   English_AntiguaBarbuda='en-AG',
//   English_Anguilla='en-AI',
//   English_Albania='en-AL',
//   English_AmericanSamoa='en-AS',
//   English_Austria='en-AT',
//   English_Australia='en-AU',
//   English_BosniaHerzegovina='en-BA',
//   English_Barbados='en-BB',
//   English_Belgium='en-BE',
//   English_Bermuda='en-BM',
//   English_Bahamas='en-BS',
//   English_Botswana='en-BW',
//   English_Belize='en-BZ',
//   English_Canada='en-CA',
//   English_CocosKeelingIslands='en-CC',
//   English_Switzerland='en-CH',
//   English_CookIslands='en-CK',
//   English_Cameroon='en-CM',
//   English_ChristmasIsland='en-CX',
//   English_Cyprus='en-CY',
//   English_CzechRepublic='en-CZ',
//   English_Germany='en-DE',
//   English_DiegoGarcia='en-DG',
//   English_Denmark='en-DK',
//   English_Dominica='en-DM',
//   English_Estonia='en-EE',
//   English_Eritrea='en-ER',
//   English_Spain='en-ES',
//   English_Finland='en-FI',
//   English_Fiji='en-FJ',
//   English_FalklandIslands='en-FK',
//   English_Micronesia='en-FM',
//   English_France='en-FR',
//   English_UnitedKingdom='en-GB',
//   English_Grenada='en-GD',
//   English_Guernsey='en-GG',
//   English_Ghana='en-GH',
//   English_Gibraltar='en-GI',
//   English_Gambia='en-GM',
//   English_Greece='en-GR',
//   English_Guam='en-GU',
//   English_Guyana='en-GY',
//   English_HongKongSARChina='en-HK',
//   English_Croatia='en-HR',
//   English_Hungary='en-HU',
//   English_Ireland='en-IE',
//   English_Israel='en-IL',
//   English_IsleofMan='en-IM',
//   English_India='en-IN',
//   English_BritishIndianOceanTerritory='en-IO',
//   English_Iceland='en-IS',
//   English_Italy='en-IT',
//   English_Jersey='en-JE',
//   English_Jamaica='en-JM',
//   English_Kenya='en-KE',
//   English_Kiribati='en-KI',
//   English_StKittsNevis='en-KN',
//   English_CaymanIslands='en-KY',
//   English_StLucia='en-LC',
//   English_Liberia='en-LR',
//   English_Lesotho='en-LS',
//   English_Lithuania='en-LT',
//   English_Luxembourg='en-LU',
//   English_Latvia='en-LV',
//   English_Montenegro='en-ME',
//   English_Madagascar='en-MG',
//   English_MarshallIslands='en-MH',
//   English_MacauSARChina='en-MO',
//   English_NorthernMarianaIslands='en-MP',
//   English_Montserrat='en-MS',
//   English_Malta='en-MT',
//   English_Mauritius='en-MU',
//   English_Malawi='en-MW',
//   English_Malaysia='en-MY',
//   English_Namibia='en-NA',
//   English_NorfolkIsland='en-NF',
//   English_Nigeria='en-NG',
//   English_Netherlands='en-NL',
//   English_Norway='en-NO',
//   English_Nauru='en-NR',
//   English_Niue='en-NU',
//   English_NewZealand='en-NZ',
//   English_PapuaNewGuinea='en-PG',
//   English_Philippines='en-PH',
//   English_Pakistan='en-PK',
//   English_Poland='en-PL',
//   English_PitcairnIslands='en-PN',
//   English_PuertoRico='en-PR',
//   English_Portugal='en-PT',
//   English_Palau='en-PW',
//   English_Romania='en-RO',
//   English_Russia='en-RU',
//   English_Rwanda='en-RW',
//   English_SolomonIslands='en-SB',
//   English_Seychelles='en-SC',
//   English_Sudan='en-SD',
//   English_Sweden='en-SE',
//   English_Singapore='en-SG',
//   English_StHelena='en-SH',
//   English_Slovenia='en-SI',
//   English_Slovakia='en-SK',
//   English_SierraLeone='en-SL',
//   English_SouthSudan='en-SS',
//   English_SintMaarten='en-SX',
//   English_Swaziland='en-SZ',
//   English_TurksCaicosIslands='en-TC',
//   English_Tokelau='en-TK',
//   English_Tonga='en-TO',
//   English_Turkey='en-TR',
//   English_TrinidadTobago='en-TT',
//   English_Tuvalu='en-TV',
//   English_Tanzania='en-TZ',
//   English_Uganda='en-UG',
//   English_USOutlyingIslands='en-UM',
//   English_UnitedStates='en-US',
//   English_UnitedStatesComputer='en-US-POSIX',
//   English_StVincentGrenadines='en-VC',
//   English_BritishVirginIslands='en-VG',
//   English_USVirginIslands='en-VI',
//   English_Vanuatu='en-VU',
//   English_Samoa='en-WS',
//   English_SouthAfrica='en-ZA',
//   English_Zambia='en-ZM',
//   English_Zimbabwe='en-ZW',
//   Esperanto='eo',
//   Spanish='es',
//   Spanish_LatinAmerica='es-419',
//   Spanish_Argentina='es-AR',
//   Spanish_Bolivia='es-BO',
//   Spanish_Chile='es-CL',
//   Spanish_Colombia='es-CO',
//   Spanish_CostaRica='es-CR',
//   Spanish_Cuba='es-CU',
//   Spanish_DominicanRepublic='es-DO',
//   Spanish_CeutaMelilla='es-EA',
//   Spanish_Ecuador='es-EC',
//   Spanish_Spain='es-ES',
//   Spanish_EquatorialGuinea='es-GQ',
//   Spanish_Guatemala='es-GT',
//   Spanish_Honduras='es-HN',
//   Spanish_CanaryIslands='es-IC',
//   Spanish_Mexico='es-MX',
//   Spanish_Nicaragua='es-NI',
//   Spanish_Panama='es-PA',
//   Spanish_Peru='es-PE',
//   Spanish_Philippines='es-PH',
//   Spanish_PuertoRico='es-PR',
//   Spanish_Paraguay='es-PY',
//   Spanish_ElSalvador='es-SV',
//   Spanish_UnitedStates='es-US',
//   Spanish_Uruguay='es-UY',
//   Spanish_Venezuela='es-VE',
//   Estonian='et',
//   Estonian_Estonia='et-EE',
//   Basque='eu',
//   Basque_Spain='eu-ES',
//   Ewondo='ewo',
//   Ewondo_Cameroon='ewo-CM',
//   Persian='fa',
//   Persian_Afghanistan='fa-AF',
//   Persian_Iran='fa-IR',
//   Fulah='ff',
//   Fulah_Cameroon='ff-CM',
//   Fulah_Guinea='ff-GN',
//   Fulah_Mauritania='ff-MR',
//   Fulah_Senegal='ff-SN',
//   Finnish='fi',
//   Finnish_Finland='fi-FI',
//   Filipino='fil',
//   Filipino_Philippines='fil-PH',
//   Faroese='fo',
//   Faroese_FaroeIslands='fo-FO',
//   French='fr',
//   French_Belgium='fr-BE',
//   French_BurkinaFaso='fr-BF',
//   French_Burundi='fr-BI',
//   French_Benin='fr-BJ',
//   French_StBarthélemy='fr-BL',
//   French_Canada='fr-CA',
//   French_CongoKinshasa='fr-CD',
//   French_CentralAfricanRepublic='fr-CF',
//   French_CongoBrazzaville='fr-CG',
//   French_Switzerland='fr-CH',
//   French_CotedIvoire='fr-CI',
//   French_Cameroon='fr-CM',
//   French_Djibouti='fr-DJ',
//   French_Algeria='fr-DZ',
//   French_France='fr-FR',
//   French_Gabon='fr-GA',
//   French_FrenchGuiana='fr-GF',
//   French_Guinea='fr-GN',
//   French_Guadeloupe='fr-GP',
//   French_EquatorialGuinea='fr-GQ',
//   French_Haiti='fr-HT',
//   French_Comoros='fr-KM',
//   French_Luxembourg='fr-LU',
//   French_Morocco='fr-MA',
//   French_Monaco='fr-MC',
//   French_StMartin='fr-MF',
//   French_Madagascar='fr-MG',
//   French_Mali='fr-ML',
//   French_Martinique='fr-MQ',
//   French_Mauritania='fr-MR',
//   French_Mauritius='fr-MU',
//   French_NewCaledonia='fr-NC',
//   French_Niger='fr-NE',
//   French_FrenchPolynesia='fr-PF',
//   French_StPierreMiquelon='fr-PM',
//   French_Réunion='fr-RE',
//   French_Rwanda='fr-RW',
//   French_Seychelles='fr-SC',
//   French_Senegal='fr-SN',
//   French_Syria='fr-SY',
//   French_Chad='fr-TD',
//   French_Togo='fr-TG',
//   French_Tunisia='fr-TN',
//   French_Vanuatu='fr-VU',
//   French_WallisFutuna='fr-WF',
//   French_Mayotte='fr-YT',
//   Friulian='fur',
//   Friulian_Italy='fur-IT',
//   Western_Frisian='fy',
//   Western_FrisianNetherlands='fy-NL',
//   Irish='ga',
//   Irish_Ireland='ga-IE',
//   Scottish_Gaelic='gd',
//   Scottish_GaelicUnitedKingdom='gd-GB',
//   Galician='gl',
//   Galician_Spain='gl-ES',
//   Swiss_German='gsw',
//   Swiss_GermanSwitzerland='gsw-CH',
//   Swiss_GermanFrance='gsw-FR',
//   Swiss_GermanLiechtenstein='gsw-LI',
//   Gujarati='gu',
//   Gujarati_India='gu-IN',
//   Gusii='guz',
//   Gusii_Kenya='guz-KE',
//   Manx='gv',
//   Manx_IsleofMan='gv-IM',
//   Hausa='ha',
//   Hausa_Latin='ha-Latn',
//   Hausa_LatinGhana='ha-Latn-GH',
//   Hausa_LatinNiger='ha-Latn-NE',
//   Hausa_LatinNigeria='ha-Latn-NG',
//   Hawaiian='haw',
//   Hawaiian_UnitedStates='haw-US',
//   Hebrew='he',
//   Hebrew_Israel='he-IL',
//   Hindi='hi',
//   Hindi_India='hi-IN',
//   Croatian='hr',
//   Croatian_BosniaHerzegovina='hr-BA',
//   Croatian_Croatia='hr-HR',
//   Upper_Sorbian='hsb',
//   Upper_SorbianGermany='hsb-DE',
//   Hungarian='hu',
//   Hungarian_Hungary='hu-HU',
//   Armenian='hy',
//   Armenian_Armenia='hy-AM',
//   Indonesian='id',
//   Indonesian_Indonesia='id-ID',
//   Igbo='ig',
//   Igbo_Nigeria='ig-NG',
//   Sichuan_Yi='ii',
//   Sichuan_YiChina='ii-CN',
//   Icelandic='is',
//   Icelandic_Iceland='is-IS',
//   Italian='it',
//   Italian_Switzerland='it-CH',
//   Italian_Italy='it-IT',
//   Italian_SanMarino='it-SM',
//   Inuktitut='iu',
//   Inuktitut_UnifiedCanadianAboriginalSyllabics='iu-Cans',
//   Inuktitut_UnifiedCanadianAboriginalSyllabicsCanada='iu-Cans-CA',
//   Japanese='ja',
//   Japanese_Japan='ja-JP',
//   Ngomba='jgo',
//   Ngomba_Cameroon='jgo-CM',
//   Machame='jmc',
//   Machame_Tanzania='jmc-TZ',
//   Georgian='ka',
//   Georgian_Georgia='ka-GE',
//   Kabyle='kab',
//   Kabyle_Algeria='kab-DZ',
//   Kamba='kam',
//   Kamba_Kenya='kam-KE',
//   Makonde='kde',
//   Makonde_Tanzania='kde-TZ',
//   Kabuverdianu='kea',
//   Kabuverdianu_CapeVerde='kea-CV',
//   Koyra_Chiini='khq',
//   Koyra_ChiiniMali='khq-ML',
//   Kikuyu='ki',
//   Kikuyu_Kenya='ki-KE',
//   Kazakh='kk',
//   Kazakh_Cyrillic='kk-Cyrl',
//   Kazakh_CyrillicKazakhstan='kk-Cyrl-KZ',
//   Kako='kkj',
//   Kako_Cameroon='kkj-CM',
//   Kalaallisut='kl',
//   Kalaallisut_Greenland='kl-GL',
//   Kalenjin='kln',
//   Kalenjin_Kenya='kln-KE',
//   Khmer='km',
//   Khmer_Cambodia='km-KH',
//   Kannada='kn',
//   Kannada_India='kn-IN',
//   Korean='ko',
//   Korean_NorthKorea='ko-KP',
//   Korean_SouthKorea='ko-KR',
//   Konkani='kok',
//   Konkani_India='kok-IN',
//   Kashmiri='ks',
//   Kashmiri_Arabic='ks-Arab',
//   Kashmiri_ArabicIndia='ks-Arab-IN',
//   Shambala='ksb',
//   Shambala_Tanzania='ksb-TZ',
//   Bafia='ksf',
//   Bafia_Cameroon='ksf-CM',
//   Colognian='ksh',
//   Colognian_Germany='ksh-DE',
//   Cornish='kw',
//   Cornish_UnitedKingdom='kw-GB',
//   Kyrgyz='ky',
//   Kyrgyz_Cyrillic='ky-Cyrl',
//   Kyrgyz_CyrillicKyrgyzstan='ky-Cyrl-KG',
//   Langi='lag',
//   Langi_Tanzania='lag-TZ',
//   Luxembourgish='lb',
//   Luxembourgish_Luxembourg='lb-LU',
//   Ganda='lg',
//   Ganda_Uganda='lg-UG',
//   Lakota='lkt',
//   Lakota_UnitedStates='lkt-US',
//   Lingala='ln',
//   Lingala_Angola='ln-AO',
//   Lingala_CongoKinshasa='ln-CD',
//   Lingala_CentralAfricanRepublic='ln-CF',
//   Lingala_CongoBrazzaville='ln-CG',
//   Lao='lo',
//   Lao_Laos='lo-LA',
//   Description='localeIdentifier',
//   Lithuanian='lt',
//   Lithuanian_Lithuania='lt-LT',
//   Luba_Katanga='lu',
//   Luba_KatangaCongoKinshasa='lu-CD',
//   Luo='luo',
//   Luo_Kenya='luo-KE',
//   Luyia='luy',
//   Luyia_Kenya='luy-KE',
//   Latvian='lv',
//   Latvian_Latvia='lv-LV',
//   Masai='mas',
//   Masai_Kenya='mas-KE',
//   Masai_Tanzania='mas-TZ',
//   Meru='mer',
//   Meru_Kenya='mer-KE',
//   Morisyen='mfe',
//   Morisyen_Mauritius='mfe-MU',
//   Malagasy='mg',
//   Malagasy_Madagascar='mg-MG',
//   Makhuwa_Meetto='mgh',
//   Makhuwa_MeettoMozambique='mgh-MZ',
//   Meta_ʼ='mgo',
//   Meta_ʼCameroon='mgo-CM',
//   Macedonian='mk',
//   Macedonian_Macedonia='mk-MK',
//   Malayalam='ml',
//   Malayalam_India='ml-IN',
//   Mongolian='mn',
//   Mongolian_Cyrillic='mn-Cyrl',
//   Mongolian_CyrillicMongolia='mn-Cyrl-MN',
//   Marathi='mr',
//   Marathi_India='mr-IN',
//   Malay='ms',
//   Malay_Arabic='ms-Arab',
//   Malay_ArabicBrunei='ms-Arab-BN',
//   Malay_ArabicMalaysia='ms-Arab-MY',
//   Malay_Latin='ms-Latn',
//   Malay_LatinBrunei='ms-Latn-BN',
//   Malay_LatinMalaysia='ms-Latn-MY',
//   Malay_LatinSingapore='ms-Latn-SG',
//   Maltese='mt',
//   Maltese_Malta='mt-MT',
//   Mundang='mua',
//   Mundang_Cameroon='mua-CM',
//   Burmese='my',
//   Burmese_MyanmarBurma='my-MM',
//   Nama='naq',
//   Nama_Namibia='naq-NA',
//   Norwegian_Bokmål='nb',
//   Norwegian_BokmålNorway='nb-NO',
//   Norwegian_BokmålSvalbardJanMayen='nb-SJ',
//   North_Ndebele='nd',
//   North_NdebeleZimbabwe='nd-ZW',
//   Nepali='ne',
//   Nepali_India='ne-IN',
//   Nepali_Nepal='ne-NP',
//   Dutch='nl',
//   Dutch_Aruba='nl-AW',
//   Dutch_Belgium='nl-BE',
//   Dutch_CaribbeanNetherlands='nl-BQ',
//   Dutch_Curaçao='nl-CW',
//   Dutch_Netherlands='nl-NL',
//   Dutch_Suriname='nl-SR',
//   Dutch_SintMaarten='nl-SX',
//   Kwasio='nmg',
//   Kwasio_Cameroon='nmg-CM',
//   Norwegian_Nynorsk='nn',
//   Norwegian_NynorskNorway='nn-NO',
//   Ngiemboon='nnh',
//   Ngiemboon_Cameroon='nnh-CM',
//   Nuer='nus',
//   Nuer_Sudan='nus-SD',
//   Nyankole='nyn',
//   Nyankole_Uganda='nyn-UG',
//   Oromo='om',
//   Oromo_Ethiopia='om-ET',
//   Oromo_Kenya='om-KE',
//   Oriya='or',
//   Oriya_India='or-IN',
//   Ossetic='os',
//   Ossetic_Georgia='os-GE',
//   Ossetic_Russia='os-RU',
//   Punjabi='pa',
//   Punjabi_Arabic='pa-Arab',
//   Punjabi_ArabicPakistan='pa-Arab-PK',
//   Punjabi_Gurmukhi='pa-Guru',
//   Punjabi_GurmukhiIndia='pa-Guru-IN',
//   Polish='pl',
//   Polish_Poland='pl-PL',
//   Pashto='ps',
//   Pashto_Afghanistan='ps-AF',
//   Portuguese='pt',
//   Portuguese_Angola='pt-AO',
//   Portuguese_Brazil='pt-BR',
//   Portuguese_CapeVerde='pt-CV',
//   Portuguese_GuineaBissau='pt-GW',
//   Portuguese_MacauSARChina='pt-MO',
//   Portuguese_Mozambique='pt-MZ',
//   Portuguese_Portugal='pt-PT',
//   Portuguese_SãoToméPríncipe='pt-ST',
//   Portuguese_TimorLeste='pt-TL',
//   Quechua='qu',
//   Quechua_Bolivia='qu-BO',
//   Quechua_Ecuador='qu-EC',
//   Quechua_Peru='qu-PE',
//   Romansh='rm',
//   Romansh_Switzerland='rm-CH',
//   Rundi='rn',
//   Rundi_Burundi='rn-BI',
//   Romanian='ro',
//   Romanian_Moldova='ro-MD',
//   Romanian_Romania='ro-RO',
//   Rombo='rof',
//   Rombo_Tanzania='rof-TZ',
//   Russian='ru',
//   Russian_Belarus='ru-BY',
//   Russian_Kyrgyzstan='ru-KG',
//   Russian_Kazakhstan='ru-KZ',
//   Russian_Moldova='ru-MD',
//   Russian_Russia='ru-RU',
//   Russian_Ukraine='ru-UA',
//   Kinyarwanda='rw',
//   Kinyarwanda_Rwanda='rw-RW',
//   Rwa='rwk',
//   Rwa_Tanzania='rwk-TZ',
//   Sakha='sah',
//   Sakha_Russia='sah-RU',
//   Samburu='saq',
//   Samburu_Kenya='saq-KE',
//   Sangu='sbp',
//   Sangu_Tanzania='sbp-TZ',
//   Northern_Sami='se',
//   Northern_SamiFinland='se-FI',
//   Northern_SamiNorway='se-NO',
//   Northern_SamiSweden='se-SE',
//   Sena='seh',
//   Sena_Mozambique='seh-MZ',
//   Koyraboro_Senni='ses',
//   Koyraboro_SenniMali='ses-ML',
//   Sango='sg',
//   Sango_CentralAfricanRepublic='sg-CF',
//   Tachelhit='shi',
//   Tachelhit_Latin='shi-Latn',
//   Tachelhit_LatinMorocco='shi-Latn-MA',
//   Tachelhit_Tifinagh='shi-Tfng',
//   Tachelhit_TifinaghMorocco='shi-Tfng-MA',
//   Sinhala='si',
//   Sinhala_SriLanka='si-LK',
//   Slovak='sk',
//   Slovak_Slovakia='sk-SK',
//   Slovenian='sl',
//   Slovenian_Slovenia='sl-SI',
//   Inari_Sami='smn',
//   Inari_SamiFinland='smn-FI',
//   Shona='sn',
//   Shona_Zimbabwe='sn-ZW',
//   Somali='so',
//   Somali_Djibouti='so-DJ',
//   Somali_Ethiopia='so-ET',
//   Somali_Kenya='so-KE',
//   Somali_Somalia='so-SO',
//   Albanian='sq',
//   Albanian_Albania='sq-AL',
//   Albanian_Macedonia='sq-MK',
//   Albanian_Kosovo='sq-XK',
//   Serbian='sr',
//   Serbian_Cyrillic='sr-Cyrl',
//   Serbian_CyrillicBosniaHerzegovina='sr-Cyrl-BA',
//   Serbian_CyrillicMontenegro='sr-Cyrl-ME',
//   Serbian_CyrillicSerbia='sr-Cyrl-RS',
//   Serbian_CyrillicKosovo='sr-Cyrl-XK',
//   Serbian_Latin='sr-Latn',
//   Serbian_LatinBosniaHerzegovina='sr-Latn-BA',
//   Serbian_LatinMontenegro='sr-Latn-ME',
//   Serbian_LatinSerbia='sr-Latn-RS',
//   Serbian_LatinKosovo='sr-Latn-XK',
//   Swedish='sv',
//   Swedish_ÅlandIslands='sv-AX',
//   Swedish_Finland='sv-FI',
//   Swedish_Sweden='sv-SE',
//   Swahili='sw',
//   Swahili_CongoKinshasa='sw-CD',
//   Swahili_Kenya='sw-KE',
//   Swahili_Tanzania='sw-TZ',
//   Swahili_Uganda='sw-UG',
//   Tamil='ta',
//   Tamil_India='ta-IN',
//   Tamil_SriLanka='ta-LK',
//   Tamil_Malaysia='ta-MY',
//   Tamil_Singapore='ta-SG',
//   Telugu='te',
//   Telugu_India='te-IN',
//   Teso='teo',
//   Teso_Kenya='teo-KE',
//   Teso_Uganda='teo-UG',
//   Tajik='tg',
//   Tajik_Cyrillic='tg-Cyrl',
//   Tajik_CyrillicTajikistan='tg-Cyrl-TJ',
//   Thai='th',
//   Thai_Thailand='th-TH',
//   Tigrinya='ti',
//   Tigrinya_Eritrea='ti-ER',
//   Tigrinya_Ethiopia='ti-ET',
//   Turkmen='tk',
//   Turkmen_Latin='tk-Latn',
//   Turkmen_LatinTurkmenistan='tk-Latn-TM',
//   Tongan='to',
//   Tongan_Tonga='to-TO',
//   Turkish='tr',
//   Turkish_Cyprus='tr-CY',
//   Turkish_Turkey='tr-TR',
//   Tasawaq='twq',
//   Tasawaq_Niger='twq-NE',
//   Central_AtlasTamazight='tzm',
//   Central_AtlasTamazightLatin='tzm-Latn',
//   Central_AtlasTamazightLatinMorocco='tzm-Latn-MA',
//   Uyghur='ug',
//   Uyghur_Arabic='ug-Arab',
//   Uyghur_ArabicChina='ug-Arab-CN',
//   Ukrainian='uk',
//   Ukrainian_Ukraine='uk-UA',
//   Urdu='ur',
//   Urdu_India='ur-IN',
//   Urdu_Pakistan='ur-PK',
//   Uzbek='uz',
//   Uzbek_Arabic='uz-Arab',
//   Uzbek_ArabicAfghanistan='uz-Arab-AF',
//   Uzbek_Cyrillic='uz-Cyrl',
//   Uzbek_CyrillicUzbekistan='uz-Cyrl-UZ',
//   Uzbek_Latin='uz-Latn',
//   Uzbek_LatinUzbekistan='uz-Latn-UZ',
//   Vai='vai',
//   Vai_Latin='vai-Latn',
//   Vai_LatinLiberia='vai-Latn-LR',
//   Vai_Vai='vai-Vaii',
//   Vai_VaiLiberia='vai-Vaii-LR',
//   Vietnamese='vi',
//   Vietnamese_Vietnam='vi-VN',
//   Vunjo='vun',
//   Vunjo_Tanzania='vun-TZ',
//   Walser='wae',
//   Walser_Switzerland='wae-CH',
//   Soga='xog',
//   Soga_Uganda='xog-UG',
//   Yangben='yav',
//   Yangben_Cameroon='yav-CM',
//   Yiddish='yi',
//   Yiddish_World='yi-001',
//   Yoruba='yo',
//   Yoruba_Benin='yo-BJ',
//   Yoruba_Nigeria='yo-NG',
//   Standard_MoroccanTamazight='zgh',
//   Standard_MoroccanTamazightMorocco='zgh-MA',
//   Chinese='zh',
//   Chinese_Simplified='zh-Hans',
//   Chinese_SimplifiedChina='zh-Hans-CN',
//   Chinese_SimplifiedHongKongSARChina='zh-Hans-HK',
//   Chinese_SimplifiedMacauSARChina='zh-Hans-MO',
//   Chinese_SimplifiedSingapore='zh-Hans-SG',
//   Chinese_Traditional='zh-Hant',
//   Chinese_TraditionalHongKongSARChina='zh-Hant-HK',
//   Chinese_TraditionalMacauSARChina='zh-Hant-MO',
//   Chinese_TraditionalTaiwan='zh-Hant-TW',
//   Zulu='zu',
//   Zulu_SouthAfrica='zu-ZA'
// }
