/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type SitesListOperationStatusItem =
  (typeof SitesListOperationStatusItem)[keyof typeof SitesListOperationStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SitesListOperationStatusItem = {
  INOPERATIVE: 'INOPERATIVE',
  IN_OPERATION: 'IN_OPERATION',
  IN_PREPARATION: 'IN_PREPARATION'
} as const
