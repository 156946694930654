/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `SCENARIO_NOT_FOUND` - 시나리오를 찾을 수 없습니다.
 * `SEQUENCE_TYPE_NOT_DELIVERY` - 배달 시퀀스가 아닙니다.
 * `ROBOT_STATUS_COVER_IS_OPEN` - 적재함이 열려있습니다.
 */
export type ScenarioUnclaimed400ExceptionCodeEnum =
  (typeof ScenarioUnclaimed400ExceptionCodeEnum)[keyof typeof ScenarioUnclaimed400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioUnclaimed400ExceptionCodeEnum = {
  SCENARIO_NOT_FOUND: 'SCENARIO_NOT_FOUND',
  SEQUENCE_TYPE_NOT_DELIVERY: 'SEQUENCE_TYPE_NOT_DELIVERY',
  ROBOT_STATUS_COVER_IS_OPEN: 'ROBOT_STATUS_COVER_IS_OPEN'
} as const
