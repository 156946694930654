/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `INOPERATIVE` - 운영 종료
 * `IN_OPERATION` - 운영 중
 * `IN_PREPARATION` - 준비 중
 */
export type OperationStatusEnum = (typeof OperationStatusEnum)[keyof typeof OperationStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationStatusEnum = {
  INOPERATIVE: 'INOPERATIVE',
  IN_OPERATION: 'IN_OPERATION',
  IN_PREPARATION: 'IN_PREPARATION'
} as const
