/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */
import { BlankEnum } from './blankEnum'
import { CalibrationBoardTypeEnum } from './calibrationBoardTypeEnum'

/**
 * 보드 타입

* `a3` - Apriltag 3x3
* `ch5` - Charuco 5x5
 */
export type RobotCalibrationSetParameterReqRequestCalibrationBoardType =
  (typeof RobotCalibrationSetParameterReqRequestCalibrationBoardType)[keyof typeof RobotCalibrationSetParameterReqRequestCalibrationBoardType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RobotCalibrationSetParameterReqRequestCalibrationBoardType = {
  ...CalibrationBoardTypeEnum,
  ...BlankEnum
} as const
