/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `AUTO_CAPTURE` - 오토 캡처
 * `CALIBRATION_END` - 캘리브레이션 종료
 * `CALIBRATION_START` - 캘리브레이션 시작
 * `PARAMETER_SET` - 파라미터 셋팅
 * `POST_PROCESS` - 후처리
 */
export type CalibrationPhaseEnum = (typeof CalibrationPhaseEnum)[keyof typeof CalibrationPhaseEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalibrationPhaseEnum = {
  AUTO_CAPTURE: 'AUTO_CAPTURE',
  CALIBRATION_END: 'CALIBRATION_END',
  CALIBRATION_START: 'CALIBRATION_START',
  PARAMETER_SET: 'PARAMETER_SET',
  POST_PROCESS: 'POST_PROCESS'
} as const
