import React, { PropsWithChildren } from 'react'
import Head from 'next/head'
import { DeployEnvironmentEnum } from '@/constants/deployEnvironment.enum'

export enum PortalTypeEnum {
  TIP_POP_UP = 'tip-pop-up',
  NON_MODAL = 'nonModal',
  MODAL = 'modal',
  TOAST = 'toast',
  FULL_PAGE_LOADING_SPINNER = 'full-page-loading-spinner'
}

const getTitle = (platformEnvironment: string | undefined) => {
  switch (platformEnvironment) {
    case DeployEnvironmentEnum.Local:
      return 'Neubie-go(Local)'
    case DeployEnvironmentEnum.Development:
      return 'Neubie-go(Dev)'
    case DeployEnvironmentEnum.Staging:
      return 'Neubie-go(Staging)'
    default:
      return 'Neubie-go'
  }
}

export const LayoutElementId = 'layout'

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <Head>
        <title>{getTitle(process.env.PLATFORM_ENV)}</title>
      </Head>
      <main id={LayoutElementId} className="h-[100dvh] w-screen">
        {children}
        <div id={PortalTypeEnum.TIP_POP_UP} className="z-tipPopup" />
        <div id={PortalTypeEnum.NON_MODAL} className="z-nonModal" />
        <div id={PortalTypeEnum.MODAL} className="z-modal" />
        <div id={PortalTypeEnum.TOAST} className="z-toast" />
        <div id={PortalTypeEnum.FULL_PAGE_LOADING_SPINNER} className="z-loading" />
      </main>
    </div>
  )
}

export default Layout
