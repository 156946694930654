/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `END` - 길 생성 종료 됨
 * `IN_PROGRESS` - 길 생성 진행 중
 * `NONE` - 길 생성 안됨
 * `PAUSED` - 길 생성 중에 일시 정지 됨
 * `PAUSED_AFTER_END` - 길 생성 종료 후에 일시 정지 됨
 */
export type LastWayStatusEnum = (typeof LastWayStatusEnum)[keyof typeof LastWayStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LastWayStatusEnum = {
  END: 'END',
  IN_PROGRESS: 'IN_PROGRESS',
  NONE: 'NONE',
  PAUSED: 'PAUSED',
  PAUSED_AFTER_END: 'PAUSED_AFTER_END'
} as const
