/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ROBOT_LIST_CONTAINS_NOT_MONITORABLE_ROBOTS` - 모니터링 할 수 없는 로봇이 포함되어 있습니다.
 * `MAX_MONITORING_ROBOT_COUNT_EXCEEDED` - 최대 모니터링 로봇 댓수를 초과했습니다.
 * `USER_MONITORING_LIST_ALREADY_EXISTS` - 사용자의 모니터링 리스트가 이미 존재합니다.
 */
export type MonitoringCreate400ExceptionCodeEnum =
  (typeof MonitoringCreate400ExceptionCodeEnum)[keyof typeof MonitoringCreate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringCreate400ExceptionCodeEnum = {
  ROBOT_LIST_CONTAINS_NOT_MONITORABLE_ROBOTS: 'ROBOT_LIST_CONTAINS_NOT_MONITORABLE_ROBOTS',
  MAX_MONITORING_ROBOT_COUNT_EXCEEDED: 'MAX_MONITORING_ROBOT_COUNT_EXCEEDED',
  USER_MONITORING_LIST_ALREADY_EXISTS: 'USER_MONITORING_LIST_ALREADY_EXISTS'
} as const
