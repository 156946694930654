import React, { ReactElement, ReactNode } from 'react'
import { init as initApm } from '@elastic/apm-rum'
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NextPage } from 'next'
import { appWithTranslation } from 'next-i18next'
import ApmTransaction from '@/components/common/ApmTransaction'
import Layout from '@/components/layouts/Layout'
import { DeployEnvironmentEnum } from '@/constants/deployEnvironment.enum'
import AuthContainer from '@/containers/common/AuthContainer'
import TimeZoneContainer from '@/containers/common/TimeZoneContainer'
import EnvUtils from '@/utils/design-system/envUtils'
import type { AppProps } from 'next/app'
import '@/styles/globals.css'

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

const apm = initApm({
  serviceName: 'platform-core-frontend',
  serverUrl: process.env.APM_URL,
  serviceVersion: process.env.APP_VERSION,
  environment: process.env.PLATFORM_ENV,
  active: process.env.APM_ENABLED === 'true'
})
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools
  }))
)

const runMockWorker = async () => {
  const { worker } = await import('@/api/mock/mockWorker')
  worker?.start?.()
}

if (!EnvUtils.isServer() && process.env.MOCK_ENABLE) {
  runMockWorker()
}

const queryCache = new QueryCache()
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      retryDelay: 300
    }
  }
})

function App({ Component, pageProps }: AppPropsWithLayout) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContainer.Provider>
        <Hydrate state={pageProps && pageProps.dehydratedState}>
          {process.env.PLATFORM_ENV !== DeployEnvironmentEnum.Production && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction position="bottom-right" />
            </React.Suspense>
          )}
          <ApmTransaction apm={apm}>
            <Layout>
              <TimeZoneContainer.Provider>
                <Component {...pageProps} />
              </TimeZoneContainer.Provider>
            </Layout>
          </ApmTransaction>
        </Hydrate>
      </AuthContainer.Provider>
    </QueryClientProvider>
  )
}

export default appWithTranslation(App)
