/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `CARGO_CLOSE` - 적재함 닫기 (동작하지 않음)
 * `CARGO_OPEN` - 적재함 열기
 * `CLEAR_GLOBAL_PATH` - 로봇의 global_path 비우기
 * `MOVE` - 로봇의 DELIVERY_STATUS를 MOVE로 바꾸기
 * `WAIT` - 로봇의 DELIVERY_STATUS를 WAITING으로 바꾸기
 */
export type ActionEnum = (typeof ActionEnum)[keyof typeof ActionEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionEnum = {
  CARGO_CLOSE: 'CARGO_CLOSE',
  CARGO_OPEN: 'CARGO_OPEN',
  CLEAR_GLOBAL_PATH: 'CLEAR_GLOBAL_PATH',
  MOVE: 'MOVE',
  WAIT: 'WAIT'
} as const
