/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `COVER` - 덮개
 * `MOVE` - 이동
 * `WAIT` - 대기
 */
export type SceneTypeEnum = (typeof SceneTypeEnum)[keyof typeof SceneTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SceneTypeEnum = {
  COVER: 'COVER',
  MOVE: 'MOVE',
  WAIT: 'WAIT'
} as const
