/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `NONE` - 업로드 비디오 없음
 * `READY` - 업로드 예정
 * `PENDING` - 업로드 대기 중
 * `START` - 업로드 중
 * `SUCCESS` - 업로드 성공
 * `FAILED` - 업로드 실패
 */
export type UploadVideoStatusEnum = (typeof UploadVideoStatusEnum)[keyof typeof UploadVideoStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadVideoStatusEnum = {
  NONE: 'NONE',
  READY: 'READY',
  PENDING: 'PENDING',
  START: 'START',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
} as const
