/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type MonitoringsIntervenesListMonitoringStatusItem =
  (typeof MonitoringsIntervenesListMonitoringStatusItem)[keyof typeof MonitoringsIntervenesListMonitoringStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitoringsIntervenesListMonitoringStatusItem = {
  COMPLETED: 'COMPLETED',
  PREOCCUPIED: 'PREOCCUPIED',
  PROCEEDING: 'PROCEEDING',
  READY: 'READY',
  WAITING: 'WAITING'
} as const
