/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type NotificationsListType = (typeof NotificationsListType)[keyof typeof NotificationsListType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationsListType = {
  ABC: 'ABC',
  ADS: 'ADS',
  MRM: 'MRM',
  ORA: 'ORA',
  OSA: 'OSA',
  SDS: 'SDS'
} as const
