/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ROBOT_IS_NOT_CONNECTED` - 로봇이 연결되어 있지 않습니다.
 * `ROBOT_SCENARIO_EXISTS` - 로봇에 시나리오가 존재합니다.
 */
export type RobotCalibrationStart400ExceptionCodeEnum =
  (typeof RobotCalibrationStart400ExceptionCodeEnum)[keyof typeof RobotCalibrationStart400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RobotCalibrationStart400ExceptionCodeEnum = {
  ROBOT_IS_NOT_CONNECTED: 'ROBOT_IS_NOT_CONNECTED',
  ROBOT_SCENARIO_EXISTS: 'ROBOT_SCENARIO_EXISTS'
} as const
