/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `ELEVATOR_HAS_ALREADY_BEEN_REGISTERED` - 엘리베이터가 이미 등록되었습니다.
 * `INVALID_ELEVATOR_NUMBER` - 잘못된 승강기고유번호입니다.
 * `INVALID_ELEVATOR_MANUFACTURER` - 잘못된 엘리베이터 제조사입니다.
 * `NOT_AVAILABLE_ELEVATOR_MANUFACTURER` - 사용할 수 없는 엘리베이터 제조사입니다.
 * `ELEVATOR_NOT_ACCESSIBLE_VIA_API` - API를 사용할 수 없는 엘리베이터입니다.
 * `MANUFACTURER_ELEVATOR_LINE_DATA_NOT_FOUND` - 엘리베이터 제조사의 엘리베이터 라인 데이터를 찾을 수 없습니다.
 * `MANUFACTURER_ELEVATOR_DATA_NOT_FOUND` - 엘리베이터 제조사의 엘리베이터 데이터를 찾을 수 없습니다.
 */
export type ElevatorCreate404ExceptionCodeEnum =
  (typeof ElevatorCreate404ExceptionCodeEnum)[keyof typeof ElevatorCreate404ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElevatorCreate404ExceptionCodeEnum = {
  ELEVATOR_HAS_ALREADY_BEEN_REGISTERED: 'ELEVATOR_HAS_ALREADY_BEEN_REGISTERED',
  INVALID_ELEVATOR_NUMBER: 'INVALID_ELEVATOR_NUMBER',
  INVALID_ELEVATOR_MANUFACTURER: 'INVALID_ELEVATOR_MANUFACTURER',
  NOT_AVAILABLE_ELEVATOR_MANUFACTURER: 'NOT_AVAILABLE_ELEVATOR_MANUFACTURER',
  ELEVATOR_NOT_ACCESSIBLE_VIA_API: 'ELEVATOR_NOT_ACCESSIBLE_VIA_API',
  MANUFACTURER_ELEVATOR_LINE_DATA_NOT_FOUND: 'MANUFACTURER_ELEVATOR_LINE_DATA_NOT_FOUND',
  MANUFACTURER_ELEVATOR_DATA_NOT_FOUND: 'MANUFACTURER_ELEVATOR_DATA_NOT_FOUND'
} as const
