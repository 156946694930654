/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `Success` - Success
 * `PartialUpdateFailure` - PartialUpdateFailure
 */
export type CommonErrorDetailResRequestResult =
  (typeof CommonErrorDetailResRequestResult)[keyof typeof CommonErrorDetailResRequestResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommonErrorDetailResRequestResult = {
  Success: 'Success',
  PartialUpdateFailure: 'PartialUpdateFailure'
} as const
