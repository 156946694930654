/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `NODE_NUMBER_ALREADY_EXISTS` - 이미 있는 노드 번호예요
 */
export type NodeCreate400ExceptionCodeEnum =
  (typeof NodeCreate400ExceptionCodeEnum)[keyof typeof NodeCreate400ExceptionCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NodeCreate400ExceptionCodeEnum = {
  NODE_NUMBER_ALREADY_EXISTS: 'NODE_NUMBER_ALREADY_EXISTS'
} as const
