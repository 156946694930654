/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `FAILURE` - 실패
 * `PENDING` - 대기
 * `RETRY` - 재시도
 * `STARTED` - 시작
 * `SUCCESS` - 성공
 */
export type StatusEnum = (typeof StatusEnum)[keyof typeof StatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEnum = {
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
  RETRY: 'RETRY',
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS'
} as const
